import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from 'semantic-ui-react';

import { CheckboxField, InputField } from '../shared/components';

import { GeoSearchDropdown } from './GeoSearchDropdown';

import {
  getAvailableCities as getAvailableCitiesAction,
  performSearch as performSearchAction,
  setFilterValue as setFilterValueAction,
  setActiveFilter as setActiveFilterAction,
} from '../../../../store/filters/filters.actions';

import { filtersSelector } from '../../../../store/filters/filters.selector';

import { GENERAL_INFORMATION_MENU_ITEMS } from './generalInformation.constants';

const {
  LABORATORY_NAME, HOLDING_NAME, PARENT_GROUP, ADDRESS, WEBPAGE, STATUS, TYPE, EMAIL, CITY, PHONE,
} = GENERAL_INFORMATION_MENU_ITEMS;

export const GeneralInformationComponent = ({
  fields,
  handleFieldChange,
  selectedCountries,
  getAvailableCities,
  areAvailableCitiesLoading,
  availableCities,
}) => {
  function getCitiesList({ countries, city }) {
    return getAvailableCities({
      countries,
      city,
    });
  }
  return (
    <>
      <div style={{ padding: '10px' }}>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <InputField
                label={LABORATORY_NAME.label}
                placeholder={LABORATORY_NAME.placeholder}
                name={LABORATORY_NAME.name}
                onChange={handleFieldChange}
                value={fields[LABORATORY_NAME.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                label={HOLDING_NAME.label}
                placeholder={HOLDING_NAME.placeholder}
                name={HOLDING_NAME.name}
                onChange={handleFieldChange}
                value={fields[HOLDING_NAME.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                label={PARENT_GROUP.label}
                placeholder={PARENT_GROUP.placeholder}
                name={PARENT_GROUP.name}
                onChange={handleFieldChange}
                value={fields[PARENT_GROUP.name] || ''}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <InputField
                label={ADDRESS.label}
                placeholder={ADDRESS.placeholder}
                name={ADDRESS.name}
                onChange={handleFieldChange}
                value={fields[ADDRESS.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                label={TYPE.label}
                placeholder={TYPE.placeholder}
                name={TYPE.name}
                onChange={handleFieldChange}
                value={fields[TYPE.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                label={STATUS.label}
                placeholder={STATUS.placeholder}
                name={STATUS.name}
                onChange={handleFieldChange}
                value={fields[STATUS.name] || ''}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div style={{ padding: '10px' }}>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <InputField
                label={PHONE.label}
                placeholder={PHONE.placeholder}
                name={PHONE.name}
                onChange={handleFieldChange}
                value={fields[PHONE.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <CheckboxField
                onChange={handleFieldChange}
                label={WEBPAGE.label}
                name={WEBPAGE.name}
                checked={!!fields[WEBPAGE.name]}
                className="exception-email"
              />
            </Grid.Column>
            <Grid.Column>
              <CheckboxField
                onChange={handleFieldChange}
                label={EMAIL.label}
                name={EMAIL.name}
                checked={!!fields[EMAIL.name]}
                className="exception-email"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="geo-information-wrapper">
        <div className="geo-information-city">
          <GeoSearchDropdown
            label={CITY.label}
            name={CITY.name}
            onChange={handleFieldChange}
            value={fields[CITY.name]}
            placeholder="Start typing to add a city"
            isLoading={areAvailableCitiesLoading}
            getAvailableItems={getCitiesList}
            availableItems={availableCities}
            selectedCountries={selectedCountries}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeFilter: filtersSelector.activeFilter(state),
  filterValues: filtersSelector.filterValues(state),
  filteringOptions: filtersSelector.filteringOptions(state),
  areAvailableCitiesLoading: filtersSelector.areAvailableCitiesLoading(state),
  availableCities: filtersSelector.availableCities(state),
  selectedCountries: filtersSelector.selectedCountries(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterValue: setFilterValueAction,
  setActiveFilter: setActiveFilterAction,
  performSearch: performSearchAction,
  getAvailableCities: getAvailableCitiesAction,
}, dispatch);

export const GeneralInformation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInformationComponent);
