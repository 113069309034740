import { CLEAR_STORE } from '../../../../core/user/user.actions';
import {
  GET_CLIENT_INFO_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  SET_LOADING_STATE,
} from './clientPage.actions';

export const initialState = {
  isLoading: false,
  clientInfo: null,
  isUsersListLoading: false,
  usersList: [],
};

export function clientPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_STATE: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case GET_CLIENT_INFO_SUCCESS: {
      const { clientInfo } = action.payload;
      return {
        ...state,
        isLoading: false,
        clientInfo,
      };
    }
    case GET_USERS_REQUEST: {
      return {
        ...state,
        isUsersListLoading: true,
      };
    }
    case GET_USERS_FAILURE: {
      return {
        ...state,
        isUsersListLoading: false,
      };
    }
    case GET_USERS_SUCCESS: {
      const { usersList } = action.payload;
      return {
        ...state,
        isUsersListLoading: false,
        usersList,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
