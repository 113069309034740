import {
  CLEAR_STORE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_AUTH_FAILURE,
  VERIFY_AUTH_REQUEST,
  VERIFY_AUTH_SUCCESS,
  GET_SAVED_SEARCHES_REQUEST,
  GET_SAVED_SEARCHES_SUCCESS,
  GET_SAVED_SEARCHES_FAILURE,
  SAVE_SEARCH_REQUEST,
  SAVE_SEARCH_FAILURE,
  DELETE_SEARCH_REQUEST,
  DELETE_SEARCH_FAILURE,
} from './user.actions';

const initialState = {
  userData: null,
  isLoggingIn: false,
  isVerifying: false,
  savedSearches: [],
  areSavedSearchesLoading: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        isLoggingIn: true,
      };
    }
    case LOGIN_SUCCESS: {
      const { userData } = action.payload;
      if (['gc@globalhealthintelligence.com'].indexOf(userData.email) > -1) {
        userData.role = 'admin';
      } else if (['omar@globalhealthintelligence.com'].indexOf(userData.email) > -1) {
        userData.role = 'manager';
      }
      return {
        ...state,
        isLoggingIn: false,
        userData,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoggingIn: false,
      };
    }
    case VERIFY_AUTH_REQUEST: {
      return {
        ...state,
        isVerifying: true,
      };
    }
    case VERIFY_AUTH_SUCCESS: {
      return {
        ...state,
        isVerifying: false,
      };
    }
    case VERIFY_AUTH_FAILURE: {
      return {
        ...state,
        isVerifying: false,
      };
    }
    case GET_SAVED_SEARCHES_REQUEST:
    case SAVE_SEARCH_REQUEST:
    case DELETE_SEARCH_REQUEST: {
      return {
        ...state,
        areSavedSearchesLoading: true,
      };
    }
    case GET_SAVED_SEARCHES_SUCCESS: {
      const { savedSearches } = action.payload;
      return {
        ...state,
        areSavedSearchesLoading: false,
        savedSearches,
      };
    }
    case GET_SAVED_SEARCHES_FAILURE:
    case SAVE_SEARCH_FAILURE:
    case DELETE_SEARCH_FAILURE: {
      return {
        ...state,
        areSavedSearchesLoading: false,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
};
