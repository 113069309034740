import React from 'react';
import { Table } from 'semantic-ui-react';

import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';
import { FILTERS } from '../../../constants/filters.constants';
import {
  GENERAL_INFORMATION_TABLE_FIELDS,
  BEDS_TABLE_FIELDS,
  INFRASTRUCTURE_TABLE_FIELDS,
  LABORATORY_TABLE_FIELDS,
  // MEDICAL_OFFICES_TABLE_FIELDS,
  EQUIPMENT_TABLE_FIELDS,
  SPECIALITIES_TABLE_FIELDS,
  TECHNOLOGY_TABLE_FIELDS,
  // HR_TABLE_FIELDS,
  KEY_PERSONNEL_TABLE_FIELDS,
} from '../tableFields.constants';
import { getSortingDirectionForArrow } from '../../../../../core/utils/core.utils';

const { ASC, DESC } = SORTING_DIRECTIONS;

export const TABLE_HEADER_CELLS = {
  [FILTERS.GENERAL_INFORMATION]: GENERAL_INFORMATION_TABLE_FIELDS,
  [FILTERS.BEDS]: BEDS_TABLE_FIELDS,
  [FILTERS.INFRASTRUCTURE]: INFRASTRUCTURE_TABLE_FIELDS,
  [FILTERS.LABORATORY]: LABORATORY_TABLE_FIELDS,
  // [FILTERS.MEDICAL_OFFICES]: MEDICAL_OFFICES_TABLE_FIELDS,
  [FILTERS.EQUIPMENT]: EQUIPMENT_TABLE_FIELDS,
  [FILTERS.SPECIALTIES]: SPECIALITIES_TABLE_FIELDS,
  [FILTERS.TECHNOLOGY]: TECHNOLOGY_TABLE_FIELDS,
  // [FILTERS.HR]: HR_TABLE_FIELDS,
  [FILTERS.KEY_PERSONNEL]: KEY_PERSONNEL_TABLE_FIELDS,
};

export const HospitalsTableHeaderComponent = ({
  filterName,
  sortedBy,
  sortingDirection,
  getHospitalsList,
}) => {
  return (
    <Table.Header>
      <Table.Row>
        {TABLE_HEADER_CELLS[filterName].map((cell) => {
          return (
            <Table.HeaderCell
              key={cell.label}
              sorted={getSortingDirectionForArrow({ key: cell.key, sortedBy, sortingDirection })}
              onClick={() => getHospitalsList({
                currentPage: 1,
                sortedBy: cell.key,
                sortingDirection: sortingDirection === ASC ? DESC : ASC,
              })}
            >
              {cell.label}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export const HospitalsTableHeader = HospitalsTableHeaderComponent;
