import { getHospitalsState } from '../hospitals.selector';

function getHospitalsDataState(state) {
  return getHospitalsState(state).hospitalsData;
}

export const hospitalsDataSelector = {
  isLoading(state) {
    return getHospitalsDataState(state).isLoading;
  },
  hospitalsList(state) {
    return getHospitalsDataState(state).hospitalsList;
  },
  predictedHospitalsList(state) {
    return getHospitalsDataState(state).predictedHospitalsList;
  },
  hospitalsCalculatedData(state) {
    return getHospitalsDataState(state).hospitalsCalculatedData;
  },
  hospitalsPredictedCalculatedData(state) {
    return getHospitalsDataState(state).hospitalsPredictedCalculatedData;
  },
  selectedHospital(state, selectedHospitalId) {
    return hospitalsDataSelector.hospitalsList(state).find((hospital) => hospital.id === selectedHospitalId);
  },
  pagination(state) {
    return getHospitalsDataState(state).pagination;
  },
  sortedBy(state) {
    return getHospitalsDataState(state).sortedBy;
  },
  sortingDirection(state) {
    return getHospitalsDataState(state).sortingDirection;
  },
};
