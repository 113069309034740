import React from 'react';

import { CalculatedDataTable } from './CalculatedDataTable';

const HospitalsCalculatedDataComponent = ({
  shouldShowCalculatedData,
  hospitalsCalculatedData,
  hospitalsPredictedCalculatedData,
  activeTabName,
}) => {
  return (
    hospitalsCalculatedData ? (
      <div
        className="hospitals-table-calculated-wrapper"
        style={{ display: shouldShowCalculatedData ? 'block' : 'none' }}
      >
        <CalculatedDataTable
          hospitalsCalculatedData={hospitalsCalculatedData}
          hospitalsPredictedCalculatedData={hospitalsPredictedCalculatedData}
          activeTabName={activeTabName}
        />
      </div>
    ) : null
  );
};

export const HospitalsCalculatedData = HospitalsCalculatedDataComponent;
