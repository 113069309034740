import { toast } from 'react-toastify';

import { AdminServiceApiProvider } from '../../services/AdminServiceApiProvider';

import { navigateToPage } from '../../../../core/routing/history';

import { TOAST_CONSTANTS } from '../../constants/admin.constants';
import { USER_PAGE_CONTEXT_TYPES } from '../../../../core/constants/core.constants';

export const GET_USER_INFO_REQUEST = '[USERS PAGE] GET USER INFO REQUEST';
export const GET_USER_INFO_SUCCESS = '[USERS PAGE] GET USER INFO SUCCESS';
export const GET_USER_INFO_FAILURE = '[USERS PAGE] GET USER INFO FAILURE';

export const SET_LOADING_STATE = '[USERS PAGE] SET_LOADING_STATE';

const TOAST_DELAY = 500;

export function setIsLoading(isLoading) {
  return (dispatch) => {
    return dispatch({
      type: SET_LOADING_STATE,
      payload: {
        isLoading,
      },
    });
  };
}

export function getUserInfo({ id, userPageContext }) {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_INFO_REQUEST,
    });
    try {
      const getInfoRequest = userPageContext === USER_PAGE_CONTEXT_TYPES.ADMIN
        ? AdminServiceApiProvider.getAdminInfo : AdminServiceApiProvider.getUserInfo;
      const { data } = await getInfoRequest({ id });
      return dispatch({
        type: GET_USER_INFO_SUCCESS,
        payload: {
          userInfo: data,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_USER_INFO_FAILURE,
        payload: error,
      });
    }
  };
}

export function toggleUserStatus({ id, isActive, userPageContext }) {
  const isEditingAdmin = userPageContext === USER_PAGE_CONTEXT_TYPES.ADMIN;
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      const toggleStatusRequest = isEditingAdmin
        ? AdminServiceApiProvider.toggleAdminStatus : AdminServiceApiProvider.toggleUserStatus;
      await toggleStatusRequest({ id, isActive });
      return getUserInfo({ id, userPageContext })(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function editUser({ id, userInfo, userPageContext }) {
  const isEditingAdmin = userPageContext === USER_PAGE_CONTEXT_TYPES.ADMIN;
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      const editRequest = isEditingAdmin ? AdminServiceApiProvider.editAdmin : AdminServiceApiProvider.editUser;
      await editRequest({ id, userInfo });
      setIsLoading(false)(dispatch);
      toast.success(isEditingAdmin ? TOAST_CONSTANTS.EDIT_ADMIN : TOAST_CONSTANTS.EDIT_USER, { delay: TOAST_DELAY });
      return getUserInfo({ id, userPageContext })(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function removeUser({ id, userPageContext }) {
  const isEditingAdmin = userPageContext === USER_PAGE_CONTEXT_TYPES.ADMIN;
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      const removeRequest = isEditingAdmin ? AdminServiceApiProvider.removeAdmin : AdminServiceApiProvider.removeUser;
      const { data } = await removeRequest({ id });
      setIsLoading(false)(dispatch);
      navigateToPage(isEditingAdmin ? '/admin/admins' : `/admin/clients/${data.clientId}`);
      return toast.success(isEditingAdmin
        ? TOAST_CONSTANTS.REMOVE_ADMIN : TOAST_CONSTANTS.REMOVE_USER, { delay: TOAST_DELAY });
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}
