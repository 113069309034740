import React, { useState, useRef } from 'react';
import {
  Dimmer, Loader, Modal,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactExport from 'react-data-export';

import { ServiceApiProvider } from '../../services/ServiceApiProvider';

import {
  renderModalActionButton,
  renderModalCancelButton,
} from '../../../hospiscope/components/modals/utils/modals.utils';
import { getMaxDownloadLimit } from '../../../hospiscope/components/HospitalsData/hospitalsData.utils';
import {
  prepareMainViewRowsForExcel,
  prepareFiltersInfoForExport,
} from '../../utils/pricescope.utils';

import ExcelDownloadImage from '../../../../assets/excel.png';
import ExcelDownloadImage2x from '../../../../assets/excel@2x.png';
import ExcelDownloadImage3x from '../../../../assets/excel@3x.png';

const DESCRIPTION_TEXT = 'Download results in an .xls file?';
const LOADING_MESSAGE = 'Preparing data...';
const DOWNLOAD_BUTTON_TEXT = 'Download report';
const CANCEL_BUTTON_TEXT = 'Cancel';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;

export const DownloadModal = ({
  role,
  filterValues,
  count,
  open,
  onClose,
  onCancel,
}) => {
  const [excelData, setExcelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const downloadButton = useRef(null);

  const EXCEL_DOWNLOAD_LIMIT = getMaxDownloadLimit(role);
  const OVER_LIMIT_MESSAGE = `To download decrease results to be less than ${EXCEL_DOWNLOAD_LIMIT}`;

  async function loadMainViewData() {
    const { data } = await ServiceApiProvider.getMainViewData({
      filterValues,
      paginationOptions: {
        limit: EXCEL_DOWNLOAD_LIMIT,
      },
      sortingOptions: {
        sortedBy: 'id',
        sortingDirection: 'ASC',
      },
    });
    return data;
  }

  async function onDownloadClick() {
    if (count > EXCEL_DOWNLOAD_LIMIT) {
      return toast.warn(OVER_LIMIT_MESSAGE);
    }

    setIsLoading(true);
    const request = loadMainViewData;
    const data = await request();
    setExcelData(data.tenders);
    setIsLoading(false);

    downloadButton.current.click();
    return onCancel();
  }

  const EXCEL_FILENAME = 'PriceScope - Tender List';
  const EXCEL_SHEET_NAME = 'Tender List';

  return (
    <Modal className="download-modal" open={open} onClose={onClose} size="mini">
      <Dimmer active={isLoading}>
        <Loader>{LOADING_MESSAGE}</Loader>
      </Dimmer>
      <Modal.Content>
        <Modal.Description>
          <div className="download-modal-content">
            <div className="download-modal-image">
              <img alt="" src={ExcelDownloadImage} srcSet={`${ExcelDownloadImage2x} 2x, ${ExcelDownloadImage3x} 3x`} />
            </div>
            <p className="download-modal-text">{DESCRIPTION_TEXT}</p>
          </div>
        </Modal.Description>
        <ExcelFile
          filename={EXCEL_FILENAME}
          element={<div ref={downloadButton} style={{ display: 'none' }} />}
        >
          <ExcelSheet
            dataSet={prepareFiltersInfoForExport(filterValues)}
            name="Filter data"
          />
          <ExcelSheet
            dataSet={prepareMainViewRowsForExcel(excelData)}
            name={EXCEL_SHEET_NAME}
          />
        </ExcelFile>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: onDownloadClick, text: DOWNLOAD_BUTTON_TEXT })}
        {renderModalCancelButton({ clickHandler: onCancel, text: CANCEL_BUTTON_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
