import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';

import { DownloadModal } from '../modals/DownloadModal';

import { performSearch as performSearchAction } from '../../store/filters/filters.actions';

import { userSelector } from '../../../../core/user/user.selector';
import { filtersSelector } from '../../store/filters/filters.selector';
import { mainViewCfg } from '../../config';
import { formatNumber } from '../../../../core/utils/core.utils';

const RESULTS = 'Results';
const DOWNLOAD_BUTTON_TEXT = 'Download';
const CLEAR_ALL_FILTERS_BUTTON_TEXT = 'Clear All Filters';

export const MainViewDataHeaderComponent = ({
  items = [],
  count,
  filterValues,
  userData,
  performSearch,
}) => {
  const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState(false);

  function renderResultsMessage() {
    return count > items.length ? `${formatNumber(count)} found`
      : `${formatNumber(items.length) || 0} found`;
  }

  function clearAll() {
    return performSearch({ filtersToClear: mainViewCfg.filtersToClearOnClearAll });
  }

  return (
    <>
      <div className="proceduresDataHeader">
        <div className="proceduresDataHeader-text">
          <div className="proceduresDataHeader-title">{RESULTS}</div>
          <div className="proceduresDataHeader-count">
            {renderResultsMessage()}
          </div>
        </div>
        <div className="proceduresDataHeader-actions">
          <div className="proceduresDataHeader-action" onClick={() => setShouldShowDownloadModal(true)}>
            <div className="proceduresDataHeader-action-text">
              {DOWNLOAD_BUTTON_TEXT}
            </div>
            <Icon name="download" size="small" />
          </div>
          <div className="proceduresDataHeader-action" onClick={clearAll}>
            <div className="proceduresDataHeader-clear">
              {CLEAR_ALL_FILTERS_BUTTON_TEXT}
            </div>
          </div>
        </div>
      </div>
      <DownloadModal
        role={userData.role}
        filterValues={filterValues}
        open={shouldShowDownloadModal}
        count={count}
        onCancel={() => setShouldShowDownloadModal(false)}
        onClose={() => setShouldShowDownloadModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  filterValues: filtersSelector.filterValues(state),
  userData: userSelector.userData(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  performSearch: performSearchAction,
}, dispatch);

export const MainViewDataHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainViewDataHeaderComponent);
