import { API_BASE_URL } from '../../../core/services/CoreServiceConfigProvider';

const PRICESCOPE = '/pricescope';
const COUNTRIES_LIST = '/countries';
const FILTERING_OPTIONS = '/filterMenuOptions';
const FILTERING_OPTIONS_DYNAMIC = '/filterMenuOptionsDynamic';
const TENDERITEMS = '/tenders';

export class ServiceConfigProvider {
  static getCountriesListEndpoint() {
    return `${API_BASE_URL}${PRICESCOPE}${COUNTRIES_LIST}`;
  }

  static getFilteringOptionsEndpoint() {
    return `${API_BASE_URL}${PRICESCOPE}${FILTERING_OPTIONS}`;
  }

  static getFilteringOptionsDynamicEndpoint() {
    return `${API_BASE_URL}${PRICESCOPE}${FILTERING_OPTIONS_DYNAMIC}`;
  }

  static getMainViewDataEndpoint() {
    return `${API_BASE_URL}${PRICESCOPE}${TENDERITEMS}`;
  }
}
