import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { SearchMenu } from './SearchMenu/SearchMenu';
import { Filters } from './Filters/Filters';
import { ProceduresData } from './ProceduresData/ProceduresData';
import { InstitutionsData } from './InstitutionsData/InstitutionsData';

import { getAvailableCountries as getAvailableCountriesAction } from '../../common/store/filters/filters.actions';
import { getFilteringOptions as getFilteringOptionsAction } from '../store/filters/filters.actions';

import { filtersSelector } from '../store/filters/filters.selector';
import { proceduresDataSelector } from '../store/proceduresData/proceduresData.selector';

import { MAIN_APP_CONTAINERS, PROCEDURES_TABLE_VIEWS } from '../../../core/constants/core.constants';


const ProceduresPageComponent = ({
  tableView,
  areAvailableCountriesLoading,
  areFilteringOptionsLoading,
  availableCountries,
  selectedCountry,
  selectedYear,
  getAvailableCountries,
  getFilteringOptions,
}) => {
  useEffect(() => {
    getAvailableCountries(MAIN_APP_CONTAINERS.SURGISCOPE);
    getFilteringOptions();
  }, [getAvailableCountries, getFilteringOptions]);

  return (
    <>
      <Dimmer active={areAvailableCountriesLoading || areFilteringOptionsLoading}>
        <Loader />
      </Dimmer>
      {areAvailableCountriesLoading || areFilteringOptionsLoading ? null : (
        <>
          <div className="proceduresPage-header">
            <div className="container main">
              <SearchMenu availableCountries={availableCountries} />
              <Filters />
            </div>
          </div>
          <div className="proceduresPage-body">
            {!selectedCountry || !selectedYear ? <Message content="Please select a year and country to query." icon="arrow up" />
              : (tableView === PROCEDURES_TABLE_VIEWS.PROCEDURES ? <ProceduresData /> : <InstitutionsData />)}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  areAvailableCountriesLoading: filtersSelector.areAvailableCountriesLoading(state),
  availableCountries: filtersSelector.availableCountries(state),
  selectedCountry: filtersSelector.selectedCountry(state),
  selectedYear: filtersSelector.selectedYear(state),
  areFilteringOptionsLoading: filtersSelector.areFilteringOptionsLoading(state),
  tableView: proceduresDataSelector.tableView(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAvailableCountries: getAvailableCountriesAction,
  getFilteringOptions: getFilteringOptionsAction,
}, dispatch);

export const ProceduresPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProceduresPageComponent);
