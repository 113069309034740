import { combineReducers } from 'redux';

import { hospitalsReducer } from '../containers/hospiscope/store/hospitals.reducer';
import { proceduresReducer } from '../containers/surgiscope/store/procedures.reducer';
import { containerName as PricescopeName } from '../containers/pricescope/container.name';
import { containerReducer as pricescopeReducer } from '../containers/pricescope/store/pricescope.reducer';
import { laboratoriesReducer } from '../containers/labscope/store/laboratories.reducer';

import { adminReducer } from '../containers/admin/store/admin.reducer';

import { userReducer } from './user/user.reducer';

export const rootReducer = combineReducers({
  hospitals: hospitalsReducer,
  procedures: proceduresReducer,
  // [pricescope.name]: pricescope.reducer,
  [PricescopeName]: pricescopeReducer,
  laboratories: laboratoriesReducer,
  admin: adminReducer,
  user: userReducer,
});
