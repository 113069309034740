import { toast } from 'react-toastify';
import { TOAST_CONSTANTS } from '../../constants/admin.constants';

import { AdminServiceApiProvider } from '../../services/AdminServiceApiProvider';
import { navigateToPage } from '../../../../core/routing/history';

export const GET_CLIENT_INFO_SUCCESS = '[CLIENT PAGE] GET CLIENT INFO SUCCESS';

export const GET_USERS_REQUEST = '[CLIENT PAGE] GET USERS REQUEST';
export const GET_USERS_SUCCESS = '[CLIENT PAGE] GET USERS SUCCESS';
export const GET_USERS_FAILURE = '[CLIENT PAGE] GET USERS FAILURE';

export const SET_LOADING_STATE = '[CLIENT PAGE] SET_LOADING_STATE';

const TOAST_DELAY = 500;

export function setIsLoading(isLoading) {
  return (dispatch) => {
    return dispatch({
      type: SET_LOADING_STATE,
      payload: {
        isLoading,
      },
    });
  };
}

export function getClientInfo({ id }) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      const { data } = await AdminServiceApiProvider.getClientInfo({ id });
      return dispatch({
        type: GET_CLIENT_INFO_SUCCESS,
        payload: {
          clientInfo: data,
        },
      });
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function setClientCountries({
  id, countries, surgiscopeCountries, labscopeCountries, pricescopeCountries,
}) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      await AdminServiceApiProvider.setClientCountries({
        id, countries, surgiscopeCountries, labscopeCountries, pricescopeCountries,
      });
      return getClientInfo({ id })(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function getUsersList({ clientId }) {
  return async (dispatch) => {
    dispatch({
      type: GET_USERS_REQUEST,
    });
    try {
      const { data } = await AdminServiceApiProvider.getClientUsersList({ clientId });
      return dispatch({
        type: GET_USERS_SUCCESS,
        payload: {
          usersList: data,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_USERS_FAILURE,
        payload: error,
      });
    }
  };
}

export function toggleClientStatus({ id, isActive }) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      await AdminServiceApiProvider.toggleClientStatus({ id, isActive });
      return getClientInfo({ id })(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function addUser({ user, clientId }) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      await AdminServiceApiProvider.addUser({ user, clientId });
      setIsLoading(false)(dispatch);
      return getUsersList({ clientId })(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function removeClient({ id }) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      await AdminServiceApiProvider.removeClient({ id });
      setIsLoading(false)(dispatch);
      navigateToPage('/admin/clients');
      return toast.success(TOAST_CONSTANTS.REMOVE_CLIENT);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}

export function editClient({ id, clientInfo }) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      await AdminServiceApiProvider.editClient({ id, clientInfo });
      setIsLoading(false)(dispatch);
      toast.success(TOAST_CONSTANTS.EDIT_CLIENT, { delay: TOAST_DELAY });
      return getClientInfo({ id })(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}
