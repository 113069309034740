import { splitArrayIntoChunks } from '../../../../../core/utils/core.utils';

import { BEDS_MENU_ITEMS, GENERATED_BEDS_MENU_ITEMS } from '../../Filters/FilterItems/Beds/beds.constants';
import {
  EQUIPMENT_INPUTS_LIST,
  // HR_INPUTS_LIST,
  KEY_PERSONNEL_INPUTS_LIST,
  // MEDICAL_OFFICES_INPUTS_LIST,
  SPECIALTIES_CHECKBOXES_LIST,
} from '../../Filters/constants';
import {
  TECHNOLOGY_MENU_ITEMS,
  GENERATED_TECHNOLOGY_MENU_ITEMS,
} from '../../Filters/FilterItems/Technology/technology.constants';
import { LABORATORY_FIELDS_LIST } from '../../Filters/FilterItems/Laboratory/laboratory.constants';
import {
  GENERATED_INFRASTRUCTURE_MENU_ITEMS,
  INFRASTRUCTURE_MENU_ITEMS,
} from '../../Filters/FilterItems/Infrastructure/infrastructure.constants';

import { FILTERS } from '../../../constants/filters.constants';
import { MODAL_GENERAL_INFORMATION_TABLE_FIELDS } from '../constants/modalTableFields.constants';

const transformIntoFields = (items) => {
  return items.map((e) => {
    return Array.isArray(e) ? e.map((el) => ({ label: el.label, key: el.name })) : { label: e.label, key: e.name };
  }).flat();
};

export const getModalTableFields = (name, chunkSize) => {
  switch (name) {
    case FILTERS.GENERAL_INFORMATION:
      return transformIntoFields(Object.values(MODAL_GENERAL_INFORMATION_TABLE_FIELDS));
    case FILTERS.BEDS:
      return transformIntoFields([
        ...Object.values(BEDS_MENU_ITEMS),
        ...Object.values(GENERATED_BEDS_MENU_ITEMS),
      ]);
    case FILTERS.INFRASTRUCTURE:
      return splitArrayIntoChunks(transformIntoFields([
        ...Object.values(INFRASTRUCTURE_MENU_ITEMS),
        ...Object.values(GENERATED_INFRASTRUCTURE_MENU_ITEMS),
      ]), chunkSize || 3);
    case FILTERS.EQUIPMENT:
      return splitArrayIntoChunks(transformIntoFields(EQUIPMENT_INPUTS_LIST), chunkSize || 3);
    case FILTERS.SPECIALTIES:
      return splitArrayIntoChunks(transformIntoFields(SPECIALTIES_CHECKBOXES_LIST), chunkSize || 3);
    // case FILTERS.MEDICAL_OFFICES:
    //   return transformIntoFields(MEDICAL_OFFICES_INPUTS_LIST);
    case FILTERS.TECHNOLOGY:
      return transformIntoFields([
        ...Object.values(TECHNOLOGY_MENU_ITEMS),
        ...Object.values(GENERATED_TECHNOLOGY_MENU_ITEMS),
      ]);
    case FILTERS.LABORATORY:
      return transformIntoFields(Object.values(LABORATORY_FIELDS_LIST));
    // case FILTERS.HR:
    //   return transformIntoFields(HR_INPUTS_LIST);
    case FILTERS.KEY_PERSONNEL:
      return transformIntoFields(KEY_PERSONNEL_INPUTS_LIST);
    default:
      return null;
  }
};
