import React from 'react';
import { Form } from 'semantic-ui-react';

export const InputField = ({
  label, name, placeholder, onChange, value, className = '',
}) => {
  return (
    <div className={`input-field-container input-group-container ${className}`}>
      <Form.Field className="input-field-inner">
        <Form.Input
          placeholder={placeholder}
          label={label}
          name={name}
          onChange={({ target }) => onChange({ name: target.name, value: target.value })}
          value={value || ''}
          className={className}
        />
      </Form.Field>
    </div>
  );
};
