import React, { memo } from 'react';
import { Form } from 'semantic-ui-react';

export const SearchOptionComponent = ({
  label,
  name,
  checked,
  onChange,
  className = '',
}) => {
  return (
    <div className={`checkbox-field-container input-group-container ${className}`}>
      <Form.Field className="checkbox-field-inner">
        <Form.Checkbox
          onChange={onChange}
          label={label}
          name={name}
          checked={checked}
        />
      </Form.Field>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => prevProps.checked === nextProps.checked;

export const SearchOption = memo(SearchOptionComponent, areEqual);
