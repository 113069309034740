import { API_BASE_URL } from '../../../core/services/CoreServiceConfigProvider';

const HOSPISCOPE = '/hospiscope';
const COUNTRIES_LIST_ENDPOINT = '/countries';
const FILTERING_OPTIONS_ENDPOINT = '/filteringOptions';
const CITIES_BY_COUNTRIES_LIST_ENDPOINT = '/cities';
const STATES_BY_COUNTRIES_LIST_ENDPOINT = '/states';
const CALCULATED_DATA = '/hospitalsCalculatedData';
const HOSPITALS = '/hospitals';

export class HospitalsServiceConfigProvider {
  static getCountriesListEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${COUNTRIES_LIST_ENDPOINT}`;
  }

  static getFilteringOptionsEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${FILTERING_OPTIONS_ENDPOINT}`;
  }

  static getStatesByCountriesAndCityNameEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${STATES_BY_COUNTRIES_LIST_ENDPOINT}`;
  }

  static getCitiesByCountriesAndCityNameEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${CITIES_BY_COUNTRIES_LIST_ENDPOINT}`;
  }

  static getHospitalsCalculatedDataEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${CALCULATED_DATA}`;
  }

  static getHospitalsListEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${HOSPITALS}`;
  }
}
