import React from 'react';
import { stringToHslColor } from '../../utils/core.utils';

const NO_NAME_STRING = 'No name';

export const UserAvatar = ({
  name = '',
  width = 24,
  height = 24,
  className,
}) => {
  const getUserInitials = (username) => {
    return username
      .split(' ')
      .map((e) => e.charAt(0))
      .slice(0, 2)
      .join('')
      .toUpperCase();
  };
  return (
    <div
      className={`user-avatar ${className || ''}`}
      style={{ width, height, backgroundColor: stringToHslColor(name || NO_NAME_STRING) }}
    >
      <span>{getUserInitials(name || NO_NAME_STRING)}</span>
    </div>
  );
};
