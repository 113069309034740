import { ProceduresServiceApiProvider } from '../../services/ProceduresServiceApiProvider';

import { getInstitutionsList } from '../institutionsData/institutionsData.actions';
import { replaceAllFilterValues } from '../filters/replaceFilterValues.action';

import { filtersSelector } from '../filters/filters.selector';
import { proceduresDataSelector } from './proceduresData.selector';

import { getOffset } from '../../../../core/utils/core.utils';

import {
  DEFAULT_PAGE_LIMIT,
  PROCEDURE_NAME_FIELD,
  PROCEDURES_TABLE_VIEWS,
  SORTING_DIRECTIONS,
} from '../../../../core/constants/core.constants';

export const GET_PROCEDURES_LIST_REQUEST = '[PROCEDURES] GET PROCEDURES LIST REQUEST';
export const GET_PROCEDURES_LIST_SUCCESS = '[PROCEDURES] GET PROCEDURES LIST SUCCESS';
export const GET_PROCEDURES_LIST_FAILURE = '[PROCEDURES] GET PROCEDURES LIST FAILURE';

export const GET_HOSPITALS_LIST_REQUEST = '[PROCEDURES] GET HOSPITALS LIST REQUEST';
export const GET_HOSPITALS_LIST_SUCCESS = '[PROCEDURES] GET HOSPITALS LIST SUCCESS';
export const GET_HOSPITALS_LIST_FAILURE = '[PROCEDURES] GET HOSPITALS LIST FAILURE';

export const TOGGLE_HOSPITALS_LIST_MODAL = '[PROCEDURES] TOGGLE HOSPITALS LIST MODAL';

export const SET_PROCEDURES_TABLE_VIEW = '[PROCEDURES] SET TABLE VIEW';

export function getProceduresList({
  limit = DEFAULT_PAGE_LIMIT,
  currentPage,
  sortedBy = PROCEDURE_NAME_FIELD,
  sortingDirection = SORTING_DIRECTIONS.ASC,
}) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PROCEDURES_LIST_REQUEST,
    });
    try {
      const filterValues = filtersSelector.filterValues(getState());
      const filterConfig = {
        limit,
        offset: getOffset({ currentPage }),
        filterValues,
        sortedBy,
        sortingDirection,
      };
      const { data } = await ProceduresServiceApiProvider.getProceduresList(filterConfig);
      return dispatch({
        type: GET_PROCEDURES_LIST_SUCCESS,
        payload: {
          proceduresList: data.procedures,
          count: data.count,
          currentPage,
          sortedBy,
          sortingDirection,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_PROCEDURES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export function toggleHospitalsListModal({
  shouldShowHospitalsModal,
  selectedProcedureName,
  selectedProcedureCode,
}) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_HOSPITALS_LIST_MODAL,
      payload: {
        shouldShowHospitalsModal,
        selectedProcedureName,
        selectedProcedureCode,
      },
    });
  };
}

export function getHospitalsList({
  procedureName,
  procedureCode,
  limit = DEFAULT_PAGE_LIMIT,
  offset = 0,
  shouldIncludeProcedureInfo = false,
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const country = filtersSelector.selectedCountry(state);
    const year = filtersSelector.selectedYear(state);
    dispatch({
      type: GET_HOSPITALS_LIST_REQUEST,
    });
    try {
      const { data } = await ProceduresServiceApiProvider.getHospitalsList({
        procedureName,
        procedureCode,
        country,
        year,
        limit,
        offset,
        shouldIncludeProcedureInfo,
      });
      dispatch({
        type: GET_HOSPITALS_LIST_SUCCESS,
        payload: {
          hospitalsList: data.hospitals,
        },
      });
      return dispatch(toggleHospitalsListModal({
        shouldShowHospitalsModal: true,
        selectedProcedureName: procedureName,
        selectedProcedureCode: procedureCode,
      }));
    } catch (error) {
      return dispatch({
        type: GET_HOSPITALS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const setTableView = ({
  tableView,
}) => {
  return (dispatch) => {
    dispatch({
      type: SET_PROCEDURES_TABLE_VIEW,
      payload: tableView,
    });
  };
};

export const loadSavedSearch = ({ filterValues }) => (dispatch, getState) => {
  const tableView = proceduresDataSelector.tableView(getState());
  replaceAllFilterValues({ filterValues })(dispatch);
  return tableView === PROCEDURES_TABLE_VIEWS.PROCEDURES
    ? getProceduresList({ currentPage: 1 })(dispatch, getState)
    : getInstitutionsList({ currentPage: 1 })(dispatch, getState);
};
