import { combineReducers } from 'redux';
import { adminsDashboardReducer } from './adminsDashboard/adminsDashboard.reducer';
import { clientsDashboardReducer } from './clientsDashboard/clientsDashboard.reducer';
import { clientPageReducer } from './clientPage/clientPage.reducer';
import { userPageReducer } from './userPage/userPage.reducer';

export const adminReducer = combineReducers({
  adminsDashboard: adminsDashboardReducer,
  clientsDashboard: clientsDashboardReducer,
  clientPage: clientPageReducer,
  userPage: userPageReducer,
});
