import React from 'react';
import { Form } from 'semantic-ui-react';

export const SearchInput = ({
  label,
  name,
  placeholder,
  onChange,
  onKeyDown,
  onKeyUp,
  value,
  className = '',
}) => {
  return (
    <div className={`input-field-container input-group-container ${className}`}>
      <Form.Field className="input-field-inner">
        <Form.Input
          placeholder={placeholder}
          label={label}
          name={name}
          onChange={(e, data) => onChange(e, data)}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          value={value || ''}
          className={className}
        />
      </Form.Field>
    </div>
  );
};
