import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OneInputModal } from '../../../../core/components/modals/OneInputModal';

import { saveSearch as saveSearchAction } from '../../../../core/user/user.actions';

const SAVE_BUTTON_TEXT = 'Save search';

export const SaveSearchComponent = ({
  pagination,
  currentPageContext,
  saveSearch,
}) => {
  const [shouldShowSaveModal, setShouldShowSaveModal] = useState(false);
  const { count } = pagination;

  function saveSearchHandler(name) {
    setShouldShowSaveModal(false);
    return saveSearch({ name, resultsCount: count, currentPageContext });
  }

  function onSaveModalOpen() {
    setShouldShowSaveModal(true);
  }

  return (
    <div className="save-search">
      <div className="save-search-text" onClick={onSaveModalOpen}>
        {SAVE_BUTTON_TEXT}
      </div>
      {shouldShowSaveModal ? (
        <OneInputModal
          open={shouldShowSaveModal}
          inputLabel="Search name"
          inputName="name"
          onClose={() => setShouldShowSaveModal(false)}
          submitAction={saveSearchHandler}
        />
      ) : null}
    </div>

  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveSearch: saveSearchAction,
}, dispatch);

export const SaveSearch = connect(
  null,
  mapDispatchToProps,
)(SaveSearchComponent);
