export const GENERAL_INFORMATION_TABLE_FIELDS = [
  { label: 'Lab Name', key: 'name' },
  { label: 'Parent group', key: 'parentGroup' },
  { label: 'Country', key: 'country' },
  { label: 'City', key: 'city' },
  { label: 'Address', key: 'address' },
  { label: 'Phone', key: 'phone' },
  { label: 'Email', key: 'email' },
  { label: 'Webpage', key: 'webpage' },
  { label: 'Status', key: 'status' },
  { label: 'Type', key: 'type' },
];

export const ADMINISTRATIVE_TABLE_FIELDS = [
  { label: 'Directors', key: 'directives' },
  { label: 'Executives', key: 'administratives' },
  { label: 'Nurses', key: 'nurses' },
  { label: 'Radiologist technicians', key: 'radiologistTechnicians' },
  { label: 'Radiologists', key: 'radiologists' },
];

export const EQUIPMENT_TABLE_FIELDS = [
  { label: 'X-ray machines', key: 'xray' },
  { label: 'Ultrasound equipment', key: 'ultrasound' },
  { label: 'Echocardiograph equipment', key: 'echocardiographs' },
  { label: 'Mastography machines', key: 'mastographer' },
  { label: 'MRI equipment', key: 'mri' },
  { label: 'CT scanners', key: 'cat' },
  { label: 'PET scanners', key: 'pets' },
  { label: 'Bone densitometers', key: 'bonedensitometer' },
  { label: 'C-arms', key: 'carm' },
  { label: 'Fluoroscopy systems', key: 'fluoroscope' },
  { label: 'Angiography  systems', key: 'angiograph' },
  { label: 'Electronic medical records (EMR)', key: 'emr' },
  { label: 'Picture archiving and communications systems (PACS)', key: 'pacs' },
  { label: 'Radiology information systems (RIS)', key: 'ris' },
  { label: 'Power injectors MRI', key: 'injectorMri' },
  { label: 'Power injectors CT scanners', key: 'injectorCat' },
  { label: 'Power injectors angiography systems', key: 'injectorAngiograph' },
];

export const PROCEDURES_TABLE_FIELDS = [
  {
    label: 'MRI procedures',
    key: 'mriProcedures',
  },
  {
    label: 'CT scanners procedures',
    key: 'catProcedures',
  },
  {
    label: 'PET scanners procedures',
    key: 'petsProcedures',
  },
];
