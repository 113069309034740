import React from 'react';
import { Table } from 'semantic-ui-react';

import { TABLE_HEADER_CELLS } from './HospitalsTableHeader';

import { LocalStorageService } from '../../../../../core/services/localStorage.service';

import { getTableCellContent } from '../hospitalsData.utils';
import { mixOriginalAndPredictedData } from '../../modals/utils/download.utils';

import { AVAILABLE_DATA_SOURCES, DATA_SOURCE_HEADER_NAME } from '../../../../../core/constants/core.constants';

const PREDICTED_VALUE_CLASSNAME = 'predicted';

export const HospitalsTableBodyComponent = ({
  filterName,
  hospitalsList,
  predictedHospitalsList,
  toggleHospitalModal,
}) => {
  const isPredictedDataSource = LocalStorageService.get(DATA_SOURCE_HEADER_NAME) === AVAILABLE_DATA_SOURCES.PREDICTED;
  const formattedHospitalsList = isPredictedDataSource
    ? mixOriginalAndPredictedData({ original: hospitalsList, predicted: predictedHospitalsList }) : hospitalsList;

  const getCellContent = ({ hospital, key }) => getTableCellContent(hospital[key], key);

  const getCellClassName = ({ hospital, key }) => {
    return hospital.predictedKeys && hospital.predictedKeys.indexOf(key) !== -1 ? PREDICTED_VALUE_CLASSNAME : '';
  };

  return (
    <Table.Body>
      {formattedHospitalsList.map((hospital) => {
        return (
          <Table.Row
            key={`${hospital.year}-${hospital.id}`}
            onClick={() => toggleHospitalModal({ open: true, id: hospital.id })}
          >
            {TABLE_HEADER_CELLS[filterName].map((cell) => (
              <Table.Cell
                key={`${cell.key}-${hospital.year}-${hospital.id}`}
                className={getCellClassName({ hospital, key: cell.key })}
              >
                {getCellContent({ hospital, key: cell.key })}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const HospitalsTableBody = HospitalsTableBodyComponent;
