export const GENERAL_INFORMATION_MENU_ITEMS = {
  TAXPAYER_ID: {
    label: 'Taxpayer ID',
    placeholder: 'Type here',
    name: 'taxPayerId',
  },
  HOSPITAL_NAME: {
    label: 'Hospital Name',
    placeholder: 'Type here',
    name: 'name',
  },
  HOSPITAL_ID: {
    label: 'Hospital ID',
    placeholder: 'Type here',
    name: 'hospitalId',
  },
  HOLDING_NAME: {
    label: 'Holding Name',
    placeholder: 'Type here',
    name: 'holdingName',
  },
  AFFILIATION: {
    label: 'Affiliation',
    placeholder: 'Type here',
    name: 'affiliation',
  },
  NATURE_OF_ESTABLISHMENT: {
    label: 'Nature of establishment',
    name: 'natureOfEstablishment',
  },
  TYPE_OF_ESTABLISHMENT: {
    label: 'Type of establishment',
    name: 'typeOfEstablishment',
  },
  LEVEL_OF_CARE: {
    label: 'Level of care',
    name: 'levelOfCare',
  },
  EMAIL: {
    label: 'Email',
    name: 'email',
  },
  STATE: {
    label: 'State',
    name: 'state',
  },
  CITY: {
    label: 'City',
    name: 'city',
  },
  ZIP_CODE: {
    label: 'Zip Code',
    placeholder: 'Type here',
    name: 'zipCode',
  },
  PHONE: {
    label: 'Phone',
    placeholder: 'Type here',
    name: 'phone',
  },
};
