import React from 'react';
import { Table } from 'semantic-ui-react';

import { TABLE_HEADER_CELLS } from './InstitutionsTableHeader';

const DEFAULT_LIMIT_FOR_PROCEDURES_MODAL = 100000;

export const InstitutionsTableBodyComponent = ({
  institutionsList,
  getProceduresList,
}) => {
  return (
    <Table.Body>
      {institutionsList.map((institution) => {
        return (
          <Table.Row
            key={institution.id}
            onClick={() => getProceduresList({
              id: institution.id,
              year: institution.year,
              limit: DEFAULT_LIMIT_FOR_PROCEDURES_MODAL,
            })}
          >
            {TABLE_HEADER_CELLS.map((cell) => (
              <Table.Cell
                key={`${cell.key}-${institution.procedure_name}`}
              >
                {institution[cell.key]}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const InstitutionsTableBody = InstitutionsTableBodyComponent;
