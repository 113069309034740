import React from 'react';
import { Switch, Route } from 'react-router';
import { ToastContainer } from 'react-toastify';

import { LoginContainer } from './containers/common/LoginContainer';
import { AppContainer } from './containers/common/AppContainer';

export const App = () => {
  return (
    <div className="content-wrapper">
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route component={AppContainer} />
      </Switch>
      <ToastContainer />
    </div>
  );
};
