import React from 'react';
import { Table } from 'semantic-ui-react';
import { mainViewCfg } from '../../config';

import { getSortingDirectionForArrow } from '../../../../core/utils/core.utils';
import { SORTING_DIRECTIONS } from '../../../../core/constants/core.constants';

const { ASC, DESC } = SORTING_DIRECTIONS;

export const MainDataTable = ({
  mainViewRows,
  sortedBy,
  sortingDirection,
  getMainViewData,
  toggleSingleRowModal,
}) => {
  const TableHeader = () => {
    return (
      <Table.Header>
        <Table.Row>
          {mainViewCfg.colLabels.map(([key, label]) => {
            return (
              <Table.HeaderCell
                key={key}
                className={mainViewCfg.columns[key]?.headerClass}
                sorted={getSortingDirectionForArrow({ key, sortedBy, sortingDirection })}
                onClick={() => getMainViewData({
                  currentPage: 1,
                  sortedBy: key,
                  sortingDirection: sortingDirection === ASC ? DESC : ASC,
                })}
              >
                {label}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
    );
  };
  const TableBody = () => {
    // Use wrapper to avoid breaking row columns when changing display to box or such
    const shouldUseWrapper = (wrapperClass, data) => (
      wrapperClass ? <div className={wrapperClass}>{data}</div>
        : data);

    return (
      <Table.Body>
        {mainViewRows.map((row, index) => {
          return (
            <Table.Row
              key={`${index}-${row.id}`}
              onClick={() => toggleSingleRowModal({ selectedRowData: row })}
            >
              {Object.entries(mainViewCfg.tableCols).map(([key, val]) => (
                <Table.Cell
                  key={`${key}-${row.id}`}
                  className={val.cellClass}
                >
                  { shouldUseWrapper(
                    val.wrapperClass,
                    (row[key] && val?.formatValue) ? val.formatValue(row[key], key, row) : row[key],
                  )}
                </Table.Cell>
              ))}
            </Table.Row>
          );
        })}
      </Table.Body>
    );
  };

  return (
    <div className="proceduresTable-wrapper">
      <Table className="MainView hospitals-table" sortable>
        <TableHeader />
        <TableBody />
      </Table>
    </div>
  );
};
