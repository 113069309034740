export const FILTER_MENU_TYPES = {
  INPUT: 'INPUT',
  FROM_TO_INPUT: 'FROM_TO_INPUT',
  CHECKBOX: 'CHECKBOX',
};

export const FILTERS = {
  YEAR: 'year',
  COUNTRIES: 'countries',
  SEARCH: 'search',
  GENERAL_INFORMATION: 'generalInformation',
  ADMINISTRATIVE: 'administrative',
  EQUIPMENT: 'equipment',
  PROCEDURES: 'procedures',
};

export const FILTER_MENU_TITLES = {
  [FILTERS.GENERAL_INFORMATION]: 'General Information',
  [FILTERS.ADMINISTRATIVE]: 'Administrative',
  [FILTERS.EQUIPMENT]: 'Equipment',
  [FILTERS.PROCEDURES]: 'Procedures',
};
