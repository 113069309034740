import React, { useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

import { RemoveModal } from '../../../../core/components/modals/RemoveModal';
import { EditClientModal } from '../modals/EditClientModal';

import { UserAvatar } from '../../../../core/components/UserAvatar/UserAvatar';
import { formatServerDate } from '../../../../core/utils/core.utils';

const ACTIVE_CLIENT_LABEL = 'Active';
const EDIT_CLIENT_TEXT = 'Edit client';
const REMOVE_CLIENT_TEXT = 'Remove client';
const DATE_MESSAGE = 'Active till: ';

export const ClientInfo = ({
  clientInfo, toggleClientStatus, editClient, removeClient,
}) => {
  const {
    id, name, is_active: isActive, active_till: activeTill,
  } = clientInfo;
  const [shouldShowRemoveClientModal, setShouldShowRemoveClientModal] = useState(false);
  const [shouldShowEditClientModal, setShouldShowEditClientModal] = useState(false);
  function removeClientHandler() {
    setShouldShowRemoveClientModal(false);
    return removeClient({ id });
  }
  function editClientHandler({ client }) {
    setShouldShowEditClientModal(false);
    return editClient({ id, clientInfo: client });
  }
  return (
    <div className="userInfo client-page-infoCard">
      <div className="userInfo-block userInfo-avatar">
        <UserAvatar name={name} width={50} height={50} />
      </div>
      <div className="userInfo-block userInfo-name">
        <span>{name}</span>
      </div>
      <div className="userInfo-block userInfo-status">
        <Checkbox
          label={ACTIVE_CLIENT_LABEL}
          checked={isActive}
          onChange={(e, { checked }) => toggleClientStatus({ id, isActive: checked })}
        />
      </div>
      <div className="userInfo-block userInfo-createdAt">
        <span>
          {`${DATE_MESSAGE} ${formatServerDate(activeTill)}`}
        </span>
      </div>
      <div className="userInfo-block userInfo-action">
        <Button
          className="userInfo-button primary"
          onClick={() => setShouldShowEditClientModal(true)}
        >
          {EDIT_CLIENT_TEXT}
        </Button>
      </div>
      <div className="userInfo-block userInfo-action">
        <Button
          className="userInfo-button cancel"
          onClick={() => setShouldShowRemoveClientModal(true)}
        >
          {REMOVE_CLIENT_TEXT}
        </Button>
      </div>
      {shouldShowRemoveClientModal ? (
        <RemoveModal
          open={shouldShowRemoveClientModal}
          name={name}
          onClose={() => setShouldShowRemoveClientModal(false)}
          removeAction={removeClientHandler}
        />
      ) : null}
      {shouldShowEditClientModal
        ? (
          <EditClientModal
            open={shouldShowEditClientModal}
            data={{ name, activeTill }}
            headerText={EDIT_CLIENT_TEXT}
            onClose={() => setShouldShowEditClientModal(false)}
            submitAction={editClientHandler}
          />
        ) : null}
    </div>
  );
};
