import React, { useRef, useState } from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactExport from 'react-data-export';

import { LabscopeServiceApiProvider } from '../../services/LabscopeServiceApiProvider';

import { renderModalActionButton, renderModalCancelButton } from './utils/modals.utils';
import { getMaxDownloadLimit } from '../LaboratoriesData/laboratoriesData.utils';
import {
  prepareLaboratoriesListForExcel,
} from './utils/download.utils';

import { exportFiltersInfo } from '../../../../core/utils/excelExport.utils';
import { isEmpty, cloneAndPrune } from '../../../../core/utils/core.utils';

import ExcelDownloadImage from '../../../../assets/excel.png';
import ExcelDownloadImage2x from '../../../../assets/excel@2x.png';
import ExcelDownloadImage3x from '../../../../assets/excel@3x.png';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;

const EXCEL_FILENAME = 'LabScope Report';
const EXCEL_EXPORT_HEADER_TITLE = 'LabScope, by GHI';

const DESCRIPTION_TEXT = 'Download results in an .xls file?';
const LOADING_MESSAGE = 'Preparing data...';
const DOWNLOAD_BUTTON_TEXT = 'Download report';
const CANCEL_BUTTON_TEXT = 'Cancel';

export const DownloadModal = ({
  role,
  filterValues,
  count,
  open,
  sortedBy,
  sortingDirection,
  onClose,
  onCancel,
}) => {
  const [csvData, setCSVData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const downloadButton = useRef(null);
  const CSV_DOWNLOAD_LIMIT = getMaxDownloadLimit(role);
  const OVER_LIMIT_MESSAGE = `To download decrease results to be less than ${CSV_DOWNLOAD_LIMIT}`;
  async function loadLaboratoriesData() {
    const { data } = await LabscopeServiceApiProvider.getLaboratoriesList({
      limit: CSV_DOWNLOAD_LIMIT,
      filterValues,
      sortedBy,
      sortingDirection,
    });
    return data.laboratories;
  }

  const prepareFiltersInfoForExport = (filters) => {
    const arrayToXLSXBoldFirstCol = (data) => {
      return data.map((row) => [
        { value: row[0], style: { font: { bold: true } } },
        { value: row[1] },
      ]);
    };
    const filtersInfo = [];
    const countries = filters.countries.join(', ');
    const year = filters.year.join(', ');
    const checkOtherFilters = { ...filters, countries: null, year: null };
    const gotOtherFilters = !isEmpty(cloneAndPrune(checkOtherFilters, isEmpty));

    if (countries) { filtersInfo.push(['Countries:', countries]); }

    if (year) { filtersInfo.push(['Year:', year]); }

    filtersInfo.push(['Filters applied:', gotOtherFilters ? 'Multiple selected' : 'All data']);

    return exportFiltersInfo(EXCEL_EXPORT_HEADER_TITLE, arrayToXLSXBoldFirstCol(filtersInfo));
  };

  async function onDownloadClick() {
    if (count > CSV_DOWNLOAD_LIMIT) {
      return toast.warn(OVER_LIMIT_MESSAGE);
    }
    setIsLoading(true);
    const laboratoriesData = await loadLaboratoriesData();
    setCSVData(laboratoriesData);
    setIsLoading(false);
    return downloadButton.current.click();
  }
  return (
    <Modal className="download-modal" open={open} onClose={onClose} size="mini">
      <Dimmer active={isLoading}>
        <Loader>{LOADING_MESSAGE}</Loader>
      </Dimmer>
      <Modal.Content>
        <Modal.Description>
          <div className="download-modal-content">
            <div className="download-modal-image">
              <img alt="" src={ExcelDownloadImage} srcSet={`${ExcelDownloadImage2x} 2x, ${ExcelDownloadImage3x} 3x`} />
            </div>
            <p className="download-modal-text">{DESCRIPTION_TEXT}</p>
          </div>
        </Modal.Description>
        <ExcelFile
          filename={EXCEL_FILENAME}
          element={<div ref={downloadButton} style={{ display: 'none' }} />}
        >
          <ExcelSheet
            name="Filter Data"
            dataSet={prepareFiltersInfoForExport(filterValues)}
          />
          <ExcelSheet
            name="LabScope Report"
            dataSet={prepareLaboratoriesListForExcel(csvData)}
          />
        </ExcelFile>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: onDownloadClick, text: DOWNLOAD_BUTTON_TEXT })}
        {renderModalCancelButton({ clickHandler: onCancel, text: CANCEL_BUTTON_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
