import React, { useState, useEffect } from 'react';

import { MultipleDropdownField } from '../shared/components';

import { generateDropdownOptions, uniqueDropdownOptions } from '../../../../../../core/utils/core.utils';

const NO_RESULTS_MESSAGE = 'No results found';
const SEARCH_MIN_LENGTH_MESSAGE = 'Please, provide 3 chars to start search';

export const GeoSearchDropdownComponent = ({
  value,
  label,
  name,
  placeholder,
  selectedCountries,
  isLoading,
  availableItems,
  onChange,
  getAvailableItems,
}) => {
  const [options, setOptions] = useState(generateDropdownOptions(availableItems));
  const [message, setMessage] = useState(SEARCH_MIN_LENGTH_MESSAGE);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setOptions(generateDropdownOptions(availableItems));
  }, [availableItems]);

  useEffect(() => {
    if (searchValue && !availableItems.length) {
      setMessage(NO_RESULTS_MESSAGE);
    }
  }, [searchValue, availableItems.length]);

  function getItems(searchQuery) {
    setMessage('');
    return getAvailableItems({ countries: selectedCountries, [name]: searchQuery });
  }

  function onSearchChange(e, { searchQuery }) {
    setSearchValue(searchQuery);
    return searchQuery.length >= 3 ? getItems(searchQuery) : setMessage(SEARCH_MIN_LENGTH_MESSAGE);
  }

  function formatOptions() {
    return value && value.length ? [...generateDropdownOptions(value), ...options] : options;
  }

  const optionsList = uniqueDropdownOptions(formatOptions());
  return (
    <MultipleDropdownField
      multiple
      search
      label={label}
      name={name}
      options={optionsList}
      value={value}
      placeholder={placeholder}
      noResultsMessage={message}
      loading={isLoading}
      disabled={!selectedCountries.length}
      onChange={onChange}
      onSearchChange={onSearchChange}
    />
  );
};

export const GeoSearchDropdown = GeoSearchDropdownComponent;
