import React from 'react';
import { Table } from 'semantic-ui-react';

import { navigateToPage } from '../../../../../core/routing/history';

import { getAdminTableCellContent } from '../../../utils/table.utils';

import { ADMINS_TABLE_CELLS } from '../../constants/tableFields.constants';

export const AdminsTableBodyComponent = ({ adminsList }) => {
  return (
    <Table.Body>
      {adminsList.map((admin) => {
        return (
          <Table.Row key={admin.user_id} onClick={() => navigateToPage(`/admin/admins/${admin.user_id}`)}>
            {ADMINS_TABLE_CELLS.map((cell, index) => (
              <Table.Cell key={`cell-${admin.user_id}-${index}`}>
                {getAdminTableCellContent({ name: cell.key, value: admin[cell.key] })}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const AdminsTableBody = AdminsTableBodyComponent;
