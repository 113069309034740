import React from 'react';

import { generateFilterMenu } from '../../utils/filters.ui.utils';

export const FilterItemComponent = ({
  type,
  fieldsList,
  columnsAmount,
  fields,
  handleFieldChange,
  primary,
}) => {
  return (
    <div className={`fields-group ${primary ? 'fields-group--primary' : ''}`}>
      {generateFilterMenu({
        type,
        fieldsList,
        columnsAmount,
        data: fields,
        onChangeHandler: handleFieldChange,
      })}
    </div>
  );
};

export const FilterItem = FilterItemComponent;
