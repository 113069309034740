import { FILTERS } from '../../../../constants/filters.constants';
import {
  BEDS_TABLE_FIELDS,
  EQUIPMENT_TABLE_FIELDS,
  // HR_TABLE_FIELDS,
  INFRASTRUCTURE_TABLE_FIELDS,
  LABORATORY_TABLE_FIELDS,
  // MEDICAL_OFFICES_TABLE_FIELDS,
  TECHNOLOGY_TABLE_FIELDS,
} from '../../tableFields.constants';

export const ACTIVE_TAB_NAME_TO_CALCULATED_DATA_KEYS = {
  [FILTERS.BEDS]: BEDS_TABLE_FIELDS.map((field) => field.key),
  [FILTERS.INFRASTRUCTURE]: INFRASTRUCTURE_TABLE_FIELDS.map((field) => field.key),
  [FILTERS.LABORATORY]: LABORATORY_TABLE_FIELDS.map((field) => field.key),
  // [FILTERS.MEDICAL_OFFICES]: MEDICAL_OFFICES_TABLE_FIELDS.map((field) => field.key),
  [FILTERS.EQUIPMENT]: EQUIPMENT_TABLE_FIELDS.map((field) => field.key),
  [FILTERS.TECHNOLOGY]: TECHNOLOGY_TABLE_FIELDS.map((field) => field.key),
  // [FILTERS.HR]: HR_TABLE_FIELDS.map((field) => field.key),
};

export const CALCULATED_DATA_ROWS = [
  { key: 'min', label: 'Min' },
  { key: 'max', label: 'Max' },
  { key: 'avg', label: 'Average' },
  { key: 'sum', label: 'Sum' },
  { key: 'count', label: 'Count' },
  { key: 'countNull', label: 'Count No Data' },
  { key: 'countAll', label: 'Count All' },
];
