import React from 'react';

import { UserPage } from '../../UserPage/components/UserPage';

import { USER_PAGE_CONTEXT_TYPES } from '../../../../core/constants/core.constants';

export const AdminPage = (props) => (
  <UserPage
    userPageContext={USER_PAGE_CONTEXT_TYPES.ADMIN}
    {...props}
  />
);
