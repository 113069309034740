import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  LOAD_SEARCH_TEXT,
  NO_SEARCHES_MESSAGE,
  HIDE_SEARCHES_TEXT,
  SHOW_SEARCHES_TEXT,
  DELETE_SEARCH_TEXT,
} from './constants/savedSearches.constants';

import { formatServerDate } from '../../../../core/utils/core.utils';
import { getResultsWording } from './utils/savedSearches.utils';

import { userSelector } from '../../../../core/user/user.selector';

import { deleteSearch as deleteSearchAction } from '../../../../core/user/user.actions';

const TOGGLE_SAVED_SEARCHES_BUTTON_CLASSNAME = 'saved-searches-button';

export const SavedSearchComponent = ({
  savedSearches,
  areSavedSearchesLoading,
  currentPageContext,
  deleteSearch,
  loadSavedSearch,
}) => {
  const [shouldShowSearches, setShouldShowSearches] = useState(false);

  const onLoadButtonClick = (data) => {
    setShouldShowSearches(false);
    return loadSavedSearch({ filterValues: data, currentPageContext });
  };

  const onDeleteClick = (id) => deleteSearch({ id, currentPageContext });

  const onTriggerClick = () => setShouldShowSearches(!shouldShowSearches);

  const renderNoSearchesMessage = () => (<span>{NO_SEARCHES_MESSAGE}</span>);

  const renderSearch = (searchData) => {
    const {
      name,
      created_at,
      results_count,
      id,
      data,
    } = searchData;
    const formattedDate = formatServerDate(created_at);
    const formattedResults = getResultsWording(results_count);

    return (
      <div className="saved-search" key={id}>
        <div className="saved-search-results">
          <span>{formattedResults}</span>
        </div>
        <div className="saved-search-date">
          <span>{formattedDate}</span>
        </div>
        <div className="saved-search-name">
          <span>{name}</span>
        </div>
        <div className="saved-search-load">
          <span onClick={() => onLoadButtonClick(data)}>{LOAD_SEARCH_TEXT}</span>
        </div>
        <div className="saved-search-delete">
          <span onClick={() => onDeleteClick(id)}>{DELETE_SEARCH_TEXT}</span>
        </div>
      </div>
    );
  };

  const renderSearches = () => (savedSearches.map((e) => renderSearch(e)));

  const renderInner = () => (savedSearches.length ? renderSearches() : renderNoSearchesMessage());

  const onOutsideClick = ({ target }) => {
    return TOGGLE_SAVED_SEARCHES_BUTTON_CLASSNAME === target.className ? false : setShouldShowSearches(false);
  };

  const renderContainer = () => (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className="saved-searches-container">
        <Dimmer active={areSavedSearchesLoading} inverted>
          <Loader />
        </Dimmer>
        {renderInner()}
      </div>
    </OutsideClickHandler>
  );

  return (
    <div className="saved-searches">
      <div className={TOGGLE_SAVED_SEARCHES_BUTTON_CLASSNAME} onClick={onTriggerClick}>
        { shouldShowSearches ? HIDE_SEARCHES_TEXT : SHOW_SEARCHES_TEXT }
      </div>
      { shouldShowSearches ? renderContainer() : null }
    </div>
  );
};

const mapStateToProps = (state) => ({
  savedSearches: userSelector.savedSearches(state),
  areSavedSearchesLoading: userSelector.areSavedSearchesLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteSearch: deleteSearchAction,
}, dispatch);

export const SavedSearches = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavedSearchComponent);
