import React from 'react';
import { Table } from 'semantic-ui-react';

import { getSortingDirectionForArrow } from '../../../../../../core/utils/core.utils';
import { SORTING_DIRECTIONS } from '../../../../../../core/constants/core.constants';

export const TABLE_HEADER_CELLS = [
  { label: 'Group', key: 'procedure_group' },
  { label: 'Category', key: 'procedure_category' },
  { label: 'Subcategory', key: 'procedure_subcategory' },
  { label: 'Procedure Name', key: 'procedure_name' },
  { label: 'Number', key: 'sum' },
];

export const HospitalsListTableHeaderComponent = ({
  sortedBy,
  sortingDirection,
  setSortedBy,
  setSortingDirection,
}) => {
  return (
    <Table.Header>
      <Table.Row>
        {TABLE_HEADER_CELLS.map((cell) => {
          return (
            <Table.HeaderCell
              key={cell.label}
              sorted={getSortingDirectionForArrow({ key: cell.key, sortedBy, sortingDirection })}
              className={cell.key}
              onClick={() => {
                const newSortingDirection = sortingDirection === SORTING_DIRECTIONS.ASC
                  ? SORTING_DIRECTIONS.DESC : SORTING_DIRECTIONS.ASC;
                return cell.key === sortedBy ? setSortingDirection(newSortingDirection) : setSortedBy(cell.key);
              }}
            >
              {cell.label}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export const ProceduresListTableHeader = HospitalsListTableHeaderComponent;
