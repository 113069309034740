import { axiosService } from '../../../core/services/axios.service';

import { LabscopeServiceConfigProvider } from './LabscopeServiceConfigProvider';

import { formatFilterValues } from '../utils/laboratoriesServiceDataFormatter';

export class LabscopeServiceApiProvider {
  static getAvailableCountries() {
    return axiosService.get(LabscopeServiceConfigProvider.getCountriesListEndpoint());
  }

  static getFilteringOptions({ countries }) {
    return axiosService({
      method: 'post',
      url: LabscopeServiceConfigProvider.getFilteringOptionsEndpoint(),
      data: {
        countries,
      },
    });
  }

  static getStatesByCountriesAndStateName({ countries, state }) {
    return axiosService({
      method: 'post',
      url: LabscopeServiceConfigProvider.getStatesByCountriesAndCityNameEndpoint(),
      data: {
        countries,
        state,
      },
    });
  }

  static getCitiesByCountriesAndCityName({ countries, state, city }) {
    return axiosService({
      method: 'post',
      url: LabscopeServiceConfigProvider.getCitiesByCountriesAndCityNameEndpoint(),
      data: {
        countries,
        state,
        city,
      },
    });
  }

  static getLaboratoriesList({
    limit,
    offset,
    filterValues = {},
    sortedBy,
    sortingDirection,
  }) {
    return axiosService({
      method: 'post',
      url: LabscopeServiceConfigProvider.getLaboratoriesListEndpoint(),
      data: {
        ...formatFilterValues(filterValues),
        sortedBy,
        sortingDirection,
        limit,
        offset,
      },
    });
  }

  static getAdditionalLaboratoryData({ id, year }) {
    return axiosService({
      method: 'get',
      url: LabscopeServiceConfigProvider.getAdditionalLaboratoryData({ id, year }),
    });
  }
}
