import React, { useState } from 'react';
import { Form, Input, Modal } from 'semantic-ui-react';

import { editUserValidationSchema, getYupErrorMessage } from '../../../utils/validation.utils';
import { renderModalActionButton } from '../../../../hospiscope/components/modals/utils/modals.utils';

import { EDIT_USER_MODAL_FORM_FIELDS } from '../../constants/modals.constants';

const MODAL_TEXT = 'Accept';

export const EditUserModal = ({
  open,
  data = {},
  headerText,
  onClose,
  submitAction,
  className = '',
}) => {
  const [formFields, setFormFields] = useState(data);
  const [formErrors, setFormErrors] = useState([]);
  const handleFieldChange = ({ name, value }) => {
    setFormErrors([]);
    setFormFields({ ...formFields, [name]: value });
  };
  async function onUserEdit() {
    try {
      await editUserValidationSchema.validate(formFields, { abortEarly: false });
      return submitAction({ user: formFields });
    } catch (e) {
      return setFormErrors(e.inner);
    }
  }
  return (
    <Modal open={open} onClose={onClose} size="mini" className={`one-input-modal ${className}`}>
      {headerText ? (
        <Modal.Header>
          {headerText}
        </Modal.Header>
      ) : null}
      <Modal.Content>
        <Modal.Description>
          <>
            <div className="input-field-container input-group-container">
              <Form>
                {EDIT_USER_MODAL_FORM_FIELDS.map(({ label, placeholder, name }) => {
                  const error = getYupErrorMessage({ path: name, errors: formErrors });
                  return (
                    <Form.Field className="input-field-inner">
                      <Form.Input
                        control={Input}
                        value={formFields[name] || ''}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e) => handleFieldChange(e.target)}
                        error={error}
                      />
                    </Form.Field>
                  );
                })}
              </Form>
            </div>
          </>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: onUserEdit, text: MODAL_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
