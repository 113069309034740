import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { history } from '../../routing/history';

import { UserAvatar } from '../UserAvatar/UserAvatar';

import { userSelector } from '../../user/user.selector';

import { formatServerDate } from '../../utils/core.utils';

const BACK_TEXT = 'Back';
const DATE_MESSAGE = 'Active since:';

export const ProfilePageComponent = ({ userData }) => {
  const {
    name, email, title, created_at: createdAt,
  } = userData;
  return (
    <div className="user-page">
      <div className="container main">
        <div className="user-page-content">
          <div className="userInfo client-page-infoCard">
            <div className="userInfo-block userInfo-avatar">
              <UserAvatar name={name} width={50} height={50} />
            </div>
            <div className="userInfo-block userInfo-name">
              <span>{name}</span>
            </div>
            <div className="userInfo-block userInfo-title">
              <span>{title}</span>
            </div>
            <div className="userInfo-block userInfo-email">
              <span>{email}</span>
            </div>
            <div className="userInfo-block userInfo-createdAt">
              <span>
                {`${DATE_MESSAGE} ${formatServerDate(createdAt)}`}
              </span>
            </div>
          </div>
          <div className="userInfo-block userInfo-action">
            <Button
              basic
              size="small"
              content={BACK_TEXT}
              icon="left chevron"
              onClick={history.goBack}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: userSelector.userData(state),
});

export const ProfilePage = connect(
  mapStateToProps,
  null,
)(ProfilePageComponent);
