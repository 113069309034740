export const BEDS_MENU_ITEMS = {
  TOTAL_HOSPITAL_BEDS: {
    label: 'Hospital Beds (total)',
    placeholder: 'Number',
    name: 'totalHospitalBeds',
  },
  AMBULATORY_BEDS: {
    label: 'Ambulatory Beds (total)',
    placeholder: 'Number',
    name: 'ambulatoryBeds',
  },
};

export const GENERATED_BEDS_MENU_ITEMS = {
  FIRST_SECTION: [
    {
      label: 'Multiple Used Beds',
      placeholder: 'Number',
      name: 'multipleUseBeds',
    },
    {
      label: 'Hospitalization Beds',
      placeholder: 'Number',
      name: 'hospitalizationBeds',
    },
  ],
  SECOND_SECTION: [
    {
      label: 'Emergency Beds',
      placeholder: 'Number',
      name: 'emergencyBeds',
    },
    {
      label: 'Surgery and Short Stay Beds',
      placeholder: 'Number',
      name: 'shortStayBeds',
    },
    {
      label: 'Labor Beds',
      placeholder: 'Number',
      name: 'laborBeds',
    },
    {
      label: 'Birthing Recuperation Beds',
      placeholder: 'Number',
      name: 'birthingRecuperationBeds',
    },
    {
      label: 'Intensive Care Beds',
      placeholder: 'Number',
      name: 'intensiveCareBeds',
    },
    {
      label: 'Pediatric Intensive Care Beds',
      placeholder: 'Number',
      name: 'pediatricIntensiveCareBeds',
    },
    {
      label: 'Neonatal Intensive Care Beds',
      placeholder: 'Number',
      name: 'neonatalIntensiveCareBeds',
    },
    {
      label: 'Intermediary Care Beds',
      placeholder: 'Number',
      name: 'intermediaryCareBeds',
    },
    {
      label: 'Other Beds',
      placeholder: 'Number',
      name: 'otherBeds',
    },
  ],
};
