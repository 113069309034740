import React, { useRef, useState } from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactExport from 'react-data-export';

import { HospitalsServiceApiProvider } from '../../services/HospitalsServiceApiProvider';
import { LocalStorageService } from '../../../../core/services/localStorage.service';

import { renderModalActionButton, renderModalCancelButton } from './utils/modals.utils';
import { formatNumericalValue, isEmpty, cloneAndPrune } from '../../../../core/utils/core.utils';
import { getMaxDownloadLimit } from '../HospitalsData/hospitalsData.utils';
import {
  mixOriginalAndPredictedData,
  prepareCalculatedDataForExcel,
  prepareHospitalsListForExcel,
} from './utils/download.utils';
import { exportFiltersInfo } from '../../../../core/utils/excelExport.utils';
import { LAST_UPDATED_OPTION } from '../../../common/constants';

import { FLATTEN_HOSPITALS_DATA_FIELDS_LIST } from '../../constants/hospitals.constants';
import { CALCULATED_DATA_ROWS } from '../HospitalsData/HospitalsCalculatedData/constants/calculatedData.constants';
import { AVAILABLE_DATA_SOURCES, DATA_SOURCE_HEADER_NAME } from '../../../../core/constants/core.constants';

import ExcelDownloadImage from '../../../../assets/excel.png';
import ExcelDownloadImage2x from '../../../../assets/excel@2x.png';
import ExcelDownloadImage3x from '../../../../assets/excel@3x.png';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;

const DESCRIPTION_TEXT = 'Download results in an .xls file?';
const LOADING_MESSAGE = 'Preparing data...';
const DOWNLOAD_CALCULATED_DATA_BUTTON_TEXT = 'Download summary';
const DOWNLOAD_BUTTON_TEXT = 'Download report';
const CANCEL_BUTTON_TEXT = 'Cancel';
const CSV_NUMBER_COLUMN_HEADER = '#';

const EXCEL_FILENAME = 'HospiScope - Hospital demographics';
const SUMMARY_FILENAME = 'HospiScope - Hospitals Summary';
const EXCEL_EXPORT_HEADER_TITLE = 'HospiScope, by GHI';

export const DownloadModal = ({
  role,
  filterValues,
  count,
  open,
  sortedBy,
  sortingDirection,
  onClose,
  onCancel,
}) => {
  const [csvData, setCSVData] = useState([]);
  const [calculatedCSVData, setCalculatedCSVData] = useState([]);
  const [calculatedCSVHeaders, setCalculatedCSVHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const downloadSummaryButton = useRef(null);
  const downloadButton = useRef(null);

  const isPredictedDataSource = LocalStorageService.get(DATA_SOURCE_HEADER_NAME) === AVAILABLE_DATA_SOURCES.PREDICTED;
  const CSV_DOWNLOAD_LIMIT = getMaxDownloadLimit(role);
  const OVER_LIMIT_MESSAGE = `To download decrease results to be less than ${CSV_DOWNLOAD_LIMIT}`;

  const prepareFiltersInfoForExport = (filters) => {
    const arrayToXLSXBoldFirstCol = (data) => {
      return data.map((row) => [
        { value: row[0], style: { font: { bold: true } } },
        { value: row[1] },
      ]);
    };
    const filtersInfo = [];
    const countries = filters.countries.join(', ');
    let year = filters.year.join(', ');
    const checkOtherFilters = { ...filters, countries: null, year: null };
    const gotOtherFilters = !isEmpty(cloneAndPrune(checkOtherFilters, isEmpty));

    if (countries) { filtersInfo.push(['Countries:', countries]); }

    if (year) {
      // If year is "Last Updated", replace with actual year number
      if (year === LAST_UPDATED_OPTION.value.toString()) {
        year = LAST_UPDATED_OPTION.text;
      }
      filtersInfo.push(['Year:', year]);
    }

    filtersInfo.push(['Filters applied:', gotOtherFilters ? 'Multiple selected' : 'All data']);

    return exportFiltersInfo(EXCEL_EXPORT_HEADER_TITLE, arrayToXLSXBoldFirstCol(filtersInfo));
  };

  function generateCalculatedDataCSVHeaders(data) {
    return [CSV_NUMBER_COLUMN_HEADER, ...Object.keys(data).reduce((result, key) => {
      // Filter out obsolete columns (check current ones in FLATTEN_HOSPITALS_DATA_FIELDS_LIST)
      const field = FLATTEN_HOSPITALS_DATA_FIELDS_LIST.find((f) => f.key === key);
      return field ? result.concat(field.label) : result;
    },
    [])];
  }

  function generateCalculatedCSVData(data) {
    return [
      ...CALCULATED_DATA_ROWS.map((row) => {
        return [row.label, ...Object.keys(data).reduce((result, key) => {
          const field = FLATTEN_HOSPITALS_DATA_FIELDS_LIST.find((f) => f.key === key);
          return field ? result.concat(formatNumericalValue(data[key][row.key])) : result;
        },
        [])];
      })];
  }
  async function loadHospitalsData() {
    const { data } = await HospitalsServiceApiProvider.getHospitalsList({
      limit: CSV_DOWNLOAD_LIMIT,
      filterValues,
      sortedBy,
      sortingDirection,
    });
    if (isPredictedDataSource) {
      const { data: predictedData } = await HospitalsServiceApiProvider.getPredictedHospitalsList({
        limit: CSV_DOWNLOAD_LIMIT,
        filterValues,
        sortedBy,
        sortingDirection,
      });
      return mixOriginalAndPredictedData({ original: data.hospitals, predicted: predictedData.hospitals });
    }
    return data.hospitals;
  }
  async function loadCalculatedData() {
    const dataRequest = isPredictedDataSource
      ? HospitalsServiceApiProvider.getHospitalsPredictedCalculatedData
      : HospitalsServiceApiProvider.getHospitalsCalculatedData;
    const { data } = await dataRequest({
      filterValues,
    });
    return data;
  }
  async function onDownloadClick() {
    if (count > CSV_DOWNLOAD_LIMIT) {
      return toast.warn(OVER_LIMIT_MESSAGE);
    }
    setIsLoading(true);
    const hospitalsData = await loadHospitalsData();
    setCSVData(hospitalsData);
    setIsLoading(false);
    return downloadButton.current.click();
  }
  async function onDownloadCalculatedDataClick() {
    setIsLoading(true);
    const calculatedData = await loadCalculatedData();
    setCalculatedCSVHeaders(generateCalculatedDataCSVHeaders(calculatedData));
    setCalculatedCSVData(generateCalculatedCSVData(calculatedData));
    setIsLoading(false);
    return downloadSummaryButton.current.click();
  }
  return (
    <Modal className="download-modal" open={open} onClose={onClose} size="mini">
      <Dimmer active={isLoading}>
        <Loader>{LOADING_MESSAGE}</Loader>
      </Dimmer>
      <Modal.Content>
        <Modal.Description>
          <div className="download-modal-content">
            <div className="download-modal-image">
              <img alt="" src={ExcelDownloadImage} srcSet={`${ExcelDownloadImage2x} 2x, ${ExcelDownloadImage3x} 3x`} />
            </div>
            <p className="download-modal-text">{DESCRIPTION_TEXT}</p>
          </div>
        </Modal.Description>
        <ExcelFile
          filename={EXCEL_FILENAME}
          element={<div ref={downloadButton} style={{ display: 'none' }} />}
        >
          <ExcelSheet
            name="Filter Data"
            dataSet={prepareFiltersInfoForExport(filterValues)}
          />
          <ExcelSheet
            name="HospiScope"
            dataSet={prepareHospitalsListForExcel(csvData)}
          />
        </ExcelFile>
        <ExcelFile
          filename={SUMMARY_FILENAME}
          element={<div ref={downloadSummaryButton} style={{ display: 'none' }} />}
        >
          <ExcelSheet
            name="Filter data"
            dataSet={prepareFiltersInfoForExport(filterValues)}
          />
          <ExcelSheet
            name="HospiScope Summary"
            dataSet={prepareCalculatedDataForExcel(calculatedCSVHeaders, calculatedCSVData, isPredictedDataSource)}
          />
        </ExcelFile>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: onDownloadClick, text: DOWNLOAD_BUTTON_TEXT })}
        {renderModalActionButton({
          clickHandler: onDownloadCalculatedDataClick,
          text: DOWNLOAD_CALCULATED_DATA_BUTTON_TEXT,
        })}
        {renderModalCancelButton({ clickHandler: onCancel, text: CANCEL_BUTTON_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
