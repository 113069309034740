import * as firebase from 'firebase/app';
import 'firebase/auth';

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyAhhT5caDXhweexVHPGqiw9FkiwHG0U-Wk',
  authDomain: 'medicine-eb1d4.firebaseapp.com',
  databaseURL: 'https://medicine-eb1d4.firebaseio.com',
  projectId: 'medicine-eb1d4',
  storageBucket: 'medicine-eb1d4.appspot.com',
  messagingSenderId: '843638599548',
  appId: '1:843638599548:web:d446e73f49606b9475fef9',
  measurementId: 'G-ZNZF73PL68',
});

export const FIREBASE_TOKEN_NAME = 'firebase_token';

export const auth = firebaseApp.auth();
