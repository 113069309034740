import { getProceduresState } from '../procedures.selector';

import {
  filtersSelector as hospitalsFiltersSelector,
} from '../../../common/store/filters/filters.selector';

import { FILTERS } from '../../constants/filters.constants';

function getProceduresFiltersState(state) {
  return getProceduresState(state).filters;
}

export const filtersSelector = {
  activeFilter(state) {
    return getProceduresFiltersState(state).activeFilter;
  },
  areFilteringOptionsLoading(state) {
    return getProceduresFiltersState(state).areFilteringOptionsLoading;
  },
  filteringOptions(state) {
    return getProceduresFiltersState(state).filteringOptions;
  },
  selectedCountry(state) {
    return getProceduresFiltersState(state).filterValues[FILTERS.COUNTRY];
  },
  selectedYear(state) {
    return getProceduresFiltersState(state).filterValues[FILTERS.YEAR];
  },
  filterValues(state) {
    return getProceduresFiltersState(state).filterValues;
  },
  areHospitalNamesLoading(state) {
    return getProceduresFiltersState(state).areHospitalNamesLoading;
  },
  hospitalNames(state) {
    return getProceduresFiltersState(state).hospitalNames;
  },
  areAvailableCountriesLoading(state) {
    return hospitalsFiltersSelector.areAvailableCountriesLoading(state);
  },
  availableCountries(state) {
    return hospitalsFiltersSelector.availableSurgiscopeCountries(state);
  },
};
