import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const LOADER_TEXT = 'Verifying Auth...';

export const FullPageLoader = () => {
  return (
    <Dimmer page active>
      <Loader>{LOADER_TEXT}</Loader>
    </Dimmer>
  );
};
