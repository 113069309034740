import React from 'react';

import { LABORATORY_FIELDS_LIST } from './laboratory.constants';
import { FILTER_MENU_TYPES } from '../../../../constants/filters.constants';

import { generateFilterMenu } from '../../utils/filters.ui.utils';

export const LaboratoryComponent = ({
  fields, handleFieldChange,
}) => {
  return (
    <>
      {/* Obsolete fields */}
      {/* <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.CHECKBOX,
          fieldsList: LABORATORY_FIELDS_LIST.FIRST_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: LABORATORY_FIELDS_LIST.SECOND_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.CHECKBOX,
          fieldsList: LABORATORY_FIELDS_LIST.THIRD_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.CHECKBOX,
          fieldsList: LABORATORY_FIELDS_LIST.FOURTH_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: LABORATORY_FIELDS_LIST.FIFTH_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div> */}
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: LABORATORY_FIELDS_LIST.SIXTH_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
    </>
  );
};

export const Laboratory = LaboratoryComponent;
