import React, { useRef, useLayoutEffect } from 'react';
import { Header, Table } from 'semantic-ui-react';

import { HospitalsTableHeader } from './HospitalsTableHeader';
import { HospitalsTableBody } from './HospitalsTableBody';

const NO_RESULTS_MESSAGE = 'No hospitals found. Try to set different filters';

// due to useState not updating on getHospitalsListHandler was forced to use this
let scrollLeftStore = {};

export const HospitalsTable = ({
  filterName,
  hospitalsList,
  predictedHospitalsList,
  sortedBy,
  sortingDirection,
  getHospitalsList,
  toggleHospitalModal,
}) => {
  const wrapperRef = useRef({ current: { scrollLeft: 0 } });

  useLayoutEffect(() => {
    wrapperRef.current.scrollLeft = scrollLeftStore[filterName] || 0;
    if (!scrollLeftStore[filterName]) {
      scrollLeftStore[filterName] = 0;
    }
  }, [sortingDirection, sortedBy, filterName]);

  const getHospitalsListHandler = (args) => {
    Object.keys(scrollLeftStore).forEach((e) => {
      if (e !== filterName) {
        scrollLeftStore[e] = 0;
      }
    });
    scrollLeftStore = {
      ...scrollLeftStore,
      [filterName]: wrapperRef.current.scrollLeft,
    };
    getHospitalsList(args);
  };

  return (
    hospitalsList.length ? (
      <div className="hospitals-table-wrapper" ref={wrapperRef}>
        <Table className="hospitals-table" sortable>
          <HospitalsTableHeader
            filterName={filterName}
            sortedBy={sortedBy}
            sortingDirection={sortingDirection}
            getHospitalsList={getHospitalsListHandler}
          />
          <HospitalsTableBody
            filterName={filterName}
            hospitalsList={hospitalsList}
            predictedHospitalsList={predictedHospitalsList}
            toggleHospitalModal={toggleHospitalModal}
          />
        </Table>
      </div>
    ) : (
      <div className="no-results-message-wrapper">
        <Header as="h3" className="no-results-message">{NO_RESULTS_MESSAGE}</Header>
      </div>
    )
  );
};
