export const API_BASE_URL = '/api';

const ME = '/me';
const HOSPISCOPE = '/hospiscope';
const SURGISCOPE = '/surgiscope';
const PRICESCOPE = '/pricescope';
const LABSCOPE = '/labscope';
const SAVED_SEARCH = '/saved-search';

export class CoreServiceConfigProvider {
  static getUserInfoEndpoint() {
    return `${API_BASE_URL}${ME}`;
  }

  static getSavedSearchesEndpoint() {
    return `${API_BASE_URL}${HOSPISCOPE}${SAVED_SEARCH}`;
  }

  static getSurgiscopeSavedSearchesEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${SAVED_SEARCH}`;
  }

  static getPricescopeSavedSearchesEndpoint() {
    return `${API_BASE_URL}${PRICESCOPE}${SAVED_SEARCH}`;
  }

  static getLabscopeSavedSearchesEndpoint() {
    return `${API_BASE_URL}${LABSCOPE}${SAVED_SEARCH}`;
  }

  static getDeleteSearchEndpoint({ id }) {
    return `${API_BASE_URL}${HOSPISCOPE}${SAVED_SEARCH}/${id}`;
  }

  static getDeleteSurgiscopeSearchEndpoint({ id }) {
    return `${API_BASE_URL}${SURGISCOPE}${SAVED_SEARCH}/${id}`;
  }

  static getDeletePricescopeSearchEndpoint({ id }) {
    return `${API_BASE_URL}${PRICESCOPE}${SAVED_SEARCH}/${id}`;
  }

  static getDeleteLabscopeSearchEndpoint({ id }) {
    return `${API_BASE_URL}${LABSCOPE}${SAVED_SEARCH}/${id}`;
  }
}
