import React, { useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

import { history } from '../../../../core/routing/history';

import { RemoveModal } from '../../../../core/components/modals/RemoveModal';
import { UserAvatar } from '../../../../core/components/UserAvatar/UserAvatar';

import { EditUserModal } from './modals/EditUserModal';

import { formatServerDate } from '../../../../core/utils/core.utils';

import { USER_PAGE_CONTEXT_TYPES } from '../../../../core/constants/core.constants';

const ACTIVE_USER_LABEL = 'Active';
const DATE_MESSAGE = 'Active since:';

const getUserInfoPageTextsObject = (userPageContext) => {
  return userPageContext === USER_PAGE_CONTEXT_TYPES.ADMIN ? {
    EDIT: 'Edit admin',
    REMOVE: 'Remove admin',
    BACK: 'Back',
  } : {
    EDIT: 'Edit user',
    REMOVE: 'Remove user',
    BACK: 'Back',
  };
};

export const UserInfo = ({
  userPageContext, userInfo, toggleUserStatus, editUser, removeUser,
}) => {
  const {
    user_id: id,
    name,
    first_name: firstName,
    last_name: lastName,
    email,
    is_active: isActive,
    title,
    created_at: createdAt,
  } = userInfo;
  const PAGE_TEXT = getUserInfoPageTextsObject(userPageContext);
  const [shouldShowEditUserModal, setShouldShowEditUserModal] = useState(false);
  const [shouldShowRemoveUserModal, setShouldShowRemoveUserModal] = useState(false);

  function editUserHandler({ user }) {
    setShouldShowEditUserModal(false);
    return editUser({ id, userInfo: user, userPageContext });
  }
  function removeUserHandler() {
    setShouldShowRemoveUserModal(false);
    return removeUser({ id, userPageContext });
  }
  return (
    userInfo ? (
      <div className="user-page-content">
        <div className="userInfo client-page-infoCard">
          <div className="userInfo-block userInfo-avatar">
            <UserAvatar name={name} width={50} height={50} />
          </div>
          <div className="userInfo-block userInfo-name">
            <span>{name}</span>
          </div>
          <div className="userInfo-block userInfo-title">
            <span>{title}</span>
          </div>
          <div className="userInfo-block userInfo-email">
            <span>{email}</span>
          </div>
          <div className="userInfo-block userInfo-createdAt">
            <span>
              {`${DATE_MESSAGE} ${formatServerDate(createdAt)}`}
            </span>
          </div>
          <div className="userInfo-block userInfo-status">
            <Checkbox
              label={ACTIVE_USER_LABEL}
              checked={isActive}
              onChange={(e, { checked }) => toggleUserStatus({ id, isActive: checked, userPageContext })}
            />
          </div>
        </div>
        <div className="userInfo-block userInfo-action">
          <Button
            className="userInfo-button primary"
            onClick={() => setShouldShowEditUserModal(true)}
          >
            {PAGE_TEXT.EDIT}
          </Button>
        </div>
        <div className="userInfo-block userInfo-action">
          <Button
            className="userInfo-button cancel"
            onClick={() => setShouldShowRemoveUserModal(true)}
          >
            {PAGE_TEXT.REMOVE}
          </Button>
        </div>
        <div className="userInfo-block userInfo-action">
          <Button
            basic
            size="small"
            content={PAGE_TEXT.BACK}
            icon="left chevron"
            onClick={history.goBack}
          />
        </div>
        {shouldShowEditUserModal ? (
          <EditUserModal
            open={shouldShowEditUserModal}
            data={{ firstName, lastName, title }}
            headerText={PAGE_TEXT.EDIT}
            onClose={() => setShouldShowEditUserModal(false)}
            submitAction={editUserHandler}
          />
        ) : null}
        {shouldShowRemoveUserModal ? (
          <RemoveModal
            open={shouldShowRemoveUserModal}
            name={name}
            onClose={() => setShouldShowRemoveUserModal(false)}
            removeAction={removeUserHandler}
          />
        ) : null}
      </div>
    ) : null
  );
};
