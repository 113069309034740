import { FILTERS } from '../../../constants/filters.constants';

import {
  GENERAL_INFORMATION_MENU_ITEMS,
} from '../../Filters/FilterItems/GeneralInformation/generalInformation.constants';
import {
  GENERATED_ADMINISTRATIVE_MENU_ITEMS,
} from '../../Filters/FilterItems/Administrative/administrative.constants';
import { EQUIPMENT_INPUTS_LIST } from '../../Filters/FilterItems/Equipment/equipment.constants';
import { PROCEDURES_INPUTS_LIST } from '../../Filters/FilterItems/Procedures/procedures.constants';

const transformIntoFields = (items) => {
  return items.map((e) => {
    return Array.isArray(e) ? e.map((el) => ({ label: el.label, key: el.name })) : { label: e.label, key: e.name };
  }).flat();
};

export const getModalTableFields = (name) => {
  switch (name) {
    case FILTERS.GENERAL_INFORMATION:
      return transformIntoFields(Object.values(GENERAL_INFORMATION_MENU_ITEMS));
    case FILTERS.ADMINISTRATIVE:
      return transformIntoFields(GENERATED_ADMINISTRATIVE_MENU_ITEMS);
    case FILTERS.EQUIPMENT:
      return transformIntoFields(EQUIPMENT_INPUTS_LIST);
    case FILTERS.PROCEDURES:
      return transformIntoFields(PROCEDURES_INPUTS_LIST);
    default:
      return null;
  }
};
