const EXCEL_EXPORT_OPTS = {
  columns:[ 
    {title: ' ', width: {wch: 4}}, // Column width, in characters; space necessary to appear emtpy
    {title: ' ', width: {wch: 24}},
    {title: ' ', width: {wch: 32}},
  ],
  // ySteps: 1, // Leave first data row emtpy, as margin // header row already empty
  xSteps: 1, // Leave first column emtpy 
};

const EXCEL_EXPORT_FOOTER = [
  [{ value: ''}],
  [{ value: ''}],
  [{ value: ''}],
  [{ value: `© ${ new Date().getFullYear() } Global Health Intelligence. All rights reserved.` }],
  [{ value:'https://globalhealthintelligence.com/' }]
];

export const exportFiltersInfo = (title, filters) => {
  const header = [
    [{ value: title, style: { font: { sz: '12', bold: true, italic: true }}}],
    [{ value: '' }],
    [{ value: 'Query date:', style: {font: {bold: true}}}, { value: new Date().toLocaleString() }],
    [{ value: ''}],
    [{ value: 'Query filters:', style: {font: {bold: true}}}],
  ];

  return [{
    ...EXCEL_EXPORT_OPTS,
    data: [ ...header, ...filters, ...EXCEL_EXPORT_FOOTER],
  }];
}

export const sortCountryAndYearFirst = (a, b) => {
  const order = { country: 1, year: 2 };
  if (order[a]) {
    return order[b] ? order[a] - order[b] : -1;
  } else if (order[b]) {
    return 1;
  } else {
    return a.localeCompare(b); 
  }
};