import React from 'react';
import { Table } from 'semantic-ui-react';

import { getSortingDirectionForArrow } from '../../../../../core/utils/core.utils';

import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';

const { ASC, DESC } = SORTING_DIRECTIONS;

export const TABLE_HEADER_CELLS = [
  { label: 'Hospital Name', key: 'hospital_name' },
  { label: 'Hospital ID', key: 'hospital_id' },
  { label: 'Country', key: 'country' },
  { label: 'Year', key: 'year' },
  { label: 'Total conducted', key: 'total_sum' },
];

export const InstitutionsTableHeaderComponent = ({
  sortedBy,
  sortingDirection,
  getInstitutionsList,
}) => {
  return (
    <Table.Header>
      <Table.Row>
        {TABLE_HEADER_CELLS.map((cell) => {
          return (
            <Table.HeaderCell
              key={cell.label}
              sorted={getSortingDirectionForArrow({ key: cell.key, sortedBy, sortingDirection })}
              onClick={() => getInstitutionsList({
                currentPage: 1,
                sortedBy: cell.key,
                sortingDirection: sortingDirection === ASC ? DESC : ASC,
              })}
            >
              {cell.label}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export const InstitutionsTableHeader = InstitutionsTableHeaderComponent;
