import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';

import { FullPageLoader } from '../../../containers/common/FullPageLoader';

export const PrivateRoute = ({
  component: Component,
  roles,
  userData,
  isVerifying,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userData && !isVerifying) {
          // Not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
        if (userData && !isVerifying) {
          if (roles && roles.indexOf(userData.role) === -1) {
            // Role not authorized so redirect to home page
            return <Redirect to={{ pathname: '/' }} />;
          }
          // Authorized so return component
          return <Component {...props} />;
        }
        return <FullPageLoader />;
      }}
    />
  );
};
