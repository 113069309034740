import React from 'react';
import { Table } from 'semantic-ui-react';

import { getSortingDirectionForArrow } from '../../../../../core/utils/core.utils';

import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';

const { ASC, DESC } = SORTING_DIRECTIONS;

export const TABLE_HEADER_CELLS = [
  { label: 'Group', key: 'procedure_group' },
  { label: 'Group Code', key: 'procedure_group_code' },
  { label: 'Category', key: 'procedure_category' },
  { label: 'Category Code', key: 'procedure_category_code' },
  { label: 'Subcategory', key: 'procedure_subcategory' },
  { label: 'Subcategory Code', key: 'procedure_subcategory_code' },
  { label: 'Procedure Name', key: 'procedure_name' },
  { label: 'Procedure Name Code', key: 'procedure_code' },
  { label: 'Number', key: 'sum' },
];

export const ProceduresTableHeaderComponent = ({
  sortedBy,
  sortingDirection,
  getProceduresList,
}) => {
  return (
    <Table.Header>
      <Table.Row>
        {TABLE_HEADER_CELLS.map((cell) => {
          return (
            <Table.HeaderCell
              key={cell.label}
              sorted={getSortingDirectionForArrow({ key: cell.key, sortedBy, sortingDirection })}
              onClick={() => getProceduresList({
                currentPage: 1,
                sortedBy: cell.key,
                sortingDirection: sortingDirection === ASC ? DESC : ASC,
              })}
            >
              {cell.label}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export const ProceduresTableHeader = ProceduresTableHeaderComponent;
