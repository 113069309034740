import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { SearchInput } from './SearchInput';
import { SearchOption } from './SearchOption';

import { renderAcceptButton } from '../../../../hospiscope/components/Filters/utils/filters.ui.utils';

const SEARCH_INPUT_LABEL = 'Value to search';
const SEARCH_INPUT_PLACEHOLDER = 'Write one value, Add it and Accept to filter';
const ADD_VALUE_LABEL = 'Add';

export const AddValueFilter = ({
  filterName,
  filterValue = [],
  onAcceptClick,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState(filterValue || []);

  const searchQueryAlreadyInList = () => selectedItems.indexOf(searchQuery) !== -1;

  const selectItem = () => {
    if (!searchQueryAlreadyInList()) {
      setSelectedItems((arr) => [...arr, searchQuery]);
      return setSearchQuery('');
    }
    return false;
  };

  const deselectItem = (value) => {
    return setSelectedItems((arr) => arr.filter((item) => item !== value));
  };

  const onSearchInputChange = (e, data) => setSearchQuery(data.value.trim());

  const renderSelectedItems = () => {
    return selectedItems.map((item) => {
      return (
        <SearchOption
          key={item}
          label={item}
          checked
          onChange={() => deselectItem(item)}
        />
      );
    });
  };

  return (
    <>
      <div className="filter-item-search">
        <div className="fields-group">
          <SearchInput
            label={SEARCH_INPUT_LABEL}
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            value={searchQuery}
            onChange={onSearchInputChange}
            onKeyUp={(e) => { e.key === 'Enter' && selectItem(); }}
          />
          <Button
            onClick={() => selectItem()}
            active={!searchQueryAlreadyInList()}
            size="tiny"
          >
            {ADD_VALUE_LABEL}
          </Button>
        </div>
      </div>
      <div className="filter-item-form">
        <div className="fields-group">
          <div className="fields-group-options-list">
            {renderSelectedItems()}
          </div>
        </div>
      </div>
      {renderAcceptButton(() => onAcceptClick({ name: filterName, value: selectedItems }))}
    </>
  );
};
