export const EQUIPMENT_INPUTS_LIST = [
  {
    label: 'Ambulances',
    placeholder: 'Number',
    name: 'ambulances',
  },
  {
    label: 'Anesthesia machines',
    placeholder: 'Number',
    name: 'anesthesiaMachines',
  },
  {
    label: 'Angiograms',
    placeholder: 'Number',
    name: 'angiograms',
  },
  {
    label: 'Bone densitometers',
    placeholder: 'Number',
    name: 'boneDensitometers',
  },
  {
    label: 'C-arms',
    placeholder: 'Number',
    name: 'cArms',
  },
  {
    label: 'Cobalt therapy machine',
    placeholder: 'Number',
    name: 'cobaltTherapyMachine',
  },
  {
    label: 'Colposcopes',
    placeholder: 'Number',
    name: 'colposcopes',
  },
  {
    label: 'Dental X-Ray machines',
    placeholder: 'Number',
    name: 'dentalXrayMachines',
  },
  {
    label: 'Echocardiogram machines',
    placeholder: 'Number',
    name: 'echoCardiogramMachines',
  },
  {
    label: 'Electrocardiogram machines',
    placeholder: 'Number',
    name: 'electroCardiogramMachines',
  },
  {
    label: 'Electroencephalogram machines',
    placeholder: 'Number',
    name: 'electroEncephalogramMachines',
  },
  {
    label: 'Endoscopes',
    placeholder: 'Number',
    name: 'endoscopes',
  },
  {
    label: 'Endoscopic lithotripsy equipments',
    placeholder: 'Number',
    name: 'endoscopicLithotripsyEquipment',
  },
  {
    label: 'Endoscopy towers',
    placeholder: 'Number',
    name: 'endoscopyTowers',
  },
  {
    label: 'Fluoroscopes',
    placeholder: 'Number',
    name: 'fluoroscopes',
  },
  {
    label: 'Gamma cameras and nuclear medicine',
    placeholder: 'Number',
    name: 'gammaCameras',
  },
  {
    label: 'Hemodialysis machines',
    placeholder: 'Number',
    name: 'hemodialysisMachines',
  },
  {
    label: 'Hyperbaric chambers',
    placeholder: 'Number',
    name: 'hyberbaricChambers',
  },
  {
    label: 'Incubators',
    placeholder: 'Number',
    name: 'incubators',
  },
  {
    label: 'Infusion pumps',
    placeholder: 'Number',
    name: 'infusionPumps',
  },
  {
    label: 'Laparoscopic surgery equipment',
    placeholder: 'Number',
    name: 'laparoscopicEquipment',
  },
  {
    label: 'Linear accelerators',
    placeholder: 'Number',
    name: 'linearAccelerators',
  },
  {
    label: 'Lithotripter machines',
    placeholder: 'Number',
    name: 'lithotripterMachines',
  },
  {
    label: 'Magnetic resonance imaging',
    placeholder: 'Number',
    name: 'magneticResonanceImagingEquipment',
  },
  {
    label: 'Mastography equipment',
    placeholder: 'Number',
    name: 'mastographyEquipment',
  },
  {
    label: 'Newborn cribs',
    placeholder: 'Number',
    name: 'newBornCribsEquipment',
  },
  {
    label: 'Patient monitoring systems',
    placeholder: 'Number',
    name: 'patientMonitoringSystems',
  },
  {
    label: 'PET / CT systems',
    placeholder: 'Number',
    name: 'petAndCtSystems',
  },
  {
    label: 'PET / MR systems',
    placeholder: 'Number',
    name: 'petMrMachines',
  },
  {
    label: 'Positron emission tomography (PET)',
    placeholder: 'Number',
    name: 'nuclearMedicineSystems',
  },
  {
    label: 'Radiant heat cribs',
    placeholder: 'Number',
    name: 'radiantHeatCribs',
  },
  {
    label: 'Radiotherapy equipment',
    placeholder: 'Number',
    name: 'radiotherapyEquipment',
  },
  {
    label: 'Stereotactic mammography machines',
    placeholder: 'Number',
    name: 'stereotacticMammographyMachines',
  },
  {
    label: 'Surgical generators - Advanced energy',
    placeholder: 'Number',
    name: 'surgicalGeneratorsAdvancedEnergyEquipment',
  },
  {
    label: 'Sterilization machines - High Temperature',
    placeholder: 'Number',
    name: 'highTemperatureSterilizationMachines',
  },
  {
    label: 'Sterilization machines - Low Temperature',
    placeholder: 'Number',
    name: 'lowTemperatureSterilizationMachines',
  },
  {
    label: 'Surgery tables',
    placeholder: 'Number',
    name: 'surgeryTableEquipment',
  },
  {
    label: 'Surgical generators - Basic energy',
    placeholder: 'Number',
    name: 'surgicalGeneratorsBasicEnergyEquipment',
  },
  {
    label: 'Tomography scanners',
    placeholder: 'Number',
    name: 'tomographyScanners',
  },
  {
    label: 'Ultrasound machines',
    placeholder: 'Number',
    name: 'ultrasoundMachines',
  },
  {
    label: 'Vacuum assisted biopsy equipment',
    placeholder: 'Number',
    name: 'vacuumAssistedBiopsyEquipment',
  },
  {
    label: 'Ventilators',
    placeholder: 'Number',
    name: 'ventilators',
  },
  {
    label: 'X-Ray machines',
    placeholder: 'Number',
    name: 'fixedAndMobileXRayMachines',
  },
];
