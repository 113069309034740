import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Loader } from 'semantic-ui-react';
import { UsersTable } from './UsersTable/UsersTable';

import {
  getUsersList as getUsersListAction,
} from '../../../store/clientPage/clientPage.actions';

import { clientPageSelector } from '../../../store/clientPage/clientPage.selector';
import { NO_USERS_MESSAGE } from './constants/user.constants';

const LOADER_TEXT = 'Loading users list...';

export const UsersComponent = ({
  id, usersList, isUsersListLoading, getUsersList,
}) => {
  useEffect(() => {
    getUsersList({ clientId: id });
  }, [getUsersList, id]);

  const renderNoUserMessage = () => {
    return <div className="client-page-noUsers"><span>{NO_USERS_MESSAGE}</span></div>;
  };

  const renderUsersTable = () => <UsersTable usersList={usersList} />;

  const renderUsers = () => (usersList && usersList.length ? renderUsersTable() : renderNoUserMessage());

  const renderLoader = () => {
    return <div className="client-page-usersLoader"><Loader active>{LOADER_TEXT}</Loader></div>;
  };

  return (
    <div className="client-page-users segment-block">
      {isUsersListLoading ? renderLoader() : renderUsers()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersList: clientPageSelector.usersList(state),
  isUsersListLoading: clientPageSelector.isUsersListLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getUsersList: getUsersListAction,
}, dispatch);

export const Users = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersComponent);
