import React from 'react';
import { Button } from 'semantic-ui-react';

import { history } from '../../../../core/routing/history';

import { CLIENT_DASHBOARD_HEADER_TEXTS } from '../constants/clientsDashboard.constants';

export const ClientsDashboardHeader = ({
  onButtonClick,
}) => {
  const { BACK, ADD_CLIENT } = CLIENT_DASHBOARD_HEADER_TEXTS;
  return (
    <div className="clients-dashboard-header segment-block">
      <div className="clients-dashboard-header-welcome" />
      <div className="clients-dashboard-header-actions">
        <Button
          basic
          className="client-page-backButton"
          content={BACK}
          icon="left chevron"
          onClick={history.goBack}
        />
        <Button className="clients-dashboard-header-button" onClick={onButtonClick}>
          {ADD_CLIENT}
        </Button>
      </div>
    </div>
  );
};
