import React from 'react';
import { Table } from 'semantic-ui-react';

import { UsersTableHeader } from './UsersTableHeader';
import { UsersTableBody } from './UsersTableBody';

export const UsersTable = ({ usersList }) => {
  return (
    <Table className="client-page-table">
      <UsersTableHeader />
      <UsersTableBody usersList={usersList} />
    </Table>
  );
};
