import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button, Modal, Table,
} from 'semantic-ui-react';
import ReactExport from 'react-data-export';

import { mainViewCfg, singleRowModalCfg } from '../../config';
import { isEmpty, toTitleCase } from '../../../../core/utils/core.utils';
import { mainViewDataSelector } from '../../store/MainViewData/mainViewData.selector';
import { filtersSelector } from '../../store/filters/filters.selector';

import {
  performSearch as performSearchAction,
} from '../../store/filters/filters.actions';

import {
  prepareMainViewRowsForExcel,
  prepareFiltersInfoForExport,
} from '../../utils/pricescope.utils';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;

const EXCEL_FILENAME = 'PriceScope - Single Transaction';

export const SingleRowModalComponent = ({
  open,
  selectedRowData,
  filterValues,
  onClose,
  performSearch,
}) => {
  const dataRow = (key, label, val) => {
    const onFilterClick = (event) => {
      event.stopPropagation();
      onClose();
      return performSearch({ filterName: key, filterValue: [val] });
    };

    const columnHasNotes = singleRowModalCfg.columnNotes[selectedRowData.country]?.[key]
      ? '*' : '';

    const filterButton = mainViewCfg.filterFromModal.includes(key)
      ? <Button icon="filter" size="mini" compact onClick={onFilterClick} />
      : '';

    return (
      <Table.Row key={label}>
        <Table.Cell>
          <strong>
            {label}
            {columnHasNotes}
          </strong>
        </Table.Cell>
        <Table.Cell>
          {val}
          &nbsp;
          {filterButton}
        </Table.Cell>
      </Table.Row>
    );
    // return (
    //   <p key={'MainModal'+ label}>
    //   <span><strong>{label}</strong></span>
    //   <span>{val}</span>
    //   </p>
    //   )
  };

  const tenderItem = Object.entries(selectedRowData).reduce((result, [key, val]) => {
    const col = mainViewCfg.columns[key];

    if (!isEmpty(val) && col?.label) {
      result.push(dataRow(key, col.label, col?.formatValue ? col.formatValue(val, key, selectedRowData) : val));
    }
    return result;
  }, []);

  const itemJson = Object.entries(selectedRowData.item_json).reduce((result, [key, val]) => {
    if (!isEmpty(val)) {
      const formattedVal = singleRowModalCfg.formatCol[key]
        ? singleRowModalCfg.formatCol[key](val, key, selectedRowData) : val;
      result.push(dataRow(key, toTitleCase(key), formattedVal));
    }
    return result;
  }, []);

  const tenderJson = Object.entries(selectedRowData.tender_json).reduce((result, [key, val]) => {
    if (!isEmpty(val)) {
      const formattedVal = singleRowModalCfg.formatCol[key]
        ? singleRowModalCfg.formatCol[key](val, key, selectedRowData) : val;
      result.push(dataRow(key, toTitleCase(key), formattedVal));
    }
    return result;
  }, []);

  // const data = tenderItem.concat(itemJson, tenderJson)

  const ColumnNotes = () => {
    if (!singleRowModalCfg.columnNotes[selectedRowData.country]) { return null; }

    const notes = Object.entries(singleRowModalCfg.columnNotes[selectedRowData.country])
    /* eslint-disable-next-line react/jsx-one-expression-per-line */
      .map(([key, val]) => <p key={key}><strong>{mainViewCfg.columns[key].label}:</strong>{' '}{val}</p>);
      // .map(([key, val]) => (<p key={key}
      //     dangerouslySetInnerHTML={{__html:  `<strong>${mainViewCfg.columns[key].label}:</strong> ${val}`}}>
      //   </p>
      // ));

    return (
      <Table.Row key="ColumnNotes">
        <Table.Cell colSpan={2}>
          <p><strong>* Data Notes</strong></p>
          {notes}
        </Table.Cell>
      </Table.Row>
    );
  };

  const StripedTable = ({ children }) => {
    return (
      <Table striped>
        <Table.Body>
          {children}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Modal open={open} onClose={onClose} className="proceduresModal">
      <Modal.Header>
        <div className="hospital-modal-header">
          <div className="header-data">
            <div className="header-data-column">
              <p className="hospital-name">
                Tender Info
              </p>
            </div>
          </div>
          <div className="header-buttons">
            <ExcelFile
              filename={EXCEL_FILENAME}
              element={<Button>Download</Button>}
            >
              <ExcelSheet
                dataSet={prepareFiltersInfoForExport(filterValues)}
                name="Filter data"
              />
              <ExcelSheet
                dataSet={prepareMainViewRowsForExcel([selectedRowData], true)}
                name="Transaction"
              />
            </ExcelFile>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <StripedTable>
          {tenderItem}
          {itemJson}
          {tenderJson}
          <ColumnNotes />
        </StripedTable>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedRowData: mainViewDataSelector.selectedRowData(state),
  filterValues: filtersSelector.filterValues(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  performSearch: performSearchAction,
}, dispatch);

export const SingleRowModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SingleRowModalComponent);
