import { getProceduresState } from '../procedures.selector';

function getInstitutionsDataState(state) {
  return getProceduresState(state).institutionsData;
}

export const institutionsDataSelector = {
  isLoading(state) {
    return getInstitutionsDataState(state).isLoading;
  },
  proceduresList(state) {
    return getInstitutionsDataState(state).proceduresList;
  },
  proceduresCount(state) {
    return getInstitutionsDataState(state).proceduresCount;
  },
  pagination(state) {
    return getInstitutionsDataState(state).pagination;
  },
  isProceduresModalLoading(state) {
    return getInstitutionsDataState(state).isProceduresModalLoading;
  },
  shouldShowProceduresModal(state) {
    return getInstitutionsDataState(state).shouldShowProceduresModal;
  },
  institutionsList(state) {
    return getInstitutionsDataState(state).institutionsList;
  },
  selectedInstitutionId(state) {
    return getInstitutionsDataState(state).selectedInstitutionId;
  },
  selectedInstitution(state, selectedInstitutionId) {
    const institutionsList = institutionsDataSelector.institutionsList(state);
    return institutionsList.find(({ id }) => id === selectedInstitutionId);
  },
  sortedBy(state) {
    return getInstitutionsDataState(state).sortedBy;
  },
  sortingDirection(state) {
    return getInstitutionsDataState(state).sortingDirection;
  },
  tableView(state) {
    return getInstitutionsDataState(state).tableView;
  },
};
