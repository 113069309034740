import { HospitalsServiceApiProvider } from '../../services/HospitalsServiceApiProvider';

import { replaceAllFilterValues } from '../../../common/store/filters/replaceFilterValues.action';

import { getOffset } from '../../../../core/utils/core.utils';

import { filtersSelector } from '../../../common/store/filters/filters.selector';

import {
  HOSPITAL_NAME_FIELD,
  SORTING_DIRECTIONS,
  DEFAULT_PAGE_LIMIT,
} from '../../../../core/constants/core.constants';

export const GET_HOSPITALS_LIST_REQUEST = '[HOSPITALS] GET HOSPITALS LIST REQUEST';
export const GET_HOSPITALS_LIST_SUCCESS = '[HOSPITALS] GET HOSPITALS LIST SUCCESS';
export const GET_HOSPITALS_LIST_FAILURE = '[HOSPITALS] GET HOSPITALS LIST FAILURE';

export const GET_HOSPITALS_CALCULATED_DATA_REQUEST = '[HOSPITALS] GET HOSPITALS CALCULATED DATA REQUEST';
export const GET_HOSPITALS_CALCULATED_DATA_SUCCESS = '[HOSPITALS] GET HOSPITALS CALCULATED DATA SUCCESS';
export const GET_HOSPITALS_CALCULATED_DATA_FAILURE = '[HOSPITALS] GET HOSPITALS CALCULATED DATA FAILURE';

export function getHospitalsCalculatedData() {
  return async (dispatch, getState) => {
    try {
      const filterValues = filtersSelector.filterValues(getState());
      dispatch({
        type: GET_HOSPITALS_CALCULATED_DATA_REQUEST,
      });
      const { data } = await HospitalsServiceApiProvider.getHospitalsCalculatedData({ filterValues });
      const { data: predictedData } = await HospitalsServiceApiProvider.getHospitalsPredictedCalculatedData({
        filterValues,
      });
      return dispatch({
        type: GET_HOSPITALS_CALCULATED_DATA_SUCCESS,
        payload: {
          hospitalsCalculatedData: data,
          hospitalsPredictedCalculatedData: predictedData,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_HOSPITALS_CALCULATED_DATA_FAILURE,
        payload: error,
      });
    }
  };
}

export function getHospitalsList({
  limit = DEFAULT_PAGE_LIMIT,
  currentPage,
  sortedBy = HOSPITAL_NAME_FIELD,
  sortingDirection = SORTING_DIRECTIONS.ASC,
}) {
  return async (dispatch, getState) => {
    const filterValues = filtersSelector.filterValues(getState());
    dispatch({
      type: GET_HOSPITALS_LIST_REQUEST,
    });
    try {
      const filterConfig = {
        limit,
        offset: getOffset({ currentPage }),
        sortedBy,
        sortingDirection,
        filterValues,
      };
      const { data } = await HospitalsServiceApiProvider.getHospitalsList(filterConfig);
      const { data: predictedData } = await HospitalsServiceApiProvider.getPredictedHospitalsList(filterConfig);
      return dispatch({
        type: GET_HOSPITALS_LIST_SUCCESS,
        payload: {
          hospitalsList: data.hospitals,
          predictedHospitalsList: predictedData.hospitals,
          count: data.count,
          currentPage,
          sortedBy,
          sortingDirection,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_HOSPITALS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const loadSavedSearch = ({ filterValues }) => (dispatch, getState) => {
  replaceAllFilterValues({ filterValues })(dispatch);
  return getHospitalsList({ currentPage: 1 })(dispatch, getState);
};
