import React, { useState } from 'react';
import { Form, Modal } from 'semantic-ui-react';

import {
  renderModalActionButton,
} from '../../../containers/hospiscope/components/modals/utils/modals.utils';

const MODAL_TEXT = 'Accept';
const EMPTY_FIELD_ERROR = 'Field is required';

export const OneInputModal = ({
  open,
  inputValue = '',
  inputName = 'name',
  inputLabel = 'Name',
  headerText,
  onClose,
  submitAction,
  className = '',
}) => {
  const [newValue, setNewValue] = useState(inputValue);
  const [formError, setFormError] = useState(null);
  function handleFieldChange({ value }) {
    setFormError(null);
    setNewValue(value);
  }
  function addClient() {
    if (!newValue) {
      return setFormError(EMPTY_FIELD_ERROR);
    }
    return submitAction(newValue);
  }
  return (
    <Modal open={open} onClose={onClose} size="mini" className={`one-input-modal ${className}`}>
      {headerText ? (
        <Modal.Header>
          {headerText}
        </Modal.Header>
      ) : null}
      <Modal.Content>
        <Modal.Description>
          <>
            <div className="input-field-container input-group-container">
              <Form onSubmit={addClient}>
                <Form.Field className="input-field-inner">
                  <Form.Input
                    value={newValue}
                    name={inputName}
                    label={inputLabel}
                    placeholder="Type here"
                    onChange={({ target }) => handleFieldChange(target)}
                    error={formError}
                  />
                </Form.Field>
              </Form>
            </div>
          </>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({
          clickHandler: addClient,
          text: MODAL_TEXT,
        })}
      </Modal.Actions>
    </Modal>
  );
};
