import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';

import { DownloadModal } from '../modals/DownloadModal';

import { performSearch as performSearchAction } from '../../store/filters/filters.actions';

import { setTableView as setTableViewAction } from '../../store/proceduresData/proceduresData.actions';

import { userSelector } from '../../../../core/user/user.selector';
import { filtersSelector } from '../../store/filters/filters.selector';
import { proceduresDataSelector } from '../../store/proceduresData/proceduresData.selector';

const RESULTS = 'Results';
const DOWNLOAD_BUTTON_TEXT = 'Download';
const CLEAR_ALL_FILTERS_BUTTON_TEXT = 'Clear All Filters';

export const SurgiscopeDataHeaderComponent = ({
  items = [],
  count,
  filterValues,
  userData,
  tableView,
  performSearch,
}) => {
  const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState(false);

  function renderResultsMessage() {
    return count > items.length ? `${count} found` : `${items.length || 0} found`;
  }

  function clearAll() {
    return performSearch({ shouldClearFilters: true });
  }

  return (
    <>
      <div className="proceduresDataHeader">
        <div className="proceduresDataHeader-text">
          <div className="proceduresDataHeader-title">{RESULTS}</div>
          <div className="proceduresDataHeader-count">
            {renderResultsMessage()}
          </div>
        </div>
        <div className="proceduresDataHeader-actions">
          <div className="proceduresDataHeader-action" onClick={() => setShouldShowDownloadModal(true)}>
            <div className="proceduresDataHeader-action-text">
              {DOWNLOAD_BUTTON_TEXT}
            </div>
            <Icon name="download" size="small" />
          </div>
          <div className="proceduresDataHeader-action" onClick={clearAll}>
            <div className="proceduresDataHeader-clear">
              {CLEAR_ALL_FILTERS_BUTTON_TEXT}
            </div>
          </div>
        </div>
      </div>
      <DownloadModal
        role={userData.role}
        filterValues={filterValues}
        open={shouldShowDownloadModal}
        count={count}
        tableView={tableView}
        onCancel={() => setShouldShowDownloadModal(false)}
        onClose={() => setShouldShowDownloadModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  filterValues: filtersSelector.filterValues(state),
  userData: userSelector.userData(state),
  tableView: proceduresDataSelector.tableView(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  performSearch: performSearchAction,
  setTableView: setTableViewAction,
}, dispatch);

export const SurgiscopeDataHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurgiscopeDataHeaderComponent);
