export const GENERATED_ADMINISTRATIVE_MENU_ITEMS = [
  {
    label: 'Directors',
    placeholder: 'Number',
    name: 'directives',
  },
  {
    label: 'Executives',
    placeholder: 'Number',
    name: 'administratives',
  },
  {
    label: 'Nurses',
    placeholder: 'Number',
    name: 'nurses',
  },
  {
    label: 'Radiologist technicians',
    placeholder: 'Number',
    name: 'radiologistTechnicians',
  },
  {
    label: 'Radiologists',
    placeholder: 'Number',
    name: 'radiologists',
  },
];
