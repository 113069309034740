import {
  GET_AVAILABLE_COUNTRIES_REQUEST,
  GET_AVAILABLE_COUNTRIES_SUCCESS,
  GET_AVAILABLE_COUNTRIES_FAILURE,
  GET_FILTERING_OPTIONS_REQUEST,
  GET_FILTERING_OPTIONS_SUCCESS,
  GET_FILTERING_OPTIONS_FAILURE,
  SET_FILTER_VALUE,
  GET_AVAILABLE_CITIES_REQUEST,
  GET_AVAILABLE_CITIES_SUCCESS,
  GET_AVAILABLE_STATES_REQUEST,
  GET_AVAILABLE_STATES_SUCCESS,
  SET_ACTIVE_FILTER,
  CLEAR_FILTERS_DEPENDENT_ON_COUNTRY,
  CLEAR_FILTERS,
} from './filters.actions';
import { REPLACE_ALL_FILTER_VALUES } from './replaceFilterValues.action';
import { CLEAR_STORE } from '../../../../core/user/user.actions';

import { FILTERS } from '../../constants/filters.constants';

// import { getLastAvailableYearOption } from '../../../../core/utils/core.utils';

export const initialState = {
  areFilteringOptionsLoading: true,
  yearOptionsWereLoaded: false,
  filteringOptions: {
    yearOptions: [],
    affiliationOptions: [],
    natureOfEstablishmentOptions: [],
    typeOfEstablishmentOptions: [],
    levelOfCareOptions: [],
    typeOfInternetAccessOptions: [],
  },
  areAvailableCountriesLoading: true,
  availableCountries: [],
  availableSurgiscopeCountries: [],
  areAvailableStatesLoading: false,
  availableStates: [],
  areAvailableCitiesLoading: false,
  availableCities: [],
  activeFilter: null,
  filterValues: {
    [FILTERS.YEAR]: [],
    [FILTERS.COUNTRIES]: [],
    [FILTERS.SEARCH]: '',
  },
};

export function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTER_VALUE: {
      const { filterName, filterValue } = action.payload;
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [filterName]: filterValue,
        },
      };
    }
    case GET_AVAILABLE_COUNTRIES_REQUEST: {
      return {
        ...state,
        areAvailableCountriesLoading: true,
      };
    }
    case GET_AVAILABLE_COUNTRIES_SUCCESS: {
      const { availableCountries, availableSurgiscopeCountries } = action.payload;
      return {
        ...state,
        areAvailableCountriesLoading: false,
        availableCountries,
        availableSurgiscopeCountries,
      };
    }
    case GET_AVAILABLE_COUNTRIES_FAILURE: {
      return {
        ...state,
        areAvailableCountriesLoading: false,
      };
    }
    case GET_FILTERING_OPTIONS_REQUEST: {
      return {
        ...state,
        areFilteringOptionsLoading: true,
      };
    }
    case GET_FILTERING_OPTIONS_SUCCESS: {
      const { filteringOptions } = action.payload;
      // const lastYear = getLastAvailableYearOption(filteringOptions.yearOptions);
      return {
        ...state,
        areFilteringOptionsLoading: false,
        yearOptionsWereLoaded: true,
        filteringOptions,
        filterValues: {
          ...state.filterValues,
          // [FILTERS.YEAR]: !state.yearOptionsWereLoaded ? lastYear : state.filterValues[FILTERS.YEAR],
        },
      };
    }
    case GET_FILTERING_OPTIONS_FAILURE: {
      return {
        ...state,
        areFilteringOptionsLoading: false,
      };
    }
    case GET_AVAILABLE_STATES_REQUEST: {
      return {
        ...state,
        areAvailableStatesLoading: true,
      };
    }
    case GET_AVAILABLE_STATES_SUCCESS: {
      const { availableStates } = action.payload;
      return {
        ...state,
        areAvailableStatesLoading: false,
        availableStates,
      };
    }
    case GET_AVAILABLE_CITIES_REQUEST: {
      return {
        ...state,
        areAvailableCitiesLoading: true,
      };
    }
    case GET_AVAILABLE_CITIES_SUCCESS: {
      const { availableCities } = action.payload;
      return {
        ...state,
        areAvailableCitiesLoading: false,
        availableCities,
      };
    }
    case SET_ACTIVE_FILTER: {
      const { activeFilter } = action.payload;
      return {
        ...state,
        activeFilter,
      };
    }
    case CLEAR_FILTERS_DEPENDENT_ON_COUNTRY: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [FILTERS.GENERAL_INFORMATION]: {
            ...state.filterValues[FILTERS.GENERAL_INFORMATION],
            affiliation: null,
            levelOfCare: null,
            typeOfEstablishment: null,
            state: [],
            city: [],
          },
        },
      };
    }
    case CLEAR_FILTERS: {
      return {
        ...state,
        filterValues: initialState.filterValues,
      };
    }
    case REPLACE_ALL_FILTER_VALUES: {
      const { filterValues } = action.payload;
      return {
        ...state,
        filterValues,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
