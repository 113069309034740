export const INFRASTRUCTURE_MENU_ITEMS = {
  TOTAL_ROOMS: {
    label: 'Rooms (total)',
    placeholder: 'Number',
    name: 'totalRooms',
  },
};

export const GENERATED_INFRASTRUCTURE_MENU_ITEMS = {
  FIRST_SECTION: [
    {
      label: 'General Operating Rooms',
      placeholder: 'Number',
      name: 'generalOperatingRooms',
    },
    {
      label: 'Birthing Rooms',
      placeholder: 'Number',
      name: 'birthingRooms',
    },
    {
      label: 'Endoscopy Rooms',
      placeholder: 'Number',
      name: 'endoscopyRooms',
    },
    {
      label: 'General Ambulatory Rooms',
      placeholder: 'Number',
      name: 'generalAmbulatoryRooms',
    },
    {
      label: 'Cystoscopy and Urology Rooms',
      placeholder: 'Number',
      name: 'cystoscopyAndUrologyRooms',
    },
    {
      label: 'Multiple Use Rooms',
      placeholder: 'Number',
      name: 'multipleUseRooms',
    },
    {
      label: 'Intensive Care Units',
      placeholder: 'Number',
      name: 'intensiveCareUnits',
    },
    {
      label: 'Pediatric Intensive Care Units',
      placeholder: 'Number',
      name: 'pediatricIntensiveCareUnits',
    },
    {
      label: 'Neonatal Intensive Care units',
      placeholder: 'Number',
      name: 'neonatalIntensiveCareUnits',
    },
    {
      label: 'Intermediary Care Units',
      placeholder: 'Number',
      name: 'intermediaryCareUnits',
    },
    {
      label: 'Cath Labs',
      placeholder: 'Number',
      name: 'cathLabs',
    },
  ],
};
