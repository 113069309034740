export const SPECIALTIES_CHECKBOXES_LIST = [
  { label: 'Addiction rehab', name: 'addictionRehab' },
  { label: 'Ambulatory', name: 'ambulatory' },
  { label: 'Anesthesiology', name: 'anesthesiology' },
  { label: 'Cardiology', name: 'cardiology' },
  { label: 'Chronic care', name: 'chronicCare' },
  { label: 'Dermatology', name: 'dermatology' },
  { label: 'Diabetes', name: 'diabetes' },
  { label: 'Ear nose throat', name: 'earNoseThroat' },
  { label: 'Emergency', name: 'emergency' },
  { label: 'Fertility', name: 'fertility' },
  { label: 'Gastroenterology', name: 'gastroenterology' },
  { label: 'General Medicine', name: 'generalMedicine' },
  { label: 'Geriatrics', name: 'geriatrics' },
  { label: 'Gynecology', name: 'gynecology' },
  { label: 'Hematology', name: 'hematology' },
  { label: 'Hemodialysis', name: 'hemodialysis' },
  { label: 'Home Care', name: 'homeCare' },
  { label: 'Maternal and child', name: 'maternalAndChild' },
  { label: 'Neonatal', name: 'neonatal' },
  { label: 'Nephrology', name: 'nephrology' },
  { label: 'Neurology', name: 'neurology' },
  { label: 'Oncology', name: 'oncology' },
  { label: 'Ophthalmology', name: 'ophthalmology' },
  { label: 'Orthodontics', name: 'orthodontics' },
  { label: 'Orthopedics', name: 'orthopedics' },
  { label: 'Other', name: 'otherSpecialties' },
  { label: 'Pediatrics', name: 'pediatrics' },
  { label: 'Physical therapy', name: 'physicalTherapy' },
  { label: 'Plastic and reconstructive surgery', name: 'plasticSurgery' },
  { label: 'Psychiatry', name: 'psychiatry' },
  { label: 'Radiology and imaging', name: 'radiology' },
  { label: 'Sleep therapy', name: 'sleepTherapy' },
  { label: 'Transplants', name: 'transplants' },
  { label: 'Trauma', name: 'trauma' },
  { label: 'Urology', name: 'urology' },
];
