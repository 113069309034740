import { toast } from 'react-toastify';
import { ProceduresServiceApiProvider } from '../../services/ProceduresServiceApiProvider';

import { getProceduresList } from '../proceduresData/proceduresData.actions';
import { getInstitutionsList } from '../institutionsData/institutionsData.actions';

import {
  PROCEDURES_TABLE_VIEWS,
  TOAST_CONSTANTS,
} from '../../../../core/constants/core.constants';
import { FILTER_NAME_TO_OPTIONS_LIST_KEY, LIST_OF_FILTERS_TO_CLEAR } from '../../constants/filters.constants';

import { filtersSelector } from './filters.selector';
import { proceduresDataSelector } from '../proceduresData/proceduresData.selector';

export const SET_ACTIVE_FILTER = '[SURGISCOPE FILTERS] SET ACTIVE FILTER';
export const SET_FILTER_VALUE = '[SURGISCOPE FILTERS] SET FILTER VALUE';

export const GET_FILTERING_OPTIONS_REQUEST = '[SURGISCOPE FILTERS] GET FILTERING OPTIONS REQUEST';
export const GET_FILTERING_OPTIONS_SUCCESS = '[SURGISCOPE FILTERS] GET FILTERING OPTIONS SUCCESS';
export const GET_FILTERING_OPTIONS_FAILURE = '[SURGISCOPE FILTERS] GET FILTERING OPTIONS FAILURE';

export const CLEAR_FILTERS_IF_NEEDED = '[SURGISCOPE FILTERS] CLEAR FILTERS IF NEEDED';

export const GET_AVAILABLE_HOSPITAL_NAMES_REQUEST = '[SURGISCOPE FILTERS] GET AVAILABLE HOSPITAL NAMES REQUEST';
export const GET_AVAILABLE_HOSPITAL_NAMES_SUCCESS = '[SURGISCOPE FILTERS] GET AVAILABLE HOSPITAL NAMES SUCCESS';

export const setFilterValue = ({ filterName, filterValue }) => (dispatch) => {
  return dispatch({
    type: SET_FILTER_VALUE,
    payload: {
      filterName,
      filterValue,
    },
  });
};

export const setActiveFilter = (activeFilter) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_FILTER,
    payload: {
      activeFilter,
    },
  });
};

export const getFilteringOptions = (filterName, filterValue) => async (dispatch, getState) => {
  const state = getState();
  const currentOptionsListKey = FILTER_NAME_TO_OPTIONS_LIST_KEY[filterName];
  dispatch({
    type: GET_FILTERING_OPTIONS_REQUEST,
  });
  const filterValues = filtersSelector.filterValues(state);
  const filterHasSelectedValues = filterValue && filterValue.length;
  const currentFilterOptions = filtersSelector.filteringOptions(state)[currentOptionsListKey];
  try {
    const { data } = await ProceduresServiceApiProvider.getFilteringOptions(filterValues);
    return dispatch({
      type: GET_FILTERING_OPTIONS_SUCCESS,
      payload: {
        filteringOptions: {
          groups: data.groups,
          categories: data.categories,
          subcategories: data.subcategories,
          procedures: data.procedures,
          year: data.year,
          // Making sure we are not clearing filter with loaded or selected options
          [currentOptionsListKey]: filterHasSelectedValues ? currentFilterOptions : data[currentOptionsListKey],
        },
      },
    });
  } catch (e) {
    toast.error(TOAST_CONSTANTS.FILTERING_OPTIONS_ERROR);
    return dispatch({
      type: GET_FILTERING_OPTIONS_FAILURE,
    });
  }
};

export const clearFiltersIfNeeded = (filtersToClear) => (dispatch) => {
  dispatch({
    type: CLEAR_FILTERS_IF_NEEDED,
    payload: {
      filtersToClear,
    },
  });
};

export const performSearch = ({
  filterName,
  filterValue,
  shouldClearFilters,
}) => (dispatch, getState) => {
  const tableView = proceduresDataSelector.tableView(getState());
  setActiveFilter(null)(dispatch);
  if (shouldClearFilters) {
    clearFiltersIfNeeded(Object.keys(LIST_OF_FILTERS_TO_CLEAR))(dispatch);
  }
  setFilterValue({ filterName, filterValue })(dispatch);
  getFilteringOptions(filterName, filterValue)(dispatch, getState);
  return tableView === PROCEDURES_TABLE_VIEWS.PROCEDURES
    ? getProceduresList({ currentPage: 1 })(dispatch, getState)
    : getInstitutionsList({ currentPage: 1 })(dispatch, getState);
};

export const getAvailableHospitalNames = ({
  name,
}) => async (dispatch, getState) => {
  const country = filtersSelector.selectedCountry(getState());
  dispatch({
    type: GET_AVAILABLE_HOSPITAL_NAMES_REQUEST,
  });
  const { data } = await ProceduresServiceApiProvider.getHospitalNames({ country, name });
  return dispatch({
    type: GET_AVAILABLE_HOSPITAL_NAMES_SUCCESS,
    payload: {
      hospitalNames: data,
    },
  });
};
