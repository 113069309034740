/* This action is separated because of the dependency cycle */

export const REPLACE_ALL_FILTER_VALUES = '[SURGISCOPE FILTERS] REPLACE ALL FILTER VALUES';

export const replaceAllFilterValues = ({ filterValues }) => (dispatch) => {
  dispatch({
    type: REPLACE_ALL_FILTER_VALUES,
    payload: {
      filterValues,
    },
  });
};
