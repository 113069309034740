import {
  GET_ADMINS_LIST_REQUEST,
  GET_ADMINS_LIST_SUCCESS,
  GET_ADMINS_LIST_FAILURE, SET_LOADING_STATE,
} from './adminsDashboard.actions';

import { CLEAR_STORE } from '../../../../core/user/user.actions';

export const initialState = {
  isLoading: false,
  adminsList: [],
};

export function adminsDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADMINS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ADMINS_LIST_SUCCESS: {
      const { adminsList } = action.payload;
      return {
        ...state,
        isLoading: false,
        adminsList: adminsList || [],
      };
    }
    case GET_ADMINS_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SET_LOADING_STATE: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
