import React, { useState } from 'react';
import {
  Button, Icon, Label,
} from 'semantic-ui-react';

import { CountriesModal } from '../../../../../core/components/modals/CountriesModal';

import { areStringArraysEqual } from '../../../../../core/utils/core.utils';

const COUNTRIES_PICKER_BUTTON_TEXT = 'Select countries';
const COUNTRIES_PICKER_BUTTON_ADD_TEXT = 'Add countries';

export const CountriesPicker = ({
  availableCountries,
  selectedCountries,
  filterName,
  setFilterValue,
  clearFiltersDependentOnCountry,
  performSearch,
  getFilteringOptions,
}) => {
  const [isCountriesModalOpened, setIsCountriesModalOpened] = useState(false);
  function submitSelectedCountries(countriesList) {
    setIsCountriesModalOpened(false);
    if (!areStringArraysEqual(selectedCountries, countriesList)) {
      clearFiltersDependentOnCountry();
      getFilteringOptions(countriesList);
      return performSearch({ filterName, filterValue: countriesList });
    }
    return false;
  }
  function removeSelectedCountry(countryKey) {
    const filterValue = selectedCountries.filter((country) => country !== countryKey);
    setFilterValue({ filterName, filterValue });
    clearFiltersDependentOnCountry();
    getFilteringOptions(filterValue);
    return performSearch({ filterName, filterValue });
  }
  function renderSelectedCountriesList() {
    return (
      <div className="selected-countries-list">
        <Button onClick={() => setIsCountriesModalOpened(true)}>
          {selectedCountries.length ? COUNTRIES_PICKER_BUTTON_ADD_TEXT : COUNTRIES_PICKER_BUTTON_TEXT}
        </Button>
        {selectedCountries.length ? selectedCountries.map((country) => (
          <Label key={country}>
            {country}
            <Icon name="delete" onClick={() => removeSelectedCountry(country)} />
          </Label>
        )) : null}
      </div>
    );
  }
  return (
    <>
      <div className="countries-picker">
        {renderSelectedCountriesList(selectedCountries)}
      </div>
      {isCountriesModalOpened ? (
        <CountriesModal
          open={isCountriesModalOpened}
          availableCountries={availableCountries}
          selectedCountries={selectedCountries}
          onClose={() => setIsCountriesModalOpened(false)}
          onSubmit={submitSelectedCountries}
        />
      ) : null}
    </>
  );
};
