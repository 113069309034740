import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';

import { ProceduresListTableHeader } from './ProceduresListTableHeader';
import { ProceduresListTableBody } from './ProceduresListTableBody';

import { sortList } from '../../../../../../core/utils/core.utils';

import { SORTING_DIRECTIONS } from '../../../../../../core/constants/core.constants';

const DEFAULT_SORTING_PARAM = 'procedure_name';

export const ProceduresListTable = ({
  proceduresList,
}) => {
  const [sortedBy, setSortedBy] = useState(DEFAULT_SORTING_PARAM);
  const [sortingDirection, setSortingDirection] = useState(SORTING_DIRECTIONS.DESC);
  return (
    <Table className="hospitals-table" sortable>
      <ProceduresListTableHeader
        sortedBy={sortedBy}
        sortingDirection={sortingDirection}
        setSortedBy={setSortedBy}
        setSortingDirection={setSortingDirection}
      />
      <ProceduresListTableBody proceduresList={sortList({ list: proceduresList, sortedBy, sortingDirection })} />
    </Table>
  );
};
