import { axiosService } from '../../../core/services/axios.service';
import { AdminServiceConfigProvider } from './AdminServiceConfigProvider';

export class AdminServiceApiProvider {
  static getClientsList() {
    return axiosService.get(AdminServiceConfigProvider.getClientsList());
  }

  static getClientInfo({ id }) {
    return axiosService.get(AdminServiceConfigProvider.getClientInfo({ id }));
  }

  static createClient({ name }) {
    return axiosService({
      method: 'post',
      url: AdminServiceConfigProvider.createClient(),
      data: {
        name,
      },
    });
  }

  static setClientCountries({
    id, countries, surgiscopeCountries, labscopeCountries, pricescopeCountries,
  }) {
    return axiosService({
      method: 'post',
      url: AdminServiceConfigProvider.setClientCountries({ id }),
      data: {
        countries,
        surgiscopeCountries,
        labscopeCountries,
        pricescopeCountries,
      },
    });
  }

  static toggleClientStatus({ id, isActive }) {
    return axiosService({
      method: 'put',
      url: AdminServiceConfigProvider.toggleClientStatus({ id, isActive }),
      data: {
        is_active: isActive,
      },
    });
  }

  static getClientUsersList({ clientId }) {
    return axiosService({
      method: 'get',
      url: AdminServiceConfigProvider.getClientUsersList({ clientId }),
    });
  }

  static removeClient({ id }) {
    return axiosService({
      method: 'delete',
      url: AdminServiceConfigProvider.removeClient({ id }),
    });
  }

  static editClient({ id, clientInfo }) {
    return axiosService({
      method: 'put',
      url: AdminServiceConfigProvider.editClient({ id }),
      data: {
        ...clientInfo,
        active_till: clientInfo.activeTill,
      },
    });
  }

  static addUser({ clientId, user }) {
    return axiosService({
      method: 'post',
      url: AdminServiceConfigProvider.addUser({ clientId }),
      data: {
        ...user,
        first_name: user.firstName,
        last_name: user.lastName,
      },
    });
  }

  static getUserInfo({ id }) {
    return axiosService({
      method: 'get',
      url: AdminServiceConfigProvider.getUserInfo({ id }),
    });
  }

  static toggleUserStatus({ id, isActive }) {
    return axiosService({
      method: 'put',
      url: AdminServiceConfigProvider.toggleUserStatus({ id, isActive }),
      data: {
        is_active: isActive,
      },
    });
  }

  static removeUser({ id }) {
    return axiosService({
      method: 'delete',
      url: AdminServiceConfigProvider.removeUser({ id }),
    });
  }

  static editUser({ id, userInfo }) {
    return axiosService({
      method: 'put',
      url: AdminServiceConfigProvider.editUser({ id }),
      data: {
        ...userInfo,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
      },
    });
  }

  static getAdminsList() {
    return axiosService.get(AdminServiceConfigProvider.getAdminsList());
  }

  static addAdmin({ admin }) {
    return axiosService({
      method: 'post',
      url: AdminServiceConfigProvider.addAdmin(),
      data: {
        ...admin,
        first_name: admin.firstName,
        last_name: admin.lastName,
      },
    });
  }

  static getAdminInfo({ id }) {
    return axiosService.get(AdminServiceConfigProvider.getAdminInfo({ id }));
  }

  static toggleAdminStatus({ id, isActive }) {
    return axiosService({
      method: 'put',
      url: AdminServiceConfigProvider.toggleAdminStatus({ id, isActive }),
      data: {
        is_active: isActive,
      },
    });
  }

  static editAdmin({ id, userInfo }) {
    return axiosService({
      method: 'put',
      url: AdminServiceConfigProvider.editAdmin({ id }),
      data: {
        ...userInfo,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
      },
    });
  }

  static removeAdmin({ id }) {
    return axiosService({
      method: 'delete',
      url: AdminServiceConfigProvider.removeAdmin({ id }),
    });
  }
}
