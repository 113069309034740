import { axiosService } from '../../../core/services/axios.service';

import { ProceduresServiceConfigProvider } from './ProceduresServiceConfigProvider';

import {
  formatFilterValues,
  formatValueToArray,
  prepareFilterValuesForOptions,
} from '../utils/proceduresServiceDataFormatter';

export class ProceduresServiceApiProvider {
  static getAvailableCountries() {
    return axiosService.get(ProceduresServiceConfigProvider.getCountriesListEndpoint());
  }

  static getFilteringOptions(data) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getFilteringOptionsEndpoint(),
      data: prepareFilterValuesForOptions(data),
    });
  }

  // payload = {
  //     "limit": 120,
  //     "offset": 0,
  //     "sortedBy": "procedure_name",
  //     "sortingDirection": "ASC",
  //     "year": [
  //         2021
  //     ],
  //     "country": [
  //         "Mexico"
  //     ],
  //     "group": null,
  //     "group_code": null,
  //     "category": null,
  //     "category_code": null,
  //     "subcategory": null,
  //     "subcategory_code": null,
  //     "procedure_name": [
  //         "BRUCELOSIS DEBIDA A BRUCELLA SUIS",
  //         "BRUCELOSIS DEBIDA A BRUCELLA SUIS",
  //         "BENZODIAZAPINAS",
  //         "DEFICIENCIA DE VITAMINA K"
  //     ],
  //     "procedure_code": [
  //         "SN010082",
  //         "SN010082",
  //         "SN010081",
  //         "SN010118"
  //     ],
  //     "institution": {}
  // }

  // response = {
  //     "procedures": [
  //         {
  //             "procedure_name": "BENZODIAZAPINAS",
  //             "procedure_code": "SN010081",
  //             "procedure_group": "OTROS",
  //             "procedure_group_code": "4",
  //             "procedure_category": "SINIESTRO",
  //             "procedure_category_code": "XX",
  //             "procedure_subcategory": null,
  //             "procedure_subcategory_code": null,
  //             "sum": "3"
  //         },
  //         {
  //             "procedure_name": "BRUCELOSIS DEBIDA A BRUCELLA SUIS",
  //             "procedure_code": "SN010082",
  //             "procedure_group": "OTROS",
  //             "procedure_group_code": "4",
  //             "procedure_category": "SINIESTRO",
  //             "procedure_category_code": "XX",
  //             "procedure_subcategory": null,
  //             "procedure_subcategory_code": null,
  //             "sum": "7"
  //         },
  //         {
  //             "procedure_name": "DEFICIENCIA DE VITAMINA K",
  //             "procedure_code": "SN010118",
  //             "procedure_group": "OTROS",
  //             "procedure_group_code": "4",
  //             "procedure_category": "SINIESTRO",
  //             "procedure_category_code": "XX",
  //             "procedure_subcategory": null,
  //             "procedure_subcategory_code": null,
  //             "sum": "3"
  //         }
  //     ],
  //     "count": 3
  // }

  static getProceduresList({
    limit,
    offset,
    filterValues,
    sortedBy,
    sortingDirection,
  }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getProceduresListEndpoint(),
      data: {
        limit,
        offset,
        sortedBy,
        sortingDirection,
        ...formatFilterValues(filterValues),
      },
    });
  }

  static getHospitalsList({
    procedureName,
    procedureCode,
    year,
    country,
    limit,
    offset,
    shouldIncludeProcedureInfo,
  }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getHospitalsListEndpoint(),
      data: {
        procedure_name: formatValueToArray(procedureName),
        procedure_code: formatValueToArray(procedureCode),
        year: formatValueToArray(year),
        country: formatValueToArray(country),
        institution: {
          shouldIncludeProcedureInfo,
        },
        limit,
        offset,
      },
    });
  }

  static getInstitutionsList({
    limit,
    offset,
    filterValues,
    sortedBy,
    sortingDirection,
  }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getHospitalsListEndpoint(),
      data: {
        limit,
        offset,
        sortedBy,
        sortingDirection,
        ...formatFilterValues(filterValues),
      },
    });
  }

  static getProceduresListByHospitalId({
    limit,
    offset,
    id,
    year,
  }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getProceduresListByHospitalIdEndpoint(),
      data: {
        limit,
        offset,
        id,
        year,
      },
    });
  }

  static getStatesByCountriesAndStateName({ countries, state }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getStatesByCountriesAndCityNameEndpoint(),
      data: {
        countries,
        state,
      },
    });
  }

  static getCitiesByCountriesAndCityName({ countries, state, city }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getCitiesByCountriesAndCityNameEndpoint(),
      data: {
        countries,
        state,
        city,
      },
    });
  }

  static getHospitalNames({ name, country }) {
    return axiosService({
      method: 'post',
      url: ProceduresServiceConfigProvider.getHospitalNamesEndpoint(),
      data: {
        name,
        country,
      },
    });
  }
}
