import {
  GENERAL_INFORMATION_TABLE_FIELDS,
  ADMINISTRATIVE_TABLE_FIELDS,
  EQUIPMENT_TABLE_FIELDS,
  PROCEDURES_TABLE_FIELDS,
} from '../components/LaboratoriesData/tableFields.constants';

export const FLATTEN_LABORATORIES_DATA_FIELDS_LIST = [
  ...GENERAL_INFORMATION_TABLE_FIELDS,
  ...ADMINISTRATIVE_TABLE_FIELDS,
  ...EQUIPMENT_TABLE_FIELDS,
  ...PROCEDURES_TABLE_FIELDS,
].flat();
