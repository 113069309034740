import { NO_DATA_MESSAGE } from '../../LaboratoriesData/laboratoriesData.constants';

import {
  FLATTEN_LABORATORIES_DATA_FIELDS_LIST,
} from '../../../constants/laboratories.constants';

export const prepareLaboratoriesListForExcel = (laboratoriesList) => {
  return [{
    columns: FLATTEN_LABORATORIES_DATA_FIELDS_LIST.map((cell) => cell.label),
    data: laboratoriesList.map((laboratory) => {
      return FLATTEN_LABORATORIES_DATA_FIELDS_LIST.map((cell) => {
        return {
          value: laboratory[cell.key] || laboratory[cell.key] === 0 ? `${laboratory[cell.key]}` : NO_DATA_MESSAGE,
          style: {
            font: {
              sz: '14',
            },
          },
        };
      });
    }),
  }];
};
