export const TOAST_CONSTANTS = {
  USER_ADDED: 'User was added successfully!',
  REMOVE_CLIENT: 'Client was removed successfully!',
  EDIT_CLIENT: 'Client was edited successfully!',
  ADD_CLIENT_FAILURE: 'Cannot add client!',
  FAILURE: 'Something went wrong!',
  REMOVE_USER: 'User was removed successfully!',
  EDIT_USER: 'User was edited successfully!',
  REMOVE_ADMIN: 'Admin was removed successfully!',
  EDIT_ADMIN: 'Admin was edited successfully!',
  SEARCH_SAVED: 'Search was saved successfully!',
  SEARCH_REMOVED: 'Search was removed successfully!',
};
