import { getAdminState } from '../admin.selector';

function getClientPageState(state) {
  return getAdminState(state).clientPage;
}

export const clientPageSelector = {
  isLoading(state) {
    return getClientPageState(state).isLoading;
  },
  clientInfo(state) {
    return getClientPageState(state).clientInfo;
  },
  clientId(state) {
    const { clientInfo } = getClientPageState(state);
    return clientInfo ? clientInfo.id : null;
  },
  usersList(state) {
    return getClientPageState(state).usersList;
  },
  isUsersListLoading(state) {
    return getClientPageState(state).isUsersListLoading;
  },
};
