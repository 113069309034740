import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

import { MenuItemBoxHeader } from '../../../containers/hospiscope/components/Filters/FilterItems/shared/components';

import { generateFilterMenu } from '../../../containers/hospiscope/components/Filters/utils/filters.ui.utils';
import { renderModalActionButton } from '../../../containers/hospiscope/components/modals/utils/modals.utils';

import { COUNTRY_PICKER_TEXTS } from '../../../containers/admin/ClientPage/constants/clientPage.constants';
import { FILTER_MENU_TYPES, DEFAULT_COLUMNS_AMOUNT } from '../../../containers/hospiscope/constants/filters.constants';

import { countriesWithContinent } from '../../constants/countries-by-continent';

export const CountriesModal = ({
  open,
  availableCountries,
  selectedCountries,
  onClose,
  onSubmit,
}) => {
  const [fields, setFields] = useState(selectedCountries);

  const handleFieldChange = ({ name, value }) => {
    return value
      ? setFields([...fields, name])
      : setFields(fields.filter((field) => field !== name));
  };

  const clearFields = () => setFields([]);

  // const getColumnsAmount = (data) => (data.length
  //   ? Math.ceil(data.length / DEFAULT_COLUMNS_AMOUNT) : DEFAULT_COLUMNS_AMOUNT);

  const availableCountriesWithContinentHeaders = () => {
    const availableCountriesByContinent = {};

    availableCountries.forEach((country) => {
      const continent = countriesWithContinent[country]?.customContinent
        || countriesWithContinent[country]?.continent || 'Other';
      if (!availableCountriesByContinent[continent]) availableCountriesByContinent[continent] = [];
      availableCountriesByContinent[continent].push(country);
    });

    Object.keys(availableCountriesByContinent).forEach((continent) => {
      availableCountriesByContinent[continent] = availableCountriesByContinent[continent].sort();
    });

    const availableContinentAndCountriesList = [];
    Object.keys(availableCountriesByContinent).forEach((continent) => {
      availableContinentAndCountriesList.push({ label: continent, type: 'listHeader' });

      availableCountriesByContinent[continent].map((country) => (
        availableContinentAndCountriesList.push({ label: country, name: country })
      ));
    });

    return availableContinentAndCountriesList;
  };

  const renderModalContent = () => {
    return (
      <div style={{ display: 'flex' }}>
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.CHECKBOX,
          fieldsList: availableCountriesWithContinentHeaders(),
          columnsAmount: DEFAULT_COLUMNS_AMOUNT,
          dataIsAnArray: true,
          arrayTypeData: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
    );
  };
  return (
    <Modal className="countries-modal" open={open} onClose={onClose}>
      <Modal.Header>
        <MenuItemBoxHeader
          title={COUNTRY_PICKER_TEXTS.MODAL_TITLE}
          onClear={clearFields}
          onSelectAll={() => setFields(availableCountries)}
        />
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {renderModalContent()}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({
          clickHandler: () => onSubmit(fields),
          text: COUNTRY_PICKER_TEXTS.ACTION_BUTTON,
        })}
      </Modal.Actions>
    </Modal>
  );
};
