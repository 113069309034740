import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';

import { verifyAuth } from './user/user.actions';

import { rootReducer } from './root.reducer';

const initialState = {};

const enhancers = [devToolsEnhancer()];

const middleware = [thunk];

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

export const store = (function configureStore() {
  const reduxStore = createStore(
    rootReducer,
    initialState,
    composedEnhancers,
  );
  reduxStore.dispatch(verifyAuth());
  return reduxStore;
}());
