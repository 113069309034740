import {
  GET_LABORATORIES_LIST_FAILURE,
  GET_LABORATORIES_LIST_REQUEST,
  GET_LABORATORIES_LIST_SUCCESS,
} from './laboratoriesData.actions';
import { CLEAR_STORE } from '../../../../core/user/user.actions';

import { LABORATORY_NAME_FIELD, SORTING_DIRECTIONS } from '../../../../core/constants/core.constants';

export const initialState = {
  isLoading: false,
  laboratoriesList: [],
  pagination: {
    currentPage: 1,
    count: 0,
  },
  sortedBy: LABORATORY_NAME_FIELD,
  sortingDirection: SORTING_DIRECTIONS.ASC,
};

export function laboratoriesDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LABORATORIES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LABORATORIES_LIST_SUCCESS: {
      const {
        laboratoriesList,
        count,
        currentPage,
        sortedBy,
        sortingDirection,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        laboratoriesList: laboratoriesList || [],
        pagination: {
          ...state.pagination,
          count,
          currentPage,
        },
        sortedBy,
        sortingDirection,
      };
    }
    case GET_LABORATORIES_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
