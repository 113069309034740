import { getProceduresState } from '../procedures.selector';

function getProceduresDataState(state) {
  return getProceduresState(state).proceduresData;
}

export const proceduresDataSelector = {
  isLoading(state) {
    return getProceduresDataState(state).isLoading;
  },
  proceduresList(state) {
    return getProceduresDataState(state).proceduresList;
  },
  pagination(state) {
    return getProceduresDataState(state).pagination;
  },
  isHospitalsModalLoading(state) {
    return getProceduresDataState(state).isHospitalsModalLoading;
  },
  shouldShowHospitalsModal(state) {
    return getProceduresDataState(state).shouldShowHospitalsModal;
  },
  hospitalsList(state) {
    return getProceduresDataState(state).hospitalsList;
  },
  selectedProcedureName(state) {
    return getProceduresDataState(state).selectedProcedureName;
  },
  selectedProcedureCode(state) {
    return getProceduresDataState(state).selectedProcedureCode;
  },
  selectedProcedure(state, selectedProcedureName, selectedProcedureCode) {
    const proceduresList = proceduresDataSelector.proceduresList(state);
    return proceduresList.find(({ procedure_name, procedure_code }) => {
      return procedure_name === selectedProcedureName && procedure_code === selectedProcedureCode;
    });
  },
  sortedBy(state) {
    return getProceduresDataState(state).sortedBy;
  },
  sortingDirection(state) {
    return getProceduresDataState(state).sortingDirection;
  },
  tableView(state) {
    return getProceduresDataState(state).tableView;
  },
};
