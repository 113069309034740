import React from 'react';
import { Table } from 'semantic-ui-react';
import { getTableCellContent } from '../../../HospitalsData/hospitalsData.utils';

const TABLE_TITLE_COLSPAN = 2;

export const FieldTable = ({
  selectedHospital,
  fields,
  plain = true,
  title = '',
  className,
  isPdfRow,
}) => {
  const itemCellRender = (item) => {
    const { label, key } = item;
    return [
      <Table.Cell key={`label-${label}-${isPdfRow ? 'pdf' : ''}`}>{label}</Table.Cell>,
      <Table.Cell key={`key-${key}-${isPdfRow ? 'pdf' : ''}`}>
        {key ? getTableCellContent(selectedHospital[key]) : ''}
      </Table.Cell>,
    ];
  };

  const plainItemRender = (item) => {
    return (
      <Table.Row key={`modal-row-${item.key}-${isPdfRow ? 'pdf' : ''}`}>
        {itemCellRender(item)}
      </Table.Row>
    );
  };

  const plainFieldsRender = (fieldsArray) => {
    return fieldsArray.map((item) => plainItemRender(item));
  };

  const arrayOfFieldsRender = (fieldsArray) => {
    const largestChunk = Math.max.apply(null, fieldsArray.map((e) => e.length));
    return fieldsArray.map((fieldArrayChunk) => {
      return (
        <Table.Row>
          {[...Array(largestChunk)].map((item, index) => {
            return fieldArrayChunk[index]
              ? itemCellRender(fieldArrayChunk[index])
              : itemCellRender({ label: '', key: '' });
          })}
        </Table.Row>
      );
    });
  };

  const getColspanValue = () => {
    return plain ? `${TABLE_TITLE_COLSPAN}` : `${TABLE_TITLE_COLSPAN * fields[0].length}`;
  };

  return (
    <Table className={`hospital-modal-table ${className}`}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={getColspanValue()}>{title}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {plain ? plainFieldsRender(fields) : arrayOfFieldsRender(fields)}
      </Table.Body>
    </Table>
  );
};
