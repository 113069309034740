import React from 'react';
import { Grid } from 'semantic-ui-react';

import { FromToInputField } from '../shared/components';

import { generateFilterMenu } from '../../utils/filters.ui.utils';

import { BEDS_MENU_ITEMS, GENERATED_BEDS_MENU_ITEMS } from './beds.constants';
import { FILTER_MENU_TYPES } from '../../../../constants/filters.constants';

const { TOTAL_HOSPITAL_BEDS, AMBULATORY_BEDS } = BEDS_MENU_ITEMS;

export const BedsComponent = ({
  fields, handleFieldChange,
}) => {
  return (
    <>
      <div className="fields-group fields-group--primary">
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <FromToInputField
                label={TOTAL_HOSPITAL_BEDS.label}
                placeholder={TOTAL_HOSPITAL_BEDS.placeholder}
                name={TOTAL_HOSPITAL_BEDS.name}
                onChange={handleFieldChange}
                value={fields[TOTAL_HOSPITAL_BEDS.name]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: GENERATED_BEDS_MENU_ITEMS.FIRST_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
      <div className="fields-group fields-group--primary">
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <FromToInputField
                label={AMBULATORY_BEDS.label}
                placeholder={AMBULATORY_BEDS.placeholder}
                name={AMBULATORY_BEDS.name}
                onChange={handleFieldChange}
                value={fields[AMBULATORY_BEDS.name]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: GENERATED_BEDS_MENU_ITEMS.SECOND_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
    </>
  );
};

export const Beds = BedsComponent;
