import React from 'react';

const SELECT_ALL_TEXT = 'Select all';
const CLEAR_ALL_TEXT = 'Clear all';

export const MenuItemBoxHeader = ({ title, onSelectAll, onClear }) => {
  return (
    <div className="filter-item-header">
      <div className="filter-item-title">{title}</div>
      {onSelectAll ? (
        <div
          className="filter-item-select-all"
          onClick={onSelectAll}
        >
          {SELECT_ALL_TEXT}
        </div>
      ) : null}
      <div className="filter-item-clear" onClick={onClear}>{CLEAR_ALL_TEXT}</div>
    </div>
  );
};
