export const GENERAL_INFORMATION_MENU_ITEMS = {
  LABORATORY_NAME: {
    label: 'Laboratory Name',
    placeholder: 'Type here',
    name: 'name',
  },
  HOLDING_NAME: {
    label: 'Holding Name',
    placeholder: 'Type here',
    name: 'holdingName',
  },
  PARENT_GROUP: {
    label: 'Parent group',
    placeholder: 'Type here',
    name: 'parentGroup',
  },
  ADDRESS: {
    label: 'Address',
    name: 'address',
    placeholder: 'Type here',
  },
  WEBPAGE: {
    label: 'Webpage',
    name: 'webpage',
    placeholder: 'Type here',
  },
  STATUS: {
    label: 'Status',
    name: 'status',
    placeholder: 'Type here',
  },
  TYPE: {
    label: 'Type',
    name: 'type',
    placeholder: 'Type here',
  },
  EMAIL: {
    label: 'Email',
    name: 'email',
    placeholder: 'Type here',
  },
  CITY: {
    label: 'City',
    name: 'city',
    placeholder: 'Type here',
  },
  PHONE: {
    label: 'Phone',
    placeholder: 'Type here',
    name: 'phone',
  },
};
