import {
  SET_ACTIVE_FILTER,
  SET_FILTER_VALUE,
  GET_FILTERING_OPTIONS_REQUEST,
  GET_FILTERING_OPTIONS_SUCCESS,
  GET_FILTERING_OPTIONS_FAILURE,
  CLEAR_FILTERS_IF_NEEDED,
  GET_AVAILABLE_HOSPITAL_NAMES_REQUEST,
  GET_AVAILABLE_HOSPITAL_NAMES_SUCCESS,
} from './filters.actions';

import { CLEAR_STORE } from '../../../../core/user/user.actions';
import { REPLACE_ALL_FILTER_VALUES } from './replaceFilterValues.action';

import { FILTERS } from '../../constants/filters.constants';

import { prepareEmptyFilters } from '../../components/Filters/filters.utils';
import { getLastAvailableYearOption } from '../../../../core/utils/core.utils';
import { clearFilteringOptionsFromEmptyItems } from '../../utils/procedures.utils';

export const initialState = {
  activeFilter: null,
  areFilteringOptionsLoading: true,
  yearOptionsWereLoaded: false,
  filteringOptions: {
    groups: [],
    categories: [],
    subcategories: [],
    procedures: [],
    year: [],
  },
  filterValues: {
    [FILTERS.YEAR]: null,
  },
  areHospitalNamesLoading: false,
  hospitalNames: [],
};

export function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_FILTER: {
      const { activeFilter } = action.payload;
      return {
        ...state,
        activeFilter,
      };
    }
    case SET_FILTER_VALUE: {
      const { filterName, filterValue } = action.payload;
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [filterName]: filterValue,
        },
      };
    }
    case GET_FILTERING_OPTIONS_REQUEST: {
      return {
        ...state,
        areFilteringOptionsLoading: true,
      };
    }
    case GET_FILTERING_OPTIONS_SUCCESS: {
      const { filteringOptions } = action.payload;
      const lastYear = getLastAvailableYearOption(filteringOptions.year);
      const selectedYear = state.filterValues[FILTERS.YEAR];

      // Select max year if no year selected, or the selected one is not in the current available years
      let year = state.yearOptionsWereLoaded && filteringOptions.year.includes(selectedYear) ?
      selectedYear : lastYear;

      return {
        ...state,
        areFilteringOptionsLoading: false,
        yearOptionsWereLoaded: true,
        filteringOptions: clearFilteringOptionsFromEmptyItems(filteringOptions),
        filterValues: {
          ...state.filterValues,
          [FILTERS.YEAR]: year
        },
      };
    }
    case GET_FILTERING_OPTIONS_FAILURE: {
      return {
        ...state,
        areFilteringOptionsLoading: false,
      };
    }
    case CLEAR_FILTERS_IF_NEEDED: {
      const { filtersToClear } = action.payload;
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          ...prepareEmptyFilters(filtersToClear),
        },
      };
    }
    case REPLACE_ALL_FILTER_VALUES: {
      const { filterValues } = action.payload;
      return {
        ...state,
        filterValues,
      };
    }
    case GET_AVAILABLE_HOSPITAL_NAMES_REQUEST: {
      return {
        ...state,
        areHospitalNamesLoading: true,
      };
    }
    case GET_AVAILABLE_HOSPITAL_NAMES_SUCCESS: {
      const { hospitalNames } = action.payload;
      return {
        ...state,
        areHospitalNamesLoading: false,
        hospitalNames,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
    return state;
  }
}
