import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from 'semantic-ui-react';

import { CheckboxField, DropdownField, InputField } from '../shared/components';

import { GeoSearchDropdown } from './GeoSearchDropdown';

import {
  getAvailableCities as getAvailableCitiesAction,
  getAvailableStates as getAvailableStatesAction,
  performSearch as performSearchAction,
  setFilterValue as setFilterValueAction,
  setActiveFilter as setActiveFilterAction,
} from '../../../../../common/store/filters/filters.actions';

import { filtersSelector } from '../../../../../common/store/filters/filters.selector';

import { generateDropdownOptions, replaceNullOption } from '../../../../../../core/utils/core.utils';

import { GENERAL_INFORMATION_MENU_ITEMS } from './generalInformation.constants';

const {
  HOSPITAL_ID, HOSPITAL_NAME, HOLDING_NAME, TAXPAYER_ID, AFFILIATION, NATURE_OF_ESTABLISHMENT,
  TYPE_OF_ESTABLISHMENT, LEVEL_OF_CARE, EMAIL, STATE, CITY, ZIP_CODE, PHONE,
} = GENERAL_INFORMATION_MENU_ITEMS;

export const GeneralInformationComponent = ({
  fields,
  handleFieldChange,
  filteringOptions,
  selectedCountries,
  areAvailableStatesLoading,
  getAvailableStates,
  availableStates,
  getAvailableCities,
  areAvailableCitiesLoading,
  availableCities,
}) => {
  const {
    affiliationOptions,
    natureOfEstablishmentOptions,
    typeOfEstablishmentOptions,
    levelOfCareOptions,
  } = filteringOptions;
  function getStateFieldValue() {
    const state = fields[STATE.name];
    if (Array.isArray(state) && state.length) {
      return state;
    }
    return state || null;
  }
  function getCitiesList({ countries, city }) {
    return getAvailableCities({
      countries,
      state: getStateFieldValue(),
      city,
    });
  }
  return (
    <>
      <div style={{ padding: '10px' }}>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <InputField
                label={TAXPAYER_ID.label}
                placeholder={TAXPAYER_ID.placeholder}
                name={TAXPAYER_ID.name}
                onChange={handleFieldChange}
                value={fields[TAXPAYER_ID.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                label={HOSPITAL_NAME.label}
                placeholder={HOSPITAL_NAME.placeholder}
                name={HOSPITAL_NAME.name}
                onChange={handleFieldChange}
                value={fields[HOSPITAL_NAME.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <DropdownField
                label={NATURE_OF_ESTABLISHMENT.label}
                name={NATURE_OF_ESTABLISHMENT.name}
                options={generateDropdownOptions(replaceNullOption(natureOfEstablishmentOptions))}
                onChange={handleFieldChange}
                value={fields[NATURE_OF_ESTABLISHMENT.name]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <InputField
                label={HOSPITAL_ID.label}
                placeholder={HOSPITAL_ID.placeholder}
                name={HOSPITAL_ID.name}
                onChange={handleFieldChange}
                value={fields[HOSPITAL_ID.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                label={HOLDING_NAME.label}
                placeholder={HOLDING_NAME.placeholder}
                name={HOLDING_NAME.name}
                onChange={handleFieldChange}
                value={fields[HOLDING_NAME.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <DropdownField
                label={AFFILIATION.label}
                name={AFFILIATION.name}
                options={generateDropdownOptions(replaceNullOption(affiliationOptions))}
                onChange={handleFieldChange}
                search
                value={fields[AFFILIATION.name]}
                direction="right"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <DropdownField
                label={TYPE_OF_ESTABLISHMENT.label}
                name={TYPE_OF_ESTABLISHMENT.name}
                options={generateDropdownOptions(typeOfEstablishmentOptions)}
                onChange={handleFieldChange}
                value={fields[TYPE_OF_ESTABLISHMENT.name]}
              />
            </Grid.Column>
            <Grid.Column>
              <DropdownField
                label={LEVEL_OF_CARE.label}
                name={LEVEL_OF_CARE.name}
                options={generateDropdownOptions(replaceNullOption(levelOfCareOptions))}
                onChange={handleFieldChange}
                value={fields[LEVEL_OF_CARE.name]}
                direction="right"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div style={{ padding: '10px' }}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <InputField
                label={PHONE.label}
                placeholder={PHONE.placeholder}
                name={PHONE.name}
                onChange={handleFieldChange}
                value={fields[PHONE.name] || ''}
              />
            </Grid.Column>
            <Grid.Column>
              <CheckboxField
                onChange={handleFieldChange}
                label={EMAIL.label}
                name={EMAIL.name}
                checked={!!fields[EMAIL.name]}
                className="exception-email"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="geo-information-wrapper">
        <div className="geo-information-state">
          <GeoSearchDropdown
            label={STATE.label}
            name={STATE.name}
            onChange={handleFieldChange}
            value={fields[STATE.name]}
            placeholder="Start typing to add a state"
            getAvailableItems={getAvailableStates}
            isLoading={areAvailableStatesLoading}
            availableItems={availableStates}
            selectedCountries={selectedCountries}
          />
        </div>
        <div className="geo-information-zip">
          <InputField
            label={ZIP_CODE.label}
            placeholder={ZIP_CODE.placeholder}
            name={ZIP_CODE.name}
            onChange={handleFieldChange}
            value={fields[ZIP_CODE.name] || ''}
          />
        </div>
        <div className="geo-information-city">
          <GeoSearchDropdown
            label={CITY.label}
            name={CITY.name}
            onChange={handleFieldChange}
            value={fields[CITY.name]}
            placeholder="Start typing to add a city"
            isLoading={areAvailableCitiesLoading}
            getAvailableItems={getCitiesList}
            availableItems={availableCities}
            selectedCountries={selectedCountries}
          />
        </div>
        <div className="geo-information-phone">
          <InputField
            label={PHONE.label}
            placeholder={PHONE.placeholder}
            name={PHONE.name}
            onChange={handleFieldChange}
            value={fields[PHONE.name] || ''}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeFilter: filtersSelector.activeFilter(state),
  filterValues: filtersSelector.filterValues(state),
  filteringOptions: filtersSelector.filteringOptions(state),
  areAvailableStatesLoading: filtersSelector.areAvailableStatesLoading(state),
  availableStates: filtersSelector.availableStates(state),
  areAvailableCitiesLoading: filtersSelector.areAvailableCitiesLoading(state),
  availableCities: filtersSelector.availableCities(state),
  selectedCountries: filtersSelector.selectedCountries(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterValue: setFilterValueAction,
  setActiveFilter: setActiveFilterAction,
  performSearch: performSearchAction,
  getAvailableStates: getAvailableStatesAction,
  getAvailableCities: getAvailableCitiesAction,
}, dispatch);

export const GeneralInformation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInformationComponent);
