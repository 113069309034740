import { toast } from 'react-toastify';
import { AdminServiceApiProvider } from '../../services/AdminServiceApiProvider';
import { TOAST_CONSTANTS } from '../../constants/admin.constants';

export const GET_CLIENTS_LIST_REQUEST = '[CLIENTS] GET CLIENTS LIST REQUEST';
export const GET_CLIENTS_LIST_SUCCESS = '[CLIENTS] GET CLIENTS LIST SUCCESS';
export const GET_CLIENTS_LIST_FAILURE = '[CLIENTS] GET CLIENTS LIST FAILURE';

export const CREATE_CLIENT_REQUEST = '[CLIENTS] CREATE CLIENT REQUEST';
export const CREATE_CLIENT_FAILURE = '[CLIENTS] CREATE CLIENT FAILURE';

export function getClientsList() {
  return async (dispatch) => {
    dispatch({
      type: GET_CLIENTS_LIST_REQUEST,
    });
    try {
      const { data } = await AdminServiceApiProvider.getClientsList();
      return dispatch({
        type: GET_CLIENTS_LIST_SUCCESS,
        payload: {
          clientsList: data,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_CLIENTS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export function addClient({ name }) {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CLIENT_REQUEST,
    });
    try {
      await AdminServiceApiProvider.createClient({ name });
      return getClientsList()(dispatch);
    } catch (e) {
      dispatch({
        type: CREATE_CLIENT_FAILURE,
      });
      return toast.error(TOAST_CONSTANTS.ADD_CLIENT_FAILURE);
    }
  };
}
