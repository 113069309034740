import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, Input } from 'semantic-ui-react';

import { CountriesPicker } from './CountriesPicker/CountriesPicker';
import { SavedSearches } from './SavedSearches';
import { SaveSearch } from './SaveSearch';

import {
  clearFiltersDependentOnCountry as clearFiltersDependentOnCountryAction,
  getFilteringOptions as getFilteringOptionsAction,
  performSearch as performSearchAction,
  setFilterValue as setFilterValueAction,
} from '../../../common/store/filters/filters.actions';
import { getSavedSearches as getSavedSearchesAction } from '../../../../core/user/user.actions';
import { loadSavedSearch as loadSavedSearchAction } from '../../store/hospitalsData/hospitalsData.actions';

import { filtersSelector } from '../../../common/store/filters/filters.selector';
import { userSelector } from '../../../../core/user/user.selector';
import { hospitalsDataSelector } from '../../store/hospitalsData/hospitalsData.selector';

import { FILTERS } from '../../constants/filters.constants';
import { LAST_UPDATED_OPTION } from "../../../common/constants";

import { generateDropdownOptions } from '../../../../core/utils/core.utils';

const SEARCH_INPUT_TEXT = {
  ACTION: 'Search',
  PLACEHOLDER: 'Hospital name',
};

const SearchMenuComponent = ({
  searchValue,
  selectedYears,
  setFilterValue,
  selectedCountries,
  availableCountries,
  filteringOptions,
  savedSearches,
  areSavedSearchesLoading,
  pagination,
  performSearch,
  clearFiltersDependentOnCountry,
  getFilteringOptions,
  getSavedSearches,
  loadSavedSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const { yearOptions } = filteringOptions;

  useEffect(() => {
    setSearchTerm(searchValue);
  }, [searchValue]);

  useEffect(() => {
    getSavedSearches();
  }, [getSavedSearches]);

  function onDropdownChange(e, { value }) {
    /* Bad UX thing for current year option,
      because for the current year we need to use another table in the DB and cannot mix it with another years :(
     */
    const currentYear = LAST_UPDATED_OPTION.value;
    const isCurrentYearSelected = selectedYears && selectedYears.indexOf(currentYear) !== -1;
    if (isCurrentYearSelected) {
      const newValue = value.filter((year) => year !== currentYear);
      setFilterValue({ filterName: FILTERS.YEAR, filterValue: newValue });
      return performSearch({ filterName: FILTERS.YEAR, filterValue: newValue });
    }
    if (value.indexOf(currentYear) !== -1) {
      const newValue = value.filter((year) => year === currentYear);
      setFilterValue({ filterName: FILTERS.YEAR, filterValue: newValue });
      return performSearch({ filterName: FILTERS.YEAR, filterValue: newValue });
    }
    setFilterValue({ filterName: FILTERS.YEAR, filterValue: value });
    return performSearch({ filterName: FILTERS.YEAR, filterValue: value });
  }

  function onKeyUpHandler(e) {
    if (e.keyCode === 13) {
      performSearch({
        filterName: FILTERS.SEARCH,
        filterValue: searchTerm,
      });
    }
  }

  return (
    <div className="search-block">
      <div className="filter-main">
        <div className="year-picker">
          <Dropdown
            text="Select year"
            options={[LAST_UPDATED_OPTION, ...generateDropdownOptions(yearOptions)]}
            value={selectedYears}
            multiple
            closeOnChange
            onChange={onDropdownChange}
          />
        </div>
        <div className="countries-picker">
          <CountriesPicker
            filterName={FILTERS.COUNTRIES}
            setFilterValue={setFilterValue}
            selectedCountries={selectedCountries}
            availableCountries={availableCountries}
            performSearch={performSearch}
            clearFiltersDependentOnCountry={clearFiltersDependentOnCountry}
            getFilteringOptions={getFilteringOptions}
          />
        </div>
      </div>
      <div className="filter-secondary">
        <div className="search-input">
          <Input
            icon="search"
            iconPosition="left"
            className="hospitals-search"
            name="searchTerm"
            value={searchTerm}
            action={{
              content: SEARCH_INPUT_TEXT.ACTION,
              onClick: () => performSearch({
                filterName: FILTERS.SEARCH,
                filterValue: searchTerm,
              }),
            }}
            placeholder={SEARCH_INPUT_TEXT.PLACEHOLDER}
            onChange={({ target: { value } }) => setSearchTerm(value)}
            onKeyUp={onKeyUpHandler}
          />
        </div>
        <div className="saved-searches-wrapper">
          <SaveSearch pagination={pagination} />
          <SavedSearches
            savedSearches={savedSearches}
            areSavedSearchesLoading={areSavedSearchesLoading}
            loadSavedSearch={loadSavedSearch}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filteringOptions: filtersSelector.filteringOptions(state),
  searchValue: filtersSelector.searchValue(state),
  selectedYears: filtersSelector.selectedYears(state),
  selectedCountries: filtersSelector.selectedCountries(state),
  availableCountries: filtersSelector.availableCountries(state),
  savedSearches: userSelector.savedSearches(state),
  areSavedSearchesLoading: userSelector.areSavedSearchesLoading(state),
  pagination: hospitalsDataSelector.pagination(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterValue: setFilterValueAction,
  performSearch: performSearchAction,
  clearFiltersDependentOnCountry: clearFiltersDependentOnCountryAction,
  getFilteringOptions: getFilteringOptionsAction,
  getSavedSearches: getSavedSearchesAction,
  loadSavedSearch: loadSavedSearchAction,
}, dispatch);

export const SearchMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMenuComponent);
