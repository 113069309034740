export const PROCEDURES_INPUTS_LIST = [
  {
    label: 'MRI procedures',
    name: 'mriProcedures',
    placeholder: 'Number',
  },
  {
    label: 'CT scanners procedures',
    name: 'catProcedures',
    placeholder: 'Number',
  },
  {
    label: 'PET scanners procedures',
    name: 'petsProcedures',
    placeholder: 'Number',
  },
];
