import React from 'react';
import { Button } from 'semantic-ui-react';

const GET_PDF_TEXT = 'GET PDF';

export const Header = ({
  selectedLaboratory,
  pdfAction,
}) => {
  const {
    name, address, city, country,
  } = selectedLaboratory;
  return (
    <div className="hospital-modal-header">
      <div className="header-data">
        <div className="header-data-column">
          <p className="hospital-name">{name}</p>
          <p>{address}</p>
          <p>{city}</p>
          <p>{country}</p>
        </div>
      </div>
      <div className="header-buttons">
        <Button onClick={pdfAction}>{GET_PDF_TEXT}</Button>
      </div>
    </div>
  );
};
