import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { UserInfo } from './UserInfo';

import {
  editUser as editUserAction,
  getUserInfo as getUserInfoAction,
  removeUser as removeUserAction,
  toggleUserStatus as toggleUserStatusAction,
} from '../../store/userPage/userPage.actions';

import { userPageSelector } from '../../store/userPage/userPage.selector';

export const UserPageComponent = ({
  userPageContext, isLoading, userInfo, getUserInfo, toggleUserStatus, editUser, removeUser,
}) => {
  const { id } = useParams();
  useEffect(() => {
    getUserInfo({ id, userPageContext });
  }, [getUserInfo, id, userPageContext]);
  return (
    <div className="user-page">
      <div className="container main">
        <>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          {userInfo ? (
            <UserInfo
              userPageContext={userPageContext}
              userInfo={userInfo}
              toggleUserStatus={toggleUserStatus}
              editUser={editUser}
              removeUser={removeUser}
            />
          ) : null}
        </>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: userPageSelector.isLoading(state),
  userInfo: userPageSelector.userInfo(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getUserInfo: getUserInfoAction,
  toggleUserStatus: toggleUserStatusAction,
  editUser: editUserAction,
  removeUser: removeUserAction,
}, dispatch);

export const UserPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPageComponent);
