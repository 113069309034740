import React from 'react';
import { Table } from 'semantic-ui-react';

import { ProceduresTableHeader } from './ProceduresTableHeader';
import { ProceduresTableBody } from './ProdecuresTableBody';

export const ProceduresTable = ({
  proceduresList,
  sortedBy,
  sortingDirection,
  getProceduresList,
  getHospitalsList,
}) => {
  return (
    <div className="proceduresTable-wrapper">
      <Table className="hospitals-table proceduresTable-table" sortable>
        <ProceduresTableHeader
          sortedBy={sortedBy}
          sortingDirection={sortingDirection}
          getProceduresList={getProceduresList}
        />
        <ProceduresTableBody
          proceduresList={proceduresList}
          getHospitalsList={getHospitalsList}
        />
      </Table>
    </div>
  );
};
