import { container } from '../../config';

import {
  filtersSelector as hospitalsFiltersSelector,
} from '../../../common/store/filters/filters.selector';

import { FILTERS } from '../../constants/filters.constants';

function getFiltersState(state) {
  return container.getState(state).filters;
}

export const filtersSelector = {
  activeFilter(state) {
    return getFiltersState(state).activeFilter;
  },
  areFilteringOptionsLoading(state) {
    return getFiltersState(state).areFilteringOptionsLoading;
  },
  filteringOptions(state) {
    return getFiltersState(state).filteringOptions;
  },
  areFilteringOptionsDynamicLoading(state) {
    return getFiltersState(state).areFilteringOptionsDynamicLoading;
  },
  filteringOptionsDynamic(state) {
    return getFiltersState(state).filteringOptionsDynamic;
  },
  selectedCountry(state) {
    return getFiltersState(state).filterValues[FILTERS.COUNTRY];
  },
  selectedYear(state) {
    return getFiltersState(state).filterValues[FILTERS.YEAR];
  },
  filterValues(state) {
    return getFiltersState(state).filterValues;
  },
  areAvailableCountriesLoading(state) {
    return hospitalsFiltersSelector.areAvailableCountriesLoading(state);
  },
  yearOptionsWereLoaded(state) {
    return getFiltersState(state).yearOptionsWereLoaded;
  },
  availableCountries(state) {
    return hospitalsFiltersSelector.availablePricescopeCountries(state);
  },
};
