export const FILTER_MENU_TYPES = {
  INPUT: 'INPUT',
  FROM_TO_INPUT: 'FROM_TO_INPUT',
  CHECKBOX: 'CHECKBOX',
};

export const FILTERS = {
  YEAR: 'year',
  SEARCH: 'search',
  GENERAL_INFORMATION: 'generalInformation',
  BEDS: 'beds',
  INFRASTRUCTURE: 'infrastructure',
  LABORATORY: 'laboratory',
  // MEDICAL_OFFICES: 'medicalOffices',
  EQUIPMENT: 'equipment',
  SPECIALTIES: 'specialties',
  TECHNOLOGY: 'technology',
  // HR: 'hr',
  KEY_PERSONNEL: 'keyPersonnel',
  COUNTRIES: 'countries',
};

export const FILTER_MENU_TITLES = {
  [FILTERS.GENERAL_INFORMATION]: 'General Information',
  [FILTERS.BEDS]: 'Beds',
  [FILTERS.INFRASTRUCTURE]: 'Infrastructure',
  [FILTERS.LABORATORY]: 'Laboratory',
  // [FILTERS.MEDICAL_OFFICES]: 'Medical Offices',
  [FILTERS.EQUIPMENT]: 'Equipment',
  [FILTERS.SPECIALTIES]: 'Specialties',
  [FILTERS.TECHNOLOGY]: 'Technology',
  // [FILTERS.HR]: 'HR',
  [FILTERS.KEY_PERSONNEL]: 'Key Personnel',
};

export const DEFAULT_COLUMNS_AMOUNT = 4;
