import { getAdminState } from '../admin.selector';

function getAdminDashboardState(state) {
  return getAdminState(state).adminsDashboard;
}

export const adminsDashboardSelector = {
  isLoading(state) {
    return getAdminDashboardState(state).isLoading;
  },
  adminsList(state) {
    return getAdminDashboardState(state).adminsList;
  },
};
