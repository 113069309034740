import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { ClientsDashboardHeader } from './ClientsDashboardHeader';
import { ClientsDashboardBody } from './ClientsDashboardBody';
import { OneInputModal } from '../../../../core/components/modals/OneInputModal';

import {
  addClient as addClientAction,
  getClientsList as getClientsListAction,
} from '../../store/clientsDashboard/clientsDashboard.actions';

import { clientsDashboardSelector } from '../../store/clientsDashboard/clientsDashboard.selector';

export const ClientsDashboardComponent = ({
  isLoading, clientsList, getClientsList, addClient,
}) => {
  const [shouldShowAddClientModal, setShouldShowAddClientModal] = useState(false);
  useEffect(() => {
    getClientsList();
  }, [getClientsList]);
  function addClientHandler(name) {
    setShouldShowAddClientModal(false);
    return addClient({ name });
  }
  return (
    <div className="clients-dashboard">
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <div className="container main">
        <ClientsDashboardHeader onButtonClick={() => setShouldShowAddClientModal(true)} />
        <ClientsDashboardBody clientsList={clientsList} />
        {shouldShowAddClientModal ? (
          <OneInputModal
            open={shouldShowAddClientModal}
            inputLabel="Name"
            inputName="name"
            onClose={() => setShouldShowAddClientModal(false)}
            submitAction={addClientHandler}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: clientsDashboardSelector.isLoading(state),
  clientsList: clientsDashboardSelector.clientsList(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getClientsList: getClientsListAction,
  addClient: addClientAction,
}, dispatch);

export const ClientsDashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientsDashboardComponent);
