import { Table } from 'semantic-ui-react';
import React from 'react';

import { USERS_TABLE_CELLS } from '../constants/tableFields.constants';

export const UsersTableHeader = () => {
  return (
    <Table.Header>
      <Table.Row>
        {USERS_TABLE_CELLS.map((cell) => {
          return <Table.HeaderCell key={cell.label} className={cell.key}>{cell.label}</Table.HeaderCell>;
        })}
      </Table.Row>
    </Table.Header>
  );
};
