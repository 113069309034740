import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { HospitalNamesDropdown } from './HospitalNamesDropdown';
import {
  InputField,
} from '../../../../../../hospiscope/components/Filters/FilterItems/shared/components';

import {
  getAvailableHospitalNames as getAvailableHospitalNamesAction,
} from '../../../../../store/filters/filters.actions';

import { renderAcceptButton } from '../../../../../../hospiscope/components/Filters/utils/filters.ui.utils';

import { INSTITUTION_MENU_ITEMS } from './institution.constants';
import { filtersSelector } from '../../../../../store/filters/filters.selector';

const {
  HOSPITAL_ID,
  HOSPITAL_NAME,
} = INSTITUTION_MENU_ITEMS;

export const InstitutionComponent = ({
  filterName,
  filterValue,
  areHospitalNamesLoading,
  hospitalNames,
  onAcceptClick,
  getAvailableHospitalNames,
}) => {
  const [fields, setFields] = useState(filterValue || {});
  const handleFieldChange = ({ name, value }) => setFields({ ...fields, [name]: value });

  const getHospitalNames = ({ hospital_name }) => getAvailableHospitalNames({ name: hospital_name });

  return (
    <>
      <div className="fields-group">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <InputField
                label={HOSPITAL_ID.label}
                placeholder={HOSPITAL_ID.placeholder}
                name={HOSPITAL_ID.name}
                value={fields[HOSPITAL_ID.name] || ''}
                onChange={handleFieldChange}
              />
            </Grid.Column>
            <Grid.Column>
              <HospitalNamesDropdown
                label={HOSPITAL_NAME.label}
                name={HOSPITAL_NAME.name}
                value={fields[HOSPITAL_NAME.name]}
                placeholder="Start typing to add a hospital name"
                isLoading={areHospitalNamesLoading}
                availableItems={hospitalNames}
                onChange={handleFieldChange}
                getAvailableItems={getHospitalNames}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div>
        {renderAcceptButton(() => onAcceptClick({ name: filterName, value: fields }))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  hospitalNames: filtersSelector.hospitalNames(state),
  areHospitalNamesLoading: filtersSelector.areHospitalNamesLoading(state),
  selectedCountry: filtersSelector.selectedCountry(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAvailableHospitalNames: getAvailableHospitalNamesAction,
}, dispatch);

export const Institution = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstitutionComponent);
