import * as JSPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { toast } from 'react-toastify';

import { SORTING_DIRECTIONS, DEFAULT_PAGE_LIMIT } from '../constants/core.constants';

export const formatNumber = (val, key, row) => new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
}).format(val);

export const formatCurrency = (val, key, row) => {
  try {
    const result = new Intl.NumberFormat(undefined,
      { style: 'currency', currency: row.currency, maximumFractionDigits: 0 })
      .format(val);
    return result;
  } catch (e) {
    if (e instanceof RangeError) {
      return `${row.currency} ${formatNumber(val)}`;
    }
    throw e;
  }
};

// Split array into chunkSize columns, mantaining vertical order
export const splitArrayIntoChunks = (array, chunkSize) => {
  const chunks = Math.ceil(array.length / chunkSize);
  const result = new Array(chunks).fill().map(() => []);

  array.forEach((val, idx) => result[Math.floor(idx % chunks)].push(val));
  return result;
  // return new Array(Math.ceil(array.length / chunkSize))
  //   .fill()
  //   .map((_, i) => array.slice(i * chunkSize, (i * chunkSize) + chunkSize));
};

function setPixelated(context) {
  const newContext = context;
  newContext.imageSmoothingEnabled = false;
  newContext.mozImageSmoothingEnabled = false;
  newContext.oImageSmoothingEnabled = false;
  newContext.webkitImageSmoothingEnabled = false;
  newContext.msImageSmoothingEnabled = false;
  return newContext;
}

export async function drawCanvas(selectors) {
  return Promise.all(selectors.map((selector) => {
    return html2canvas(document.querySelector(selector), { logging: false });
  })).catch((e) => {
    return toast.error(e);
  });
}

export function createPDF(canvasList) {
  try {
    const doc = new JSPDF('p', 'mm', 'a4');
    for (let i = 0; i < canvasList.length; i += 1) {
      const canvas = canvasList[i];
      const imgData = setPixelated(canvas).toDataURL('image/jpeg');
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      if (i > 0) {
        doc.addPage();
      }
      doc.setPage(i + 1);
      doc.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    }
    return doc;
  } catch (e) {
    return toast.error(e);
  }
}

export const isObject = (obj) => {
  return (!!obj) && (obj.constructor === Object);
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getDateInfo = (dateObj) => {
  return {
    seconds: dateObj.getSeconds(),
    hours: dateObj.getHours(),
    minutes: dateObj.getMinutes(),
    day: dateObj.getDate(),
    month: dateObj.getMonth(),
    monthName: monthNames[dateObj.getMonth()],
    year: dateObj.getFullYear(),
  };
};

export const formatServerDate = (date) => {
  const dateObj = new Date(Date.parse(date));
  const formattedDate = getDateInfo(dateObj);
  const { day, monthName, year } = formattedDate;
  return `${monthName} ${day} ${year}`;
};

export const areStringArraysEqual = (first, second) => {
  if (first.length !== second.length) {
    return false;
  }
  return first.every((val) => second.indexOf(val) !== -1);
};

export const stringToHslColor = (str, saturation = 50, lightness = 60) => {
  if (!str) {
    return null;
  }
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
};

export const formatNumericalValue = (num) => {
  if (Number.isNaN(+num)) {
    return num;
  }
  const rounded = num ? Math.round(num * 10) / 10 : 0;
  return rounded.toLocaleString('en');
};

export const safeToLowerCase = (string) => {
  return string && typeof string === 'string' ? string.toLowerCase().trim() : string;
};

export const sortList = ({ list, sortedBy, sortingDirection }) => {
  const sortedList = list.sort((a, b) => {
    const itemA = a[sortedBy];
    const itemB = b[sortedBy];
    const paramA = Number.isNaN(Number(itemA)) ? safeToLowerCase(itemA) : Number(itemA);
    const paramB = Number.isNaN(Number(itemB)) ? safeToLowerCase(itemB) : Number(itemB);
    if (typeof paramA === 'string' && typeof paramA === 'string') {
      return paramA.localeCompare(paramB);
    }
    if (paramA < paramB) {
      return -1;
    }
    if (paramA > paramB) {
      return 1;
    }
    return 0;
  });
  return sortingDirection === SORTING_DIRECTIONS.ASC ? sortedList : sortedList.reverse();
};

export const getSortingDirectionForArrow = ({ key, sortedBy, sortingDirection }) => {
  if (key === sortedBy) {
    return sortingDirection === SORTING_DIRECTIONS.ASC ? 'ascending' : 'descending';
  }
  return null;
};

export const getQueryParameterByName = (name, url) => {
  const currentUrl = url || window.location.href;
  // eslint-disable-next-line no-useless-escape
  const currentName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${currentName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(currentUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const generateDropdownOptions = (list) => {
  if (list !== undefined && list instanceof Array && list.length) {
    return list.map((item) => ({
      key: item,
      text: item,
      value: item,
    }));
  }
  return [];
};

export const uniqueDropdownOptions = (list) => {
  return list.filter((thing, index, self) => index === self.findIndex((t) => (
    t.value === thing.value
  )));
};

export const replaceNullOption = (options) => options.map((option) => option || 'No data');

export const getOffset = ({ currentPage, limit = DEFAULT_PAGE_LIMIT }) => currentPage * limit - limit;

export const getPaginationItemsCount = ({ count, limit = DEFAULT_PAGE_LIMIT }) => {
  return count ? Math.ceil(count / limit) : 0;
};

export const removeDuplicatesFromArray = (array) => (array && array.length ? [...new Set(array)] : []);

export const getLastAvailableYearOption = (list) => {
  const lastYear = list && list.length ? Math.max(...list) : [];
  return lastYear;
};

export const isEmpty = (obj) => {
  // Undefined, null and empty string are "empty", but not 0 or false
  return (!obj && obj !== 0 && obj !== false)
  || (obj.constructor === Array && obj.length === 0) // Empty array
  || (obj.constructor === Object && Object.keys(obj).length === 0); // Emtpy object
};

export const collapseObjToArray = (value) => {
  const result = [];
  if (/^(number|bigint|boolean|string)$/.test(typeof value)) {
    result.push(value);
  } else if (value.constructor === Array) {
    value.forEach((v) => result.push(collapseObjToArray(v)));
  } else if (value.constructor === Object) {
    Object.entries(value).forEach(([k, v]) => {
      result.push(k);
      result.push(collapseObjToArray(v));
    });
  }
  return result;
};

export const cloneAndPrune = function (obj, pruneFunc = () => false) {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (obj == null || typeof obj !== 'object') { return pruneFunc(obj) ? undefined : obj; }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      const r = cloneAndPrune(obj[i], pruneFunc);
      if (!pruneFunc(r)) { copy.push(r); }
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        const r = cloneAndPrune(obj[attr], pruneFunc);
        if (!pruneFunc(r)) { copy[attr] = r; }
      }
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const toTitleCase = (str) => {
  let result = str.replace(/(.)([A-Z][a-z])/g, '$1 $2');
  result = result.replace(/_/g, ' ');
  return result.replace(
    /\w\S*/g,
    (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    },
  );
};
