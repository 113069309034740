const NO_COUNTRIES_MESSAGE = 'You do not have any countries. Please, contact with your client';
const SUBSCRIPTION_EXPIRED_MESSAGE = 'Client subscription is expired.';
const CLIENT_NOT_ACTIVE_MESSAGE = 'Client is not active.';

export const REQUEST_ERROR_MESSAGES = [NO_COUNTRIES_MESSAGE, SUBSCRIPTION_EXPIRED_MESSAGE, CLIENT_NOT_ACTIVE_MESSAGE];

export const LAST_UPDATED_OPTION = {
  key: 'currentYear',
  text: 'Last updated',
  value: -1,
};
