import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button, Dimmer, Form, Loader,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { FullPageLoader } from '../../../containers/common/FullPageLoader';
import { OneInputModal } from '../modals/OneInputModal';

import { navigateToPage } from '../../routing/history';
import { auth } from '../../services/firebase.service';

import { login as loginAction } from '../../user/user.actions';

import { userSelector } from '../../user/user.selector';

import { getQueryParameterByName } from '../../utils/core.utils';

import { TOAST_CONSTANTS } from '../../constants/core.constants';

import LogoLogin from '../../../assets/new-logo-black.png';

const LOGIN_TEXT = 'Login';
const PASSWORD_RESTORE_LINK_TEXT = 'Forgot password?';
const RESET_EMAIL_LOADER_TEXT = 'Sending reset email...';
const FROM_WELCOME_EMAIL_QUERY_PARAM_NAME = 'from_email';

export const LoginPageComponent = ({
  userData, isVerifying, isLoggingIn, login,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [shouldShowResetPasswordModal, setShouldShowResetPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const emailQueryParam = getQueryParameterByName(FROM_WELCOME_EMAIL_QUERY_PARAM_NAME);
    if (emailQueryParam) {
      toast.info(TOAST_CONSTANTS.RESET_PASSWORD_INFO);
    }
  }, []);
  useEffect(() => {
    if (userData && !isVerifying) {
      navigateToPage('/');
    }
  });
  async function resetPasswordHandler(resetEmail) {
    setShouldShowResetPasswordModal(false);
    setIsLoading(true);
    try {
      await auth.sendPasswordResetEmail(resetEmail);
      toast.success(TOAST_CONSTANTS.RESET_EMAIL_SENT);
    } catch (e) {
      toast.error(e.message);
    }
    setIsLoading(false);
  }
  return (
    <>
      {!userData && isVerifying && <FullPageLoader />}
      {!userData && !isVerifying
      && (
      <div className="login-page">
        <Dimmer active={isLoading}><Loader>{RESET_EMAIL_LOADER_TEXT}</Loader></Dimmer>
        <div className="login-content">
          <div className="login-logo">
            <img alt="" src={LogoLogin} />
          </div>
          <Form className="login-form">
            <Form.Input
              fluid
              label="Email"
              placeholder="Email Address"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <Form.Input
              fluid
              label="Password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <Button
              className="login-form-action"
              onClick={() => login({ email, password })}
              loading={isLoggingIn}
            >
              {LOGIN_TEXT}
            </Button>
          </Form>
          <div className="login-newUser">
            <span onClick={() => setShouldShowResetPasswordModal(true)}>{PASSWORD_RESTORE_LINK_TEXT}</span>
          </div>
        </div>
        {shouldShowResetPasswordModal ? (
          <OneInputModal
            open={shouldShowResetPasswordModal}
            onClose={() => setShouldShowResetPasswordModal(false)}
            inputLabel="Email"
            inputName="email"
            headerText="Reset password"
            submitAction={resetPasswordHandler}
          />
        ) : null}
      </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggingIn: userSelector.isLoggingIn(state),
  isVerifying: userSelector.isVerifying(state),
  userData: userSelector.userData(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  login: loginAction,
}, dispatch);

export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPageComponent);
