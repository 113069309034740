import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { SearchInput } from '../../../shared/SearchInput';
import { SearchOption } from '../../../shared/SearchOption';

import { renderAcceptButton } from '../../../../../../hospiscope/components/Filters/utils/filters.ui.utils';

const MIN_SEARCH_QUERY_LENGTH = 2;
const MIN_RESULTS_TO_SHOW = 50;
const SEARCH_INPUT_LABEL = 'Search items to filter';
const SEARCH_INPUT_PLACEHOLDER = `Start typing (min ${MIN_SEARCH_QUERY_LENGTH} characters)`;
const LOAD_MORE = 'Load more';

export const CODE_AND_NAME_DELIMITER = '-|-';

export const CommonFilter = ({
  items,
  filterName,
  filterValue = [],
  onAcceptClick,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState(filterValue || []);
  const [amountOfResultsToShow, setAmountOfResultsToShow] = useState(MIN_RESULTS_TO_SHOW);

  const formattedItems = items && items.length
    ? [...new Set(items.map((item) => `${item.name}${CODE_AND_NAME_DELIMITER}${item.code}`))] : [];

  const currentItems = formattedItems.slice(0, amountOfResultsToShow);

  const formattedSearchQuery = searchQuery.toLowerCase();

  const shouldShowLoadMoreButton = formattedItems.length > amountOfResultsToShow && !searchQuery;

  const selectItem = (newItem) => {
    if (selectedItems.indexOf(newItem) !== -1) {
      return setSelectedItems((arr) => arr.filter((item) => item !== newItem));
    }
    return setSelectedItems((arr) => [...arr, newItem]);
  };

  const onSearchInputChange = (e, data) => setSearchQuery(data.value);

  const renderItemLabel = (item) => {
    const [name, code] = item.split(CODE_AND_NAME_DELIMITER);
    return `${name} (${code})`;
  };

  const renderSearchOption = (item) => {
    return filterValue.indexOf(item) === -1 ? (
      <SearchOption
        key={item}
        label={renderItemLabel(item)}
        onChange={() => selectItem(item)}
      />
    ) : null;
  };

  const renderItems = () => currentItems.map((item) => renderSearchOption(item));

  const renderFilteredItems = () => {
    const searchElements = formattedItems.filter((item) => {
      const formattedItem = item.toLowerCase();
      return formattedItem.indexOf(formattedSearchQuery) !== -1;
    });
    return searchElements.map((element) => renderSearchOption(element));
  };

  const renderSelectedItems = () => {
    return filterValue.map((item) => {
      return (
        <SearchOption
          key={item}
          label={renderItemLabel(item)}
          checked={selectedItems.indexOf(item) !== -1}
          onChange={() => selectItem(item)}
        />
      );
    });
  };

  return (
    <>
      <div className="filter-item-search">
        <div className="fields-group">
          <SearchInput
            label={SEARCH_INPUT_LABEL}
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            value={searchQuery}
            onChange={onSearchInputChange}
          />
        </div>
      </div>
      <div className="filter-item-form">
        <div className="fields-group">
          <div className="fields-group-options-list">
            {renderSelectedItems()}
            {searchQuery.length >= MIN_SEARCH_QUERY_LENGTH ? renderFilteredItems() : renderItems()}
            {shouldShowLoadMoreButton ? (
              <Button
                onClick={() => setAmountOfResultsToShow(amountOfResultsToShow + MIN_RESULTS_TO_SHOW)}
              >
                {LOAD_MORE}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      {renderAcceptButton(() => onAcceptClick({ name: filterName, value: selectedItems }))}
    </>
  );
};
