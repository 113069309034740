import { axiosService, predictedDataService } from '../../../core/services/axios.service';

import { HospitalsServiceConfigProvider } from './HospitalsServiceConfigProvider';

import { formatFilterValues } from '../utils/hospitalsServiceDataFormatter';

export class HospitalsServiceApiProvider {
  static getAvailableCountries() {
    return axiosService.get(HospitalsServiceConfigProvider.getCountriesListEndpoint());
  }

  static getFilteringOptions({ countries }) {
    return axiosService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getFilteringOptionsEndpoint(),
      data: {
        countries,
      },
    });
  }

  static getStatesByCountriesAndStateName({ countries, state }) {
    return axiosService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getStatesByCountriesAndCityNameEndpoint(),
      data: {
        countries,
        state,
      },
    });
  }

  static getCitiesByCountriesAndCityName({ countries, state, city }) {
    return axiosService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getCitiesByCountriesAndCityNameEndpoint(),
      data: {
        countries,
        state,
        city,
      },
    });
  }

  static getHospitalsCalculatedData({ filterValues = {} }) {
    return axiosService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getHospitalsCalculatedDataEndpoint(),
      data: {
        ...formatFilterValues(filterValues),
      },
    });
  }

  static getHospitalsPredictedCalculatedData({ filterValues = {} }) {
    return predictedDataService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getHospitalsCalculatedDataEndpoint(),
      data: {
        ...formatFilterValues(filterValues),
      },
    });
  }

  static getHospitalsList({
    limit,
    offset,
    filterValues = {},
    sortedBy,
    sortingDirection,
  }) {
    return axiosService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getHospitalsListEndpoint(),
      data: {
        ...formatFilterValues(filterValues),
        sortedBy,
        sortingDirection,
        limit,
        offset,
      },
    });
  }

  static getPredictedHospitalsList({
    limit,
    offset,
    filterValues = {},
    sortedBy,
    sortingDirection,
  }) {
    return predictedDataService({
      method: 'post',
      url: HospitalsServiceConfigProvider.getHospitalsListEndpoint(),
      data: {
        ...formatFilterValues(filterValues),
        sortedBy,
        sortingDirection,
        limit,
        offset,
      },
    });
  }
}
