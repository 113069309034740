import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FilterItemWrapper } from './shared/FilterItemWrapper';
import { CommonFilter } from './shared/CommonFilter/CommonFilter';
import { Institution } from './shared/Institution/Institution';

import {
  performSearch as performSearchAction,
  setActiveFilter as setActiveFilterAction,
  setFilterValue as setFilterValueAction,
} from '../../../store/filters/filters.actions';

import { filtersSelector } from '../../../store/filters/filters.selector';

import { FILTERS } from '../../../constants/filters.constants';
import { checkIfFilterIsEmpty } from '../../../../hospiscope/components/Filters/utils/filters.utils';

export const FilterItemsFactoryComponent = ({
  filterName,
  filterClassName,
  title,
  activeFilter,
  filteringOptions,
  filterValues,
  setActiveFilter,
  setFilterValue,
  performSearch,
}) => {
  const filterValue = filterValues[filterName];

  const isFilterEmpty = checkIfFilterIsEmpty({ filterValue });

  const onAcceptClick = ({ name, value }) => {
    setFilterValue({ filterName: name, filterValue: value });
    return performSearch({ filterName: name, filterValue: value });
  };

  const onClear = ({ name }) => {
    setFilterValue({ filterName: name, filterValue: [] });
    return performSearch({ filterName: name, filterValue: [] });
  };

  const filterItemWrapperProps = {
    filterName,
    filterClassName,
    title,
    activeFilter,
    isFilterEmpty,
    setActiveFilter,
    onClear,
  };

  function renderMenuItemContent() {
    switch (filterName) {
      case FILTERS.GROUP: {
        return (
          <CommonFilter
            items={filteringOptions.groups}
            filterName={FILTERS.GROUP}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      case FILTERS.CATEGORY: {
        return (
          <CommonFilter
            items={filteringOptions.categories}
            filterName={FILTERS.CATEGORY}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      case FILTERS.SUBCATEGORY: {
        return (
          <CommonFilter
            items={filteringOptions.subcategories}
            filterName={FILTERS.SUBCATEGORY}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      case FILTERS.PROCEDURE: {
        return (
          <CommonFilter
            items={filteringOptions.procedures}
            filterName={FILTERS.PROCEDURE}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      case FILTERS.INSTITUTION: {
        return (
          <Institution
            filterName={FILTERS.INSTITUTION}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      default: {
        return null;
      }
    }
  }
  return (
    <FilterItemWrapper {...filterItemWrapperProps}>
      {renderMenuItemContent()}
    </FilterItemWrapper>
  );
};

const mapStateToProps = (state) => ({
  activeFilter: filtersSelector.activeFilter(state),
  filteringOptions: filtersSelector.filteringOptions(state),
  filterValues: filtersSelector.filterValues(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setActiveFilter: setActiveFilterAction,
  setFilterValue: setFilterValueAction,
  performSearch: performSearchAction,
}, dispatch);

export const FilterItemsFactory = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterItemsFactoryComponent);
