import { getAdminState } from '../admin.selector';

function getUserPageState(state) {
  return getAdminState(state).userPage;
}

export const userPageSelector = {
  isLoading(state) {
    return getUserPageState(state).isLoading;
  },
  userInfo(state) {
    return getUserPageState(state).userInfo;
  },
};
