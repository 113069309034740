import React from 'react';
import { Table } from 'semantic-ui-react';

import { TABLE_HEADER_CELLS } from './LaboratoriesTableHeader';

import { getTableCellContent } from '../laboratoriesData.utils';

export const LaboratoriesTableBodyComponent = ({
  laboratoriesList,
  toggleLaboratoryModal,
}) => {
  const formattedHospitalsList = laboratoriesList;

  const getCellContent = ({ laboratory, key }) => getTableCellContent(laboratory[key], key);

  return (
    <Table.Body>
      {formattedHospitalsList.map((laboratory) => {
        return (
          <Table.Row
            key={`${laboratory.year}-${laboratory.id}`}
            onClick={() => toggleLaboratoryModal({ open: true, id: laboratory.id })}
          >
            {TABLE_HEADER_CELLS.map((cell) => (
              <Table.Cell key={`${cell.key}-${laboratory.year}-${laboratory.id}`}>
                {getCellContent({ laboratory, key: cell.key })}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const LaboratoriesTableBody = LaboratoriesTableBodyComponent;
