import { getLaboratoriesState } from '../laboratories.selector';

function getLaboratoriesDataState(state) {
  return getLaboratoriesState(state).laboratoriesData;
}

export const laboratoriesDataSelector = {
  isLoading(state) {
    return getLaboratoriesDataState(state).isLoading;
  },
  laboratoriesList(state) {
    return getLaboratoriesDataState(state).laboratoriesList;
  },
  selectedLaboratory(state, selectedLaboratoryId) {
    const list = laboratoriesDataSelector.laboratoriesList(state);
    return list.find((laboratory) => laboratory.id === selectedLaboratoryId);
  },
  pagination(state) {
    return getLaboratoriesDataState(state).pagination;
  },
  sortedBy(state) {
    return getLaboratoriesDataState(state).sortedBy;
  },
  sortingDirection(state) {
    return getLaboratoriesDataState(state).sortingDirection;
  },
};
