import React from 'react';

import { Header } from './Header';
import { FieldTable } from './FieldTable';
import { Logo } from '../../../../../../core/components/Logo/Logo';

import { getModalTableFields } from '../../utils/hospitalModal.utils';

import { FILTERS } from '../../../../constants/filters.constants';
import { HOSPITAL_PDF_SELECTORS } from '../../constants/pdfSelectors.constants';

export const PdfContent = ({ selectedHospital }) => {
  const renderFooter = (page) => {
    return (
      <div className="pdf-footer">
        <Logo />
        <p className="page-number">{page}</p>
      </div>
    );
  };
  return (
    <div className="pdf-wrapper">
      <div className="pdf-content">
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.FIRST_PAGE}`}>
          <Header selectedHospital={selectedHospital} />
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.GENERAL_INFORMATION)}
              title="General information"
              className="general-info"
              isPdfRow
            />
          </div>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.INFRASTRUCTURE)}
              title="Infrastructure"
              plain={false}
              className="infrastructure"
              isPdfRow
            />
          </div>
          {renderFooter('01')}
        </div>
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.SECOND_PAGE}`}>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.BEDS)}
              title="Beds"
              className="beds"
              isPdfRow
            />
          </div>
          {/* <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.MEDICAL_OFFICES)}
              title="Medical offices"
              className="medical-offices"
              isPdfRow
            />
          </div> */}
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.SPECIALTIES, 2)}
              title="Specialities"
              className="specialities"
              plain={false}
              isPdfRow
            />
          </div>
          {renderFooter('02')}
        </div>
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.THIRD_PAGE}`}>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.EQUIPMENT, 2)}
              title="Equipment"
              className="equipment"
              plain={false}
              isPdfRow
            />
          </div>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.LABORATORY)}
              title="Laboratory"
              className="laboratory"
              isPdfRow
            />
          </div>
          {renderFooter('03')}
        </div>
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.FOURTH_PAGE}`}>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.TECHNOLOGY)}
              title="Technology"
              className="technology"
              isPdfRow
            />
          </div>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.KEY_PERSONNEL)}
              title="Key personnel"
              className="key-personnel"
              isPdfRow
            />
          </div>
          {renderFooter('04')}
        </div>
        {/* <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.FIFTH_PAGE}`}>
          {renderFooter('05')}
        </div>
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.SIXTH_PAGE}`}>
          {renderFooter('06')}
        </div>
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.SEVENTH_PAGE}`}>
          <div className="pdf-info-block">
            <FieldTable
              selectedHospital={selectedHospital}
              fields={getModalTableFields(FILTERS.HR)}
              title="HR"
              className="hr"
              isPdfRow
            />
          </div>
          {renderFooter('07')}
        </div>
        <div className={`pdf-page ${HOSPITAL_PDF_SELECTORS.EIGHTH_PAGE}`}>
          {renderFooter('08')}
        </div> */}
      </div>
    </div>
  );
};
