export const FILTERS = {
  YEAR: 'year',
  COUNTRY: 'country',
  GROUP: 'group',
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
  PROCEDURE: 'procedure',
  INSTITUTION: 'institution',
};

export const FILTER_MENU_TITLES = {
  [FILTERS.GROUP]: 'Group',
  [FILTERS.CATEGORY]: 'Category',
  [FILTERS.SUBCATEGORY]: 'Subcategory',
  [FILTERS.PROCEDURE]: 'Procedure',
  [FILTERS.INSTITUTION]: 'Institution',
};

export const LIST_OF_FILTERS_TO_CLEAR = {
  [FILTERS.GROUP]: [FILTERS.CATEGORY, FILTERS.SUBCATEGORY, FILTERS.PROCEDURE],
  [FILTERS.CATEGORY]: [FILTERS.SUBCATEGORY, FILTERS.PROCEDURE],
  [FILTERS.SUBCATEGORY]: [FILTERS.PROCEDURE],
  [FILTERS.PROCEDURE]: [],
  [FILTERS.INSTITUTION]: [],
};

export const FILTER_NAME_TO_OPTIONS_LIST_KEY = {
  [FILTERS.GROUP]: 'groups',
  [FILTERS.CATEGORY]: 'categories',
  [FILTERS.SUBCATEGORY]: 'subcategories',
  [FILTERS.PROCEDURE]: 'procedures',
  [FILTERS.INSTITUTION]: 'institution',
};
