import { toast } from 'react-toastify';

import { container } from '../../config';

import { ServiceApiProvider } from '../../services/ServiceApiProvider';

import { getMainViewData } from '../MainViewData/mainViewData.actions';

import { TOAST_CONSTANTS } from '../../../../core/constants/core.constants';

import { filtersSelector } from './filters.selector';

export const SET_ACTIVE_FILTER = `${container.name}/Filters/SET ACTIVE FILTER`;
export const SET_FILTER_VALUE = `${container.name}/Filters/SET FILTER VALUE`;
export const SET_DYNAMIC_FILTER_VALUE = `${container.name}/Filters/SET DYNAMIC FILTER VALUE`;
export const SET_YEAR_OPTIONS_WERE_LOADED = `${container.name}/Filters/SET YEAR OPTIONS WERE LOADED`;

export const GET_FILTERING_OPTIONS_REQUEST = `${container.name}/Filters/GET FILTERING OPTIONS REQUEST`;
export const GET_FILTERING_OPTIONS_SUCCESS = `${container.name}/Filters/GET FILTERING OPTIONS SUCCESS`;
export const GET_FILTERING_OPTIONS_FAILURE = `${container.name}/Filters/GET FILTERING OPTIONS FAILURE`;

export const GET_FILTERING_OPTIONS_DYNAMIC_REQUEST = `${container.name}/Filters/GET DYNAMIC OPTIONS REQUEST`;
export const GET_FILTERING_OPTIONS_DYNAMIC_SUCCESS = `${container.name}/Filters/GET DYNAMIC OPTIONS SUCCESS`;
export const GET_FILTERING_OPTIONS_DYNAMIC_FAILURE = `${container.name}/Filters/GET DYNAMIC OPTIONS FAILURE`;

export const CLEAR_FILTERS_IF_NEEDED = `${container.name}/Filters/CLEAR FILTERS IF NEEDED`;

export const setFilterValue = ({ filterName, filterValue, addToCurrent }) => (dispatch) => {
  return dispatch({
    type: SET_FILTER_VALUE,
    payload: {
      filterName,
      filterValue,
      addToCurrent,
    },
  });
};

export const setDynamicFilterValue = ({ filterName, searchQuery }) => (dispatch) => {
  return dispatch({
    type: SET_DYNAMIC_FILTER_VALUE,
    payload: {
      dynamicFilter: {
        filterName,
        searchQuery,
      },
    },
  });
};

export const setActiveFilter = (activeFilter) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_FILTER,
    payload: {
      activeFilter,
    },
  });
};

export const setYearOptionsWereLoaded = (payload) => (dispatch) => {
  return dispatch({
    type: SET_YEAR_OPTIONS_WERE_LOADED,
    payload,
  });
};

export const getFilteringOptions = (filterName, filterValue) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: GET_FILTERING_OPTIONS_REQUEST,
  });
  const filterValues = filtersSelector.filterValues(state);
  const filterHasSelectedValues = filterValue && filterValue.length;
  const currentFilterOptions = filtersSelector.filteringOptions(state)[filterName];
  try {
    const { data } = await ServiceApiProvider.getFilteringOptions(filterValues);

    return dispatch({
      type: GET_FILTERING_OPTIONS_SUCCESS,
      payload: {
        filteringOptions: {
          ...data,
          // Making sure we are not clearing filter with loaded or selected options
          [filterName]: filterHasSelectedValues ? currentFilterOptions : data[filterName],
        },
      },
    });
  } catch (e) {
    toast.error(TOAST_CONSTANTS.FILTERING_OPTIONS_ERROR);
    return dispatch({
      type: GET_FILTERING_OPTIONS_FAILURE,
    });
  }
};

export const getFilteringOptionsDynamic = (filterName, filterValue, searchQuery) => async (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: GET_FILTERING_OPTIONS_DYNAMIC_REQUEST,
  });
  const filterValues = filtersSelector.filterValues(state);
  // const filterHasSelectedValues = filterValue && filterValue.length;
  // const currentFilterOptions = filtersSelector.filteringOptions(state)[filterName];
  const dynamicFilter = { filterName, searchQuery };
  try {
    const { data } = await ServiceApiProvider.getFilteringOptionsDynamic(filterValues, dynamicFilter);
    // const data = {
    //   item: {
    //     'ACEPROMACINA (EN FORMA DE MALEATO)  5 MG., ALCOHOL BENCÍLICO (EXCIPIENTE) 10 MG.': '8',
    //      ALCOHOL: '20',
    //     'ALCOHOL 1,000 ML 96°': '2',
    //     'ALCOHOL DESNATURALIZADO. ENVASE CON 20 LITROS. ENVASE/1/ENVASE': '46',
    //     'ALCOHOL DESNATURALIZADO PARA CURACIONES, BOTELLA DE 500 ML': '3',
    //     'ALCOHOL DESNATURALIZADO SELECTA EN PRESENTACIÓN DE UN GALÓN': '3',
    //   },

    return dispatch({
      type: GET_FILTERING_OPTIONS_DYNAMIC_SUCCESS,
      payload: {
        filteringOptions: {
          ...data,
          // Making sure we are not clearing filter with loaded or selected options
          // [filterName]: filterHasSelectedValues ? currentFilterOptions : data[filterName],
        },
      },
    });
  } catch (e) {
    toast.error(TOAST_CONSTANTS.FILTERING_OPTIONS_ERROR);
    return dispatch({
      type: GET_FILTERING_OPTIONS_DYNAMIC_FAILURE,
    });
  }
};

export const clearFilters = (filtersToClear) => (dispatch) => {
  dispatch({
    type: CLEAR_FILTERS_IF_NEEDED,
    payload: {
      filtersToClear,
    },
  });
};

export const performSearch = ({
  filterName,
  filterValue,
  addToCurrent,
  filtersToClear,
}) => (dispatch, getState) => {
  setActiveFilter(null)(dispatch);

  if (filtersToClear) {
    clearFilters(filtersToClear)(dispatch);
  }
  if (filterName) {
    setFilterValue({ filterName, filterValue, addToCurrent })(dispatch);
  }

  getFilteringOptions(filterName, filterValue)(dispatch, getState);

  return getMainViewData({ currentPage: 1 })(dispatch, getState);
};
