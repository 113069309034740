export const TECHNOLOGY_MENU_ITEMS = {
  // TOTAL_COMPUTERS: {
  //   label: 'Computers',
  //   placeholder: 'Number',
  //   name: 'computers',
  // },
  INTERNET_CONNECTION: {
    label: 'Internet connection',
    name: 'internetConnection',
  },
  // FIREWALL: {
  //   label: 'Presence of a firewall',
  //   name: 'firewall',
  // },
  INTERNET_CONNECTION_TYPE_DROPDOWN: {
    label: 'Type of internet access',
    name: 'internetAccessType',
  },
};

export const GENERATED_TECHNOLOGY_MENU_ITEMS = {
  // FIRST_SECTION: [
  //   {
  //     label: 'Desktop',
  //     placeholder: 'Number',
  //     name: 'desktop',
  //   },
  //   {
  //     label: 'Laptops',
  //     placeholder: 'Number',
  //     name: 'laptops',
  //   },
  //   {
  //     label: 'Servers',
  //     placeholder: 'Number',
  //     name: 'servers',
  //   },
  //   {
  //     label: 'Routers',
  //     placeholder: 'Number',
  //     name: 'routers',
  //   },
  //   {
  //     label: 'Tablets',
  //     placeholder: 'Number',
  //     name: 'tablets',
  //   },
  // ],
  SECOND_SECTION: [
    {
      label: 'Presence of EMR / EHR',
      name: 'emr',
    },
    {
      label: 'EMR is DICOM compatible',
      name: 'emrDicom',
    },
    {
      label: 'Picture Archiving & Communication System (PACS)',
      name: 'pictureArchiving',
    },
    {
      label: 'Presence of Radiological Information System (RIS)',
      name: 'radiologicalInformationSystem',
    },
    {
      label: 'Patient interoperability ',
      name: 'patientInteroperability',
    },
    {
      label: 'Patient home monitoring',
      name: 'patientHomeMonitoring',
    },
    {
      label: 'Telemedicine participation',
      name: 'telemedicineParticipation',
    },
    {
      label: 'Telemedicine hub',
      name: 'telemedicineHub',
    },
    {
      label: 'International Telemedicine',
      name: 'internationalTelemedicine',
    },
    {
      label: 'Is a telemedicine spoke',
      name: 'isTelemedecineSpokesite',
    },
  ],
};
