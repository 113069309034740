import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { SearchMenu } from './SearchMenu/SearchMenu';
import { HospitalsData } from './HospitalsData/HospitalsData';
import { Filters } from './Filters/Filters';

import {
  getAvailableCountries as getAvailableCountriesAction,
  getFilteringOptions as getFilteringOptionsAction,
} from '../../common/store/filters/filters.actions';

import { filtersSelector } from '../../common/store/filters/filters.selector';

import { MAIN_APP_CONTAINERS } from '../../../core/constants/core.constants';

const HospitalsPageComponent = ({
  areAvailableCountriesLoading,
  areFilteringOptionsLoading,
  getAvailableCountries,
  getFilteringOptions,
}) => {
  useEffect(() => {
    getAvailableCountries(MAIN_APP_CONTAINERS.HOSPISCOPE);
    getFilteringOptions();
  }, [getAvailableCountries, getFilteringOptions]);
  return (
    <>
      <Dimmer active={areAvailableCountriesLoading || areFilteringOptionsLoading}>
        <Loader />
      </Dimmer>
      {areAvailableCountriesLoading || areFilteringOptionsLoading ? null : (
        <>
          <div className="page-header">
            <div className="container main">
              <SearchMenu />
              <Filters />
            </div>
          </div>
          <div className="hospitals-content-wrapper">
            <HospitalsData />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  areAvailableCountriesLoading: filtersSelector.areAvailableCountriesLoading(state),
  areFilteringOptionsLoading: filtersSelector.areFilteringOptionsLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAvailableCountries: getAvailableCountriesAction,
  getFilteringOptions: getFilteringOptionsAction,
}, dispatch);

export const HospitalsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HospitalsPageComponent);
