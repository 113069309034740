import { AdminServiceApiProvider } from '../../services/AdminServiceApiProvider';

export const GET_ADMINS_LIST_REQUEST = '[ADMINS] GET ADMINS LIST REQUEST';
export const GET_ADMINS_LIST_SUCCESS = '[ADMINS] GET ADMINS LIST SUCCESS';
export const GET_ADMINS_LIST_FAILURE = '[ADMINS] GET ADMINS LIST FAILURE';

export const SET_LOADING_STATE = '[ADMINS] SET LOADING STATE';

export function setIsLoading(isLoading) {
  return (dispatch) => {
    return dispatch({
      type: SET_LOADING_STATE,
      payload: {
        isLoading,
      },
    });
  };
}

export function getAdminsList() {
  return async (dispatch) => {
    dispatch({
      type: GET_ADMINS_LIST_REQUEST,
    });
    try {
      const { data } = await AdminServiceApiProvider.getAdminsList();
      return dispatch({
        type: GET_ADMINS_LIST_SUCCESS,
        payload: {
          adminsList: data,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_ADMINS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export function addAdmin({ admin }) {
  return async (dispatch) => {
    setIsLoading(true)(dispatch);
    try {
      await AdminServiceApiProvider.addAdmin({ admin });
      setIsLoading(false)(dispatch);
      return getAdminsList()(dispatch);
    } catch (error) {
      return setIsLoading(false)(dispatch);
    }
  };
}
