import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Button, Loader, Modal } from 'semantic-ui-react';
import ReactExport from 'react-data-export';

import { HospitalsListTable } from './HospitalsListTable/HospitalsListTable';

import { proceduresDataSelector } from '../../../store/proceduresData/proceduresData.selector';

import { HOSPITALS_MODAL_TEXTS } from './hospitalsListModal.constants';
import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';

import {
  prepareFiltersInfoForExport,
  singleProcedureExportHospitalsList,
} from '../../../utils/procedures.utils';
import { sortList } from '../../../../../core/utils/core.utils';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;

const EXCEL_FILENAME = 'SurgiScope - Single Procedure';
const DEFAULT_SORTING_PARAM = 'hospital_name';

export const HospitalsListModalComponent = ({
  open,
  isLoading,
  hospitalsList,
  selectedProcedure,
  onClose,
}) => {
  const [sortedBy, setSortedBy] = useState(DEFAULT_SORTING_PARAM);
  const [sortingDirection, setSortingDirection] = useState(SORTING_DIRECTIONS.DESC);
  const sortedHospitalsList = sortList({ list: hospitalsList, sortedBy, sortingDirection });
  const filterValues = useSelector((state) => state.procedures.filters.filterValues);

  return (
    <Modal open={open} onClose={onClose} className="proceduresModal">
      {isLoading || !selectedProcedure ? <Loader active={isLoading} />
        : (
          <>
            <Modal.Header>
              <div className="hospital-modal-header">
                <div className="header-data">
                  <div className="header-data-column">
                    <p className="hospital-name">{HOSPITALS_MODAL_TEXTS.PROCEDURE_INFO}</p>
                    <p>
                      <span>{HOSPITALS_MODAL_TEXTS.GROUP}</span>
                      <span>{selectedProcedure.procedure_group}</span>
                    </p>
                    <p>
                      <span>{HOSPITALS_MODAL_TEXTS.CATEGORY}</span>
                      <span>{selectedProcedure.procedure_category}</span>
                    </p>
                    <p>
                      <span>{HOSPITALS_MODAL_TEXTS.SUBCATEGORY}</span>
                      <span>{selectedProcedure.procedure_subcategory}</span>
                    </p>
                    <p>
                      <span>{HOSPITALS_MODAL_TEXTS.PROCEDURE}</span>
                      <span>{selectedProcedure.procedure_name}</span>
                    </p>
                    <p>
                      <span>{HOSPITALS_MODAL_TEXTS.TOTAL_CONDUCTED}</span>
                      <span>{selectedProcedure.sum}</span>
                    </p>
                  </div>
                </div>
                <div className="header-buttons">
                  <ExcelFile
                    filename={EXCEL_FILENAME}
                    element={<Button>Download</Button>}
                  >
                    <ExcelSheet
                      dataSet={prepareFiltersInfoForExport(filterValues)}
                      name="Filter data"
                    />
                    <ExcelSheet
                      dataSet={singleProcedureExportHospitalsList(sortedHospitalsList)}
                      name="Hospital List"
                    />
                  </ExcelFile>
                </div>
              </div>
            </Modal.Header>
            <Modal.Content scrolling>
              <HospitalsListTable
                hospitalsList={sortedHospitalsList}
                sortedBy={sortedBy}
                sortingDirection={sortingDirection}
                setSortedBy={setSortedBy}
                setSortingDirection={setSortingDirection}
              />
            </Modal.Content>
          </>
        )}
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  selectedProcedure:
    proceduresDataSelector.selectedProcedure(state, props.selectedProcedureName, props.selectedProcedureCode),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const HospitalsListModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HospitalsListModalComponent);
