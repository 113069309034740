import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { usePrevious } from 'react-delta';

import { mainViewCfg } from '../../config';
import { SaveSearch } from '../../../hospiscope/components/SearchMenu/SaveSearch';
import { SavedSearches } from '../../../hospiscope/components/SearchMenu/SavedSearches';

import { FILTERS } from '../../constants/filters.constants';
import { MAIN_APP_CONTAINERS } from '../../../../core/constants/core.constants';
import { getLastAvailableYearOption } from '../../../../core/utils/core.utils';

import {
  setFilterValue as setFilterValueAction,
  getFilteringOptions as getFilteringOptionsAction,
  setYearOptionsWereLoaded as setYearOptionsWereLoadedAction,
  performSearch as performSearchAction,
  clearFilters as clearFiltersAction,
} from '../../store/filters/filters.actions';
import { getSavedSearches as getSavedSearchesAction } from '../../../../core/user/user.actions';
import {
  loadSavedSearch as loadSavedSearchAction,
  setIsLoadingSavedSearch as setIsLoadingSavedSearchAction,
} from '../../store/MainViewData/mainViewData.actions';

import { filtersSelector } from '../../store/filters/filters.selector';
import { userSelector } from '../../../../core/user/user.selector';
import { mainViewDataSelector } from '../../store/MainViewData/mainViewData.selector';

export const SearchMenuComponent = ({
  selectedCountry,
  selectedYear,
  availableCountries,
  filteringOptions,
  getFilteringOptions,
  areFilteringOptionsLoading,
  yearOptionsWereLoaded,
  setYearOptionsWereLoaded,
  savedSearches,
  areSavedSearchesLoading,
  isLoadingSavedSearch,
  setIsLoadingSavedSearch,
  pagination,
  setFilterValue,
  getSavedSearches,
  clearFilters,
  performSearch,
  loadSavedSearch,
}) => {
  const { year: years } = filteringOptions;
  const selectedCountryPrev = usePrevious(selectedCountry);
  const selectedYearPrev = usePrevious(selectedYear);

  useEffect(() => {
    getSavedSearches(MAIN_APP_CONTAINERS.PRICESCOPE);
  }, [getSavedSearches]);

  useEffect(() => {
    if (selectedCountryPrev !== undefined && selectedCountry !== selectedCountryPrev) {
      // Let loadSaveSearch() set its own filters, but double check we have a valid year
      setYearOptionsWereLoaded(false);
      if (!isLoadingSavedSearch) {
        clearFilters(mainViewCfg.filtersToClearOnCountryChange);
      }
    }
  }, [selectedCountry, selectedCountryPrev, setYearOptionsWereLoaded, isLoadingSavedSearch,
    clearFilters]);

  useEffect(() => {
    if (!areFilteringOptionsLoading && selectedCountry) {
      if (!yearOptionsWereLoaded) {
        getFilteringOptions();
      // Select max year if no year is selected, or if the selected one is not in the current available years
      } else if (!selectedYear || !years.includes(selectedYear)) {
        setFilterValue({ filterName: FILTERS.YEAR, filterValue: getLastAvailableYearOption(years) });
        // Let loadSaveSearch() call getMainViewData(), without reseting filterValues nor getting same filterOptions
        // selectedYearPrev changes from undefined to null after we first select a year, so check vs undefined
      } else if (!isLoadingSavedSearch && selectedYearPrev !== undefined && selectedYear !== selectedYearPrev) {
        performSearch({ filtersToClear: mainViewCfg.filtersToClearOnYearChange });
      } else if (isLoadingSavedSearch) {
        // Set to false here now that selectedYearPrev == selectedYear and filters and view data are done
        setIsLoadingSavedSearch(false);
      }
    }
  }, [areFilteringOptionsLoading, yearOptionsWereLoaded, isLoadingSavedSearch,
    selectedCountry, years, selectedYear, selectedYearPrev,
    getFilteringOptions, performSearch, setFilterValue, setIsLoadingSavedSearch]);

  function onYearChange(value) {
    setFilterValue({ filterName: FILTERS.YEAR, filterValue: value });
  }

  function onCountryChange(value) {
    setFilterValue({ filterName: FILTERS.COUNTRY, filterValue: value });
  }

  function getOptionLabelClassName(isSelected) {
    return `filter-option-picker-label clickable ${isSelected ? '' : 'unselected'}`;
  }

  return (
    <div className="search-block">
      <div className="filter-main">
        <div className="filter-option-picker">
          {(availableCountries !== undefined && availableCountries.length) ? availableCountries.map((item) => {
            const isSelected = item === selectedCountry;
            return (
              <div
                key={item}
                className={getOptionLabelClassName(isSelected)}
                onClick={() => onCountryChange(item)}
              >
                <span>{item}</span>
              </div>
            );
          }) : null}
        </div>
        <div className="filter-option-picker">
          {years && years.length ? years.map((item) => {
            const isSelected = item === selectedYear;
            return (
              <div
                key={item}
                className={getOptionLabelClassName(isSelected)}
                onClick={() => onYearChange(item)}
              >
                <span>{item}</span>
              </div>
            );
          }) : null}
        </div>
      </div>
      <div className="filter-secondary">
        <div className="saved-searches-wrapper">
          <SaveSearch pagination={pagination} currentPageContext={MAIN_APP_CONTAINERS.PRICESCOPE} />
          <SavedSearches
            savedSearches={savedSearches}
            areSavedSearchesLoading={areSavedSearchesLoading}
            currentPageContext={MAIN_APP_CONTAINERS.PRICESCOPE}
            loadSavedSearch={loadSavedSearch}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCountry: filtersSelector.selectedCountry(state),
  selectedYear: filtersSelector.selectedYear(state),
  availableCountries: filtersSelector.availableCountries(state),
  filteringOptions: filtersSelector.filteringOptions(state),
  areFilteringOptionsLoading: filtersSelector.areFilteringOptionsLoading(state),
  yearOptionsWereLoaded: filtersSelector.yearOptionsWereLoaded(state),
  savedSearches: userSelector.savedSearches(state),
  areSavedSearchesLoading: userSelector.areSavedSearchesLoading(state),
  isLoadingSavedSearch: mainViewDataSelector.isLoadingSavedSearch(state),
  pagination: mainViewDataSelector.pagination(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterValue: setFilterValueAction,
  getFilteringOptions: getFilteringOptionsAction,
  setYearOptionsWereLoaded: setYearOptionsWereLoadedAction,
  setIsLoadingSavedSearch: setIsLoadingSavedSearchAction,
  performSearch: performSearchAction,
  clearFilters: clearFiltersAction,
  getSavedSearches: getSavedSearchesAction,
  loadSavedSearch: loadSavedSearchAction,
}, dispatch);

export const SearchMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMenuComponent);
