import { object, string } from 'yup';

export const FORM_VALIDATION_ERRORS = {
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last name is required',
  EMAIL_REQUIRED: 'Email is required',
  INVALID_EMAIL: 'Email is not valid',
  TITLE_REQUIRED: 'Title is required',
  NAME_REQUIRED: 'Name is required',
};

const {
  FIRST_NAME_REQUIRED, LAST_NAME_REQUIRED, EMAIL_REQUIRED, INVALID_EMAIL, TITLE_REQUIRED, NAME_REQUIRED,
} = FORM_VALIDATION_ERRORS;

const MAX_CHARS = 20;
const LENGTH_ERROR_MESSAGE = `Should be less than ${MAX_CHARS} chars.`;

export const addUserValidationSchema = object().shape({
  firstName: string().required(FIRST_NAME_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
  lastName: string().required(LAST_NAME_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
  title: string().required(TITLE_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
  email: string().required(EMAIL_REQUIRED).email(INVALID_EMAIL),
});

export const editUserValidationSchema = object().shape({
  firstName: string().required(FIRST_NAME_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
  lastName: string().required(LAST_NAME_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
  title: string().required(TITLE_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
});

export const editClientValidationSchema = object().shape({
  name: string().required(NAME_REQUIRED).max(MAX_CHARS, LENGTH_ERROR_MESSAGE),
});

export function getYupErrorMessage({ errors, path }) {
  const error = errors.find((errorObj) => errorObj.path === path);
  return error && error.message;
}
