import axios from 'axios';
import { axiosService } from '../../../core/services/axios.service';
import { ServiceConfigProvider } from './ServiceConfigProvider';
import { isEmpty } from '../../../core/utils/core.utils';

export class ServiceApiProvider {
  static getAvailableCountries() {
    return axiosService.get(ServiceConfigProvider.getCountriesListEndpoint());
  }

  static getFilteringOptions(data) {
    return axiosService({
      method: 'post',
      url: ServiceConfigProvider.getFilteringOptionsEndpoint(),
      data: { filterValues: data },
      transformResponse: axios.defaults.transformResponse.concat((rawData) => {
        let supplier = Object.entries(rawData.buyer)
          .reduce((result, [, value]) => [...result, ...value.filter((val) => !isEmpty(val))],
            []);

        // Remove duplicates and sort
        supplier = Array.from(new Set(supplier)).sort();

        const buyer = Array.from(new Set(Object.keys(rawData.buyer))).sort();

        return { ...rawData, buyer, supplier };
      }),
    });
  }

  static getFilteringOptionsDynamic(filterValues, dynamicFilter) {
    return axiosService({
      method: 'post',
      url: ServiceConfigProvider.getFilteringOptionsDynamicEndpoint(),
      data: { filterValues, dynamicFilter },

    });
  }

  static getMainViewData(queryOptions) {
    return axiosService({
      method: 'post',
      url: ServiceConfigProvider.getMainViewDataEndpoint(),
      data: queryOptions,
      // transformRequest: [function (data, headers) {
      //   return data;
      // }],
      // Axios example fails, gotta transform response to Json with:
      transformResponse: axios.defaults.transformResponse.concat((data) => {
        // console.log(data) // this should now be JSON
        // Flatten tender.items to feed tables
        const tenderItems = [];
        data.tenders.forEach((tender) => {
          const { items } = tender;
          delete tender.items;

          items.forEach((item) => { tenderItems.push({ ...tender, ...item }); });
        });

        data.tenders = tenderItems;
        return data;
      }),
      // {
      //   limit,
      //   offset,
      //   sortedBy,
      //   sortingDirection,
      //   // ...formatFilterValues(filterValues),
      //   ...filterValues
      // },
    });
  }
}
