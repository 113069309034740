import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { AdminsDashboardHeader } from './AdminsDashboardHeader';
import { AdminsDashboardBody } from './AdminsDashboardBody';

import { AddUserModal } from '../../ClientPage/modals/AddUserModal';

import {
  getAdminsList as getAdminsListAction,
  addAdmin as addAdminAction,
} from '../../store/adminsDashboard/adminsDashboard.actions';

import { adminsDashboardSelector } from '../../store/adminsDashboard/adminsDashboard.selector';

export const AdminsDashboardComponent = ({
  isLoading, adminsList, getAdminsList, addAdmin,
}) => {
  const [shouldShowAddAdminModal, setShouldShowAddAdminModal] = useState(false);
  useEffect(() => {
    getAdminsList();
  }, [getAdminsList]);
  function addAdminHandler({ user: admin }) {
    setShouldShowAddAdminModal(false);
    return addAdmin({ admin });
  }
  return (
    <div className="clients-dashboard">
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <div className="container main">
        <AdminsDashboardHeader onButtonClick={() => setShouldShowAddAdminModal(true)} />
        <AdminsDashboardBody adminsList={adminsList} />
        {shouldShowAddAdminModal ? (
          <AddUserModal
            open={shouldShowAddAdminModal}
            onClose={() => setShouldShowAddAdminModal(false)}
            addUser={addAdminHandler}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: adminsDashboardSelector.isLoading(state),
  adminsList: adminsDashboardSelector.adminsList(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAdminsList: getAdminsListAction,
  addAdmin: addAdminAction,
}, dispatch);

export const AdminsDashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminsDashboardComponent);
