import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'; //, useSelector
import { Button, Loader, Modal } from 'semantic-ui-react';
import ReactExport from 'react-data-export';

import { ProceduresListTable } from './ProceduresListTable/ProceduresListTable';

import { institutionsDataSelector } from '../../../store/institutionsData/institutionsData.selector';

import { PROCEDURES_MODAL_TEXTS } from './proceduresListModal.constants';

import { prepareFiltersInfoForExport,
         prepareProceduresDataForExcel } from '../../../utils/procedures.utils';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ExcelFile;

const EXCEL_FILENAME = 'SurgiScope - Procedures of Single Institution';

export const ProceduresListModalComponent = ({
  open,
  isLoading,
  proceduresList,
  proceduresCount,
  selectedInstitution,
  onClose,
}) => {
  // const filterValues = useSelector((state) => state.procedures.filters.filterValues);

  return (
    <Modal open={open} onClose={onClose} className="proceduresModal">
      {isLoading || !selectedInstitution ? <Loader active={isLoading} />
        : (
          <>
            <Modal.Header>
              <div className="hospital-modal-header">
                <div className="header-data">
                  <div className="header-data-column">
                    <p className="hospital-name">{PROCEDURES_MODAL_TEXTS.HOSPITAL_INFO}</p>
                    <p>
                      <span>{PROCEDURES_MODAL_TEXTS.HOSPITAL_NAME}</span>
                      <span>{selectedInstitution.hospital_name}</span>
                    </p>
                    <p>
                      <span>{PROCEDURES_MODAL_TEXTS.COUNTRY}</span>
                      <span>{selectedInstitution.country}</span>
                    </p>
                    <p>
                      <span>{PROCEDURES_MODAL_TEXTS.TOTAL_CONDUCTED}</span>
                      <span>{proceduresCount}</span>
                    </p>
                  </div>
                </div>
                <div className="header-buttons">
                  <ExcelFile 
                    filename={EXCEL_FILENAME}
                    element={<Button>Download</Button>}
                  >
                    <ExcelSheet
                      // dataSet={prepareProceduresDataForExcel({
                      //   selectedInstitution,
                      //   proceduresList,
                      //   proceduresCount,
                      // }).institution}
                      dataSet={prepareFiltersInfoForExport(selectedInstitution)}
                      name="Filter Data"
                    />
                    <ExcelSheet
                      dataSet={prepareProceduresDataForExcel({
                        selectedInstitution,
                        proceduresList,
                        proceduresCount,
                      }).procedures}
                      name="Procedure List"
                    />
                  </ExcelFile>
                </div>
              </div>
            </Modal.Header>
            <Modal.Content scrolling>
              <ProceduresListTable proceduresList={proceduresList} />
            </Modal.Content>
          </>
        )}
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  selectedInstitution: institutionsDataSelector.selectedInstitution(state, props.selectedInstitutionId),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const ProceduresListModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProceduresListModalComponent);
