import { generateTableFields } from '../../utils/table.utils';

import {
  EQUIPMENT_INPUTS_LIST,
  // HR_INPUTS_LIST,
  KEY_PERSONNEL_INPUTS_LIST,
  // MEDICAL_OFFICES_INPUTS_LIST,
  SPECIALTIES_CHECKBOXES_LIST,
} from '../Filters/constants';

import {
  BEDS_MENU_ITEMS,
  GENERATED_BEDS_MENU_ITEMS,
} from '../Filters/FilterItems/Beds/beds.constants';

import {
  GENERATED_INFRASTRUCTURE_MENU_ITEMS,
  INFRASTRUCTURE_MENU_ITEMS,
} from '../Filters/FilterItems/Infrastructure/infrastructure.constants';

import {
  TECHNOLOGY_MENU_ITEMS,
  GENERATED_TECHNOLOGY_MENU_ITEMS,
} from '../Filters/FilterItems/Technology/technology.constants';

import { LABORATORY_FIELDS_LIST } from '../Filters/FilterItems/Laboratory/laboratory.constants';

export const MAIN_HOSPITAL_TABLE_FIELDS = [
  { label: 'Hospital Name', key: 'name' },
  { label: 'Year', key: 'year' },
  { label: 'Hospital ID', key: 'hospitalId' },
  { label: 'Country', key: 'country' },
  { label: 'City', key: 'city' },
];

export const GENERAL_INFORMATION_TABLE_FIELDS = [
  { label: 'Hospital Name', key: 'name' },
  { label: 'Year', key: 'year' },
  { label: 'Hospital Id', key: 'hospitalId' },
  { label: 'Holding Name', key: 'holdingName' },
  { label: 'Country', key: 'country' },
  { label: 'State', key: 'state' },
  { label: 'City', key: 'city' },
  { label: 'Zip Code', key: 'zipCode' },
  { label: 'Address', key: 'address' },
  { label: 'Affiliation', key: 'affiliation' },
  { label: 'Phone', key: 'phone' },
  { label: 'Email', key: 'email' },
  { label: 'Level Of Care', key: 'levelOfCare' },
  { label: 'Nature Of Establishment', key: 'natureOfEstablishment' },
  { label: 'Type Of Establishment', key: 'typeOfEstablishment' },
  { label: 'Taxpayer Id', key: 'taxPayerId' },
];

export const BEDS_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields([
    BEDS_MENU_ITEMS.TOTAL_HOSPITAL_BEDS,
    ...GENERATED_BEDS_MENU_ITEMS.FIRST_SECTION,
    ...GENERATED_BEDS_MENU_ITEMS.SECOND_SECTION,
  ]),
];

export const INFRASTRUCTURE_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields([
    INFRASTRUCTURE_MENU_ITEMS.TOTAL_ROOMS,
    ...GENERATED_INFRASTRUCTURE_MENU_ITEMS.FIRST_SECTION,
  ]),
];

export const LABORATORY_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields([
    // Obsolete fields
    // ...LABORATORY_FIELDS_LIST.FIRST_SECTION,
    // ...LABORATORY_FIELDS_LIST.SECOND_SECTION,
    // ...LABORATORY_FIELDS_LIST.THIRD_SECTION,
    // ...LABORATORY_FIELDS_LIST.FOURTH_SECTION,
    // ...LABORATORY_FIELDS_LIST.FIFTH_SECTION,
    ...LABORATORY_FIELDS_LIST.SIXTH_SECTION,
  ]),
];

// export const MEDICAL_OFFICES_TABLE_FIELDS = [
// //   ...MAIN_HOSPITAL_TABLE_FIELDS,
//   // ...generateTableFields(MEDICAL_OFFICES_INPUTS_LIST),
// ];

export const EQUIPMENT_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields(EQUIPMENT_INPUTS_LIST),
];

export const SPECIALITIES_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields(SPECIALTIES_CHECKBOXES_LIST),
];

export const TECHNOLOGY_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields([
    // TECHNOLOGY_MENU_ITEMS.TOTAL_COMPUTERS,
    TECHNOLOGY_MENU_ITEMS.INTERNET_CONNECTION,
    // TECHNOLOGY_MENU_ITEMS.FIREWALL,
    TECHNOLOGY_MENU_ITEMS.INTERNET_CONNECTION_TYPE_DROPDOWN,
    // ...GENERATED_TECHNOLOGY_MENU_ITEMS.FIRST_SECTION,
    ...GENERATED_TECHNOLOGY_MENU_ITEMS.SECOND_SECTION,
  ]),
];

// export const HR_TABLE_FIELDS = [
//   ...MAIN_HOSPITAL_TABLE_FIELDS,
//   ...generateTableFields(HR_INPUTS_LIST),
// ];

export const KEY_PERSONNEL_TABLE_FIELDS = [
  ...MAIN_HOSPITAL_TABLE_FIELDS,
  ...generateTableFields(KEY_PERSONNEL_INPUTS_LIST),
];
