import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
} from 'semantic-ui-react';

import {
  CheckboxField, DropdownField,
} from '../shared/components';

import { filtersSelector } from '../../../../../common/store/filters/filters.selector';

import { generateDropdownOptions } from '../../../../../../core/utils/core.utils';
import { generateFilterMenu } from '../../utils/filters.ui.utils';

import { GENERATED_TECHNOLOGY_MENU_ITEMS, TECHNOLOGY_MENU_ITEMS } from './technology.constants';
import { FILTER_MENU_TYPES } from '../../../../constants/filters.constants';

const {
  INTERNET_CONNECTION, INTERNET_CONNECTION_TYPE_DROPDOWN,
} = TECHNOLOGY_MENU_ITEMS;

export const TechnologyComponent = ({
  fields, handleFieldChange, filteringOptions,
}) => {
  const { typeOfInternetAccessOptions } = filteringOptions;
  return (
    <>
      {/* <div className="fields-group fields-group--primary">
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <FromToInputField
                label={TOTAL_COMPUTERS.label}
                placeholder={TOTAL_COMPUTERS.placeholder}
                name={TOTAL_COMPUTERS.name}
                onChange={handleFieldChange}
                value={fields[TOTAL_COMPUTERS.name]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: GENERATED_TECHNOLOGY_MENU_ITEMS.FIRST_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div> */}
      <div className="fields-group fields-group--primary">
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <CheckboxField
                className="exception-firewall"
                onChange={handleFieldChange}
                label={INTERNET_CONNECTION.label}
                name={INTERNET_CONNECTION.name}
                checked={!!fields[INTERNET_CONNECTION.name]}
              />
            </Grid.Column>
            <Grid.Column>
              <DropdownField
                label={INTERNET_CONNECTION_TYPE_DROPDOWN.label}
                name={INTERNET_CONNECTION_TYPE_DROPDOWN.name}
                options={generateDropdownOptions(typeOfInternetAccessOptions)}
                onChange={handleFieldChange}
                value={INTERNET_CONNECTION_TYPE_DROPDOWN.name}
              />
            </Grid.Column>
            {/* <Grid.Column>
              <CheckboxField
                className="exception-firewall"
                onChange={handleFieldChange}
                label={FIREWALL.label}
                name={FIREWALL.name}
                checked={!!fields[FIREWALL.name]}
              />
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </div>
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.CHECKBOX,
          fieldsList: GENERATED_TECHNOLOGY_MENU_ITEMS.SECOND_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  filteringOptions: filtersSelector.filteringOptions(state),
});

export const Technology = connect(
  mapStateToProps,
  null,
)(TechnologyComponent);
