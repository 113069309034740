import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

export const DropdownField = ({
  label,
  name,
  options,
  value,
  search,
  className = '',
  direction,
  selectOnBlur = false,
  loading,
  placeholder = 'Select option',
  noResultsMessage = 'No results found',
  onChange,
  onSearchChange,
}) => {
  const [dropdownValue, setDropdownValue] = useState(value);

  useEffect(() => {
    setDropdownValue(value || null);
  }, [value]);

  function onChangeHandler(e, data) {
    setDropdownValue(data.value);
    onChange({ name: data.name, value: data.value });
  }
  return (
    <div className={`input-group-container dropdown-field-container ${className}`}>
      <Form.Field className="dropdown-field-inner">
        <Form.Dropdown
          label={label}
          placeholder={placeholder}
          name={name}
          options={options}
          value={dropdownValue}
          search={search}
          className={className}
          direction={direction}
          selectOnBlur={selectOnBlur}
          loading={loading}
          noResultsMessage={noResultsMessage}
          onChange={onChangeHandler}
          onSearchChange={onSearchChange}
        />
      </Form.Field>
    </div>
  );
};
