import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/new_logo.png';

export const Logo = () => {
  return (
    <div className="navbar-link-wrapper">
      <Link to="/">
        <div className="navbar-logo logo">
          <img src={logo} alt="Global health intelligence" />
        </div>
      </Link>
    </div>
  );
};
