export const CLIENT_NAME = 'name';
export const IS_ACTIVE_FIELD_NAME = 'is_active';
export const USERS_AMOUNT_FIELD_NAME = 'users_amount';
export const CREATED_AT_FIELD_NAME = 'created_at';
export const ACTIVE_TILL_FIELD_NAME = 'active_till';

export const CLIENTS_TABLE_CELLS = [
  { label: 'Client Name', key: 'name' },
  { label: 'Number of Users', key: 'users_amount' },
  { label: 'Status', key: 'is_active' },
  { label: 'Active Since', key: 'created_at' },
  { label: 'Active Till', key: 'active_till' },
];
