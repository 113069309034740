import React from 'react';
import { Table } from 'semantic-ui-react';

import { navigateToPage } from '../../../../../../core/routing/history';

import { getAdminTableCellContent } from '../../../../utils/table.utils';

import { USERS_TABLE_CELLS } from '../constants/tableFields.constants';

export const UsersTableBodyComponent = ({ usersList }) => {
  return (
    <Table.Body>
      {usersList.map((user) => {
        const { user_id: id } = user;
        return (
          <Table.Row key={id} onClick={() => navigateToPage(`/users/${id}`)}>
            {USERS_TABLE_CELLS.map((cell, index) => (
              <Table.Cell key={`cell-${id}-${index}`}>
                {getAdminTableCellContent({ name: cell.key, value: user[cell.key] })}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const UsersTableBody = UsersTableBodyComponent;
