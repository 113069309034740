import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, Icon } from 'semantic-ui-react';

import { LocalStorageService } from '../../../../core/services/localStorage.service';

import { DownloadModal } from '../modals/DownloadModal';

import {
  performSearch as performSearchAction,
} from '../../../common/store/filters/filters.actions';
import {
  saveSearch as saveSearchAction,
} from '../../../../core/user/user.actions';
import {
  getHospitalsList as getHospitalsListAction,
  getHospitalsCalculatedData as getHospitalsCalculatedDataAction,
} from '../../store/hospitalsData/hospitalsData.actions';

import { hospitalsDataSelector } from '../../store/hospitalsData/hospitalsData.selector';
import { filtersSelector } from '../../../common/store/filters/filters.selector';
import { userSelector } from '../../../../core/user/user.selector';

import { AVAILABLE_DATA_SOURCES, DATA_SOURCE_HEADER_NAME } from '../../../../core/constants/core.constants';

const DISPLAY_MORE_TEXT = 'Show summary';
const HIDE_TEXT = 'Hide summary';
const DOWNLOAD_BUTTON_TEXT = 'Download';
const CLEAR_ALL_FILTERS_BUTTON_TEXT = 'Clear All Filters';
const DATA_SOURCE_CHECKBOX = 'Use predicted data';

const { ORIGINAL, PREDICTED } = AVAILABLE_DATA_SOURCES;

export const HospitalsDataHeaderComponent = ({
  userData,
  hospitalsList,
  count,
  filterValues,
  sortedBy,
  sortingDirection,
  shouldShowCalculatedData,
  setShouldShowCalculatedData,
  performSearch,
  getHospitalsList,
  getHospitalsCalculatedData,
}) => {
  const defaultHospitalsDataSource = LocalStorageService.get(DATA_SOURCE_HEADER_NAME) || ORIGINAL;
  const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState(false);

  const [hospitalsDataSource, setHospitalsDataSource] = useState(defaultHospitalsDataSource);
  function renderResultsMessage() {
    return count > hospitalsList.length ? `${count} found` : `${hospitalsList.length || 0} found`;
  }
  function onDataSourceChange() {
    const newDataSource = hospitalsDataSource === PREDICTED ? ORIGINAL : PREDICTED;
    LocalStorageService.set(DATA_SOURCE_HEADER_NAME, newDataSource);
    setHospitalsDataSource(newDataSource);
    getHospitalsCalculatedData();
    getHospitalsList({ currentPage: 1 });
  }
  function clearAll() {
    return performSearch({ shouldClearFilters: true });
  }
  return (
    <div className="hospitals-data-header">
      <div className="hospitals-data-header-text">
        <div className="hospitals-data-header-title">Results</div>
        <div className="hospitals-data-header-count">
          {renderResultsMessage()}
        </div>
        <div className="hospitals-data-header-slider">
          <Checkbox
            slider
            label={DATA_SOURCE_CHECKBOX}
            checked={hospitalsDataSource === PREDICTED}
            onChange={onDataSourceChange}
          />
        </div>
      </div>
      <div className="hospitals-data-header-actions">
        <div
          className="hospitals-data-header-action"
          onClick={() => setShouldShowCalculatedData(!shouldShowCalculatedData)}
        >
          <div className="hospitals-data-header-action-text">
            {shouldShowCalculatedData ? HIDE_TEXT : DISPLAY_MORE_TEXT}
          </div>
          <Icon
            name={shouldShowCalculatedData ? 'caret up' : 'caret down'}
            className="hospitals-data-header-action-icon"
          />
        </div>
        <div className="hospitals-data-header-action" onClick={() => setShouldShowDownloadModal(true)}>
          <div className="hospitals-data-header-action-text">
            {DOWNLOAD_BUTTON_TEXT}
          </div>
          <Icon name="download" size="small" />
        </div>
        <div className="hospitals-data-header-action" onClick={clearAll}>
          <div className="hospitals-data-header-clear">
            {CLEAR_ALL_FILTERS_BUTTON_TEXT}
          </div>
        </div>
      </div>
      <DownloadModal
        role={userData.role}
        filterValues={filterValues}
        open={shouldShowDownloadModal}
        count={count}
        sortedBy={sortedBy}
        sortingDirection={sortingDirection}
        onCancel={() => setShouldShowDownloadModal(false)}
        onClose={() => setShouldShowDownloadModal(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  hospitalsList: hospitalsDataSelector.hospitalsList(state),
  sortedBy: hospitalsDataSelector.sortedBy(state),
  sortingDirection: hospitalsDataSelector.sortingDirection(state),
  filterValues: filtersSelector.filterValues(state),
  userData: userSelector.userData(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  performSearch: performSearchAction,
  saveSearch: saveSearchAction,
  getHospitalsList: getHospitalsListAction,
  getHospitalsCalculatedData: getHospitalsCalculatedDataAction,
}, dispatch);

export const HospitalsDataHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HospitalsDataHeaderComponent);
