import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';

import { DownloadModal } from '../modals/DownloadModal';

import {
  performSearch as performSearchAction,
} from '../../store/filters/filters.actions';
import {
  saveSearch as saveSearchAction,
} from '../../../../core/user/user.actions';
import {
  getLaboratoriesList as getLaboratoriesListAction,
} from '../../store/laboratoriesData/laboratoriesData.actions';

import { laboratoriesDataSelector } from '../../store/laboratoriesData/laboratoriesData.selector';
import { filtersSelector } from '../../store/filters/filters.selector';
import { userSelector } from '../../../../core/user/user.selector';

const DOWNLOAD_BUTTON_TEXT = 'Download';
const CLEAR_ALL_FILTERS_BUTTON_TEXT = 'Clear All Filters';
const RESULTS = 'Results';

export const LaboratoriesDataHeaderComponent = ({
  userData,
  laboratoriesList,
  count,
  filterValues,
  sortedBy,
  sortingDirection,
  performSearch,
}) => {
  const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState(false);
  function renderResultsMessage() {
    return count > laboratoriesList.length ? `${count} found` : `${laboratoriesList.length || 0} found`;
  }
  function clearAll() {
    return performSearch({ shouldClearFilters: true });
  }
  return (
    <div className="hospitals-data-header">
      <div className="hospitals-data-header-text">
        <div className="hospitals-data-header-title">{RESULTS}</div>
        <div className="hospitals-data-header-count">
          {renderResultsMessage()}
        </div>
      </div>
      <div className="hospitals-data-header-actions">
        <div className="hospitals-data-header-action" onClick={() => setShouldShowDownloadModal(true)}>
          <div className="hospitals-data-header-action-text">
            {DOWNLOAD_BUTTON_TEXT}
          </div>
          <Icon name="download" size="small" />
        </div>
        <div className="hospitals-data-header-action" onClick={clearAll}>
          <div className="hospitals-data-header-clear">
            {CLEAR_ALL_FILTERS_BUTTON_TEXT}
          </div>
        </div>
      </div>
      <DownloadModal
        role={userData.role}
        filterValues={filterValues}
        open={shouldShowDownloadModal}
        count={count}
        sortedBy={sortedBy}
        sortingDirection={sortingDirection}
        onCancel={() => setShouldShowDownloadModal(false)}
        onClose={() => setShouldShowDownloadModal(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  laboratoriesList: laboratoriesDataSelector.laboratoriesList(state),
  sortedBy: laboratoriesDataSelector.sortedBy(state),
  sortingDirection: laboratoriesDataSelector.sortingDirection(state),
  filterValues: filtersSelector.filterValues(state),
  userData: userSelector.userData(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  performSearch: performSearchAction,
  saveSearch: saveSearchAction,
  getLaboratoriesList: getLaboratoriesListAction,
}, dispatch);

export const LaboratoriesDataHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LaboratoriesDataHeaderComponent);
