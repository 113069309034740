export const MODAL_GENERAL_INFORMATION_TABLE_FIELDS = {
  TAXPAYER_ID: {
    label: 'Taxpayer ID',
    placeholder: 'Type here',
    name: 'taxPayerId',
  },
  HOSPITAL_ID: {
    label: 'Hospital ID',
    placeholder: 'Type here',
    name: 'hospitalId',
  },
  HOLDING_NAME: {
    label: 'Holding Name',
    placeholder: 'Type here',
    name: 'holdingName',
  },
  AFFILIATION: {
    label: 'Affiliation',
    placeholder: 'Type here',
    name: 'affiliation',
  },
  NATURE_OF_ESTABLISHMENT: {
    label: 'Nature of establishment',
    name: 'natureOfEstablishment',
  },
  TYPE_OF_ESTABLISHMENT: {
    label: 'Type of establishment',
    name: 'typeOfEstablishment',
  },
  LEVEL_OF_CARE: {
    label: 'Level of care',
    name: 'levelOfCare',
  },
  PHONE: {
    label: 'Phone number',
    name: 'phone',
  },
  EMAIL: {
    label: 'Email',
    name: 'email',
  },
};
