import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader, Message } from 'semantic-ui-react';

import { container } from '../config';
import { SearchMenu } from './SearchMenu/SearchMenu';
import { Filters } from './Filters/Filters';
import { MainViewData } from './MainViewData/MainViewData';

import { getAvailableCountries as getAvailableCountriesAction } from '../../common/store/filters/filters.actions';
import { filtersSelector } from '../store/filters/filters.selector';

const MainviewPageComponent = ({
  areAvailableCountriesLoading,
  areFilteringOptionsLoading,
  availableCountries,
  selectedCountry,
  selectedYear,
  getAvailableCountries,
}) => {
  useEffect(() => {
    getAvailableCountries(container.name);
  }, [getAvailableCountries]);

  return (
    <div className="appContainer PriceScope">
      <Dimmer active={areAvailableCountriesLoading || areFilteringOptionsLoading}>
        <Loader />
      </Dimmer>
      {areAvailableCountriesLoading || areFilteringOptionsLoading ? null : (
        <>
          <div className="proceduresPage-header">
            <div className="container main">
              <SearchMenu availableCountries={availableCountries} />
              <Filters />
            </div>
          </div>
          <div className="proceduresPage-body">
            {!selectedCountry || !selectedYear
              ? <Message content="Please select country and year to query." icon="arrow up" />
              : <MainViewData /> }
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  areAvailableCountriesLoading: filtersSelector.areAvailableCountriesLoading(state),
  availableCountries: filtersSelector.availableCountries(state),
  selectedCountry: filtersSelector.selectedCountry(state),
  selectedYear: filtersSelector.selectedYear(state),
  areFilteringOptionsLoading: filtersSelector.areFilteringOptionsLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAvailableCountries: getAvailableCountriesAction,
}, dispatch);

export const MainviewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainviewPageComponent);
