import { containerName as PricescopeName } from '../../containers/pricescope/container.name';

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  MANAGER: 'manager', // hardcoded role @user.reducer for a single user right now
};

export const ROLE_DOWNLOAD_LIMIT = {
  [ROLES.USER]: 2000,
  [ROLES.ADMIN]: 25000,
  [ROLES.SUPER_ADMIN]: 25000,
  [ROLES.MANAGER]: 10000,
};

export const TOAST_CONSTANTS = {
  FILTERING_OPTIONS_ERROR: 'Could not get filtering options!',
  COUNTRIES_LIST_ERROR: 'Could not get countries list!',
  HOSPITALS_LIST_ERROR: 'Could not get hospitals list!',
  FIELDS_REMOVED: 'Note that some fields in general information will be removed',
  PDF_ERROR: 'Error while creating PDF',
  RESET_EMAIL_SENT: 'Email with instructions was sent!',
  RESET_EMAIL_ERROR: 'Something went wrong!',
  RESET_PASSWORD_INFO: 'If you want to change your password, click "Forgot password" button',
};

export const USER_PAGE_CONTEXT_TYPES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const SORTING_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const PROCEDURES_TABLE_VIEWS = {
  PROCEDURES: 'Procedures',
  INSTITUTIONS: 'Institutions',
};

export const HOSPITAL_NAME_FIELD = 'name';

export const DATEPICKER_INPUT_FIELD_TYPE = 'datepicker';

export const DATA_SOURCE_HEADER_NAME = 'hospitals-data-source';

export const AVAILABLE_DATA_SOURCES = {
  ORIGINAL: 'ORIGINAL',
  PREDICTED: 'PREDICTED',
};

export const MAIN_APP_CONTAINERS = {
  HOSPISCOPE: 'HOSPISCOPE',
  SURGISCOPE: 'SURGISCOPE',
  [PricescopeName]: PricescopeName,
  LABSCOPE: 'LABSCOPE',
};

export const DEFAULT_PAGE_LIMIT = 100;

export const PROCEDURE_NAME_FIELD = 'procedure_name';

export const INSTITUTION_NAME_FIELD = 'hospital_name';

export const LABORATORY_NAME_FIELD = 'name';
