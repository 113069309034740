import React from 'react';
import { Table } from 'semantic-ui-react';

import { navigateToPage } from '../../../../../core/routing/history';

import { getAdminTableCellContent } from '../../../utils/table.utils';

import { CLIENTS_TABLE_CELLS } from '../../constants/tableFields.constants';

export const ClientsTableBodyComponent = ({ clientsList }) => {
  return (
    <Table.Body>
      {clientsList.map((client) => {
        return (
          <Table.Row key={client.id} onClick={() => navigateToPage(`/admin/clients/${client.id}`)}>
            {CLIENTS_TABLE_CELLS.map((cell, index) => (
              <Table.Cell key={`cell-${client.id}-${index}`}>
                {getAdminTableCellContent({ name: cell.key, value: client[cell.key] })}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const ClientsTableBody = ClientsTableBodyComponent;
