import React, { useState } from 'react';
import { Form, Input, Modal } from 'semantic-ui-react';

import { renderModalActionButton } from '../../../hospiscope/components/modals/utils/modals.utils';
import { addUserValidationSchema, getYupErrorMessage } from '../../utils/validation.utils';

import { ADD_USER_MODAL_FORM_FIELDS, ADD_USER_MODAL_TEXT } from '../constants/modals.constans';

export const AddUserModal = ({ open, onClose, addUser }) => {
  const [formFields, setFormFields] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const handleFieldChange = ({ name, value }) => {
    setFormErrors([]);
    setFormFields({ ...formFields, [name]: value });
  };
  async function onUserAdd() {
    try {
      await addUserValidationSchema.validate(formFields, { abortEarly: false });
      return addUser({ user: formFields });
    } catch (e) {
      return setFormErrors(e.inner);
    }
  }
  return (
    <Modal open={open} onClose={onClose} size="mini" className="add-user-modal">
      <Modal.Content>
        <Modal.Description>
          <Form onSubmit={onUserAdd}>
            {ADD_USER_MODAL_FORM_FIELDS.map(({ label, placeholder, name }) => {
              const error = getYupErrorMessage({ path: name, errors: formErrors });
              return (
                <Form.Field className="input-field-inner" key={name}>
                  <Form.Input
                    control={Input}
                    value={formFields[name] || ''}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    onChange={(e) => handleFieldChange(e.target)}
                    error={error}
                  />
                </Form.Field>
              );
            })}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: onUserAdd, text: ADD_USER_MODAL_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
