import {
  GET_INSTITUTIONS_LIST_REQUEST,
  GET_INSTITUTIONS_LIST_SUCCESS,
  GET_INSTITUTIONS_LIST_FAILURE,
  TOGGLE_PROCEDURES_LIST_MODAL, GET_PROCEDURES_LIST_REQUEST, GET_PROCEDURES_LIST_SUCCESS, GET_PROCEDURES_LIST_FAILURE,
} from './institutionsData.actions';

import { CLEAR_STORE } from '../../../../core/user/user.actions';

import {
  SORTING_DIRECTIONS,
  INSTITUTION_NAME_FIELD,
} from '../../../../core/constants/core.constants';

export const initialState = {
  isLoading: false,
  isProceduresModalLoading: false,
  shouldShowProceduresModal: false,
  selectedInstitutionId: '',
  institutionsList: [],
  proceduresList: [],
  proceduresCount: 0,
  pagination: {
    currentPage: 1,
    count: 0,
  },
  sortedBy: INSTITUTION_NAME_FIELD,
  sortingDirection: SORTING_DIRECTIONS.ASC,
};

export function institutionsDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INSTITUTIONS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INSTITUTIONS_LIST_SUCCESS: {
      const {
        institutionsList,
        count,
        currentPage,
        sortedBy,
        sortingDirection,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        institutionsList: institutionsList || [],
        pagination: {
          ...state.pagination,
          count,
          currentPage,
        },
        sortedBy,
        sortingDirection,
      };
    }
    case GET_INSTITUTIONS_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_PROCEDURES_LIST_REQUEST: {
      return {
        ...state,
        isProceduresModalLoading: true,
        shouldShowProceduresModal: true,
      };
    }
    case GET_PROCEDURES_LIST_SUCCESS: {
      return {
        ...state,
        isProceduresModalLoading: false,
        proceduresList: action.payload.proceduresList || [],
        proceduresCount: action.payload.proceduresCount,
      };
    }
    case GET_PROCEDURES_LIST_FAILURE: {
      return {
        ...state,
        isProceduresModalLoading: false,
      };
    }
    case TOGGLE_PROCEDURES_LIST_MODAL: {
      const { shouldShowProceduresModal, selectedInstitutionId } = action.payload;
      return {
        ...state,
        shouldShowProceduresModal,
        selectedInstitutionId: selectedInstitutionId || '',
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
