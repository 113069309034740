import { getHospitalsState } from '../../../hospiscope/store/hospitals.selector';
import { FILTERS } from '../../../hospiscope/constants/filters.constants';

export function getHospitalsFiltersState(state) {
  return getHospitalsState(state).filters;
}

export const filtersSelector = {
  filterValues(state) {
    return getHospitalsFiltersState(state).filterValues;
  },
  selectedCountries(state) {
    return getHospitalsFiltersState(state).filterValues[FILTERS.COUNTRIES];
  },
  searchValue(state) {
    return getHospitalsFiltersState(state).filterValues[FILTERS.SEARCH];
  },
  selectedYears(state) {
    return getHospitalsFiltersState(state).filterValues[FILTERS.YEAR];
  },
  activeFilter(state) {
    return getHospitalsFiltersState(state).activeFilter;
  },
  areFilteringOptionsLoading(state) {
    return getHospitalsFiltersState(state).areFilteringOptionsLoading;
  },
  filteringOptions(state) {
    return getHospitalsFiltersState(state).filteringOptions;
  },
  areAvailableCountriesLoading(state) {
    return getHospitalsFiltersState(state).areAvailableCountriesLoading;
  },
  availableCountries(state) {
    return getHospitalsFiltersState(state).availableCountries;
  },
  availableSurgiscopeCountries(state) {
    return getHospitalsFiltersState(state).availableSurgiscopeCountries;
  },
  availablePricescopeCountries(state) {
    return getHospitalsFiltersState(state).availablePricescopeCountries;
  },
  availableLabscopeCountries(state) {
    return getHospitalsFiltersState(state).availableLabscopeCountries;
  },
  areAvailableStatesLoading(state) {
    return getHospitalsFiltersState(state).areAvailableStatesLoading;
  },
  availableStates(state) {
    return getHospitalsFiltersState(state).availableStates;
  },
  areAvailableCitiesLoading(state) {
    return getHospitalsFiltersState(state).areAvailableCitiesLoading;
  },
  availableCities(state) {
    return getHospitalsFiltersState(state).availableCities;
  },
};
