import React from 'react';
import { Button, Grid } from 'semantic-ui-react';

import { FromToInputField, CheckboxField, InputField } from '../FilterItems/shared/components';

import { splitArrayIntoChunks } from '../../../../../core/utils/core.utils';

import { FILTER_MENU_TYPES } from '../../../constants/filters.constants';

const ACCEPT_BUTTON_TEXT = 'Accept';

export const generateFilterMenu = ({
  type,
  fieldsList,
  columnsAmount,
  onChangeHandler,
  data = {},
  dataIsAnArray = false,
  arrayTypeData = [],
}) => {
  const MENU_TYPES = {
    [FILTER_MENU_TYPES.INPUT]: ({
      placeholder, label, name,
    }) => {
      return (
        <InputField
          placeholder={placeholder}
          label={label}
          name={name}
          onChange={onChangeHandler}
          value={data[name]}
        />
      );
    },
    [FILTER_MENU_TYPES.FROM_TO_INPUT]: ({
      placeholder, label, name,
    }) => {
      return (
        <FromToInputField
          placeholder={placeholder}
          label={label}
          name={name}
          onChange={onChangeHandler}
          value={data[name]}
        />
      );
    },
    [FILTER_MENU_TYPES.CHECKBOX]: ({
      label, name,
    }) => {
      return (
        dataIsAnArray ? (
          <CheckboxField
            label={label}
            name={name}
            onChange={onChangeHandler}
            checked={arrayTypeData.indexOf(name) !== -1}
          />
        ) : (
          <CheckboxField
            label={label}
            name={name}
            onChange={onChangeHandler}
            checked={!!data[name]}
          />
        )
      );
    },
  };
  return (
    <Grid>
      {splitArrayIntoChunks(fieldsList, columnsAmount).map((chunk, index) => (
        <Grid.Row columns={columnsAmount} key={`menu-row-${index}`}>
          {chunk.map((formElementData, i) => (
            <Grid.Column key={`menu-column-${i}`}>
              {MENU_TYPES[type](formElementData)}
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
    </Grid>
  );
};

export const renderAcceptButton = (clickHandler) => (
  <div className="filter-item-button">
    <Button
      onClick={(e) => {
        e.stopPropagation();
        clickHandler();
      }}
    >
      {ACCEPT_BUTTON_TEXT}
    </Button>
  </div>
);
