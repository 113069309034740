import { getAdminState } from '../admin.selector';

function getClientsDashboardState(state) {
  return getAdminState(state).clientsDashboard;
}

export const clientsDashboardSelector = {
  isLoading(state) {
    return getClientsDashboardState(state).isLoading;
  },
  clientsList(state) {
    return getClientsDashboardState(state).clientsList;
  },
};
