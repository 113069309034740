import { CLEAR_STORE } from '../../../../core/user/user.actions';
import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  SET_LOADING_STATE,
} from './userPage.actions';

export const initialState = {
  isLoading: false,
  userInfo: null,
};

export function userPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFO_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_USER_INFO_SUCCESS: {
      const { userInfo } = action.payload;
      return {
        ...state,
        isLoading: false,
        userInfo,
      };
    }
    case GET_USER_INFO_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SET_LOADING_STATE: {
      const { isLoading } = action.payload;
      return {
        ...state,
        isLoading,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
