import React from 'react';
import { Header, Table } from 'semantic-ui-react';

import { AdminsTableHeader } from './AdminsTableHeader';
import { AdminsTableBody } from './AdminsTableBody';

const NO_RESULTS_MESSAGE = 'There are no admins. Try to add one.';

export const AdminsTable = ({ adminsList }) => {
  return (
    adminsList.length ? (
      <Table className="clients-dashboard-table">
        <AdminsTableHeader />
        <AdminsTableBody adminsList={adminsList} />
      </Table>
    ) : (
      <div className="no-results-message-wrapper">
        <Header as="h3" className="no-results-message">{NO_RESULTS_MESSAGE}</Header>
      </div>
    )
  );
};
