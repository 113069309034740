import { API_BASE_URL } from '../../../core/services/CoreServiceConfigProvider';

const SURGISCOPE = '/surgiscope';
const COUNTRIES_LIST = '/countries';
const FILTERING_OPTIONS = '/filters';
const PROCEDURES = '/procedures';
const HOSPITALS = '/hospitals';
const CITIES_BY_COUNTRIES_LIST_ENDPOINT = '/cities';
const STATES_BY_COUNTRIES_LIST_ENDPOINT = '/states';
const HOSPITAL_NAMES_ENDPOINT = '/hospitalNames';

export class ProceduresServiceConfigProvider {
  static getCountriesListEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${COUNTRIES_LIST}`;
  }

  static getFilteringOptionsEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${FILTERING_OPTIONS}`;
  }

  static getProceduresListEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${PROCEDURES}`;
  }

  static getHospitalsListEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${HOSPITALS}`;
  }

  static getProceduresListByHospitalIdEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${HOSPITALS}${PROCEDURES}`;
  }

  static getStatesByCountriesAndCityNameEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${STATES_BY_COUNTRIES_LIST_ENDPOINT}`;
  }

  static getCitiesByCountriesAndCityNameEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${CITIES_BY_COUNTRIES_LIST_ENDPOINT}`;
  }

  static getHospitalNamesEndpoint() {
    return `${API_BASE_URL}${SURGISCOPE}${HOSPITAL_NAMES_ENDPOINT}`;
  }
}
