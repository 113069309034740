import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

const FROM_FIELD = 'from';
const TO_FIELD = 'to';

export const FromToInputField = ({
  value, label, placeholder, name, onChange,
}) => {
  const [valueObject, setValueObject] = useState(value || {});

  useEffect(() => {
    if (value) {
      setValueObject(value);
    } else {
      setValueObject({});
    }
  }, [value]);

  function onChangeHandler(field, text) {
    onChange({ name, value: { ...valueObject, [field]: text } });
    setValueObject({ ...valueObject, [field]: text });
  }

  return (
    <div className="from-to-input-field-container input-group-container">
      <Form.Field className="from-to-input-field-inner">
        <label className="from-to-input-field-label">{label}</label>
        <Form.Input
          onChange={(e) => onChangeHandler(FROM_FIELD, e.target.value)}
          placeholder={placeholder}
          className="from-to-input-field-item"
          value={valueObject[FROM_FIELD] || ''}
        />
        <div className="from-to-input-field-delimiter">to</div>
        <Form.Input
          onChange={(e) => onChangeHandler(TO_FIELD, e.target.value)}
          placeholder={placeholder}
          className="from-to-input-field-item"
          value={valueObject[TO_FIELD] || ''}
        />
      </Form.Field>
    </div>
  );
};
