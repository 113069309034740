import React from 'react';

import { AdminsTable } from './AdminsTable/AdminsTable';

import { ADMINS_DASHBOARD_TITLE } from '../constants/adminsDashboard.constants';

export const AdminsDashboardBody = ({ adminsList }) => {
  return (
    <div className="clients-dashboard-body">
      <div className="clients-dashboard-title segment-block">
        {ADMINS_DASHBOARD_TITLE}
      </div>
      <div className="clients-dashboard-content segment-block">
        <AdminsTable adminsList={adminsList} />
      </div>
    </div>
  );
};
