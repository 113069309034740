import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { MenuItemBoxHeader } from './components';

import { renderAcceptButton } from '../../utils/filters.ui.utils';

const FILTER_CLICK_FIELD_CLASSNAMES = ['filter-item-text', 'filter-item-textWrapper'];

export const FilterItemWrapper = (props) => {
  const {
    filterName,
    filterClassName,
    isFilterEmpty,
    onClear,
    activeFilter,
    title,
    search,
    fields,
    setFilterValue,
    setActiveFilter,
    children,
  } = props;
  const isCurrentFilterActive = activeFilter === filterName;

  function getMenuItemClassName() {
    return (
      `filter-item ${filterClassName} ${isCurrentFilterActive ? 'active' : ''} ${!isFilterEmpty ? 'has-value' : ''}`
    );
  }

  function onFilterClose() {
    setActiveFilter(null);
    setFilterValue({ filterName, filterValue: fields });
  }

  function onOutsideClick({ target }) {
    return FILTER_CLICK_FIELD_CLASSNAMES.indexOf(target.className) !== -1 ? false : onFilterClose();
  }

  function onFilterClick() {
    return isCurrentFilterActive ? setActiveFilter(null) : setActiveFilter(filterName);
  }

  return (
    <div key={filterName} className={getMenuItemClassName()}>
      <>
        <div onClick={onFilterClick} className="filter-item-textWrapper">
          <div className="filter-item-text">{title}</div>
        </div>
        {isCurrentFilterActive ? (
          <OutsideClickHandler onOutsideClick={onOutsideClick}>
            <div className="filter-item-content">
              <div className="filter-item-inner">
                <MenuItemBoxHeader title={title} onClear={onClear} />
                <div className="filter-item-form">
                  {children}
                </div>
                {renderAcceptButton(search)}
              </div>
            </div>
          </OutsideClickHandler>
        ) : null}
      </>
    </div>
  );
};
