import React from 'react';
import { Table } from 'semantic-ui-react';

import { InstitutionsTableHeader } from './InstitutionsTableHeader';
import { InstitutionsTableBody } from './InstitutionsTableBody';

export const InstitutionsTable = ({
  institutionsList,
  sortedBy,
  sortingDirection,
  getProceduresList,
  getInstitutionsList,
}) => {
  return (
    <div className="proceduresTable-wrapper">
      <Table className="hospitals-table proceduresTable-table" sortable>
        <InstitutionsTableHeader
          sortedBy={sortedBy}
          sortingDirection={sortingDirection}
          getInstitutionsList={getInstitutionsList}
        />
        <InstitutionsTableBody
          institutionsList={institutionsList}
          getProceduresList={getProceduresList}
        />
      </Table>
    </div>
  );
};
