import React from 'react';
import { UserAvatar } from '../../../../../core/components/UserAvatar/UserAvatar';

export const FullNameCell = ({ name }) => {
  return (
    <>
      <UserAvatar name={name} width={50} height={50} />
      <div className="clients-dashboard-table-name">{name}</div>
    </>
  );
};
