import { API_BASE_URL } from '../../../core/services/CoreServiceConfigProvider';

const SUPER_ADMIN = '/super-admin';
const ADMIN = '/admin';
const ADMINS = '/admins';
const CLIENTS = '/clients';
const CLIENT = '/client';
const COUNTRIES = '/countries';
const ACTIVE = '/active';
const USERS = '/users';
const USER = '/user';

export class AdminServiceConfigProvider {
  static getClientsList() {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}`;
  }

  static getClientInfo({ id }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${id}`;
  }

  static createClient() {
    return `${API_BASE_URL}${ADMIN}${CLIENT}`;
  }

  static setClientCountries({ id }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${id}${COUNTRIES}`;
  }

  static toggleClientStatus({ id }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${id}${ACTIVE}`;
  }

  static getClientUsersList({ clientId }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${clientId}${USERS}`;
  }

  static removeClient({ id }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${id}`;
  }

  static editClient({ id }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${id}`;
  }

  static addUser({ clientId }) {
    return `${API_BASE_URL}${ADMIN}${CLIENTS}/${clientId}${USER}`;
  }

  static getUserInfo({ id }) {
    return `${API_BASE_URL}${ADMIN}${USERS}/${id}`;
  }

  static removeUser({ id }) {
    return `${API_BASE_URL}${ADMIN}${USERS}/${id}`;
  }

  static editUser({ id }) {
    return `${API_BASE_URL}${ADMIN}${USERS}/${id}`;
  }

  static toggleUserStatus({ id }) {
    return `${API_BASE_URL}${ADMIN}${USERS}/${id}${ACTIVE}`;
  }

  static getAdminsList() {
    return `${API_BASE_URL}${SUPER_ADMIN}${ADMINS}`;
  }

  static addAdmin() {
    return `${API_BASE_URL}${SUPER_ADMIN}${ADMIN}`;
  }

  static getAdminInfo({ id }) {
    return `${API_BASE_URL}${SUPER_ADMIN}${ADMINS}/${id}`;
  }

  static toggleAdminStatus({ id }) {
    return `${API_BASE_URL}${SUPER_ADMIN}${ADMINS}/${id}${ACTIVE}`;
  }

  static editAdmin({ id }) {
    return `${API_BASE_URL}${SUPER_ADMIN}${ADMINS}/${id}`;
  }

  static removeAdmin({ id }) {
    return `${API_BASE_URL}${SUPER_ADMIN}${ADMINS}/${id}`;
  }
}
