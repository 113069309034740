export const ADD_USER_BUTTON_TEXT = 'Add user';
export const BACK_BUTTON_TEXT = 'Back';
export const LOADER_TEXT = 'Loading...';

export const COUNTRY_PICKER_TEXTS = {
  TITLE: 'HospiScope',
  LOADER: 'Loading countries...',
  BUTTON_ADD_TEXT: 'Add countries',
  BUTTON_EDIT_TEXT: 'Edit countries',
  MODAL_TITLE: 'Select countries',
  ACTION_BUTTON: 'Accept',
};

export const SURGISCOPE_COUNTRY_PICKER_TEXTS = {
  ...COUNTRY_PICKER_TEXTS,
  TITLE: 'SurgiScope',
};

export const LABSCOPE_COUNTRY_PICKER_TEXTS = {
  ...COUNTRY_PICKER_TEXTS,
  TITLE: 'LabScope',
};

export const PRICESCOPE_COUNTRY_PICKER_TEXTS = {
  ...COUNTRY_PICKER_TEXTS,
  TITLE: 'Pricescope',
};
