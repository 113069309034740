import { DATEPICKER_INPUT_FIELD_TYPE } from '../../../../core/constants/core.constants';

export const ADD_USER_MODAL_FORM_FIELDS = [
  {
    label: 'First Name',
    placeholder: 'Type here',
    name: 'firstName',
  },
  {
    label: 'Last Name',
    placeholder: 'Type here',
    name: 'lastName',
  },
  {
    label: 'Title',
    placeholder: 'Type here',
    name: 'title',
  },
  {
    label: 'Email',
    placeholder: 'example@mail.com',
    name: 'email',
  },
];

export const EDIT_CLIENT_MODAL_FORM_FIELDS = [
  {
    label: 'Client Name',
    placeholder: 'Type here',
    name: 'name',
  },
  {
    label: 'Active till',
    name: 'activeTill',
    type: DATEPICKER_INPUT_FIELD_TYPE,
  },
];

export const ADD_USER_MODAL_TEXT = 'Accept';
