import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

import { UserAvatar } from '../UserAvatar/UserAvatar';
import { Logo } from '../Logo/Logo';

import { logout as logoutAction } from '../../user/user.actions';

import { userSelector } from '../../user/user.selector';

import { ROLES } from '../../constants/core.constants';

const ALLOWED_ROLES = [ROLES.ADMIN, ROLES.SUPER_ADMIN];

const NavigationBar = ({ userData, logout }) => {
  const { name } = userData;
  return (
    <div className="navbar">
      <div className="container main">
        <Menu
          inverted
          attached
        >
          <Menu.Menu position="left">
            <Logo />
          </Menu.Menu>
          <Menu.Menu position="right">
            <Dropdown
              item
              trigger={(
                <span className="navbar-dropdown-trigger">
                  <UserAvatar name={name} />
                  {name}
                </span>
              )}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="home"
                  text="Home"
                  as={Link}
                  to="/"
                />
                <Dropdown.Item
                  icon="user"
                  text="Profile"
                  as={Link}
                  to="/profile"
                />
                <Dropdown.Item
                  icon="line graph"
                  text="HospiScope"
                  as={Link}
                  to="/hospiscope"
                />
                <Dropdown.Item
                  icon="chart bar"
                  text="SurgiScope"
                  as={Link}
                  to="/surgiscope"
                />
                <Dropdown.Item
                  icon="syringe"
                  text="LabScope"
                  as={Link}
                  to="/labscope"
                />
                <Dropdown.Item
                  icon="shopping basket"
                  text="PriceScope"
                  as={Link}
                  to="/pricescope"
                />
                {ALLOWED_ROLES.indexOf(userData.role) !== -1 ? (
                  <Dropdown.Item
                    icon="setting"
                    text="Manage Clients"
                    as={Link}
                    to="/admin/clients"
                  />
                ) : null}
                {userData.role === ROLES.SUPER_ADMIN ? (
                  <Dropdown.Item
                    icon="id card"
                    text="Manage Admins"
                    as={Link}
                    to="/admin/admins"
                  />
                ) : null}
                <Dropdown.Item
                  icon="sign-out"
                  text="Logout"
                  onClick={logout}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    </div>
  );
};

export const NavbarComponent = ({ userData, logout }) => (userData
  ? <NavigationBar userData={userData} logout={logout} />
  : null);

const mapStateToProps = (state) => ({
  userData: userSelector.userData(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout: logoutAction,
}, dispatch);

export const Navbar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavbarComponent);
