import { isObject } from '../../../../../core/utils/core.utils';

/*
  This function checks if filter has something selected inside.
  Available types of values are: string, boolean, array and object like { from: 1, to: 2 }
 */

export const checkIfFilterIsEmpty = ({ filterValue = {} }) => {
  let isEmpty = true;
  const keys = Object.keys(filterValue);
  if (!keys.length) {
    return isEmpty;
  }
  for (let i = 0; i < keys.length; i += 1) {
    const currentValue = filterValue[keys[i]];
    if (currentValue) {
      if (typeof currentValue === 'string' || typeof currentValue === 'boolean') {
        isEmpty = false;
        break;
      } else if (Array.isArray(currentValue) && currentValue.length) {
        isEmpty = false;
        break;
      } else if (isObject(currentValue)) {
        isEmpty = !(currentValue.from || currentValue.to);
        break;
      }
    }
  }
  return isEmpty;
};
