import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import { Pagination } from '../../../common/Pagination';
import { HospitalModal } from '../modals/HospitalModal/HospitalModal';
import { HospitalsDataHeader } from './HospitalsDataHeader';
import { HospitalsTabs } from './HospitalsTabs/HospitalsTabs';
import { HospitalsCalculatedData } from './HospitalsCalculatedData/HospitalsCalculatedData';

import {
  getHospitalsCalculatedData as getHospitalsCalculatedDataAction,
  getHospitalsList as getHospitalsListAction,
} from '../../store/hospitalsData/hospitalsData.actions';

import { hospitalsDataSelector } from '../../store/hospitalsData/hospitalsData.selector';

import { FILTER_MENU_TITLES } from '../../constants/filters.constants';

import { getPaginationItemsCount } from '../../../../core/utils/core.utils';

const HospitalsTableComponent = ({
  isLoading,
  hospitalsList,
  predictedHospitalsList,
  hospitalsCalculatedData,
  hospitalsPredictedCalculatedData,
  sortedBy,
  sortingDirection,
  pagination,
  getHospitalsList,
  getHospitalsCalculatedData,
}) => {
  const [shouldShowHospitalModal, setShouldShowHospitalModal] = useState(false);
  const [shouldShowCalculatedData, setShouldShowCalculatedData] = useState(false);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { count, currentPage } = pagination;

  function toggleHospitalModal({ open, id }) {
    setShouldShowHospitalModal(open);
    setSelectedHospitalId(id);
  }

  useEffect(() => {
    getHospitalsList({ currentPage: 1 });
    getHospitalsCalculatedData();
  }, [getHospitalsList, getHospitalsCalculatedData]);

  function onPageChange(e, { activePage }) {
    return getHospitalsList({ currentPage: activePage, sortedBy, sortingDirection });
  }

  function getActiveTabName() {
    return Object.keys(FILTER_MENU_TITLES)[activeTabIndex];
  }

  return (
    <div className="container main">
      {isLoading ? <Loader active={isLoading} />
        : (
          <div className="hospitals-content">
            <HospitalsDataHeader
              count={pagination.count}
              shouldShowCalculatedData={shouldShowCalculatedData}
              setShouldShowCalculatedData={setShouldShowCalculatedData}
            />
            <HospitalsCalculatedData
              shouldShowCalculatedData={shouldShowCalculatedData}
              hospitalsCalculatedData={hospitalsCalculatedData}
              hospitalsPredictedCalculatedData={hospitalsPredictedCalculatedData}
              activeTabName={getActiveTabName()}
            />
            <HospitalsTabs
              activeTabIndex={activeTabIndex}
              hospitalsList={hospitalsList}
              predictedHospitalsList={predictedHospitalsList}
              sortedBy={sortedBy}
              sortingDirection={sortingDirection}
              setActiveTabIndex={setActiveTabIndex}
              toggleHospitalModal={toggleHospitalModal}
              getHospitalsList={getHospitalsList}
            />
            {hospitalsList.length ? (
              <Pagination
                currentPage={currentPage}
                totalPages={getPaginationItemsCount({ count })}
                onPageChange={onPageChange}
              />
            ) : null}
            <HospitalModal
              selectedHospitalId={selectedHospitalId}
              open={shouldShowHospitalModal}
              onClose={() => toggleHospitalModal({ open: false, id: null })}
            />
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: hospitalsDataSelector.isLoading(state),
  hospitalsList: hospitalsDataSelector.hospitalsList(state),
  predictedHospitalsList: hospitalsDataSelector.predictedHospitalsList(state),
  hospitalsCalculatedData: hospitalsDataSelector.hospitalsCalculatedData(state),
  hospitalsPredictedCalculatedData: hospitalsDataSelector.hospitalsPredictedCalculatedData(state),
  sortedBy: hospitalsDataSelector.sortedBy(state),
  sortingDirection: hospitalsDataSelector.sortingDirection(state),
  pagination: hospitalsDataSelector.pagination(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getHospitalsList: getHospitalsListAction,
  getHospitalsCalculatedData: getHospitalsCalculatedDataAction,
}, dispatch);

export const HospitalsData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HospitalsTableComponent);
