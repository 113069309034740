import * as pick from 'lodash.pick';

import { TABLE_HEADER_CELLS } from '../components/ProceduresData/ProceduresTable/ProceduresTableHeader';
import {
  TABLE_HEADER_CELLS as HOSPITALS_LIST_TABLE_CELLS,
} from '../components/ProceduresData/HospitalsListModal/HospitalsListTable/HospitalsListTableHeader';
import {
  TABLE_HEADER_CELLS as INSTITUTIONS_TABLE_CELLS,
} from '../components/InstitutionsData/InstitutionsTable/InstitutionsTableHeader';

import { HOSPITALS_MODAL_TEXTS } from '../components/ProceduresData/HospitalsListModal/hospitalsListModal.constants';
import { NO_DATA_MESSAGE } from '../../hospiscope/components/HospitalsData/hospitalsData.constants';
import { CODE_AND_NAME_DELIMITER } from '../components/Filters/FilterItems/shared/CommonFilter/CommonFilter';

import { exportFiltersInfo, sortCountryAndYearFirst } from '../../../core/utils/excelExport.utils';
import { isEmpty, collapseObjToArray } from '../../../core/utils/core.utils';

const EXCEL_EXPORT_HEADER_TITLE = 'SurgiScope, by GHI';

const rowLabels = {};
INSTITUTIONS_TABLE_CELLS.map((val) => rowLabels[val.key] = val.label);
Object.entries(HOSPITALS_MODAL_TEXTS).forEach(([k, v]) => rowLabels[k.toLowerCase()] = v);

export const prepareFiltersInfoForExport = (filterValues) => {
  const formattedFilters = [];

  const addDataRow = (firstCol, secondCol) => {
    formattedFilters.push([
      { value: rowLabels[firstCol] ? rowLabels[firstCol] : firstCol, style: { font: { bold: true } } },
      { value: secondCol },
    ]);
  };

  Object.keys(filterValues).sort(sortCountryAndYearFirst).map((key) => {
    const filterObj = filterValues[key];

    if (/^(number|bigint|boolean|string)$/.test(typeof filterObj)
    && !isEmpty(filterObj)) {
      addDataRow(key, filterObj);
    } else if (filterObj.constructor === Object) {
      Object.entries(filterObj).forEach(([k, v]) => {
        addDataRow(k, collapseObjToArray(v).join(', '));
      });
    } else if (filterObj.constructor === Array && filterObj.length) {
      const namesLabel = rowLabels[key] ? rowLabels[key] : key;
      const codesLabel = `${namesLabel} Code`;
      const names = [];
      const codes = [];

      filterObj.forEach((val) => {
        const [name, code] = val.split(CODE_AND_NAME_DELIMITER);
        names.push(name || NO_DATA_MESSAGE);
        codes.push(code || NO_DATA_MESSAGE);
      });

      addDataRow(namesLabel, names.join(', '));
      addDataRow(codesLabel, codes.join(', '));
    }
    return void 1;
  });

  return exportFiltersInfo(EXCEL_EXPORT_HEADER_TITLE, formattedFilters);
};

// procedureSample = {
//     "procedure_name": "ABCESO CUTANEO, FURUNCULO Y CARBUNCO",
//     "procedure_code": "SN000003",
//     "procedure_group": "OTROS",
//     "procedure_group_code": "4",
//     "procedure_category": "SINIESTRO",
//     "procedure_category_code": "XX",
//     "procedure_subcategory": null,
//     "procedure_subcategory_code": null,
//     "sum": "8"
// }

// export const singleProcedureExportFiltersInfo = (procedure, filterValues) => {
//   const procedureDescriptionData = Object.keys(procedure).map((key) => {
//     return [
//       {
//         value: TABLE_HEADER_CELLS.find((item) => item.key === key).label,
//         style: { font: { bold: true } },
//       },
//       {
//         value: procedure[key] ? `${procedure[key]}` : NO_DATA_MESSAGE,
//       },
//     ];
//   });

//   const { year, country } = filterValues;

//   return exportFiltersInfo(EXCEL_EXPORT_HEADER_TITLE, year, country, procedureDescriptionData);
// };

export const singleProcedureExportHospitalsList = (hospitalsList) => {
  return [{
    columns: HOSPITALS_LIST_TABLE_CELLS.map((cell) => ({ title: cell.label, width: { wch: 24 } })),
    data: hospitalsList.map((hospital) => {
      return HOSPITALS_LIST_TABLE_CELLS.map((cell) => {
        return {
          value: hospital[cell.key] ? `${hospital[cell.key]}` : NO_DATA_MESSAGE,
        };
      });
    }),
  }];
};

export const prepareProceduresListForExcel = (proceduresList) => {
  return [{
    columns: TABLE_HEADER_CELLS.map((cell) => ({ title: cell.label, width: { wch: 24 }, style: { font: { bold: true } } })),
    data: proceduresList.map((procedure) => {
      return TABLE_HEADER_CELLS.map((cell) => {
        return {
          value: procedure[cell.key] ? `${procedure[cell.key]}` : NO_DATA_MESSAGE,
        };
      });
    }),
  }];
};

export const prepareInstitutionsListForExcel = (institutionsList) => {
  return [{
    columns: INSTITUTIONS_TABLE_CELLS.map((cell) => ({ title: cell.label, width: { wch: 24 }, style: { font: { bold: true } } })),
    data: institutionsList.map((institution) => {
      return INSTITUTIONS_TABLE_CELLS.map((cell) => {
        return {
          value: institution[cell.key] ? `${institution[cell.key]}` : NO_DATA_MESSAGE,
        };
      });
    }),
  }];
};

const createTotalConductedTableData = (proceduresCount) => {
  return [
    {
      value: 'Total conducted',
      width: { wch: 24 },
      style: { font: { bold: true } },
    },
    {
      value: `${proceduresCount}`,
    },
  ];
};

// TODO: remove "id"
// selectedInstution = {
//     "id": "8676",
//     "country": "Mexico",
//     "hospital_name": "AMANALCO",
//     "hospital_id": "MCIMO000105",
//     "year": 2021,
//     "total_sum": "41"
// }
export const prepareProceduresDataForExcel = ({ selectedInstitution, proceduresList, proceduresCount }) => {
  const formattedInstitution = pick(selectedInstitution, INSTITUTIONS_TABLE_CELLS.map((cell) => cell.key));

  const institutionDescriptionData = Object.keys(formattedInstitution).map((key) => {
    return [
      {
        value: INSTITUTIONS_TABLE_CELLS.find((item) => item.key === key).label,
        style: { font: { bold: true } },
      },
      {
        value: formattedInstitution[key] ? `${formattedInstitution[key]}` : NO_DATA_MESSAGE,
      },
    ];
  });
  return {
    institution: [{
      columns: [],
      data: [...institutionDescriptionData, createTotalConductedTableData(proceduresCount)],
    }],
    procedures: [{
      columns: TABLE_HEADER_CELLS.map((cell) => ({ title: cell.label, width: { wch: 24 }, style: { font: { bold: true } } })),
      data: proceduresList.map((procedure) => {
        return TABLE_HEADER_CELLS.map((cell) => {
          return {
            value: procedure[cell.key] ? `${procedure[cell.key]}` : NO_DATA_MESSAGE,
          };
        });
      }),
    }],
  };
};

export const clearFilteringOptionsFromEmptyItems = (filteringOptions) => {
  return {
    ...filteringOptions,
    groups: filteringOptions.groups.filter((item) => item.name !== null),
    categories: filteringOptions.categories.filter((item) => item.name !== null),
    subcategories: filteringOptions.subcategories.filter((item) => item.name !== null),
    procedures: filteringOptions.procedures.filter((item) => item.name !== null),
  };
};
