import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SaveSearch } from '../../../hospiscope/components/SearchMenu/SaveSearch';
import { SavedSearches } from '../../../hospiscope/components/SearchMenu/SavedSearches';

import { FILTERS } from '../../constants/filters.constants';
import { MAIN_APP_CONTAINERS, PROCEDURES_TABLE_VIEWS } from '../../../../core/constants/core.constants';

import {
  setFilterValue as setFilterValueAction,
  performSearch as performSearchAction,
  clearFiltersIfNeeded as clearFiltersIfNeededAction,
} from '../../store/filters/filters.actions';
import { getSavedSearches as getSavedSearchesAction } from '../../../../core/user/user.actions';
import {
  loadSavedSearch as loadSavedSearchAction,
  setTableView as setTableViewAction,
} from '../../store/proceduresData/proceduresData.actions';

import { filtersSelector } from '../../store/filters/filters.selector';
import { userSelector } from '../../../../core/user/user.selector';
import { proceduresDataSelector } from '../../store/proceduresData/proceduresData.selector';

const { PROCEDURES, INSTITUTIONS } = PROCEDURES_TABLE_VIEWS;

export const SearchMenuComponent = ({
  selectedCountry,
  selectedYear,
  availableCountries,
  filteringOptions,
  savedSearches,
  areSavedSearchesLoading,
  pagination,
  tableView,
  setFilterValue,
  getSavedSearches,
  clearFiltersIfNeeded,
  performSearch,
  loadSavedSearch,
  setTableView,
}) => {
  const { year } = filteringOptions;

  useEffect(() => {
    getSavedSearches(MAIN_APP_CONTAINERS.SURGISCOPE);
  }, [getSavedSearches]);

  function onYearChange(value) {
    setFilterValue({ filterName: FILTERS.YEAR, filterValue: value });
    performSearch({ filterName: FILTERS.YEAR, filterValue: value });
  }

  function onCountryChange(value) {
    clearFiltersIfNeeded([
      FILTERS.GROUP,
      FILTERS.CATEGORY,
      FILTERS.SUBCATEGORY,
      FILTERS.PROCEDURE,
      FILTERS.INSTITUTION,
    ]);
    setFilterValue({ filterName: FILTERS.COUNTRY, filterValue: value });
    performSearch({ filterName: FILTERS.COUNTRY, filterValue: value });
  }

  function onTableViewChange(view) {
    return setTableView({ tableView: view });
  }

  function getOptionLabelClassName(isSelected) {
    return `filter-option-picker-label clickable ${isSelected ? '' : 'unselected'}`;
  }

  return (
    <div className="search-block">
      <div className="filter-main">
        <div className="filter-option-picker">
          {(availableCountries !== undefined && availableCountries.length) ? availableCountries.map((item) => {
            const isSelected = item === selectedCountry;
            return (
              <div
                key={item}
                className={getOptionLabelClassName(isSelected)}
                onClick={() => onCountryChange(item)}
              >
                <span>{item}</span>
              </div>
            );
          }) : null}
        </div>
        <div className="filter-option-picker">
          {year && year.length ? year.map((item) => {
            const isSelected = item === selectedYear;
            return (
              <div
                key={item}
                className={getOptionLabelClassName(isSelected)}
                onClick={() => onYearChange(item)}
              >
                <span>{item}</span>
              </div>
            );
          }) : null}
        </div>
        <div className="filter-option-picker">
          {[PROCEDURES, INSTITUTIONS].map((item) => {
            const isSelected = item === tableView;
            return (
              <div
                key={item}
                className={getOptionLabelClassName(isSelected)}
                onClick={() => onTableViewChange(item)}
              >
                <span>{item}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="filter-secondary">
        <div className="saved-searches-wrapper">
          <SaveSearch pagination={pagination} currentPageContext={MAIN_APP_CONTAINERS.SURGISCOPE} />
          <SavedSearches
            savedSearches={savedSearches}
            areSavedSearchesLoading={areSavedSearchesLoading}
            currentPageContext={MAIN_APP_CONTAINERS.SURGISCOPE}
            loadSavedSearch={loadSavedSearch}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCountry: filtersSelector.selectedCountry(state),
  selectedYear: filtersSelector.selectedYear(state),
  availableCountries: filtersSelector.availableCountries(state),
  filteringOptions: filtersSelector.filteringOptions(state),
  savedSearches: userSelector.savedSearches(state),
  areSavedSearchesLoading: userSelector.areSavedSearchesLoading(state),
  pagination: proceduresDataSelector.pagination(state),
  tableView: proceduresDataSelector.tableView(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterValue: setFilterValueAction,
  performSearch: performSearchAction,
  clearFiltersIfNeeded: clearFiltersIfNeededAction,
  getSavedSearches: getSavedSearchesAction,
  loadSavedSearch: loadSavedSearchAction,
  setTableView: setTableViewAction,
}, dispatch);

export const SearchMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchMenuComponent);
