export const LABORATORY_FIELDS_LIST = {
  // Obsolete fields
  // FIRST_SECTION: [
  //   {
  //     label: 'Microbiology Laboratory',
  //     name: 'microbiology',
  //   },
  //   {
  //     label: 'Bone marrow transplants',
  //     name: 'boneNarrowTransplants',
  //   },
  // ],
  // SECOND_SECTION: [
  //   {
  //     label: 'Clinical Analysis Laboratory',
  //     placeholder: 'Number',
  //     name: 'clinicalAnalysis',
  //   },
  //   {
  //     label: 'Blood samples processed daily',
  //     placeholder: 'Number',
  //     name: 'bloodSamplesProcessedDaily',
  //   },
  //   {
  //     label: 'Percentage of samples are taken with closed system',
  //     placeholder: 'Number',
  //     name: 'percentageOfSamplesTakenWithClosedSystem',
  //   },
  //   {
  //     label: 'Percentage of samples are taken with syringe',
  //     placeholder: 'Number',
  //     name: 'percentageOfSamplesTakenWithSyringe',
  //   },
  // ],
  // THIRD_SECTION: [
  //   {
  //     label: 'CD4 lymphocyte quantification tests ',
  //     name: 'lymphocyteQuantificationTests',
  //   },
  //   {
  //     label: 'Diagnostic tests for leukemias and lymphomas',
  //     name: 'leukemiasAndLymphomasDiagnosticTests',
  //   },
  // ],
  // FOURTH_SECTION: [
  //   {
  //     label: 'Arterial blood gas syringes',
  //     name: 'arterialBloodSyringes',
  //   },
  //   {
  //     label: 'Vacuum system for blood sampling ',
  //     name: 'vacuumSystemForBloodSampling',
  //   },
  //   {
  //     label: 'Needles with a safety device in blood draws',
  //     name: 'needlesWithSafetyDevice',
  //   },
  // ],
  // FIFTH_SECTION: [
  //   {
  //     label: 'Cultures per week',
  //     placeholder: 'Number',
  //     name: 'culturesPerWeek',
  //   },
  //   {
  //     label: 'Cytologies per week',
  //     placeholder: 'Number',
  //     name: 'cytologiesPerWeek',
  //   },
  // ],
  SIXTH_SECTION: [
    {
      label: 'Tomograph procedures per week',
      placeholder: 'Number',
      name: 'tomographProceduresPerWeek',
    },
    {
      label: 'Percentage of Tomography using contrast media',
      placeholder: 'Number',
      name: 'percentageOfTomographyUsingContrastMedia',
    },
    {
      label: 'MRI procedures per week',
      placeholder: 'Number',
      name: 'mRIProceduresPerWeek',
    },
    {
      label: 'Percentage of MRI using contrast media',
      placeholder: 'Number',
      name: 'percentageOfMRIUsingContrastMedia',
    },
    {
      label: 'Percentage of MRI using power injector',
      placeholder: 'Number',
      name: 'percentageOfMRIUsingPowerInjector',
    },
  ],
};
