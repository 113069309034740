import React from 'react';
import { Table } from 'semantic-ui-react';

import { LocalStorageService } from '../../../../../core/services/localStorage.service';

import {
  FLATTEN_HOSPITALS_DATA_FIELDS_LIST,
} from '../../../constants/hospitals.constants';
import {
  ACTIVE_TAB_NAME_TO_CALCULATED_DATA_KEYS,
  CALCULATED_DATA_ROWS,
} from './constants/calculatedData.constants';
import { AVAILABLE_DATA_SOURCES, DATA_SOURCE_HEADER_NAME } from '../../../../../core/constants/core.constants';

import { formatNumericalValue } from '../../../../../core/utils/core.utils';

const PREDICTED_VALUE_CLASSNAME = 'predicted';

export const CalculatedDataTable = ({
  hospitalsCalculatedData,
  hospitalsPredictedCalculatedData,
  activeTabName,
}) => {
  const isPredictedDataSource = LocalStorageService.get(DATA_SOURCE_HEADER_NAME) === AVAILABLE_DATA_SOURCES.PREDICTED;
  const calculatedData = isPredictedDataSource ? hospitalsPredictedCalculatedData : hospitalsCalculatedData;
  const getTableHeaderLabel = (key) => {
    try {
      return FLATTEN_HOSPITALS_DATA_FIELDS_LIST.find((field) => field.key === key).label;
    } catch (e) {
      return null;
    }
  };
  const currentFieldsKeys = ACTIVE_TAB_NAME_TO_CALCULATED_DATA_KEYS[activeTabName];
  const calculatedDataKeys = currentFieldsKeys ? currentFieldsKeys.filter((key) => {
    return Object.keys(calculatedData).indexOf(key) !== -1;
  }) : [];

  const cellClassName = isPredictedDataSource ? PREDICTED_VALUE_CLASSNAME : '';

  return (
    calculatedDataKeys && calculatedDataKeys.length ? (
      <Table className="hospitals-table calculated-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {calculatedDataKeys.map((key) => {
              const headerLabel = getTableHeaderLabel(key);
              return headerLabel
                ? <Table.HeaderCell key={`header-cell-${key}`}>{headerLabel}</Table.HeaderCell>
                : null;
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {CALCULATED_DATA_ROWS.map((row) => {
            return (
              <Table.Row key={`calculated-data-cell-${row.key}`}>
                <Table.Cell>
                  {row.label}
                </Table.Cell>
                {calculatedDataKeys.map((key) => (
                  <Table.Cell key={`calculated-data-cell-${key}`} className={cellClassName}>
                    {formatNumericalValue(calculatedData[key][row.key])}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    ) : null
  );
};
