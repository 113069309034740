import { GENERAL_INFORMATION_TABLE_FIELDS } from '../components/HospitalsData/tableFields.constants';
import { BEDS_MENU_ITEMS, GENERATED_BEDS_MENU_ITEMS } from '../components/Filters/FilterItems/Beds/beds.constants';
import {
  GENERATED_INFRASTRUCTURE_MENU_ITEMS,
  INFRASTRUCTURE_MENU_ITEMS,
} from '../components/Filters/FilterItems/Infrastructure/infrastructure.constants';
import { LABORATORY_FIELDS_LIST } from '../components/Filters/FilterItems/Laboratory/laboratory.constants';
import {
  EQUIPMENT_INPUTS_LIST,
  // HR_INPUTS_LIST,
  KEY_PERSONNEL_INPUTS_LIST,
  // MEDICAL_OFFICES_INPUTS_LIST,
  SPECIALTIES_CHECKBOXES_LIST,
} from '../components/Filters/constants';
import {
  GENERATED_TECHNOLOGY_MENU_ITEMS,
  TECHNOLOGY_MENU_ITEMS,
} from '../components/Filters/FilterItems/Technology/technology.constants';

import { generateTableFields } from '../utils/table.utils';

export const FLATTEN_HOSPITALS_DATA_FIELDS_LIST = [
  ...GENERAL_INFORMATION_TABLE_FIELDS,
  ...generateTableFields([
    BEDS_MENU_ITEMS.TOTAL_HOSPITAL_BEDS,
    BEDS_MENU_ITEMS.AMBULATORY_BEDS,
    ...GENERATED_BEDS_MENU_ITEMS.FIRST_SECTION,
    ...GENERATED_BEDS_MENU_ITEMS.SECOND_SECTION,
  ]),
  ...generateTableFields([
    INFRASTRUCTURE_MENU_ITEMS.TOTAL_ROOMS,
    ...GENERATED_INFRASTRUCTURE_MENU_ITEMS.FIRST_SECTION,
  ]),
  ...generateTableFields([
    // ...LABORATORY_FIELDS_LIST.FIRST_SECTION,
    // ...LABORATORY_FIELDS_LIST.SECOND_SECTION,
    // ...LABORATORY_FIELDS_LIST.THIRD_SECTION,
    // ...LABORATORY_FIELDS_LIST.FOURTH_SECTION,
    // ...LABORATORY_FIELDS_LIST.FIFTH_SECTION,
    ...LABORATORY_FIELDS_LIST.SIXTH_SECTION,
  ]),
  // ...generateTableFields(MEDICAL_OFFICES_INPUTS_LIST),
  ...generateTableFields(EQUIPMENT_INPUTS_LIST),
  ...generateTableFields(SPECIALTIES_CHECKBOXES_LIST),
  ...generateTableFields([
    // TECHNOLOGY_MENU_ITEMS.TOTAL_COMPUTERS,
    TECHNOLOGY_MENU_ITEMS.INTERNET_CONNECTION,
    TECHNOLOGY_MENU_ITEMS.INTERNET_CONNECTION_TYPE_DROPDOWN,
    // ...GENERATED_TECHNOLOGY_MENU_ITEMS.FIRST_SECTION,
    ...GENERATED_TECHNOLOGY_MENU_ITEMS.SECOND_SECTION,
  ]),
  // ...generateTableFields(HR_INPUTS_LIST),
  ...generateTableFields(KEY_PERSONNEL_INPUTS_LIST),
].flat();
