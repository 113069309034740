import React from 'react';
import { connect } from 'react-redux';

import { PrivateRoute } from '../../core/components/PrivateRoute/PrivateRoute';

import { Navbar } from '../../core/components/Navbar/Navbar';

import { HospitalsPage } from '../hospiscope/components/HospitalsPage';
import { ProceduresPage } from '../surgiscope/components/ProceduresPage';
import { MainviewPage } from '../pricescope/components/MainViewPage';
import { LaboratoriesPage } from '../labscope/components/LaboratoriesPage';

import { ClientsDashboard } from '../admin/ClientsDasboard/components/ClientsDashboard';
import { ClientPage } from '../admin/ClientPage/components/ClientPage';

import { AdminsDashboard } from '../admin/AdminsDashboard/components/AdminsDashboard';
import { AdminPage } from '../admin/AdminPage/components/AdminPage';
import { HomePage } from '../home/HomePage';
import { UserPage } from '../admin/UserPage/components/UserPage';
import { ProfilePage } from '../../core/components/ProfilePage/ProfilePage';

import { ROLES } from '../../core/constants/core.constants';

import { userSelector } from '../../core/user/user.selector';

export const AppContainerComponent = ({ userData, isVerifying }) => {
  return (
    <>
      <Navbar />
      <div className="inner-content-wrapper">
        <PrivateRoute
          path="/"
          component={HomePage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/hospiscope"
          component={HospitalsPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/surgiscope"
          component={ProceduresPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/pricescope"
          component={MainviewPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/labscope"
          component={LaboratoriesPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/admin/clients"
          roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
          component={ClientsDashboard}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/admin/clients/:id"
          roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
          component={ClientPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/admin/admins"
          roles={[ROLES.SUPER_ADMIN]}
          component={AdminsDashboard}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/admin/admins/:id"
          roles={[ROLES.SUPER_ADMIN]}
          component={AdminPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/users/:id"
          roles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
          component={UserPage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
        <PrivateRoute
          path="/profile"
          component={ProfilePage}
          userData={userData}
          isVerifying={isVerifying}
          exact
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: userSelector.userData(state),
  isVerifying: userSelector.isVerifying(state),
});

export const AppContainer = connect(
  mapStateToProps,
  null,
)(AppContainerComponent);
