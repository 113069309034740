import { LabscopeServiceApiProvider } from '../../services/LabscopeServiceApiProvider';

import { replaceAllFilterValues } from '../filters/replaceFilterValues.action';

import { getOffset } from '../../../../core/utils/core.utils';

import { filtersSelector } from '../filters/filters.selector';

import {
  LABORATORY_NAME_FIELD,
  SORTING_DIRECTIONS,
  DEFAULT_PAGE_LIMIT,
} from '../../../../core/constants/core.constants';

export const GET_LABORATORIES_LIST_REQUEST = '[LABSCOPE] GET LABORATORIES LIST REQUEST';
export const GET_LABORATORIES_LIST_SUCCESS = '[LABSCOPE] GET LABORATORIES LIST SUCCESS';
export const GET_LABORATORIES_LIST_FAILURE = '[LABSCOPE] GET LABORATORIES LIST FAILURE';

export function getLaboratoriesList({
  limit = DEFAULT_PAGE_LIMIT,
  currentPage,
  sortedBy = LABORATORY_NAME_FIELD,
  sortingDirection = SORTING_DIRECTIONS.ASC,
}) {
  return async (dispatch, getState) => {
    const filterValues = filtersSelector.filterValues(getState());
    dispatch({
      type: GET_LABORATORIES_LIST_REQUEST,
    });
    try {
      const filterConfig = {
        limit,
        offset: getOffset({ currentPage }),
        sortedBy,
        sortingDirection,
        filterValues,
      };
      const { data } = await LabscopeServiceApiProvider.getLaboratoriesList(filterConfig);
      return dispatch({
        type: GET_LABORATORIES_LIST_SUCCESS,
        payload: {
          laboratoriesList: data.laboratories,
          count: data.count,
          currentPage,
          sortedBy,
          sortingDirection,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_LABORATORIES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const loadSavedSearch = ({ filterValues }) => (dispatch, getState) => {
  replaceAllFilterValues({ filterValues })(dispatch);
  return getLaboratoriesList({ currentPage: 1 })(dispatch, getState);
};
