import React from 'react';
import { Modal } from 'semantic-ui-react';
import {
  renderModalActionButton,
  renderModalCancelButton,
} from '../../../containers/hospiscope/components/modals/utils/modals.utils';

const REMOVE_TEXT = 'Are you sure you want to remove';
const CANCEL_ACTION_TEXT = 'Cancel';
const CONFIRM_ACTION_TEXT = 'Confirm';

export const RemoveModal = ({
  open, onClose, name, removeAction,
}) => {
  return (
    <Modal className="remove-modal" open={open} onClose={onClose} size="mini">
      <Modal.Content>
        <Modal.Description>
          <div className="remove-modal-content">
            <p className="remove-modal-text">{`${REMOVE_TEXT} - ${name}?`}</p>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: removeAction, text: CONFIRM_ACTION_TEXT })}
        {renderModalCancelButton({ clickHandler: onClose, text: CANCEL_ACTION_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
