import React from 'react';
import { Table } from 'semantic-ui-react';

import { TABLE_HEADER_CELLS } from './ProceduresListTableHeader';

import { formatNumericalValue } from '../../../../../../core/utils/core.utils';

const NUMERICAL_CELL_KEY = 'sum';

export const ProceduresListTableBodyComponent = ({
  proceduresList,
}) => {
  return (
    <Table.Body>
      {proceduresList.map((procedure, index) => {
        return (
          <Table.Row
            key={`${procedure.procedure_name}-${index}`}
          >
            {TABLE_HEADER_CELLS.map((cell, i) => (
              <Table.Cell
                key={`${i}-${cell.key}-${procedure.procedure_name}`}
              >
                {cell.key === NUMERICAL_CELL_KEY ? formatNumericalValue(procedure[cell.key]) : procedure[cell.key]}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const ProceduresListTableBody = ProceduresListTableBodyComponent;
