import React from 'react';
import { Table } from 'semantic-ui-react';

import { TABLE_HEADER_CELLS } from './ProceduresTableHeader';

import { formatNumericalValue } from '../../../../../core/utils/core.utils';

const DEFAULT_LIMIT_FOR_HOSPITALS_MODAL = 100000;
const NUMERICAL_CELL_KEY = 'sum';

export const ProceduresTableBodyComponent = ({
  proceduresList,
  getHospitalsList,
}) => {
  return (
    <Table.Body>
      {proceduresList.map((procedure, index) => {
        return (
          <Table.Row
            key={`${index}-${procedure.procedure_name}`}
            onClick={() => getHospitalsList({
              procedureName: procedure.procedure_name,
              procedureCode: procedure.procedure_code,
              shouldIncludeProcedureInfo: true,
              // Sending big limit because of not adding pagination to modal
              limit: DEFAULT_LIMIT_FOR_HOSPITALS_MODAL,
            })}
          >
            {TABLE_HEADER_CELLS.map((cell) => (
              <Table.Cell
                key={`${cell.key}-${procedure.procedure_name}`}
              >
                {cell.key === NUMERICAL_CELL_KEY ? formatNumericalValue(procedure[cell.key]) : procedure[cell.key]}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const ProceduresTableBody = ProceduresTableBodyComponent;
