import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { mainViewCfg } from '../../config';

import { FilterItemWrapper } from './shared/FilterItemWrapper';
import { SelectOptionsFilter } from './shared/SelectOptionsFilter';
import { AddValueFilter } from './shared/AddValueFilter';
import { DynamicFilter } from './shared/DynamicFilter';
import { DatePickRangeInYear } from './shared/DatePickRangeInYear';

import {
  performSearch as performSearchAction,
  setActiveFilter as setActiveFilterAction,
  setFilterValue as setFilterValueAction,
  setDynamicFilterValue as setDynamicFilterValueAction,
  getFilteringOptionsDynamic as getFilteringOptionsDynamicAction,
} from '../../store/filters/filters.actions';

import { filtersSelector } from '../../store/filters/filters.selector';

import { checkIfFilterIsEmpty } from '../../../hospiscope/components/Filters/utils/filters.utils';

export const FilterItemsFactoryComponent = ({
  filterName,
  title,
  activeFilter,
  filteringOptions,
  filterValues,
  setActiveFilter,
  setFilterValue,
  performSearch,
  setDynamicFilterValue,
  areFilteringOptionsDynamicLoading,
  getFilteringOptionsDynamic,
  selectedYear,
}) => {
  const filterValue = filterValues[filterName];
  const filterClassName = `${filterName}-menu`;
  const allowedYears = filteringOptions.year;

  const isFilterEmpty = checkIfFilterIsEmpty({ filterValue });

  const onAcceptClick = ({ name, value }) => {
    setFilterValue({ filterName: name, filterValue: value });
    return performSearch({ filterName: name, filterValue: value });
  };

  const onClear = ({ name }) => {
    setFilterValue({ filterName: name, filterValue: [] });
    return performSearch({ filterName: name, filterValue: [] });
  };

  const filterItemWrapperProps = {
    filterName,
    filterClassName,
    title,
    activeFilter,
    isFilterEmpty,
    setActiveFilter,
    onClear,
  };

  function renderMenuItemContent() {
    switch (mainViewCfg.columns[filterName].filter) {
      case 'DatePickRangeInYear': {
        return (
          <DatePickRangeInYear
            year={selectedYear}
            filterName={filterName}
            filterValue={filterValue}
            allowedYears={allowedYears}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      case 'DynamicFilter': {
        return (
          <DynamicFilter
            items={filteringOptions[filterName]}
            filterName={filterName}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
            areFilteringOptionsDynamicLoading={areFilteringOptionsDynamicLoading}
            setDynamicFilterValue={setDynamicFilterValue}
            getFilteringOptionsDynamic={getFilteringOptionsDynamic}
          />
        );
      }
      case 'SelectOptionsFilter': {
        return (
          <SelectOptionsFilter
            items={filteringOptions[filterName]}
            filterName={filterName}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
      default: {
        return (
          <AddValueFilter
            filterName={filterName}
            filterValue={filterValue}
            onAcceptClick={onAcceptClick}
          />
        );
      }
    }
  }
  return (
    <FilterItemWrapper {...filterItemWrapperProps}>
      {renderMenuItemContent()}
    </FilterItemWrapper>
  );
};

const mapStateToProps = (state) => ({
  activeFilter: filtersSelector.activeFilter(state),
  filteringOptions: filtersSelector.filteringOptions(state),
  filterValues: filtersSelector.filterValues(state),
  areFilteringOptionsDynamicLoading: filtersSelector.areFilteringOptionsDynamicLoading(state),
  selectedYear: filtersSelector.selectedYear(state),

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setActiveFilter: setActiveFilterAction,
  setFilterValue: setFilterValueAction,
  performSearch: performSearchAction,
  setDynamicFilterValue: setDynamicFilterValueAction,
  getFilteringOptionsDynamic: getFilteringOptionsDynamicAction,
}, dispatch);

export const FilterItemsFactory = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterItemsFactoryComponent);

export const Filters = () => {
  return (
    <div className="filter-menu">
      {Object.entries(mainViewCfg.filterMenu).map(
        ([key, val]) => <FilterItemsFactory key={key} {...{ filterName: key, title: val.label }} />,
      )}
    </div>
  );
};
