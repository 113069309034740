import {
  SET_IS_LOADING_SAVED_SEARCH,
  GET_PROCEDURES_LIST_REQUEST,
  GET_PROCEDURES_LIST_SUCCESS,
  GET_PROCEDURES_LIST_FAILURE,
  TOGGLE_SINGLE_ROW_MODAL,
} from './mainViewData.actions';

import { CLEAR_STORE } from '../../../../core/user/user.actions';

import {
  SORTING_DIRECTIONS,
} from '../../../../core/constants/core.constants';

export const initialState = {
  isLoading: false,
  isLoadingSavedSearch: false,
  shouldShowSingleRowModal: false,
  mainViewRows: [],
  pagination: {
    currentPage: 1,
    count: 0,
  },
  sortedBy: 'id',
  sortingDirection: SORTING_DIRECTIONS.ASC,
};

export function mainViewDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING_SAVED_SEARCH: {
      return {
        ...state,
        isLoadingSavedSearch: action.payload,
      };
    }
    case GET_PROCEDURES_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PROCEDURES_LIST_SUCCESS: {
      const {
        mainViewRows,
        count,
        currentPage,
        sortedBy,
        sortingDirection,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        mainViewRows: mainViewRows || [],
        pagination: {
          ...state.pagination,
          count,
          currentPage,
        },
        sortedBy,
        sortingDirection,
      };
    }
    case GET_PROCEDURES_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    case TOGGLE_SINGLE_ROW_MODAL: {
      const { shouldShowSingleRowModal, selectedRowData } = action.payload;
      return {
        ...state,
        shouldShowSingleRowModal,
        selectedRowData: selectedRowData || [],
      };
    }
    default:
      return state;
  }
}
