import {
  GET_PROCEDURES_LIST_REQUEST,
  GET_PROCEDURES_LIST_SUCCESS,
  GET_PROCEDURES_LIST_FAILURE,
  GET_HOSPITALS_LIST_REQUEST,
  GET_HOSPITALS_LIST_SUCCESS,
  GET_HOSPITALS_LIST_FAILURE,
  TOGGLE_HOSPITALS_LIST_MODAL, SET_PROCEDURES_TABLE_VIEW,
} from './proceduresData.actions';

import { CLEAR_STORE } from '../../../../core/user/user.actions';

import {
  PROCEDURE_NAME_FIELD,
  SORTING_DIRECTIONS,
  PROCEDURES_TABLE_VIEWS,
} from '../../../../core/constants/core.constants';

export const initialState = {
  isLoading: false,
  isHospitalsModalLoading: false,
  shouldShowHospitalsModal: false,
  selectedProcedureName: '',
  selectedProcedureCode: '',
  proceduresList: [],
  hospitalsList: [],
  pagination: {
    currentPage: 1,
    count: 0,
  },
  sortedBy: PROCEDURE_NAME_FIELD,
  sortingDirection: SORTING_DIRECTIONS.ASC,
  tableView: PROCEDURES_TABLE_VIEWS.PROCEDURES,
};

export function proceduresDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROCEDURES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROCEDURES_LIST_SUCCESS: {
      const {
        proceduresList,
        count,
        currentPage,
        sortedBy,
        sortingDirection,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        proceduresList: proceduresList || [],
        pagination: {
          ...state.pagination,
          count,
          currentPage,
        },
        sortedBy,
        sortingDirection,
      };
    }
    case GET_PROCEDURES_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_HOSPITALS_LIST_REQUEST: {
      return {
        ...state,
        isHospitalsModalLoading: true,
        shouldShowHospitalsModal: true,
      };
    }
    case GET_HOSPITALS_LIST_SUCCESS: {
      return {
        ...state,
        isHospitalsModalLoading: false,
        hospitalsList: action.payload.hospitalsList || [],
      };
    }
    case GET_HOSPITALS_LIST_FAILURE: {
      return {
        ...state,
        isHospitalsModalLoading: false,
      };
    }
    case TOGGLE_HOSPITALS_LIST_MODAL: {
      const { shouldShowHospitalsModal, selectedProcedureName, selectedProcedureCode } = action.payload;
      return {
        ...state,
        shouldShowHospitalsModal,
        selectedProcedureName: selectedProcedureName || '',
        selectedProcedureCode: selectedProcedureCode || '',
      };
    }
    case SET_PROCEDURES_TABLE_VIEW: {
      return {
        ...state,
        tableView: action.payload,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
