import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import { navigateToPage } from '../../../core/routing/history';

const TOAST_MESSAGE = 'Your subscription currently does not give you access to this data.';
const BACK_TEXT = 'Go back';

const CONTACT_MESSAGE = 'Please contact your account manager for further access';
const CONTACT_DETAILS = ['You can also contact Carmen Contreras ', ' for support.', 'Thank you.'];
const [FIRST, SECOND, THIRD] = CONTACT_DETAILS;

const CONTACT_EMAIL = 'carmen@intelligence.health';
const CONTACT_LINK = `mailto:${CONTACT_EMAIL}`;

export const NoAccessToast = () => (
  <div className="expired-token-toast">
    <Header as="h3">{TOAST_MESSAGE}</Header>
    <Header sub>{CONTACT_MESSAGE}</Header>
    <p>
      {FIRST}
      <a href={CONTACT_LINK}>{CONTACT_EMAIL}</a>
      {SECOND}
    </p>
    <p>{THIRD}</p>
    <Button primary onClick={() => navigateToPage('/')}>
      {BACK_TEXT}
    </Button>
  </div>
);
