import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import { Pagination } from '../../../common/Pagination';
import { InstitutionsTable } from './InstitutionsTable/InstitutionsTable';
import { SurgiscopeDataHeader } from '../ProceduresData/SurgiscopeDataHeader';
import { ProceduresListModal } from './ProceduresListModal/ProceduresListModal';

import {
  getInstitutionsList as getInstitutionsListAction,
  getProceduresList as getProceduresListAction,
  toggleProceduresListModal as toggleProceduresListModalAction,
} from '../../store/institutionsData/institutionsData.actions';

import { institutionsDataSelector } from '../../store/institutionsData/institutionsData.selector';

import { getPaginationItemsCount } from '../../../../core/utils/core.utils';

const InstitutionsDataComponent = ({
  isLoading,
  institutionsList,
  pagination,
  sortedBy,
  selectedInstitutionId,
  shouldShowProceduresModal,
  sortingDirection,
  isProceduresModalLoading,
  proceduresList,
  proceduresCount,
  getInstitutionsList,
  getProceduresList,
  toggleProceduresListModal,
}) => {
  const { count, currentPage } = pagination;

  useEffect(() => {
    getInstitutionsList({ currentPage: 1 });
  }, [getInstitutionsList]);

  const getProceduresDataHeaderProps = () => ({ items: institutionsList, count });

  const onPageChange = (e, { activePage }) => {
    return getInstitutionsList({ currentPage: activePage, sortedBy, sortingDirection });
  };

  return (
    <div className="container main">
      {isLoading ? <Loader active={isLoading} /> : (
        <div className="proceduresPage-content">
          <SurgiscopeDataHeader {...getProceduresDataHeaderProps()} />
          <InstitutionsTable
            institutionsList={institutionsList}
            sortedBy={sortedBy}
            sortingDirection={sortingDirection}
            getInstitutionsList={getInstitutionsList}
            getProceduresList={getProceduresList}
          />
          {institutionsList.length ? (
            <Pagination
              currentPage={currentPage}
              totalPages={getPaginationItemsCount({ count })}
              onPageChange={onPageChange}
            />
          ) : null}
          {shouldShowProceduresModal ? (
            <ProceduresListModal
              open={shouldShowProceduresModal}
              isLoading={isProceduresModalLoading}
              selectedInstitutionId={selectedInstitutionId}
              proceduresList={proceduresList}
              proceduresCount={proceduresCount}
              onClose={() => toggleProceduresListModal({ shouldShowProceduresModal: false })}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: institutionsDataSelector.isLoading(state),
  proceduresList: institutionsDataSelector.proceduresList(state),
  proceduresCount: institutionsDataSelector.proceduresCount(state),
  pagination: institutionsDataSelector.pagination(state),
  shouldShowProceduresModal: institutionsDataSelector.shouldShowProceduresModal(state),
  isProceduresModalLoading: institutionsDataSelector.isProceduresModalLoading(state),
  institutionsList: institutionsDataSelector.institutionsList(state),
  selectedInstitutionId: institutionsDataSelector.selectedInstitutionId(state),
  sortedBy: institutionsDataSelector.sortedBy(state),
  sortingDirection: institutionsDataSelector.sortingDirection(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getInstitutionsList: getInstitutionsListAction,
  getProceduresList: getProceduresListAction,
  toggleProceduresListModal: toggleProceduresListModalAction,
}, dispatch);

export const InstitutionsData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstitutionsDataComponent);
