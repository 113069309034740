import React from 'react';
import { Table } from 'semantic-ui-react';

export const EquipmentFieldTable = ({
  selectedLaboratory,
  fields,
  className,
  isPdfRow,
}) => {
  const getFieldValue = (field) => (field && field !== '0' ? field : 'No data');
  const { details } = selectedLaboratory;
  return (
    <Table className={`hospital-modal-table ${className}`}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Brand</Table.HeaderCell>
          <Table.HeaderCell>Year of installation</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {fields.map(({ label, key }) => {
          return (
            <Table.Row key={`key-${key}-${isPdfRow ? 'pdf' : ''}`}>
              <Table.Cell className="laboratory-modal-text-bold">{label}</Table.Cell>
              <Table.Cell className="laboratory-modal-text-common">
                {getFieldValue(details[key]?.amount)}
              </Table.Cell>
              <Table.Cell className="laboratory-modal-text-common">
                {getFieldValue(details[key]?.brand)}
              </Table.Cell>
              <Table.Cell className="laboratory-modal-text-common">
                {getFieldValue(details[key]?.year_of_inst)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
