import React from 'react';
import { Header, Table } from 'semantic-ui-react';

import { LaboratoriesTableHeader } from './LaboratoriesTableHeader';
import { LaboratoriesTableBody } from './LaboratoriesTableBody';

const NO_RESULTS_MESSAGE = 'No laboratories found. Try to set different filters';

export const LaboratoriesTable = ({
  filterName,
  laboratoriesList,
  sortedBy,
  sortingDirection,
  getLaboratoriesList,
  toggleLaboratoryModal,
}) => {
  return (
    laboratoriesList.length ? (
      <div className="hospitals-table-wrapper">
        <Table className="hospitals-table" sortable>
          <LaboratoriesTableHeader
            filterName={filterName}
            sortedBy={sortedBy}
            sortingDirection={sortingDirection}
            getLaboratoriesList={getLaboratoriesList}
          />
          <LaboratoriesTableBody
            filterName={filterName}
            laboratoriesList={laboratoriesList}
            toggleLaboratoryModal={toggleLaboratoryModal}
          />
        </Table>
      </div>
    ) : (
      <div className="no-results-message-wrapper">
        <Header as="h3" className="no-results-message">{NO_RESULTS_MESSAGE}</Header>
      </div>
    )
  );
};
