import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import {
  Dimmer, Grid, Loader, Modal,
} from 'semantic-ui-react';

import { Header } from './HospitalModalContent/Header';
import { PdfContent } from './HospitalModalContent/PdfContent';
import { FieldTable } from './HospitalModalContent/FieldTable';

import { hospitalsDataSelector } from '../../../store/hospitalsData/hospitalsData.selector';

import { createPDF, drawCanvas } from '../../../../../core/utils/core.utils';
import { renderModalActionButton } from '../utils/modals.utils';
import { getModalTableFields } from '../utils/hospitalModal.utils';

import { FILTERS } from '../../../constants/filters.constants';
import { TOAST_CONSTANTS } from '../../../../../core/constants/core.constants';
import { HOSPITAL_PDF_SELECTORS } from '../constants/pdfSelectors.constants';
import { handlePhoneCellContent } from '../../HospitalsData/hospitalsData.utils';

const LOADER_TEXT = 'Generating PDF...';

export const HospitalModalComponent = ({
  open,
  selectedHospital,
  onClose,
}) => {
  const formattedHospital = selectedHospital
    ? { ...selectedHospital, phone: handlePhoneCellContent(selectedHospital.phone) } : selectedHospital;
  const [isLoading, setIsLoading] = useState(false);
  async function getPDF() {
    try {
      const pdfItemsSelectors = Object.values(HOSPITAL_PDF_SELECTORS).map((selector) => `.${selector}`);
      const canvasList = await drawCanvas(pdfItemsSelectors);
      const pdfDOC = createPDF(canvasList);
      setIsLoading(false);
      pdfDOC.save(`${formattedHospital.name}.pdf`);
    } catch (e) {
      setIsLoading(false);
      toast.error(TOAST_CONSTANTS.PDF_ERROR);
    }
  }
  function getPdfHandler() {
    setIsLoading(true);
    /* hack fot html2canvas (it is blocking loader render) */
    setTimeout(getPDF, 0);
  }
  function renderModalContent() {
    return (
      <Modal open={open} onClose={onClose} className="hospital-modal">
        <Dimmer active={isLoading}>
          <Loader>{LOADER_TEXT}</Loader>
        </Dimmer>
        <Modal.Header>
          <Header selectedHospital={formattedHospital} pdfAction={getPdfHandler} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.GENERAL_INFORMATION)}
                  title="General information"
                  className="general-info"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.INFRASTRUCTURE)}
                  title="Infrastructure"
                  plain={false}
                  className="infrastructure"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.BEDS)}
                  title="Beds"
                  className="beds"
                />
              </Grid.Column>
              {/* <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.MEDICAL_OFFICES)}
                  title="Medical offices"
                  className="medical-offices"
                />
              </Grid.Column> */}
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.EQUIPMENT)}
                  title="Equipment"
                  plain={false}
                  className="equipment"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.SPECIALTIES)}
                  title="Specialities"
                  plain={false}
                  className="specialities"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.LABORATORY)}
                  title="Laboratory"
                  className="laboratory"
                />
              </Grid.Column>
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.TECHNOLOGY)}
                  title="Technology"
                  className="technology"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              {/* <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.HR)}
                  title="HR"
                  className="hr"
                />
              </Grid.Column> */}
              <Grid.Column>
                <FieldTable
                  selectedHospital={formattedHospital}
                  fields={getModalTableFields(FILTERS.KEY_PERSONNEL)}
                  title="Key personnel"
                  className="key-personnel"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {renderModalActionButton({ clickHandler: getPdfHandler, text: 'Get PDF', className: 'pdf' })}
        </Modal.Actions>
        <PdfContent selectedHospital={formattedHospital} />
      </Modal>
    );
  }
  return formattedHospital ? renderModalContent() : null;
};

const mapStateToProps = (state, props) => ({
  selectedHospital: hospitalsDataSelector.selectedHospital(state, props.selectedHospitalId),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const HospitalModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HospitalModalComponent);
