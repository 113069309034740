import { container } from '../../config';
import { ServiceApiProvider } from '../../services/ServiceApiProvider';
import { replaceAllFilterValues } from '../filters/replaceFilterValues.action';
import { filtersSelector } from '../filters/filters.selector';
import { getOffset } from '../../../../core/utils/core.utils';

import { DEFAULT_PAGE_LIMIT, SORTING_DIRECTIONS } from '../../../../core/constants/core.constants';

export const SET_IS_LOADING_SAVED_SEARCH = `${container.name}/SET IS LOADING SAVED SEARCH`;
export const GET_PROCEDURES_LIST_REQUEST = `${container.name}/GET TENDERS LIST REQUEST`;
export const GET_PROCEDURES_LIST_SUCCESS = `${container.name}/GET TENDERS LIST SUCCESS`;
export const GET_PROCEDURES_LIST_FAILURE = `${container.name}/GET TENDERS LIST FAILURE`;

export const TOGGLE_SINGLE_ROW_MODAL = `${container.name}/TOGGLE SINGLE ROW LIST MODAL`;

export function getMainViewData({
  limit = DEFAULT_PAGE_LIMIT,
  currentPage,
  sortedBy = 'id',
  sortingDirection = SORTING_DIRECTIONS.ASC,
}) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PROCEDURES_LIST_REQUEST,
    });
    try {
      const filterValues = filtersSelector.filterValues(getState());
      const filterConfig = {
        filterValues,
        sortingOptions: {
          sortedBy,
          sortingDirection,
        },
        paginationOptions: {
          limit,
          offset: getOffset({ currentPage }),
        },
      };
      const { data } = await ServiceApiProvider.getMainViewData(filterConfig);

      return dispatch({
        type: GET_PROCEDURES_LIST_SUCCESS,
        payload: {
          mainViewRows: data.tenders,
          count: data.count,
          currentPage,
          sortedBy,
          sortingDirection,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_PROCEDURES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export function toggleSingleRowModal({ selectedRowData } = {}) {
  const shouldShowSingleRowModal = !!selectedRowData;

  return (dispatch) => {
    dispatch({
      type: TOGGLE_SINGLE_ROW_MODAL,
      payload: {
        shouldShowSingleRowModal,
        selectedRowData,
      },
    });
  };
}

export const setIsLoadingSavedSearch = (payload) => (dispatch) => {
  return dispatch({
    type: SET_IS_LOADING_SAVED_SEARCH,
    payload,
  });
};

export const loadSavedSearch = ({ filterValues }) => (dispatch, getState) => {
  setIsLoadingSavedSearch(true)(dispatch);
  replaceAllFilterValues({ filterValues })(dispatch);
  getMainViewData({ currentPage: 1 })(dispatch, getState);
};
