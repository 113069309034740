function getUserState(state) {
  return state.user;
}

export const userSelector = {
  userData(state) {
    return getUserState(state).userData;
  },
  isLoggingIn(state) {
    return getUserState(state).isLoggingIn;
  },
  isVerifying(state) {
    return getUserState(state).isVerifying;
  },
  savedSearches(state) {
    return getUserState(state).savedSearches;
  },
  areSavedSearchesLoading(state) {
    return getUserState(state).areSavedSearchesLoading;
  },
};
