import React from 'react';
import { Table } from 'semantic-ui-react';

import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';
import {
  GENERAL_INFORMATION_TABLE_FIELDS,
  ADMINISTRATIVE_TABLE_FIELDS,
  EQUIPMENT_TABLE_FIELDS,
  PROCEDURES_TABLE_FIELDS,
} from '../tableFields.constants';

import { getSortingDirectionForArrow } from '../../../../../core/utils/core.utils';

const { ASC, DESC } = SORTING_DIRECTIONS;

export const TABLE_HEADER_CELLS = [
  ...GENERAL_INFORMATION_TABLE_FIELDS,
  ...ADMINISTRATIVE_TABLE_FIELDS,
  ...EQUIPMENT_TABLE_FIELDS,
  ...PROCEDURES_TABLE_FIELDS,
];

export const LaboratoriesTableHeaderComponent = ({
  sortedBy,
  sortingDirection,
  getLaboratoriesList,
}) => {
  return (
    <Table.Header>
      <Table.Row>
        {TABLE_HEADER_CELLS.map((cell) => {
          return (
            <Table.HeaderCell
              key={cell.label}
              sorted={getSortingDirectionForArrow({ key: cell.key, sortedBy, sortingDirection })}
              onClick={() => getLaboratoriesList({
                currentPage: 1,
                sortedBy: cell.key,
                sortingDirection: sortingDirection === ASC ? DESC : ASC,
              })}
            >
              {cell.label}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export const LaboratoriesTableHeader = LaboratoriesTableHeaderComponent;
