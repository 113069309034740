import { container } from '../../config';

// Returns state.[container].mainViewData
function getDataState(state) {
  return container.getState(state).mainViewData;
}

export const mainViewDataSelector = {
  isLoading(state) {
    return getDataState(state).isLoading;
  },
  isLoadingSavedSearch(state) {
    return getDataState(state).isLoadingSavedSearch;
  },
  mainViewRows(state) {
    return getDataState(state).mainViewRows;
  },
  pagination(state) {
    return getDataState(state).pagination;
  },
  shouldShowSingleRowModal(state) {
    return getDataState(state).shouldShowSingleRowModal;
  },
  selectedRowData(state) {
    return getDataState(state).selectedRowData;
  },
  sortedBy(state) {
    return getDataState(state).sortedBy;
  },
  sortingDirection(state) {
    return getDataState(state).sortingDirection;
  },
};
