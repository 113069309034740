export const HOSPITAL_PDF_SELECTORS = {
  FIRST_PAGE: 'pdf-page-first',
  SECOND_PAGE: 'pdf-page-second',
  THIRD_PAGE: 'pdf-page-third',
  FOURTH_PAGE: 'pdf-page-fourth',
  // FIFTH_PAGE: 'pdf-page-fifth',
  // SIXTH_PAGE: 'pdf-page-sixth',
  // SEVENTH_PAGE: 'pdf-page-seventh',
  // EIGHTH_PAGE: 'pdf-page-eighth',
};
