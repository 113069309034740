import { containerName } from './container.name';
import { formatNumber, formatCurrency } from '../../core/utils/core.utils';

export const container = new function containerCfg() {
  this.name = containerName;
  this.getState = (state) => state[this.name];
}();

const formatDateAsQuarter = (val, key, row) => {
  if (row.country === 'Argentina') {
    // Y-M-d is read as UTC date; use getUTC* to avoid getting a different local date
    const quarter = Math.floor((new Date(val).getUTCMonth() + 3) / 3);
    const year = new Date(val).getUTCFullYear();
    return `${year} Q${quarter}`;
  }

  return val;
};

export const mainViewCfg = new function mainViewCfg() {
  this.columns = {
    id: { hidden: true, hiddenInModal: true, hiddenInExport: true },
    country: { hidden: true, label: 'Country', filter: true },
    transaction_id: {
      label: 'Tender ID', filter: 'AddValueFilter', showInFilterMenu: true, filterFromModal: true, cellClass: 'max-width-medium',
    },
    date: {
      label: 'Date', filter: 'DatePickRangeInYear', showInFilterMenu: true, headerClass: 'width-date', formatValue: formatDateAsQuarter,
    },
    buyer: {
      label: 'Buyer', filter: 'SelectOptionsFilter', showInFilterMenu: true, filterFromModal: true, headerClass: 'min-width-medium',
    },
    supplier: {
      label: 'Supplier', filter: 'SelectOptionsFilter', showInFilterMenu: true, filterFromModal: true, headerClass: 'min-width-medium',
    },
    item_code: { hidden: true, label: 'Item code' },
    item: {
      label: 'Item', filter: 'DynamicFilter', showInFilterMenu: true, headerClass: 'min-width-medium', wrapperClass: 'clamp-lines',
    },
    item_description: {
      label: 'Item description', filter: 'DynamicFilter', showInFilterMenu: true, headerClass: 'min-width-wide', wrapperClass: 'clamp-lines',
    },
    quantity: { label: 'Quantity', cellClass: 'numeric', formatValue: formatNumber },
    unit: { label: 'Unit' },
    currency: { hidden: true, label: 'Currency' },
    unit_price: { label: 'Unit price', cellClass: 'monetary', formatValue: formatCurrency },
    total_price: { label: 'Total price', cellClass: 'monetary', formatValue: formatCurrency },
    item_json: { hidden: true, json: true },
    tender_json: { hidden: true, json: true },
  };

  this.colLabels = Object.entries(this.columns)
    .filter(([, props]) => !props.hidden)
    .map(([col, props]) => {
      return [col, props.label];
    });

  this.tableCols = Object.fromEntries(
    Object.entries(this.columns).filter(([, val]) => !val.hidden),
  );

  this.exportCols = Object.fromEntries(
    Object.entries(this.columns).filter(([, val]) => !val.hiddenInExport),
  );

  this.filterMenu = Object.fromEntries(
    Object.entries(this.columns).filter(([, val]) => val.showInFilterMenu),
  );

  this.filterFromModal = Object.entries(this.columns)
    .filter(([, val]) => val.filterFromModal)
    .map(([key]) => key);

  this.filtersToClearOnYearChange = ['date', 'transaction_id'];
  this.filtersToClearOnCountryChange = Object.keys(this.filterMenu).concat(['year']);
  this.filtersToClearOnClearAll = Object.keys(this.filterMenu);

  // this.DynamicFilters = Object.keys(
  //   Object.entries(this.columns).filter(([, props]) => props.filter === 'DynamicFilter'),
  // );
}();

// Config for the SingleRowModal of MainView
export const singleRowModalCfg = new function singleRowModalCfg() {
  // Format for JSON fields
  this.formatCol = {};

  this.addArrToColFormat = (format, arr) => {
    arr.forEach((col) => { this.formatCol[col] = format; });
  };

  // Brazil
  // this.addArrToColFormat(formatNumber, 'quantity'.split(' '));
  this.addArrToColFormat(formatCurrency,
    'unit_price pre_tax_total discount charges total_taxes total_charges total_discount total_value total_unit total'
      .split(' '));

  // this.addArrToColFormat(formatNumber, 'quantity'.split(' '));
  this.addArrToColFormat(formatCurrency,
    // Chile, Colombia
    `unit_price pre_tax_total discount charges total_taxes total_charges total_discount total_value total_unit total
    PrecioMinimoUnitario PrecioMaximoUnitario PrecioUnitarioMinimo PrecioUnitarioMaximo`.split(/\s+/));

  // Mexico
  this.addArrToColFormat(formatCurrency, ['Importe del contrato']);

  this.columnNotes = {
    Argentina: { total_price: 'amount listed is for the whole tender, not single item.' },
    Mexico: { total_price: 'amount listed is for all the items of same supplier.' },
  };
}();
