import React from 'react';
import { Table } from 'semantic-ui-react';

import { HospitalsListTableHeader } from './HospitalsListTableHeader';
import { HospitalsListTableBody } from './HospitalsListTableBody';

export const HospitalsListTable = ({
  hospitalsList,
  sortedBy,
  sortingDirection,
  setSortedBy,
  setSortingDirection,
}) => {
  return (
    <Table className="hospitals-table" sortable>
      <HospitalsListTableHeader
        sortedBy={sortedBy}
        sortingDirection={sortingDirection}
        setSortedBy={setSortedBy}
        setSortingDirection={setSortingDirection}
      />
      <HospitalsListTableBody hospitalsList={hospitalsList} />
    </Table>
  );
};
