import React, { useState, useEffect } from 'react';

import {
  DropdownField,
} from '../../../../../../hospiscope/components/Filters/FilterItems/shared/components';
import { generateDropdownOptions } from '../../../../../../../core/utils/core.utils';

const MIN_SEARCH_LENGTH = 3;
const NO_RESULTS_MESSAGE = 'No results found';
const SEARCH_MIN_LENGTH_MESSAGE = `Please, provide ${MIN_SEARCH_LENGTH} chars to start search`;

export const HospitalNamesDropdownComponent = ({
  value,
  label,
  name,
  placeholder,
  isLoading,
  availableItems,
  disabled,
  onChange,
  getAvailableItems,
}) => {
  const [options, setOptions] = useState(generateDropdownOptions(availableItems));
  const [message, setMessage] = useState(SEARCH_MIN_LENGTH_MESSAGE);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setOptions(generateDropdownOptions(availableItems));
  }, [availableItems]);

  useEffect(() => {
    if (searchValue >= 3 && !availableItems.length && !isLoading) {
      setMessage(NO_RESULTS_MESSAGE);
    }
  }, [searchValue, availableItems.length, isLoading]);

  function getItems(searchQuery) {
    setMessage(null);
    return getAvailableItems({ [name]: searchQuery });
  }

  function onSearchChange(e, { searchQuery }) {
    setSearchValue(searchQuery);
    return searchQuery.length >= MIN_SEARCH_LENGTH ? getItems(searchQuery) : setMessage(SEARCH_MIN_LENGTH_MESSAGE);
  }

  return (
    <DropdownField
      search
      label={label}
      name={name}
      options={options}
      value={value}
      placeholder={placeholder}
      noResultsMessage={message}
      loading={isLoading}
      disabled={disabled}
      onChange={onChange}
      onSearchChange={onSearchChange}
      direction="left"
    />
  );
};

export const HospitalNamesDropdown = HospitalNamesDropdownComponent;
