import React, { useState } from 'react';
import { Header, Table } from 'semantic-ui-react';

import { ClientsTableHeader } from './ClientsTableHeader';
import { ClientsTableBody } from './ClientsTableBody';

import { sortList } from '../../../../../core/utils/core.utils';

import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';

const NO_RESULTS_MESSAGE = 'There are no clients. Try to add one.';

const DEFAULT_SORTING_PARAM = 'created_at';

export const ClientsTable = ({ clientsList }) => {
  const [sortedBy, setSortedBy] = useState(DEFAULT_SORTING_PARAM);
  const [sortingDirection, setSortingDirection] = useState(SORTING_DIRECTIONS.DESC);
  return (
    clientsList.length ? (
      <Table sortable className="clients-dashboard-table">
        <ClientsTableHeader
          sortedBy={sortedBy}
          sortingDirection={sortingDirection}
          setSortedBy={setSortedBy}
          setSortingDirection={setSortingDirection}
        />
        <ClientsTableBody clientsList={sortList({ list: clientsList, sortedBy, sortingDirection })} />
      </Table>
    ) : (
      <div className="no-results-message-wrapper">
        <Header as="h3" className="no-results-message">{NO_RESULTS_MESSAGE}</Header>
      </div>
    )
  );
};
