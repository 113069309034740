import React from 'react';
import { ClientsTable } from './ClientsTable/ClientsTable';
import { CLIENTS_DASHBOARD_TITLE } from '../constants/clientsDashboard.constants';

export const ClientsDashboardBody = ({ clientsList }) => {
  return (
    <div className="clients-dashboard-body">
      <div className="clients-dashboard-title segment-block">
        {CLIENTS_DASHBOARD_TITLE}
      </div>
      <div className="clients-dashboard-content segment-block">
        <ClientsTable clientsList={clientsList} />
      </div>
    </div>
  );
};
