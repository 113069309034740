import { FILTER_MENU_TITLES, FILTERS } from '../../../constants/filters.constants';

export const FILTER_ITEMS_PROPS = [
  {
    key: FILTER_MENU_TITLES[FILTERS.GROUP],
    title: FILTER_MENU_TITLES[FILTERS.GROUP],
    filterName: FILTERS.GROUP,
    filterClassName: 'group-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.CATEGORY],
    title: FILTER_MENU_TITLES[FILTERS.CATEGORY],
    filterName: FILTERS.CATEGORY,
    filterClassName: 'category-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.SUBCATEGORY],
    title: FILTER_MENU_TITLES[FILTERS.SUBCATEGORY],
    filterName: FILTERS.SUBCATEGORY,
    filterClassName: 'subcategory-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.PROCEDURE],
    title: FILTER_MENU_TITLES[FILTERS.PROCEDURE],
    filterName: FILTERS.PROCEDURE,
    filterClassName: 'procedure-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.INSTITUTION],
    title: FILTER_MENU_TITLES[FILTERS.INSTITUTION],
    filterName: FILTERS.INSTITUTION,
    filterClassName: 'institution-menu',
  },
];
