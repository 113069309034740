export const EQUIPMENT_INPUTS_LIST = [
  {
    label: 'X-Ray Machines',
    name: 'xray',
    placeholder: 'Number',
  },
  {
    label: 'Ultrasound equipment',
    name: 'ultrasound',
    placeholder: 'Number',
  },
  {
    label: 'Echocardiograph equipment',
    name: 'echocardiographs',
    placeholder: 'Number',
  },
  {
    label: 'Mastography machines',
    name: 'mastographer',
    placeholder: 'Number',
  },
  {
    label: 'MRI equipment',
    name: 'mri',
    placeholder: 'Number',
  },
  {
    label: 'CT scanners',
    name: 'cat',
    placeholder: 'Number',
  },
  {
    label: 'PET scanners',
    name: 'pets',
    placeholder: 'Number',
  },
  {
    label: 'Bone densitometers',
    name: 'bonedensitometer',
    placeholder: 'Number',
  },
  {
    label: 'C-arms',
    name: 'carm',
    placeholder: 'Number',
  },
  {
    label: 'Fluoroscopy systems',
    name: 'fluoroscope',
    placeholder: 'Number',
  },
  {
    label: 'Angiography  systems',
    name: 'angiograph',
    placeholder: 'Number',
  },
  {
    label: 'Electronic medical records (EMR)',
    name: 'emr',
    placeholder: 'Number',
  },
  {
    label: 'Picture archiving and communications systems (PACS)',
    name: 'pacs',
    placeholder: 'Number',
  },
  {
    label: 'Radiology information systems (RIS)',
    name: 'ris',
    placeholder: 'Number',
  },
  {
    label: 'Power injectors MRI',
    name: 'injectorMri',
    placeholder: 'Number',
  },
  {
    label: 'Power injectors CT scanners',
    name: 'injectorCat',
    placeholder: 'Number',
  },
  {
    label: 'Power injectors angiography systems',
    name: 'injectorAngiograph',
    placeholder: 'Number',
  },
];
