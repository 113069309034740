import React from 'react';
import { Table } from 'semantic-ui-react';

import { ADMINS_TABLE_CELLS } from '../../constants/tableFields.constants';

export const AdminsTableHeaderComponent = () => (
  <Table.Header>
    <Table.Row>
      {ADMINS_TABLE_CELLS.map((cell) => {
        return <Table.HeaderCell key={cell.label} className={cell.key}>{cell.label}</Table.HeaderCell>;
      })}
    </Table.Row>
  </Table.Header>
);

export const AdminsTableHeader = AdminsTableHeaderComponent;
