import React from 'react';
import { Table } from 'semantic-ui-react';
import { CLIENTS_TABLE_CELLS } from '../../constants/tableFields.constants';
import { getSortingDirectionForArrow } from '../../../../../core/utils/core.utils';
import { SORTING_DIRECTIONS } from '../../../../../core/constants/core.constants';

export const ClientsTableHeaderComponent = ({
  sortedBy,
  sortingDirection,
  setSortedBy,
  setSortingDirection,
}) => (
  <Table.Header>
    <Table.Row>
      {CLIENTS_TABLE_CELLS.map((cell) => {
        return (
          <Table.HeaderCell
            key={cell.label}
            sorted={getSortingDirectionForArrow({ key: cell.key, sortedBy, sortingDirection })}
            className={cell.key}
            onClick={() => {
              const newSortingDirection = sortingDirection === SORTING_DIRECTIONS.ASC
                ? SORTING_DIRECTIONS.DESC : SORTING_DIRECTIONS.ASC;
              return cell.key === sortedBy ? setSortingDirection(newSortingDirection) : setSortedBy(cell.key);
            }}
          >
            {cell.label}
          </Table.HeaderCell>
        );
      })}
    </Table.Row>
  </Table.Header>
);

export const ClientsTableHeader = ClientsTableHeaderComponent;
