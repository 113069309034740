export const EDIT_USER_MODAL_FORM_FIELDS = [
  {
    label: 'First Name',
    placeholder: 'Type here',
    name: 'firstName',
  },
  {
    label: 'Last Name',
    placeholder: 'Type here',
    name: 'lastName',
  },
  {
    label: 'Title',
    placeholder: 'Type here',
    name: 'title',
  },
];
