import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import { MainViewDataHeader } from './MainViewDataHeader';
import { Pagination } from '../../../common/Pagination';
import { MainDataTable } from './MainViewTable';
import { SingleRowModal } from './SingleRowModal';

import {
  getMainViewData as getMainViewDataAction,
  toggleSingleRowModal as toggleSingleRowModalAction,
} from '../../store/MainViewData/mainViewData.actions';

import { mainViewDataSelector } from '../../store/MainViewData/mainViewData.selector';
import { filtersSelector } from '../../store/filters/filters.selector';
import { getPaginationItemsCount } from '../../../../core/utils/core.utils';

const MainViewDataComponent = ({
  isLoading,
  mainViewRows,
  pagination,
  shouldShowSingleRowModal,
  selectedRowData,
  sortedBy,
  sortingDirection,
  getMainViewData,
  toggleSingleRowModal,
}) => {
  const { count, currentPage } = pagination;

  const getMainViewDataHeaderProps = () => ({ items: mainViewRows, count });

  const onPageChange = (e, { activePage }) => {
    return getMainViewData({ currentPage: activePage, sortedBy, sortingDirection });
  };

  return (
    <div className="container main">
      {isLoading ? <Loader active={isLoading} /> : (
        <div className="proceduresPage-content">
          <MainViewDataHeader {...getMainViewDataHeaderProps()} />
          <MainDataTable
            mainViewRows={mainViewRows}
            sortedBy={sortedBy}
            sortingDirection={sortingDirection}
            getMainViewData={getMainViewData}
            toggleSingleRowModal={toggleSingleRowModal}
          />
          {mainViewRows.length ? (
            <Pagination
              currentPage={currentPage}
              totalPages={getPaginationItemsCount({ count })}
              onPageChange={onPageChange}
            />
          ) : null}
          {shouldShowSingleRowModal ? (
            <SingleRowModal
              open={shouldShowSingleRowModal}
              selectedRowData={selectedRowData}
              onClose={() => toggleSingleRowModal()}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: mainViewDataSelector.isLoading(state),
  mainViewRows: mainViewDataSelector.mainViewRows(state),
  pagination: mainViewDataSelector.pagination(state),
  shouldShowSingleRowModal: mainViewDataSelector.shouldShowSingleRowModal(state),
  selectedRowData: mainViewDataSelector.selectedRowData(state),
  selectedCountry: filtersSelector.selectedCountry(state),
  sortedBy: mainViewDataSelector.sortedBy(state),
  sortingDirection: mainViewDataSelector.sortingDirection(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getMainViewData: getMainViewDataAction,
  toggleSingleRowModal: toggleSingleRowModalAction,
}, dispatch);

export const MainViewData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainViewDataComponent);
