import React, { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { max, min } from 'lodash';

import { renderAcceptButton } from '../../../../hospiscope/components/Filters/utils/filters.ui.utils';

export const DatePickRangeInYear = ({
  filterName,
  filterValue = [],
  allowedYears,
  onAcceptClick,
  year,
}) => {
  const defaultSelected = {
    from: undefined,
    to: undefined,
  };
  const [range, setRange] = useState(filterValue || defaultSelected);

  const handleDayClick = (day) => {
    setRange(DateUtils.addDayToRange(day, range));
  };

  // First of Jan shows Dec for some reason, so use the 2nd of Jan
  const initialMonth = range.from ? range.from : new Date(`${year}-01-02`);
  const fromMonth = new Date(`${min(allowedYears)}-01-02`);
  const toMonth = new Date(`${max(allowedYears)}-12-01`);
  const userLocale = new Intl.Locale(navigator.language);
  // Firefox doesn't support weekInfo, default to 1 (Monday)
  const firstDayOfWeekLocale = userLocale?.weekInfo?.firstDay || 1;
  // Sunday is 7 in Locale, DayPicker wants 0
  const firstDayOfWeek = firstDayOfWeekLocale > 6 ? 0 : firstDayOfWeekLocale;

  return (
    <>
      <div className="filter-item-search">
        <div className="fields-group">
          <DayPicker
            className="day-picker-range"
            firstDayOfWeek={firstDayOfWeek}
            initialMonth={initialMonth}
            fromMonth={fromMonth}
            toMonth={toMonth}
            selectedDays={[range.from, range]}
            // Give specific classes to start and end days for styling
            modifiers={{ start: range.from, end: range.to }}
            // todayButton="Today"
            onDayClick={(day) => handleDayClick(day)}
          />
        </div>
      </div>
      <div className="filter-item-form">
        <div className="fields-group">
          <div className="fields-group-options-list">
            <p>
              {!range.from && !range.to && 'Please select the first day.'}
              {range.from && !range.to && 'Please select the last day.'}
              {range.from && range.to
              && `Selected from ${range.from.toLocaleDateString()} to
                    ${range.to.toLocaleDateString()}`}
            </p>
          </div>
        </div>
      </div>
      {renderAcceptButton(() => onAcceptClick({ name: filterName, value: range }))}
    </>
  );
};
