import { toTitleCase, isEmpty, collapseObjToArray } from '../../../core/utils/core.utils';
import { mainViewCfg } from '../config';
import { NO_DATA_MESSAGE } from '../../hospiscope/components/HospitalsData/hospitalsData.constants';

import { exportFiltersInfo, sortCountryAndYearFirst } from '../../../core/utils/excelExport.utils';

const EXCEL_EXPORT_HEADER_TITLE = 'PriceScope, by GHI';

const rowLabels = {};
Object.entries(mainViewCfg.colLabels).forEach(([k, v]) => rowLabels[k.toLowerCase()] = v);

export const prepareFiltersInfoForExport = (filterValues) => {
  const formattedFilters = [];

  const addDataRow = (firstCol, secondCol) => {
    formattedFilters.push([
      { value: rowLabels[firstCol] || toTitleCase(firstCol), style: { font: { bold: true } } },
      { value: secondCol },
    ]);
  };

  Object.keys(filterValues).sort(sortCountryAndYearFirst).map((key) => {
    const filterObj = filterValues[key];

    if (/^(number|bigint|boolean|string)$/.test(typeof filterObj)
    && !isEmpty(filterObj)) {
      addDataRow(key, filterObj);
    } else if (filterObj?.constructor === Object) {
      Object.entries(filterObj).forEach(([k, v]) => {
        addDataRow(k, collapseObjToArray(v).join(', '));
      });
    } else if (filterObj?.constructor === Array && filterObj.length) {
      const namesLabel = rowLabels[key] ? rowLabels[key] : toTitleCase(key);
      const names = [];

      filterObj.forEach((val) => {
        names.push(val || NO_DATA_MESSAGE);
      });

      addDataRow(namesLabel, names.join(', '));
    }
    return undefined;
  });

  return exportFiltersInfo(EXCEL_EXPORT_HEADER_TITLE, formattedFilters);
};

export const prepareMainViewRowsForExcel = (mainViewRows, transpose) => {
  // Get all column [key, label, json_col], including embeded json
  const headers = Object.entries(mainViewCfg.exportCols)
    .reduce((result, [key, val]) => {
      if (!val.json) {
        return result.concat([[key, val.label, false]]);
      } if (mainViewRows.length) {
        return result.concat(Object.keys(mainViewRows[0][key])
          .map((jkey) => [jkey, toTitleCase(jkey), key]));
      } return result;
    }, []);

  let columns = [];
  let data = [];
  if (!transpose) {
    columns = headers.map(([key, label, json_col]) => (
      { title: label, width: { wch: 24 }, style: { font: { bold: true } } }));

    data = mainViewRows
      .map((row) => {
        return headers.map(([key, label, json_col]) => {
          const value = !json_col ? row[key] : row[json_col][key];
          return {
            value: value || NO_DATA_MESSAGE,
          };
        });
      });
  } else {
    // Transpose single row to list field and value vertically
    columns = [{ title: 'Field', width: { wch: 24 }, style: { font: { bold: true } } },
      { title: 'Value', width: { wch: 24 } }];

    const row = mainViewRows[0];
    data = headers.map(([key, label, json_col]) => {
      const value = !json_col ? row[key] : row[json_col][key];
      return [{ value: label, style: { font: { bold: true } } }, { value: value || NO_DATA_MESSAGE }];
    });
  }

  return [{ columns, data }];
};
