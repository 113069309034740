import axios from 'axios';

import { LocalStorageService } from './localStorage.service';
import { FIREBASE_TOKEN_NAME } from './firebase.service';

import { AVAILABLE_DATA_SOURCES, DATA_SOURCE_HEADER_NAME } from '../constants/core.constants';

export const axiosService = axios.create();

export const predictedDataService = axios.create();

const FIREBASE_EXPIRED_TOKEN_ERROR = 'Firebase token is expired.';

// Request interceptor
axiosService.interceptors.request.use(
  (config) => {
    const requestConfig = config;
    // Setting auth header and data source (for db table) header
    requestConfig.headers.Authorization = LocalStorageService.get(FIREBASE_TOKEN_NAME);
    requestConfig.headers[DATA_SOURCE_HEADER_NAME] = AVAILABLE_DATA_SOURCES.ORIGINAL;
    return requestConfig;
  },
  (error) => Promise.reject(error),
);

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data === FIREBASE_EXPIRED_TOKEN_ERROR) {
      return window.location.reload();
    }
    return Promise.reject(error);
  },
);

predictedDataService.interceptors.request.use(
  (config) => {
    const requestConfig = config;
    // Setting auth header and data source (for db table) header
    requestConfig.headers.Authorization = LocalStorageService.get(FIREBASE_TOKEN_NAME);
    requestConfig.headers[DATA_SOURCE_HEADER_NAME] = AVAILABLE_DATA_SOURCES.PREDICTED;
    return requestConfig;
  },
  (error) => Promise.reject(error),
);

predictedDataService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data === FIREBASE_EXPIRED_TOKEN_ERROR) {
      return window.location.reload();
    }
    return Promise.reject(error);
  },
);
