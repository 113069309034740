import React from 'react';
import {
  Card, Divider, Grid, Image,
} from 'semantic-ui-react';

const containers = ['hospiscope', 'surgiscope', 'labscope', 'pricescope'].reduce((acc, container) => {
  acc[container] = require(`../../assets/${container}-container-img.png`); return acc;
}, {});

export const HomePage = () => {
  return (
    <div className="container main homepage">
      <Divider hidden />
      <Grid columns={2} centered stackable container>
        {Object.entries(containers).map(([name, img]) => (
          <Grid.Column key={name}>
            <Card centered raised link href={`/${name}`}>
              <Image src={img} wrapped ui={false} />
            </Card>
          </Grid.Column>
        ))}
      </Grid>
      <Divider hidden />
    </div>
  );
};
