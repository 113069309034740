import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import {
  Dimmer, Grid, Loader, Modal,
} from 'semantic-ui-react';

import { LabscopeServiceApiProvider } from '../../../services/LabscopeServiceApiProvider';

import { Header } from './LaboratoryModalContent/Header';
import { PdfContent } from './LaboratoryModalContent/PdfContent';
import { FieldTable } from './LaboratoryModalContent/FieldTable';
import { EquipmentFieldTable } from './LaboratoryModalContent/EquipmentFieldTable';

import { laboratoriesDataSelector } from '../../../store/laboratoriesData/laboratoriesData.selector';
import { filtersSelector } from '../../../store/filters/filters.selector';

import { createPDF, drawCanvas } from '../../../../../core/utils/core.utils';
import { renderModalActionButton } from '../utils/modals.utils';
import { getModalTableFields } from '../utils/laboratoryModal.utils';

import { FILTERS } from '../../../constants/filters.constants';
import { TOAST_CONSTANTS } from '../../../../../core/constants/core.constants';
import { LABORATORY_PDF_SELECTORS } from '../constants/pdfSelectors.constants';

const LOADER_TEXT = 'Loading...';

export const LaboratoryModalComponent = ({
  open,
  selectedLaboratory,
  onClose,
}) => {
  const formattedLaboratory = selectedLaboratory;
  const [isLoading, setIsLoading] = useState(false);
  const [detailedLabInfo, setDetailedLabInfo] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await LabscopeServiceApiProvider.getAdditionalLaboratoryData({
        id: formattedLaboratory.id,
        year: formattedLaboratory.year,
      });
      setDetailedLabInfo({ ...formattedLaboratory, details: response.data });
      setIsLoading(false);
    }
    if (formattedLaboratory) {
      fetchData();
    }
  }, [formattedLaboratory]);

  async function getPDF() {
    try {
      const pdfItemsSelectors = Object.values(LABORATORY_PDF_SELECTORS).map((selector) => `.${selector}`);
      const canvasList = await drawCanvas(pdfItemsSelectors);
      const pdfDOC = createPDF(canvasList);
      setIsLoading(false);
      pdfDOC.save(`${formattedLaboratory.name}.pdf`);
    } catch (e) {
      setIsLoading(false);
      toast.error(TOAST_CONSTANTS.PDF_ERROR);
    }
  }

  function getPdfHandler() {
    setIsLoading(true);
    /* hack fot html2canvas (it is blocking loader render) */
    setTimeout(getPDF, 0);
  }

  function renderModalContent() {
    return (
      <Modal open={open} className="hospital-modal" onClose={onClose}>
        <Dimmer active={isLoading}>
          <Loader>{LOADER_TEXT}</Loader>
        </Dimmer>
        <Modal.Header>
          <Header selectedLaboratory={detailedLabInfo} pdfAction={getPdfHandler} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedLaboratory={detailedLabInfo}
                  fields={getModalTableFields(FILTERS.GENERAL_INFORMATION)}
                  title="General information"
                  className="general-info"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedLaboratory={detailedLabInfo}
                  fields={getModalTableFields(FILTERS.ADMINISTRATIVE)}
                  title="Administrative"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <EquipmentFieldTable
                  selectedLaboratory={detailedLabInfo}
                  fields={getModalTableFields(FILTERS.EQUIPMENT)}
                  title="Equipment"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <FieldTable
                  selectedLaboratory={detailedLabInfo}
                  fields={getModalTableFields(FILTERS.PROCEDURES)}
                  title="Procedures"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {renderModalActionButton({ clickHandler: getPdfHandler, text: 'Get PDF', className: 'pdf' })}
        </Modal.Actions>
        <PdfContent selectedLaboratory={detailedLabInfo} />
      </Modal>
    );
  }
  return detailedLabInfo ? renderModalContent() : (
    <Dimmer active={isLoading}>
      <Loader>{LOADER_TEXT}</Loader>
    </Dimmer>
  );
};

const mapStateToProps = (state, props) => ({
  selectedLaboratory: laboratoriesDataSelector.selectedLaboratory(state, props.selectedLaboratoryId),
  filterValues: filtersSelector.filterValues(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export const LaboratoryModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LaboratoryModalComponent);
