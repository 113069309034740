import { Button } from 'semantic-ui-react';
import React from 'react';

export const renderModalActionButton = ({ clickHandler, text = 'Accept', className }) => (
  <div className={`${className} modal-action-button`}>
    <Button onClick={(e) => { e.stopPropagation(); clickHandler(); }}>{text}</Button>
  </div>
);

export const renderModalCancelButton = ({ clickHandler, text = 'Cancel', className }) => (
  <div className={`${className} modal-cancel-button`}>
    <Button onClick={(e) => { e.stopPropagation(); clickHandler(); }}>{text}</Button>
  </div>
);
