/* This action is separated because of the dependency cycle */
import { container } from '../../config';

export const REPLACE_ALL_FILTER_VALUES = `${container.name}/Filters/REPLACE ALL FILTER VALUES`;

export const replaceAllFilterValues = ({ filterValues }) => (dispatch) => {
  dispatch({
    type: REPLACE_ALL_FILTER_VALUES,
    payload: {
      filterValues,
    },
  });
};
