import React from 'react';

import { FILTER_ITEMS_PROPS } from './FilterItems/FilterItems';
import { FilterItemsFactory } from './FilterItems/FilterItemsFactory';

export const Filters = () => {
  return (
    <div className="filter-menu">
      {FILTER_ITEMS_PROPS.map((props) => <FilterItemsFactory {...props} />)}
    </div>
  );
};
