import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { GeneralInformation } from './GeneralInformation/GeneralInformation';
import { Beds } from './Beds/Beds';
import { Infrastructure } from './Infrastructure/Infrastructure';
import { Laboratory } from './Laboratory/Laboratory';
import { Technology } from './Technology/Technology';
// import { MedicalOffices } from './MedicalOffices/MedicalOffices';

import { FilterItem } from './shared/FilterItem';
import { FilterItemWrapper } from './shared/FilterItemWrapper';

import {
  performSearch as performSearchAction,
  setActiveFilter as setActiveFilterAction,
  setFilterValue as setFilterValueAction,
} from '../../../../common/store/filters/filters.actions';

import { filtersSelector } from '../../../../common/store/filters/filters.selector';

import { checkIfFilterIsEmpty } from '../utils/filters.utils';

import { FILTERS } from '../../../constants/filters.constants';

export const FilterItemsFactoryComponent = ({
  title,
  filterName,
  type,
  fieldsList,
  columnsAmount,
  filterValues,
  activeFilter,
  filterClassName,
  setFilterValue,
  performSearch,
  setActiveFilter,
  primary,
}) => {
  const [fields, setFields] = useState({});
  const handleFieldChange = ({ name, value }) => setFields({ ...fields, [name]: value });
  const setAllFields = (list = {}) => setFields(list);
  const clearFields = () => setFields({});
  const currentFilterValues = filterValues[filterName];
  const isFilterEmpty = checkIfFilterIsEmpty({ filterValue: currentFilterValues });

  useEffect(() => setAllFields(currentFilterValues), [currentFilterValues]);

  const onClear = () => {
    setFilterValue({ filterName, filterValue: {} });
    return clearFields();
  };

  const search = () => performSearch({ filterName, filterValue: fields });

  const filterItemWrapperProps = {
    filterName,
    filterClassName,
    isFilterEmpty,
    onClear,
    activeFilter,
    setActiveFilter,
    setFilterValue,
    title,
    search,
    fields,
  };
  function renderMenuItemContent() {
    const contentProps = { fields, handleFieldChange };
    switch (filterName) {
      case FILTERS.GENERAL_INFORMATION: {
        return <GeneralInformation {...contentProps} />;
      }
      case FILTERS.BEDS: {
        return <Beds {...contentProps} />;
      }
      case FILTERS.INFRASTRUCTURE: {
        return <Infrastructure {...contentProps} />;
      }
      // case FILTERS.MEDICAL_OFFICES: {
      //   return <MedicalOffices {...contentProps} />;
      // }
      case FILTERS.LABORATORY: {
        return <Laboratory {...contentProps} />;
      }
      case FILTERS.TECHNOLOGY: {
        return <Technology {...contentProps} />;
      }
      default: {
        return (
          <FilterItem
            {...contentProps}
            type={type}
            fieldsList={fieldsList}
            columnsAmount={columnsAmount}
            primary={primary}
          />
        );
      }
    }
  }
  return (
    <FilterItemWrapper {...filterItemWrapperProps}>
      {renderMenuItemContent()}
    </FilterItemWrapper>
  );
};

const mapStateToProps = (state) => ({
  activeFilter: filtersSelector.activeFilter(state),
  filterValues: filtersSelector.filterValues(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFilterValue: setFilterValueAction,
  setActiveFilter: setActiveFilterAction,
  performSearch: performSearchAction,
}, dispatch);

export const FilterItemsFactory = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterItemsFactoryComponent);
