import React from 'react';

import { generateFilterMenu } from '../../utils/filters.ui.utils';

import { GENERATED_ADMINISTRATIVE_MENU_ITEMS } from './administrative.constants';
import { FILTER_MENU_TYPES } from '../../../../constants/filters.constants';

export const AdministrativeComponent = ({
  fields, handleFieldChange,
}) => {
  return (
    <>
      <div className="fields-group fields-group--primary">
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: GENERATED_ADMINISTRATIVE_MENU_ITEMS,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: handleFieldChange,
        })}
      </div>
    </>
  );
};

export const Administrative = AdministrativeComponent;
