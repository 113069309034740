import React from 'react';

import { Grid } from 'semantic-ui-react';

import { FromToInputField } from '../shared/components';

import { generateFilterMenu } from '../../utils/filters.ui.utils';

import { INFRASTRUCTURE_MENU_ITEMS, GENERATED_INFRASTRUCTURE_MENU_ITEMS } from './infrastructure.constants';
import { FILTER_MENU_TYPES } from '../../../../constants/filters.constants';

const { TOTAL_ROOMS } = INFRASTRUCTURE_MENU_ITEMS;

export const InfrastructureComponent = ({
  fields, handleFieldChange,
}) => {
  return (
    <>
      <div className="fields-group fields-group--primary">
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <FromToInputField
                label={TOTAL_ROOMS.label}
                placeholder={TOTAL_ROOMS.placeholder}
                name={TOTAL_ROOMS.name}
                onChange={handleFieldChange}
                value={fields[TOTAL_ROOMS.name]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {generateFilterMenu({
          type: FILTER_MENU_TYPES.FROM_TO_INPUT,
          fieldsList: GENERATED_INFRASTRUCTURE_MENU_ITEMS.FIRST_SECTION,
          columnsAmount: 3,
          data: fields,
          onChangeHandler: ({ name, value }) => {
            handleFieldChange({ name, value });
          },
        })}
      </div>
    </>
  );
};

export const Infrastructure = InfrastructureComponent;
