import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import { Pagination } from '../../../common/Pagination';
import { LaboratoryModal } from '../modals/LaboratoryModal/LaboratoryModal';
import { LaboratoriesDataHeader } from './LaboratoriesDataHeader';
import { LaboratoriesTable } from './LaboratoriesTable/LaboratoriesTable';

import {
  getLaboratoriesList as getLaboratoriesListAction,
} from '../../store/laboratoriesData/laboratoriesData.actions';

import { laboratoriesDataSelector } from '../../store/laboratoriesData/laboratoriesData.selector';

import { getPaginationItemsCount } from '../../../../core/utils/core.utils';

const LaboratoriesTableComponent = ({
  isLoading,
  laboratoriesList,
  sortedBy,
  sortingDirection,
  pagination,
  getLaboratoriesList,
}) => {
  const [shouldShowLaboratoryModal, setShouldShowLaboratoryModal] = useState(false);
  const [selectedLaboratoryId, setSelectedLaboratoryId] = useState(null);
  const { count, currentPage } = pagination;

  function toggleLaboratoryModal({ open, id }) {
    setShouldShowLaboratoryModal(open);
    setSelectedLaboratoryId(id);
  }

  useEffect(() => {
    getLaboratoriesList({ currentPage: 1 });
  }, [getLaboratoriesList]);

  function onPageChange(e, { activePage }) {
    return getLaboratoriesList({ currentPage: activePage, sortedBy, sortingDirection });
  }

  return (
    <div className="container main">
      {isLoading ? <Loader active={isLoading} />
        : (
          <div className="hospitals-content">
            <LaboratoriesDataHeader count={pagination.count} />
            <LaboratoriesTable
              laboratoriesList={laboratoriesList}
              sortedBy={sortedBy}
              sortingDirection={sortingDirection}
              toggleLaboratoryModal={toggleLaboratoryModal}
              getLaboratoriesList={getLaboratoriesList}
            />
            {laboratoriesList.length ? (
              <Pagination
                currentPage={currentPage}
                totalPages={getPaginationItemsCount({ count })}
                onPageChange={onPageChange}
              />
            ) : null}
            <LaboratoryModal
              selectedLaboratoryId={selectedLaboratoryId}
              open={shouldShowLaboratoryModal}
              onClose={() => toggleLaboratoryModal({ open: false, id: null })}
            />
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: laboratoriesDataSelector.isLoading(state),
  laboratoriesList: laboratoriesDataSelector.laboratoriesList(state),
  sortedBy: laboratoriesDataSelector.sortedBy(state),
  sortingDirection: laboratoriesDataSelector.sortingDirection(state),
  pagination: laboratoriesDataSelector.pagination(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getLaboratoriesList: getLaboratoriesListAction,
}, dispatch);

export const LaboratoriesData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LaboratoriesTableComponent);
