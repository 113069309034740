import { FILTER_MENU_TYPES } from '../../../../hospiscope/constants/filters.constants';

import { FILTER_MENU_TITLES, FILTERS } from '../../../constants/filters.constants';
import { EQUIPMENT_INPUTS_LIST } from './Equipment/equipment.constants';
import { PROCEDURES_INPUTS_LIST } from './Procedures/procedures.constants';

export const FILTER_ITEMS_PROPS = [
  {
    key: FILTER_MENU_TITLES[FILTERS.GENERAL_INFORMATION],
    title: FILTER_MENU_TITLES[FILTERS.GENERAL_INFORMATION],
    filterName: FILTERS.GENERAL_INFORMATION,
    filterClassName: 'general-information-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.ADMINISTRATIVE],
    title: FILTER_MENU_TITLES[FILTERS.ADMINISTRATIVE],
    filterName: FILTERS.ADMINISTRATIVE,
    filterClassName: 'administrative-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.EQUIPMENT],
    title: FILTER_MENU_TITLES[FILTERS.EQUIPMENT],
    filterName: FILTERS.EQUIPMENT,
    type: FILTER_MENU_TYPES.FROM_TO_INPUT,
    fieldsList: EQUIPMENT_INPUTS_LIST,
    columnsAmount: 4,
    filterClassName: 'equipment-labscope-menu',
    primary: true,
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.PROCEDURES],
    title: FILTER_MENU_TITLES[FILTERS.PROCEDURES],
    filterName: FILTERS.PROCEDURES,
    type: FILTER_MENU_TYPES.FROM_TO_INPUT,
    fieldsList: PROCEDURES_INPUTS_LIST,
    columnsAmount: 3,
    filterClassName: 'procedures-labscope-menu',
    primary: true,
  },
];
