import React from 'react';

import { FullNameCell } from '../ClientsDasboard/components/ClientsTable/FullNameCell';

import { formatServerDate } from '../../../core/utils/core.utils';

import {
  CLIENT_NAME,
  CREATED_AT_FIELD_NAME,
  ACTIVE_TILL_FIELD_NAME,
  IS_ACTIVE_FIELD_NAME,
  USERS_AMOUNT_FIELD_NAME,
} from '../ClientsDasboard/constants/tableFields.constants';
import { ADMIN_NAME } from '../AdminsDashboard/constants/tableFields.constants';
import { USER_NAME } from '../ClientPage/components/Users/constants/tableFields.constants';

const ACTIVE_CLIENT_STRING_VALUE = 'Active';
const NOT_ACTIVE_CLIENT_STRING_VALUE = 'Not Active';

const NO_USERS_STRING_VALUE = 'No users';

export const getAdminTableCellContent = ({ name, value }) => {
  const TABLE_CELL_CONTENT = {
    [CLIENT_NAME]: () => <FullNameCell name={value} />,
    [ADMIN_NAME]: () => <FullNameCell name={value} />,
    [USER_NAME]: () => <FullNameCell name={value} />,
    [IS_ACTIVE_FIELD_NAME]: () => (value ? ACTIVE_CLIENT_STRING_VALUE : NOT_ACTIVE_CLIENT_STRING_VALUE),
    [USERS_AMOUNT_FIELD_NAME]: () => value || NO_USERS_STRING_VALUE,
    [CREATED_AT_FIELD_NAME]: () => formatServerDate(value),
    [ACTIVE_TILL_FIELD_NAME]: () => formatServerDate(value),
  };
  return TABLE_CELL_CONTENT[name] ? TABLE_CELL_CONTENT[name]() : value;
};
