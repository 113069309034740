import { API_BASE_URL } from '../../../core/services/CoreServiceConfigProvider';

const LABSCOPE = '/labscope';
const LABORATORIES = '/laboratories';
const LAB_DETAILS = '/lab-details';
const COUNTRIES_LIST_ENDPOINT = '/countries';
const FILTERING_OPTIONS_ENDPOINT = '/filteringOptions';
const CITIES_BY_COUNTRIES_LIST_ENDPOINT = '/cities';

export class LabscopeServiceConfigProvider {
  static getCountriesListEndpoint() {
    return `${API_BASE_URL}${LABSCOPE}${COUNTRIES_LIST_ENDPOINT}`;
  }

  static getFilteringOptionsEndpoint() {
    return `${API_BASE_URL}${LABSCOPE}${FILTERING_OPTIONS_ENDPOINT}`;
  }

  static getCitiesByCountriesAndCityNameEndpoint() {
    return `${API_BASE_URL}${LABSCOPE}${CITIES_BY_COUNTRIES_LIST_ENDPOINT}`;
  }

  static getLaboratoriesListEndpoint() {
    return `${API_BASE_URL}${LABSCOPE}${LABORATORIES}`;
  }

  static getAdditionalLaboratoryData({ id, year }) {
    return `${API_BASE_URL}${LABSCOPE}${LAB_DETAILS}/${id}?year=${year}`;
  }
}
