import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import { SurgiscopeDataHeader } from './SurgiscopeDataHeader';
import { Pagination } from '../../../common/Pagination';
import { ProceduresTable } from './ProceduresTable/ProceduresTable';
import { HospitalsListModal } from './HospitalsListModal/HospitalsListModal';

import {
  getProceduresList as getProceduresListAction,
  getHospitalsList as getHospitalsListAction,
  toggleHospitalsListModal as toggleHospitalsListModalAction,
} from '../../store/proceduresData/proceduresData.actions';

import { proceduresDataSelector } from '../../store/proceduresData/proceduresData.selector';

import { getPaginationItemsCount } from '../../../../core/utils/core.utils';

const ProceduresDataComponent = ({
  isLoading,
  proceduresList,
  pagination,
  shouldShowHospitalsModal,
  isHospitalsModalLoading,
  hospitalsList,
  selectedProcedureName,
  selectedProcedureCode,
  sortedBy,
  sortingDirection,
  getProceduresList,
  getHospitalsList,
  toggleHospitalsListModal,
}) => {
  const { count, currentPage } = pagination;

  useEffect(() => {
    getProceduresList({ currentPage: 1 });
  }, [getProceduresList]);

  const getProceduresDataHeaderProps = () => ({ items: proceduresList, count });

  const onPageChange = (e, { activePage }) => {
    return getProceduresList({ currentPage: activePage, sortedBy, sortingDirection });
  };

  return (
    <div className="container main">
      {isLoading ? <Loader active={isLoading} /> : (
        <div className="proceduresPage-content">
          <SurgiscopeDataHeader {...getProceduresDataHeaderProps()} />
          <ProceduresTable
            proceduresList={proceduresList}
            sortedBy={sortedBy}
            sortingDirection={sortingDirection}
            getProceduresList={getProceduresList}
            getHospitalsList={getHospitalsList}
          />
          {proceduresList.length ? (
            <Pagination
              currentPage={currentPage}
              totalPages={getPaginationItemsCount({ count })}
              onPageChange={onPageChange}
            />
          ) : null}
          {shouldShowHospitalsModal ? (
            <HospitalsListModal
              open={shouldShowHospitalsModal}
              isLoading={isHospitalsModalLoading}
              selectedProcedureName={selectedProcedureName}
              selectedProcedureCode={selectedProcedureCode}
              hospitalsList={hospitalsList}
              onClose={() => toggleHospitalsListModal({ shouldShowHospitalsModal: false })}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: proceduresDataSelector.isLoading(state),
  proceduresList: proceduresDataSelector.proceduresList(state),
  pagination: proceduresDataSelector.pagination(state),
  shouldShowHospitalsModal: proceduresDataSelector.shouldShowHospitalsModal(state),
  isHospitalsModalLoading: proceduresDataSelector.isHospitalsModalLoading(state),
  hospitalsList: proceduresDataSelector.hospitalsList(state),
  selectedProcedureName: proceduresDataSelector.selectedProcedureName(state),
  selectedProcedureCode: proceduresDataSelector.selectedProcedureCode(state),
  sortedBy: proceduresDataSelector.sortedBy(state),
  sortingDirection: proceduresDataSelector.sortingDirection(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProceduresList: getProceduresListAction,
  getHospitalsList: getHospitalsListAction,
  toggleHospitalsListModal: toggleHospitalsListModalAction,
}, dispatch);

export const ProceduresData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProceduresDataComponent);
