import React from 'react';
import { Form } from 'semantic-ui-react';

export const CheckboxField = ({
  label, name, checked, onChange, className = '',
}) => {
  return (
    <div className={`checkbox-field-container input-group-container ${className}`}>
      <Form.Field className="checkbox-field-inner">
        <Form.Checkbox
          onChange={(e, data) => onChange({
            name: data.name,
            value: data.checked,
          })}
          label={label}
          name={name}
          checked={checked}
        />
      </Form.Field>
    </div>
  );
};
