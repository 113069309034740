import { NO_DATA_MESSAGE } from '../../HospitalsData/hospitalsData.constants';
import { FLATTEN_HOSPITALS_DATA_FIELDS_LIST } from '../../../constants/hospitals.constants';
import { CALCULATED_DATA_ROWS } from '../../HospitalsData/HospitalsCalculatedData/constants/calculatedData.constants';

import { handlePhoneCellContent } from '../../HospitalsData/hospitalsData.utils';

export const replaceOriginalHospitalNullFields = (originalHospital, predictedHospital) => {
  const newHospitalObject = {};
  const predictedKeys = [];
  Object.keys(originalHospital).forEach((key) => {
    if (originalHospital[key] === null && predictedHospital[key]) {
      newHospitalObject[key] = predictedHospital[key];
      predictedKeys.push(key);
    } else {
      newHospitalObject[key] = originalHospital[key];
    }
  });
  return { ...newHospitalObject, predictedKeys };
};

export const mixOriginalAndPredictedData = ({ original, predicted }) => {
  return original.map((hospital) => {
    const matchHospital = predicted.find((predictedHospital) => {
      return predictedHospital.name === hospital.name
        && (+predictedHospital.year === +hospital.year || predictedHospital.year === 'YTD');
    });
    return matchHospital ? replaceOriginalHospitalNullFields(hospital, matchHospital) : hospital;
  });
};

export const formatCellValue = (hospital, cell) => {
  const PHONE_KEY = 'phone';
  if (cell.key === PHONE_KEY) {
    return handlePhoneCellContent(hospital[cell.key]);
  }
  return hospital[cell.key] || hospital[cell.key] === 0 ? `${hospital[cell.key]}` : NO_DATA_MESSAGE;
};

export const prepareHospitalsListForExcel = (hospitalsList) => {
  return [{
    columns: FLATTEN_HOSPITALS_DATA_FIELDS_LIST.map((cell) => cell.label),
    data: hospitalsList.map((hospital) => {
      return FLATTEN_HOSPITALS_DATA_FIELDS_LIST.map((cell) => {
        return {
          value: formatCellValue(hospital, cell),
          style: {
            font: {
              sz: '14',
              color: {
                rgb: hospital.predictedKeys && hospital.predictedKeys.indexOf(cell.key) !== -1 ? 'FFFF0000' : '',
              },
            },
          },
        };
      });
    }),
  }];
};

export const prepareCalculatedDataForExcel = (columns, data, isPredicted) => {
  const uncoloredLabels = CALCULATED_DATA_ROWS.map((row) => row.label);
  return [{
    columns,
    data: data.map((arr) => {
      return arr.map((item) => {
        return {
          value: item,
          style: {
            font: {
              sz: '14',
              color: {
                rgb: isPredicted && uncoloredLabels.indexOf(item) === -1 ? 'FFFF0000' : '',
              },
            },
          },
        };
      });
    }),
  }];
};
