import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Loader } from 'semantic-ui-react';

import { history } from '../../../../core/routing/history';

import { ClientInfo } from './ClientInfo';
import { ClientCountriesPicker } from './ClientCountriesPicker/ClientCountriesPicker';
import { Users } from './Users/Users';
import { AddUserModal } from '../modals/AddUserModal';

import {
  getAvailableCountries as getAvailableCountriesAction,
} from '../../../common/store/filters/filters.actions';
import {
  getClientInfo as getClientInfoAction,
  addUser as addUserAction,
  toggleClientStatus as toggleClientStatusAction,
  editClient as editClientAction,
  removeClient as removeClientAction,
  setClientCountries as setClientCountriesAction,
} from '../../store/clientPage/clientPage.actions';

import { filtersSelector } from '../../../common/store/filters/filters.selector';
import { clientPageSelector } from '../../store/clientPage/clientPage.selector';

import {
  ADD_USER_BUTTON_TEXT,
  BACK_BUTTON_TEXT,
  COUNTRY_PICKER_TEXTS,
  SURGISCOPE_COUNTRY_PICKER_TEXTS,
  LABSCOPE_COUNTRY_PICKER_TEXTS,
  PRICESCOPE_COUNTRY_PICKER_TEXTS,
  LOADER_TEXT,
} from '../constants/clientPage.constants';
import { MAIN_APP_CONTAINERS } from '../../../../core/constants/core.constants';

export const ClientPageComponent = ({
  isLoading,
  clientInfo,
  areAvailableCountriesLoading,
  getClientInfo,
  toggleClientStatus,
  editClient,
  removeClient,
  availableCountries,
  availableSurgiscopeCountries,
  availableLabscopeCountries,
  availablePricescopeCountries,
  getAvailableCountries,
  setClientCountries,
  addUser,
}) => {
  const { id } = useParams();
  const [shouldShowAddUserModal, setShouldShowAddUserModal] = useState(false);

  function addUserHandler({ user }) {
    setShouldShowAddUserModal(false);
    return addUser({ user, clientId: id });
  }

  function setClientCountriesHandler({ countries }, source) {
    return setClientCountries({
      id,
      countries: source === MAIN_APP_CONTAINERS.HOSPISCOPE ? countries : clientInfo.countries,
      surgiscopeCountries: source === MAIN_APP_CONTAINERS.SURGISCOPE ? countries : clientInfo.surgiscope_countries,
      labscopeCountries: source === MAIN_APP_CONTAINERS.LABSCOPE ? countries : clientInfo.labscope_countries,
      pricescopeCountries: source === MAIN_APP_CONTAINERS.PRICESCOPE ? countries : clientInfo.pricescope_countries,
    });
  }

  function getClientCountries() {
    return {
      countries: clientInfo.countries && clientInfo.countries.length
        ? clientInfo.countries : [],
      surgiscopeCountries: clientInfo.surgiscope_countries && clientInfo.surgiscope_countries.length
        ? clientInfo.surgiscope_countries : [],
      labscopeCountries: clientInfo.labscope_countries && clientInfo.labscope_countries.length
        ? clientInfo.labscope_countries : [],
      pricescopeCountries: clientInfo.pricescope_countries && clientInfo.pricescope_countries.length
        ? clientInfo.pricescope_countries : [],
    };
  }

  useEffect(() => {
    getAvailableCountries();
    getClientInfo({ id });
  }, [getAvailableCountries, getClientInfo, id]);

  return (
    <div className="client-page">
      <Dimmer active={isLoading}><Loader>{LOADER_TEXT}</Loader></Dimmer>
      {clientInfo ? (
        <div className="container main">
          <div className="client-page-header segment-block">
            <Button
              basic
              className="client-page-backButton"
              content={BACK_BUTTON_TEXT}
              icon="left chevron"
              onClick={history.goBack}
            />
            <Button
              className="client-page-addUser"
              onClick={() => setShouldShowAddUserModal(true)}
            >
              {ADD_USER_BUTTON_TEXT}
            </Button>
          </div>
          <div className="client-page-body">
            <div className="client-page-sidebar">
              <ClientInfo
                clientInfo={clientInfo}
                toggleClientStatus={toggleClientStatus}
                editClient={editClient}
                removeClient={removeClient}
              />
              <ClientCountriesPicker
                title={COUNTRY_PICKER_TEXTS.TITLE}
                loading={areAvailableCountriesLoading}
                availableCountries={availableCountries}
                clientCountries={getClientCountries().countries}
                setClientCountries={(data) => setClientCountriesHandler(data, MAIN_APP_CONTAINERS.HOSPISCOPE)}
              />
              <ClientCountriesPicker
                title={SURGISCOPE_COUNTRY_PICKER_TEXTS.TITLE}
                loading={areAvailableCountriesLoading}
                availableCountries={availableSurgiscopeCountries}
                clientCountries={getClientCountries().surgiscopeCountries}
                setClientCountries={(data) => setClientCountriesHandler(data, MAIN_APP_CONTAINERS.SURGISCOPE)}
              />
              <ClientCountriesPicker
                title={LABSCOPE_COUNTRY_PICKER_TEXTS.TITLE}
                loading={areAvailableCountriesLoading}
                availableCountries={availableLabscopeCountries}
                clientCountries={getClientCountries().labscopeCountries}
                setClientCountries={(data) => setClientCountriesHandler(data, MAIN_APP_CONTAINERS.LABSCOPE)}
              />
              <ClientCountriesPicker
                title={PRICESCOPE_COUNTRY_PICKER_TEXTS.TITLE}
                loading={areAvailableCountriesLoading}
                availableCountries={availablePricescopeCountries}
                clientCountries={getClientCountries().pricescopeCountries}
                setClientCountries={(data) => setClientCountriesHandler(data, MAIN_APP_CONTAINERS.PRICESCOPE)}
              />
            </div>
            <div className="client-page-main">
              <Users id={id} />
            </div>
          </div>
        </div>
      ) : null}
      {shouldShowAddUserModal ? (
        <AddUserModal
          open={shouldShowAddUserModal}
          onClose={() => setShouldShowAddUserModal(false)}
          addUser={addUserHandler}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: clientPageSelector.isLoading(state),
  clientInfo: clientPageSelector.clientInfo(state),
  areAvailableCountriesLoading: filtersSelector.areAvailableCountriesLoading(state),
  availableCountries: filtersSelector.availableCountries(state),
  availableSurgiscopeCountries: filtersSelector.availableSurgiscopeCountries(state),
  availableLabscopeCountries: filtersSelector.availableLabscopeCountries(state),
  availablePricescopeCountries: filtersSelector.availablePricescopeCountries(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAvailableCountries: getAvailableCountriesAction,
  setClientCountries: setClientCountriesAction,
  getClientInfo: getClientInfoAction,
  toggleClientStatus: toggleClientStatusAction,
  editClient: editClientAction,
  removeClient: removeClientAction,
  addUser: addUserAction,
}, dispatch);

export const ClientPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientPageComponent);
