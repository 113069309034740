import React from 'react';
import { toast } from 'react-toastify';

import { NoAccessToast } from '../../Toasts/NoAccessToast';

import { HospitalsServiceApiProvider } from '../../../hospiscope/services/HospitalsServiceApiProvider';
import { ProceduresServiceApiProvider } from '../../../surgiscope/services/ProceduresServiceApiProvider';
import { ServiceApiProvider as PricescopeServiceApiProvider } from '../../../pricescope/services/ServiceApiProvider';
import { LabscopeServiceApiProvider } from '../../../labscope/services/LabscopeServiceApiProvider';

import {
  getHospitalsCalculatedData,
  getHospitalsList,
} from '../../../hospiscope/store/hospitalsData/hospitalsData.actions';

import { filtersSelector } from './filters.selector';

import { FILTERS } from '../../../hospiscope/constants/filters.constants';
import { REQUEST_ERROR_MESSAGES } from '../../constants';
import { MAIN_APP_CONTAINERS, TOAST_CONSTANTS } from '../../../../core/constants/core.constants';

import { areFieldsDependentOnCountrySelected } from '../../../hospiscope/utils/hospitalsServiceDataFormatter';

export const GET_AVAILABLE_COUNTRIES_REQUEST = '[FILTERS] GET AVAILABLE COUNTRIES REQUEST';
export const GET_AVAILABLE_COUNTRIES_SUCCESS = '[FILTERS] GET AVAILABLE COUNTRIES SUCCESS';
export const GET_AVAILABLE_COUNTRIES_FAILURE = '[FILTERS] GET AVAILABLE COUNTRIES FAILURE';

export const GET_FILTERING_OPTIONS_REQUEST = '[FILTERS] GET FILTERING OPTIONS REQUEST';
export const GET_FILTERING_OPTIONS_SUCCESS = '[FILTERS] GET FILTERING OPTIONS SUCCESS';
export const GET_FILTERING_OPTIONS_FAILURE = '[FILTERS] GET FILTERING OPTIONS FAILURE';

export const GET_AVAILABLE_CITIES_SUCCESS = '[FILTERS] GET AVAILABLE CITIES SUCCESS';
export const GET_AVAILABLE_CITIES_REQUEST = '[FILTERS] GET AVAILABLE CITIES REQUEST';

export const GET_AVAILABLE_STATES_SUCCESS = '[FILTERS] GET AVAILABLE STATES SUCCESS';
export const GET_AVAILABLE_STATES_REQUEST = '[FILTERS] GET AVAILABLE STATES REQUEST';

export const SET_FILTER_VALUE = '[FILTERS] SET FILTER VALUE';
export const SET_ACTIVE_FILTER = '[FILTERS] SET ACTIVE FILTER';

export const CLEAR_FILTERS_DEPENDENT_ON_COUNTRY = '[FILTERS] CLEAR STATE AND CITY';
export const CLEAR_FILTERS = '[FILTERS] CLEAR FILTERS';

/* BAD FIX FOR GETTING COUNTRIES DEPENDING ON PAGE CONTEXT */
export const getAvailableHospiscopeCountries = () => async (dispatch) => {
  try {
    const countries = await HospitalsServiceApiProvider.getAvailableCountries();
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_SUCCESS,
      payload: {
        availableCountries: countries.data,
        availableSurgiscopeCountries: [],
        availablePricescopeCountries: [],
        availableLabscopeCountries: [],
      },
    });
  } catch (e) {
    if (REQUEST_ERROR_MESSAGES.indexOf(e.response?.data) !== -1) {
      toast(<NoAccessToast />, { autoClose: false });
    }
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_FAILURE,
    });
  }
};

export const getAvailableSurgiscopeCountries = () => async (dispatch) => {
  try {
    const surgiscopeCountries = await ProceduresServiceApiProvider.getAvailableCountries();
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_SUCCESS,
      payload: {
        availableCountries: [],
        availableSurgiscopeCountries: surgiscopeCountries.data,
        availablePricescopeCountries: [],
        availableLabscopeCountries: [],
      },
    });
  } catch (e) {
    if (REQUEST_ERROR_MESSAGES.indexOf(e.response.data) !== -1) {
      toast(<NoAccessToast />, { autoClose: false });
    }
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_FAILURE,
    });
  }
};

export const getAvailablePricescopeCountries = () => async (dispatch) => {
  try {
    const pricescopeCountries = await PricescopeServiceApiProvider.getAvailableCountries();
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_SUCCESS,
      payload: {
        availableCountries: [],
        availableSurgiscopeCountries: [],
        availablePricescopeCountries: pricescopeCountries.data,
        availableLabscopeCountries: [],
      },
    });
  } catch (e) {
    if (REQUEST_ERROR_MESSAGES.indexOf(e.response.data) !== -1) {
      toast(<NoAccessToast />, { autoClose: false });
    }
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_FAILURE,
    });
  }
};

export const getAvailableLabscopeCountries = () => async (dispatch) => {
  try {
    const labscopeCountries = await LabscopeServiceApiProvider.getAvailableCountries();
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_SUCCESS,
      payload: {
        availableCountries: [],
        availableSurgiscopeCountries: [],
        availablePricescopeCountries: [],
        availableLabscopeCountries: labscopeCountries.data,
      },
    });
  } catch (e) {
    if (REQUEST_ERROR_MESSAGES.indexOf(e.response.data) !== -1) {
      toast(<NoAccessToast />, { autoClose: false });
    }
    return dispatch({
      type: GET_AVAILABLE_COUNTRIES_FAILURE,
    });
  }
};

const MAP_CONTAINER_NAME_TO_REQUEST = {
  [MAIN_APP_CONTAINERS.HOSPISCOPE]: getAvailableHospiscopeCountries,
  [MAIN_APP_CONTAINERS.SURGISCOPE]: getAvailableSurgiscopeCountries,
  [MAIN_APP_CONTAINERS.PRICESCOPE]: getAvailablePricescopeCountries,
  [MAIN_APP_CONTAINERS.LABSCOPE]: getAvailableLabscopeCountries,
};

const MAP_CONTAINER_NAME_TO_API_PROVIDER = {
  [MAIN_APP_CONTAINERS.HOSPISCOPE]: HospitalsServiceApiProvider,
  [MAIN_APP_CONTAINERS.SURGISCOPE]: ProceduresServiceApiProvider,
  [MAIN_APP_CONTAINERS.PRICESCOPE]: PricescopeServiceApiProvider,
  [MAIN_APP_CONTAINERS.LABSCOPE]: LabscopeServiceApiProvider,
};

/* END */

export const getAvailableCountries = (currentPageContext) => async (dispatch) => {
  dispatch({
    type: GET_AVAILABLE_COUNTRIES_REQUEST,
  });
  if (!currentPageContext) {
    try {
      const countries = await HospitalsServiceApiProvider.getAvailableCountries();
      const surgiscopeCountries = await ProceduresServiceApiProvider.getAvailableCountries();
      const pricescopeCountries = await PricescopeServiceApiProvider.getAvailableCountries();
      const labscopeCountries = await LabscopeServiceApiProvider.getAvailableCountries();
      return dispatch({
        type: GET_AVAILABLE_COUNTRIES_SUCCESS,
        payload: {
          availableCountries: countries.data,
          availableSurgiscopeCountries: surgiscopeCountries.data,
          availablePricescopeCountries: pricescopeCountries.data,
          availableLabscopeCountries: labscopeCountries.data,
        },
      });
    } catch (e) {
      return dispatch({
        type: GET_AVAILABLE_COUNTRIES_FAILURE,
      });
    }
  }
  return dispatch(MAP_CONTAINER_NAME_TO_REQUEST[currentPageContext]());
};

export const getFilteringOptions = (countries) => async (dispatch) => {
  dispatch({
    type: GET_FILTERING_OPTIONS_REQUEST,
  });
  try {
    const filteringOptions = await HospitalsServiceApiProvider.getFilteringOptions({
      countries,
    });
    return dispatch({
      type: GET_FILTERING_OPTIONS_SUCCESS,
      payload: {
        filteringOptions: filteringOptions.data,
      },
    });
  } catch (e) {
    return dispatch({
      type: GET_FILTERING_OPTIONS_FAILURE,
    });
  }
};

export const getAvailableStates = ({
  countries,
  state,
  currentPageContext = MAIN_APP_CONTAINERS.HOSPISCOPE,
}) => async (dispatch) => {
  dispatch({
    type: GET_AVAILABLE_STATES_REQUEST,
  });
  const statesRequest = MAP_CONTAINER_NAME_TO_API_PROVIDER[currentPageContext].getStatesByCountriesAndStateName;
  const { data } = await statesRequest({ countries, state });
  return dispatch({
    type: GET_AVAILABLE_STATES_SUCCESS,
    payload: {
      availableStates: data,
    },
  });
};

export const getAvailableCities = ({
  countries,
  state,
  city,
  currentPageContext = MAIN_APP_CONTAINERS.HOSPISCOPE,
}) => async (dispatch) => {
  dispatch({
    type: GET_AVAILABLE_CITIES_REQUEST,
  });
  const citiesRequest = MAP_CONTAINER_NAME_TO_API_PROVIDER[currentPageContext].getCitiesByCountriesAndCityName;
  const { data } = await citiesRequest({
    countries,
    state,
    city,
  });
  return dispatch({
    type: GET_AVAILABLE_CITIES_SUCCESS,
    payload: {
      availableCities: data,
    },
  });
};

export const setFilterValue = ({ filterName, filterValue }) => (dispatch) => {
  return dispatch({
    type: SET_FILTER_VALUE,
    payload: {
      filterName,
      filterValue,
    },
  });
};

export const setActiveFilter = (activeFilter) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_FILTER,
    payload: {
      activeFilter,
    },
  });
};

export const clearFiltersDependentOnCountry = () => (dispatch, getState) => {
  const generalInformation = filtersSelector.filterValues(getState())[FILTERS.GENERAL_INFORMATION];
  if (areFieldsDependentOnCountrySelected(generalInformation)) {
    toast.info(TOAST_CONSTANTS.FIELDS_REMOVED);
  }
  return dispatch({
    type: CLEAR_FILTERS_DEPENDENT_ON_COUNTRY,
  });
};

export const clearFilters = () => (dispatch) => {
  return dispatch({
    type: CLEAR_FILTERS,
  });
};

export const performSearch = ({ filterName, filterValue, shouldClearFilters }) => (dispatch, getState) => {
  if (shouldClearFilters) {
    clearFilters()(dispatch);
  }
  setFilterValue({ filterName, filterValue })(dispatch);
  setActiveFilter(null)(dispatch);
  getHospitalsCalculatedData()(dispatch, getState);
  return getHospitalsList({ currentPage: 1 })(dispatch, getState);
};
