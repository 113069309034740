import React from 'react';
import { Pagination as PaginationComponent } from 'semantic-ui-react';

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <div className="hospitals-pagination pagination-wrapper">
      <PaginationComponent
        secondary
        activePage={currentPage}
        totalPages={totalPages}
        boundaryRange={1}
        onPageChange={onPageChange}
        firstItem={{
          'aria-label': 'First item',
          content: '⟨⟨',
          disabled: currentPage === 1,
        }}
        lastItem={{
          'aria-label': 'First item',
          content: '⟩⟩',
          disabled: currentPage === totalPages,
        }}
        nextItem={{
          'aria-label': 'Next item',
          content: '⟩',
          disabled: currentPage === totalPages,
        }}
        prevItem={{
          'aria-label': 'Previous item',
          content: '⟨',
          disabled: currentPage === 1,
        }}
      />
    </div>
  );
};
