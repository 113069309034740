export const KEY_PERSONNEL_INPUTS_LIST = [
  {
    label: 'Managing Director',
    placeholder: 'Type name',
    name: 'managingDirector',
  },
  {
    label: 'Operations director',
    placeholder: 'Type name',
    name: 'operationsDirector',
  },
  {
    label: 'Finance Director',
    placeholder: 'Type name',
    name: 'financeDirector',
  },
  {
    label: 'IT Director',
    placeholder: 'Type name',
    name: 'itDirector',
  },
  {
    label: 'Medical quality director',
    placeholder: 'Type name',
    name: 'medicalQualityDirector',
  },
  {
    label: 'Medical Director',
    placeholder: 'Type name',
    name: 'medicalDirector',
  },
  {
    label: 'Purchasing Director',
    placeholder: 'Type name',
    name: 'purchasingDirector',
  },
  {
    label: 'IT Manager',
    placeholder: 'Type name',
    name: 'itManager',
  },
  {
    label: 'Head of operating room',
    placeholder: 'Type name',
    name: 'operatingRoomHead',
  },
  {
    label: 'Head of ambulatory care',
    placeholder: 'Type name',
    name: 'ambulatoryCareHead',
  },
  {
    label: 'Head of admin',
    placeholder: 'Type name',
    name: 'adminHead',
  },
  {
    label: 'Head of admin email',
    placeholder: 'Type email',
    name: 'adminHeadEmail',
  },
  {
    label: 'Compliance manager',
    placeholder: 'Type name',
    name: 'complianceManager',
  },
  {
    label: 'Head nurse',
    placeholder: 'Type name',
    name: 'nurseHead',
  },
  {
    label: 'Marketing manager',
    placeholder: 'Type name',
    name: 'marketingManager',
  },
  {
    label: 'Legal representative',
    placeholder: 'Type name',
    name: 'legalRepresentative',
  },
];
