import { getLaboratoriesState } from '../laboratories.selector';
import { FILTERS } from '../../constants/filters.constants';

import {
  filtersSelector as hospitalsFiltersSelector,
} from '../../../common/store/filters/filters.selector';

export function getLaboratoriesFiltersState(state) {
  return getLaboratoriesState(state).filters;
}

export const filtersSelector = {
  filterValues(state) {
    return getLaboratoriesFiltersState(state).filterValues;
  },
  selectedCountries(state) {
    return getLaboratoriesFiltersState(state).filterValues[FILTERS.COUNTRIES];
  },
  searchValue(state) {
    return getLaboratoriesFiltersState(state).filterValues[FILTERS.SEARCH];
  },
  selectedYear(state) {
    return getLaboratoriesFiltersState(state).filterValues[FILTERS.YEAR];
  },
  activeFilter(state) {
    return getLaboratoriesFiltersState(state).activeFilter;
  },
  areFilteringOptionsLoading(state) {
    return getLaboratoriesFiltersState(state).areFilteringOptionsLoading;
  },
  filteringOptions(state) {
    return getLaboratoriesFiltersState(state).filteringOptions;
  },
  availableSurgiscopeCountries(state) {
    return getLaboratoriesFiltersState(state).availableSurgiscopeCountries;
  },
  areAvailableStatesLoading(state) {
    return getLaboratoriesFiltersState(state).areAvailableStatesLoading;
  },
  availableStates(state) {
    return getLaboratoriesFiltersState(state).availableStates;
  },
  areAvailableCitiesLoading(state) {
    return getLaboratoriesFiltersState(state).areAvailableCitiesLoading;
  },
  availableCities(state) {
    return getLaboratoriesFiltersState(state).availableCities;
  },
  areAvailableCountriesLoading(state) {
    return hospitalsFiltersSelector.areAvailableCountriesLoading(state);
  },
  availableCountries(state) {
    return hospitalsFiltersSelector.availableLabscopeCountries(state);
  },
};
