import React, { useState, useEffect } from 'react';
import { truncate } from 'lodash';

import { SearchInput } from './SearchInput';
import { SearchOption } from './SearchOption';
import { renderAcceptButton } from '../../../../hospiscope/components/Filters/utils/filters.ui.utils';

const SEARCH_INPUT_LABEL = 'Value to search';
const SEARCH_INPUT_PLACEHOLDER = 'Search for word to load options';
const SEARCH_INPUT_QUERY_DELAY = 500;
const SEARCH_INPUT_MIN_LEN = 3;
// const MIN_RESULTS_TO_SHOW = 50;
const MAX_RESULTS_TO_SHOW = 500;
// const LOAD_MORE = 'Load more';
const MAX_OPTION_LABEL_LENGTH = 128;

export const DynamicFilter = ({
  filterName,
  filterValue = [],
  items,
  onAcceptClick,
  setDynamicFilterValue,
  areFilteringOptionsDynamicLoading,
  getFilteringOptionsDynamic,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState(filterValue || []);
  // const [amountOfResultsToShow, setAmountOfResultsToShow] = useState(MIN_RESULTS_TO_SHOW);
  const [searchInputTimer, setSearchInputTimer] = useState(null);
  const formattedSearchQuery = searchQuery.toLowerCase();

  // Calls boolean testFun with a and b, returns the proper value for sort():
  // -1 if a only 'a' gives true with testFun, 1 if b, 0 if both
  const sortComparison = (a, b, testFun) => (testFun(b) ? 1 : 0) - (testFun(a) ? 1 : 0);

  const sortBelowLen = 40;

  // List of comparison functions.  Should return -1 if a goes before b, 1 if after, 0 if equal.
  const sortComparisons = [
    // Contains matchText (ignore case)
    (a, b) => sortComparison(a, b, (x) => x.toLowerCase().indexOf(formattedSearchQuery) >= 0),
    // Starts with a letter (including Latin alphabets)
    (a, b) => sortComparison(a, b, (x) => /^[a-z\u00C0-\u00F6\u00F8-\u017E]/i.test(x)),
    // Starts with a number
    (a, b) => sortComparison(a, b, (x) => /^\d/i.test(x)),
    // Is shorter than sortBelowLen
    (a, b) => sortComparison(a, b, (x) => x.length < sortBelowLen),
    // Finally, sort by the usual order, but localized (ignore case)
    (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
  ];

  // Runs comparisons in sortTests until a sorting difference is found
  const multiComparisonSort = (a, b) => {
    for (const comparison of sortComparisons) {
      const result = comparison(a, b);
      if (result !== 0) { return result; }
    }
    return 0;
  };

  const formattedItems = items && Object.keys(items).length ? Object.keys(items).sort(multiComparisonSort) : [];
  // const currentItems = formattedItems.slice(0, MIN_RESULTS_TO_SHOW);
  // const shouldShowLoadMoreButton = formattedItems.length > MIN_RESULTS_TO_SHOW && !searchQuery;

  const selectItem = (newItem) => {
    if (newItem.length >= SEARCH_INPUT_MIN_LEN
      && selectedItems.indexOf(newItem) !== -1) {
      return setSelectedItems((arr) => arr.filter((item) => item !== newItem));
    }
    return setSelectedItems((arr) => [...arr, newItem]);
  };

  const onSearchInputChange = (e, data) => setSearchQuery(data.value);

  const searchInputDelayedAction = () => {
    if (searchQuery.length >= SEARCH_INPUT_MIN_LEN) {
      setDynamicFilterValue({ filterName, searchQuery });
      getFilteringOptionsDynamic(filterName, filterValue, searchQuery);
    }
    setSearchInputTimer(null);
  };

  // useEffect(() => {
  //   getFilteringOptionsDynamic();
  // }, []);

  // Handle searchInput changes
  useEffect(() => {
    const delay = searchQuery === '' ? 0 : SEARCH_INPUT_QUERY_DELAY;

    clearTimeout(searchInputTimer);

    const newTimer = setTimeout(() => {
      searchInputDelayedAction();
    }, delay);

    setSearchInputTimer(newTimer);

    // Clean up the timer when the component unmounts or when searchQuery changes again
    return () => {
      clearTimeout(newTimer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const renderItemLabel = (item) => {
    const label = truncate(item, { length: MAX_OPTION_LABEL_LENGTH, separator: /,? +/ });
    const count = items ? items[item] : '';
    return `${label}: ${count}`;
  };

  const renderSearchOption = (item) => {
    return filterValue.indexOf(item) === -1 ? (
      <SearchOption
        key={item}
        label={renderItemLabel(item)}
        onChange={() => selectItem(item)}
      />
    ) : null;
  };

  // const renderItems = () => currentItems.map((item) => renderSearchOption(item));
  const renderItems = () => formattedItems.map((item) => renderSearchOption(item));

  // const renderFilteredItems = () => {
  //   const searchElements = formattedItems.filter((item) => {
  //     const formattedItem = item.toLowerCase();
  //     return formattedItem.indexOf(formattedSearchQuery) !== -1;
  //   });
  //   return searchElements.map((element) => renderSearchOption(element));
  // };

  const renderSelectedItems = () => {
    return filterValue.map((item) => {
      return (
        <SearchOption
          key={item}
          label={renderItemLabel(item)}
          checked={selectedItems.indexOf(item) !== -1}
          onChange={() => selectItem(item)}
        />
      );
    });
  };

  const renderOptions = () => {
    if (areFilteringOptionsDynamicLoading) {
      return (
        <>
          <p>Loading options...</p>
          {renderSelectedItems()}
        </>
      );
    }

    return (
      <>

        {searchQuery.length >= SEARCH_INPUT_MIN_LEN
          && !searchInputTimer && !formattedItems.length
          && <p>No matches found, please try another search term.</p>}
        {/* {searchQuery.length >= SEARCH_INPUT_MIN_LEN ? renderFilteredItems() : renderItems()} */}

        {renderSelectedItems()}
        {renderItems()}

        {formattedItems.length >= MAX_RESULTS_TO_SHOW
        && <p>Try a more specific search to get more results</p>}
        {/* {shouldShowLoadMoreButton && (
          <Button
            onClick={() => setAmountOfResultsToShow(amountOfResultsToShow + MIN_RESULTS_TO_SHOW)}
          >
            {LOAD_MORE}
          </Button>
        )} */}
      </>
    );
  };

  return (
    <>
      <div className="filter-item-search">
        <div className="fields-group">
          <SearchInput
            label={SEARCH_INPUT_LABEL}
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            value={searchQuery}
            onChange={onSearchInputChange}
          />
        </div>
      </div>
      <div className="filter-item-form">
        <div className="fields-group">
          <div className="fields-group-options-list">
            {renderOptions()}
          </div>
        </div>
      </div>
      {renderAcceptButton(() => onAcceptClick({ name: filterName, value: selectedItems }))}
    </>
  );
};
