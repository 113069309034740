import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

export const MultipleDropdownField = ({
  label,
  name,
  options,
  value,
  search,
  selection,
  placeholder,
  noResultsMessage,
  disabled,
  loading,
  onChange,
  onSearchChange,
}) => {
  const [dropdownValue, setDropdownValue] = useState(value || []);

  useEffect(() => {
    setDropdownValue(value || []);
  }, [value]);

  function onChangeHandler(e, data) {
    setDropdownValue(data.value);
    onChange({ name: data.name, value: data.value });
  }

  return (
    <div className="dropdown-field-container">
      <Form.Field className="dropdown-field-inner">
        <Form.Dropdown
          multiple
          label={label}
          name={name}
          options={options}
          value={dropdownValue}
          search={search}
          selection={selection}
          onChange={onChangeHandler}
          onSearchChange={onSearchChange}
          placeholder={placeholder}
          noResultsMessage={noResultsMessage}
          disabled={disabled}
          loading={loading}
        />
      </Form.Field>
    </div>
  );
};
