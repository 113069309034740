import React, { useState } from 'react';
import {
  Form, Input, Label, Modal,
} from 'semantic-ui-react';
import DayPicker from 'react-day-picker';

import { editClientValidationSchema, getYupErrorMessage } from '../../utils/validation.utils';
import { renderModalActionButton } from '../../../hospiscope/components/modals/utils/modals.utils';

import { EDIT_CLIENT_MODAL_FORM_FIELDS } from '../constants/modals.constans';
import { DATEPICKER_INPUT_FIELD_TYPE } from '../../../../core/constants/core.constants';

const MODAL_TEXT = 'Accept';
const ACTIVE_TILL_TEXT = 'Active till';

export const EditClientModal = ({
  open,
  data = {},
  headerText,
  onClose,
  submitAction,
  className = '',
}) => {
  const [formFields, setFormFields] = useState(data);
  const [formErrors, setFormErrors] = useState([]);
  const handleFieldChange = ({ name, value }) => {
    setFormErrors([]);
    setFormFields({ ...formFields, [name]: value });
  };
  async function onClientEdit() {
    try {
      await editClientValidationSchema.validate(formFields, { abortEarly: false });
      return submitAction({ client: formFields });
    } catch (e) {
      return setFormErrors(e.inner);
    }
  }
  const handleDayClick = (day, name) => handleFieldChange({ name, value: day.toISOString() });
  return (
    <Modal open={open} onClose={onClose} size="mini" className={`one-input-modal ${className}`}>
      {headerText ? (
        <Modal.Header>
          {headerText}
        </Modal.Header>
      ) : null}
      <Modal.Content>
        <Modal.Description>
          <>
            <div className="input-field-container input-group-container">
              <Form>
                {EDIT_CLIENT_MODAL_FORM_FIELDS.map(({
                  label, placeholder, name, type,
                }) => {
                  const error = getYupErrorMessage({ path: name, errors: formErrors });
                  return (
                    type === DATEPICKER_INPUT_FIELD_TYPE ? (
                      <div className="datepicker-wrapper">
                        <Label>{ACTIVE_TILL_TEXT}</Label>
                        <DayPicker
                          firstDayOfWeek={1}
                          month={formFields[name] ? new Date(formFields[name]) : null}
                          selectedDays={formFields[name] ? new Date(formFields[name]) : null}
                          onDayClick={(day) => handleDayClick(day, name)}
                        />
                      </div>
                    ) : (
                      <Form.Field className="input-field-inner">
                        <Form.Input
                          control={Input}
                          value={formFields[name] || ''}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e) => handleFieldChange(e.target)}
                          error={error}
                        />
                      </Form.Field>
                    )
                  );
                })}
              </Form>
            </div>
          </>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {renderModalActionButton({ clickHandler: onClientEdit, text: MODAL_TEXT })}
      </Modal.Actions>
    </Modal>
  );
};
