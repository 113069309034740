import { combineReducers } from 'redux';

import { filtersReducer } from './filters/filters.reducer';
import { proceduresDataReducer } from './proceduresData/proceduresData.reducer';
import { institutionsDataReducer } from './institutionsData/institutionsData.reducer';

export const proceduresReducer = combineReducers({
  filters: filtersReducer,
  proceduresData: proceduresDataReducer,
  institutionsData: institutionsDataReducer,
});
