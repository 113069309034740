import React, { useState } from 'react';
import { Button, Label } from 'semantic-ui-react';

import { CountriesModal } from '../../../../../core/components/modals/CountriesModal';

import { areStringArraysEqual } from '../../../../../core/utils/core.utils';

import { COUNTRY_PICKER_TEXTS } from '../../constants/clientPage.constants';

export const ClientCountriesPicker = ({
  title,
  loading,
  clientCountries,
  availableCountries,
  setClientCountries,
}) => {
  const [isCountriesModalOpened, setIsCountriesModalOpened] = useState(false);

  const submitSelectedCountries = (countriesList) => {
    setIsCountriesModalOpened(false);
    if (!areStringArraysEqual(clientCountries, countriesList)) {
      return setClientCountries({ countries: countriesList });
    }
    return false;
  };

  const renderSelectedCountriesList = () => {
    return (
      <div className="selected-countries-list">
        <Button onClick={() => setIsCountriesModalOpened(true)}>
          {clientCountries.length ? COUNTRY_PICKER_TEXTS.BUTTON_EDIT_TEXT : COUNTRY_PICKER_TEXTS.BUTTON_ADD_TEXT}
        </Button>
        {clientCountries.length ? clientCountries.map((country) => <Label key={country}>{country}</Label>) : null}
      </div>
    );
  };
  return (
    <div className="client-page-infoCard">
      {loading ? <div>{COUNTRY_PICKER_TEXTS.LOADER}</div> : (
        <>
          <div className="client-page-infoCard-title">
            <span>{title}</span>
          </div>
          <>
            <div className="countries-picker">
              {renderSelectedCountriesList()}
            </div>
            {isCountriesModalOpened ? (
              <CountriesModal
                open={isCountriesModalOpened}
                availableCountries={availableCountries}
                selectedCountries={clientCountries}
                onClose={() => setIsCountriesModalOpened(false)}
                onSubmit={submitSelectedCountries}
              />
            ) : null}
          </>
        </>
      )}
    </div>
  );
};
