import React from 'react';
import { Tab } from 'semantic-ui-react';

import { HospitalsTable } from '../HospitalsTable/HospitalsTable';

import { FILTER_MENU_TITLES } from '../../../constants/filters.constants';

export const generateHospitalsTabsPanes = ({
  hospitalsList,
  predictedHospitalsList,
  sortedBy,
  sortingDirection,
  toggleHospitalModal,
  getHospitalsList,
}) => {
  return Object.keys(FILTER_MENU_TITLES).map((filterName) => {
    return {
      menuItem: FILTER_MENU_TITLES[filterName],
      render: () => (
        <Tab.Pane attached={false} className="hospitals-data-tabs-pane">
          <HospitalsTable
            filterName={filterName}
            hospitalsList={hospitalsList}
            predictedHospitalsList={predictedHospitalsList}
            sortedBy={sortedBy}
            sortingDirection={sortingDirection}
            toggleHospitalModal={toggleHospitalModal}
            getHospitalsList={getHospitalsList}
          />
        </Tab.Pane>
      ),
    };
  });
};
