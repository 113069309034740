import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { SearchInput } from './SearchInput';
import { SearchOption } from './SearchOption';

import { renderAcceptButton } from '../../../../hospiscope/components/Filters/utils/filters.ui.utils';

const MIN_SEARCH_QUERY_LENGTH = 2;
const MIN_RESULTS_TO_SHOW = 50;
const SEARCH_INPUT_LABEL = 'Search items to filter';
const SEARCH_INPUT_PLACEHOLDER = `Start typing (min ${MIN_SEARCH_QUERY_LENGTH} characters)`;
const SEARCH_INPUT_MIN_LEN = 3;
const LOAD_MORE = 'Load more';

export const SelectOptionsFilter = ({
  items,
  filterName,
  filterValue = [],
  onAcceptClick,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState(filterValue || []);
  const [amountOfResultsToShow, setAmountOfResultsToShow] = useState(MIN_RESULTS_TO_SHOW);

  // Calls boolean testFun with a and b, returns the proper value for sort():
  // -1 if a only 'a' gives true with testFun, 1 if b, 0 if both
  const sortComparison = (a, b, testFun) => (testFun(b) ? 1 : 0) - (testFun(a) ? 1 : 0);

  const sortBelowLen = 80;

  // List of comparison functions.  Should return -1 if a goes before b, 1 if after, 0 if equal.
  const sortComparisons = [
    // Contains matchText (ignore case)
    // (a, b) => sortComparison(a, b, (x) => x.toLowerCase().indexOf(formattedSearchQuery) >= 0),
    // Starts with a letter (including Latin alphabets)
    (a, b) => sortComparison(a, b, (x) => /^[a-z\u00C0-\u00F6\u00F8-\u017E]/i.test(x)),
    // Starts with a number
    (a, b) => sortComparison(a, b, (x) => /^\d/i.test(x)),
    // Is shorter than sortBelowLen
    (a, b) => sortComparison(a, b, (x) => x.length < sortBelowLen),
    // Finally, sort by the usual order, but localized (ignore case)
    (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
  ];

  // Runs comparisons in sortTests until a sorting difference is found
  const multiComparisonSort = (a, b) => {
    for (const comparison of sortComparisons) {
      const result = comparison(a, b);
      if (result !== 0) { return result; }
    }
    return 0;
  };

  const formattedItems = items && items.length ? items.sort(multiComparisonSort) : [];
  // ? [...new Set(items.map((item) => `${item.name}${CODE_AND_NAME_DELIMITER}${item.code}`))] : [];

  const currentItems = formattedItems.slice(0, amountOfResultsToShow);

  const formattedSearchQuery = searchQuery.toLowerCase();

  const shouldShowLoadMoreButton = formattedItems.length > amountOfResultsToShow && !searchQuery;

  const selectItem = (newItem) => {
    if (newItem.length >= SEARCH_INPUT_MIN_LEN
      && selectedItems.indexOf(newItem) !== -1) {
      return setSelectedItems((arr) => arr.filter((item) => item !== newItem));
    }
    return setSelectedItems((arr) => [...arr, newItem]);
  };

  const onSearchInputChange = (e, data) => setSearchQuery(data.value);

  // const renderItemLabel = (item) => {
  //   const [name, code] = item.split(CODE_AND_NAME_DELIMITER);
  //   return `${name} (${code})`;
  // };

  const renderSearchOption = (item) => {
    return filterValue.indexOf(item) === -1 ? (
      <SearchOption
        key={item}
        label={item}
        onChange={() => selectItem(item)}
      />
    ) : null;
  };

  const renderItems = () => currentItems.map((item) => renderSearchOption(item));

  const renderFilteredItems = () => {
    const searchElements = formattedItems.filter((item) => {
      const formattedItem = item.toLowerCase();
      return formattedItem.indexOf(formattedSearchQuery) !== -1;
    });
    return searchElements.map((element) => renderSearchOption(element));
  };

  const renderSelectedItems = () => {
    return filterValue.map((item) => {
      return (
        <SearchOption
          key={item}
          label={item}
          checked={selectedItems.indexOf(item) !== -1}
          onChange={() => selectItem(item)}
        />
      );
    });
  };

  return (
    <>
      <div className="filter-item-search">
        <div className="fields-group">
          <SearchInput
            label={SEARCH_INPUT_LABEL}
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            value={searchQuery}
            onChange={onSearchInputChange}
          />
        </div>
      </div>
      <div className="filter-item-form">
        <div className="fields-group">
          <div className="fields-group-options-list">
            {renderSelectedItems()}
            {searchQuery.length >= MIN_SEARCH_QUERY_LENGTH ? renderFilteredItems() : renderItems()}
            {shouldShowLoadMoreButton ? (
              <Button
                onClick={() => setAmountOfResultsToShow(amountOfResultsToShow + MIN_RESULTS_TO_SHOW)}
              >
                {LOAD_MORE}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      {renderAcceptButton(() => onAcceptClick({ name: filterName, value: selectedItems }))}
    </>
  );
};
