import { axiosService } from './axios.service';
import { CoreServiceConfigProvider } from './CoreServiceConfigProvider';

export class CoreServiceApiProvider {
  static getUserInfo() {
    return axiosService.get(CoreServiceConfigProvider.getUserInfoEndpoint());
  }

  static getSavedSearches() {
    return axiosService.get(CoreServiceConfigProvider.getSavedSearchesEndpoint());
  }

  static getSurgiscopeSavedSearches() {
    return axiosService.get(CoreServiceConfigProvider.getSurgiscopeSavedSearchesEndpoint());
  }

  static getPricescopeSavedSearches() {
    return axiosService.get(CoreServiceConfigProvider.getPricescopeSavedSearchesEndpoint());
  }

  static getLabscopeSavedSearches() {
    return axiosService.get(CoreServiceConfigProvider.getLabscopeSavedSearchesEndpoint());
  }

  static saveSearch({ name, data, resultsCount }) {
    return axiosService({
      method: 'post',
      url: CoreServiceConfigProvider.getSavedSearchesEndpoint(),
      data: {
        name,
        data,
        results_count: resultsCount,
      },
    });
  }

  static saveSurgiscopeSearch({ name, data, resultsCount }) {
    return axiosService({
      method: 'post',
      url: CoreServiceConfigProvider.getSurgiscopeSavedSearchesEndpoint(),
      data: {
        name,
        data,
        results_count: resultsCount,
      },
    });
  }

  static savePricescopeSearch({ name, data, resultsCount }) {
    return axiosService({
      method: 'post',
      url: CoreServiceConfigProvider.getPricescopeSavedSearchesEndpoint(),
      data: {
        name,
        data,
        results_count: resultsCount,
      },
    });
  }

  static saveLabscopeSearch({ name, data, resultsCount }) {
    return axiosService({
      method: 'post',
      url: CoreServiceConfigProvider.getLabscopeSavedSearchesEndpoint(),
      data: {
        name,
        data,
        results_count: resultsCount,
      },
    });
  }

  static deleteSearch({ id }) {
    return axiosService({
      method: 'delete',
      url: CoreServiceConfigProvider.getDeleteSearchEndpoint({ id }),
    });
  }

  static deleteSurgiscopeSearch({ id }) {
    return axiosService({
      method: 'delete',
      url: CoreServiceConfigProvider.getDeleteSurgiscopeSearchEndpoint({ id }),
    });
  }

  static deletePricescopeSearch({ id }) {
    return axiosService({
      method: 'delete',
      url: CoreServiceConfigProvider.getDeletePricescopeSearchEndpoint({ id }),
    });
  }

  static deleteLabscopeSearch({ id }) {
    return axiosService({
      method: 'delete',
      url: CoreServiceConfigProvider.getDeleteLabscopeSearchEndpoint({ id }),
    });
  }
}
