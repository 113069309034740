import { FILTER_MENU_TYPES, FILTER_MENU_TITLES, FILTERS } from '../../../constants/filters.constants';

import {
  EQUIPMENT_INPUTS_LIST,
  SPECIALTIES_CHECKBOXES_LIST,
  // HR_INPUTS_LIST,
  KEY_PERSONNEL_INPUTS_LIST,
} from '../constants';

export const FILTER_ITEMS_PROPS = [
  {
    key: FILTER_MENU_TITLES[FILTERS.GENERAL_INFORMATION],
    title: FILTER_MENU_TITLES[FILTERS.GENERAL_INFORMATION],
    filterName: FILTERS.GENERAL_INFORMATION,
    filterClassName: 'general-information-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.BEDS],
    title: FILTER_MENU_TITLES[FILTERS.BEDS],
    filterName: FILTERS.BEDS,
    filterClassName: 'beds-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.INFRASTRUCTURE],
    title: FILTER_MENU_TITLES[FILTERS.INFRASTRUCTURE],
    filterName: FILTERS.INFRASTRUCTURE,
    filterClassName: 'infrastructure-menu',
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.LABORATORY],
    title: FILTER_MENU_TITLES[FILTERS.LABORATORY],
    filterName: FILTERS.LABORATORY,
    filterClassName: 'laboratory-menu',
  },
  // {
  //   key: FILTER_MENU_TITLES[FILTERS.MEDICAL_OFFICES],
  //   title: FILTER_MENU_TITLES[FILTERS.MEDICAL_OFFICES],
  //   filterName: FILTERS.MEDICAL_OFFICES,
  //   filterClassName: 'medical-offices-menu',
  // },
  {
    key: FILTER_MENU_TITLES[FILTERS.EQUIPMENT],
    title: FILTER_MENU_TITLES[FILTERS.EQUIPMENT],
    filterName: FILTERS.EQUIPMENT,
    type: FILTER_MENU_TYPES.FROM_TO_INPUT,
    fieldsList: EQUIPMENT_INPUTS_LIST,
    columnsAmount: 4,
    filterClassName: 'equipment-menu',
    primary: true,
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.SPECIALTIES],
    title: FILTER_MENU_TITLES[FILTERS.SPECIALTIES],
    filterName: FILTERS.SPECIALTIES,
    type: FILTER_MENU_TYPES.CHECKBOX,
    fieldsList: SPECIALTIES_CHECKBOXES_LIST,
    columnsAmount: 3,
    filterClassName: 'specialties-menu',
    primary: true,
  },
  {
    key: FILTER_MENU_TITLES[FILTERS.TECHNOLOGY],
    title: FILTER_MENU_TITLES[FILTERS.TECHNOLOGY],
    filterName: FILTERS.TECHNOLOGY,
    filterClassName: 'technology-menu',
  },
  // {
  //   key: FILTER_MENU_TITLES[FILTERS.HR],
  //   title: FILTER_MENU_TITLES[FILTERS.HR],
  //   filterName: FILTERS.HR,
  //   type: FILTER_MENU_TYPES.FROM_TO_INPUT,
  //   fieldsList: HR_INPUTS_LIST,
  //   columnsAmount: 3,
  //   filterClassName: 'hr-menu',
  //   primary: true,
  // },
  {
    key: FILTER_MENU_TITLES[FILTERS.KEY_PERSONNEL],
    title: FILTER_MENU_TITLES[FILTERS.KEY_PERSONNEL],
    filterName: FILTERS.KEY_PERSONNEL,
    type: FILTER_MENU_TYPES.INPUT,
    fieldsList: KEY_PERSONNEL_INPUTS_LIST,
    columnsAmount: 3,
    filterClassName: 'key-personnel-menu',
  },
];
