import React from 'react';
import { Table } from 'semantic-ui-react';

import { TABLE_HEADER_CELLS } from './HospitalsListTableHeader';

import { formatNumericalValue } from '../../../../../../core/utils/core.utils';

const NUMERICAL_CELL_KEY = 'sum';

export const HospitalsListTableBodyComponent = ({
  hospitalsList,
}) => {
  return (
    <Table.Body>
      {hospitalsList.map((hospital) => {
        const { hospital_id, hospital_name, sum } = hospital;
        const reactRowKey = `row-${hospital_id}-${hospital_name}-${sum}`;
        return (
          <Table.Row
            key={reactRowKey}
          >
            {TABLE_HEADER_CELLS.map((cell) => (
              <Table.Cell
                key={`${cell.key}-${hospital_id}-${hospital_name}`}
              >
                {cell.key === NUMERICAL_CELL_KEY ? formatNumericalValue(hospital[cell.key]) : hospital[cell.key]}
              </Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

export const HospitalsListTableBody = HospitalsListTableBodyComponent;
