export const isGeneralInformationValueFalsy = (value) => {
  if (!value) {
    return true;
  }
  return Array.isArray(value) && !value.length;
};

export const removeFalsyValuesFromObject = (obj, checkFunction) => {
  return Object.entries(obj).reduce((item, [key, value]) => {
    if (checkFunction) {
      return !checkFunction(value) ? { ...item, [key]: value } : item;
    }
    return value ? { ...item, [key]: value } : item;
  }, {});
};

export const formatCountriesList = (countries) => (countries.length ? countries : null);

export const formatGeneralInformation = (generalInformation) => {
  if (!generalInformation) {
    return null;
  }
  return removeFalsyValuesFromObject(generalInformation, isGeneralInformationValueFalsy);
};

export const areFieldsDependentOnCountrySelected = (generalInformation) => {
  if (!generalInformation) {
    return false;
  }
  const {
    affiliation, levelOfCare, typeOfEstablishment, state, city,
  } = generalInformation;
  const statesAndCitiesSelected = (state && state.length) || (city && city.length);
  const otherFieldsSelected = affiliation || levelOfCare || typeOfEstablishment;
  return statesAndCitiesSelected || otherFieldsSelected;
};

export const formatYearValue = (value) => {
  return value && value.length ? value : null;
};

export const formatFilterValues = (filterValues = {}) => {
  const {
    year,
    countries,
    search,
    generalInformation,
    beds,
    infrastructure,
    laboratory,
    // medicalOffices,
    equipment,
    specialties,
    technology,
    hr,
    keyPersonnel,
  } = filterValues;
  return {
    year: formatYearValue(year),
    countries: formatCountriesList(countries),
    name: search,
    generalInformation: formatGeneralInformation(generalInformation),
    beds,
    infrastructure,
    laboratory,
    // medicalOffices,
    equipment,
    specialties,
    technology,
    hr,
    keyPersonnel,
  };
};
