import {
  isGeneralInformationValueFalsy,
  removeFalsyValuesFromObject,
} from '../../hospiscope/utils/hospitalsServiceDataFormatter';
import { CODE_AND_NAME_DELIMITER } from '../components/Filters/FilterItems/shared/CommonFilter/CommonFilter';

export const formatValueToArray = (value) => (value ? [value] : null);

export const formatArrayValue = (value) => (value && value.length ? value : null);

export const formatNameAndCodeValue = (value) => {
  let nameValues = null;
  let codeValues = null;
  if (value && value.length) {
    nameValues = [];
    codeValues = [];
    for (let i = 0; i < value.length; i += 1) {
      const [name, code] = value[i].split(CODE_AND_NAME_DELIMITER);
      nameValues.push(name);
      codeValues.push(code);
    }
  }
  return { nameValues, codeValues };
};

export const formatInstitutionValues = (values) => {
  if (!values) {
    return null;
  }
  return removeFalsyValuesFromObject(values, isGeneralInformationValueFalsy);
};

export const formatFilterValues = (filterValues = {}) => {
  const {
    year,
    country,
    group,
    category,
    subcategory,
    procedure,
    institution,
  } = filterValues;
  const institutionValues = formatInstitutionValues(institution);
  const groupValues = formatNameAndCodeValue(group);
  const categoryValues = formatNameAndCodeValue(category);
  const subCategoryValues = formatNameAndCodeValue(subcategory);
  const procedureValues = formatNameAndCodeValue(procedure);
  return {
    year: formatValueToArray(year),
    country: formatValueToArray(country),
    group: groupValues.nameValues,
    group_code: groupValues.codeValues,
    category: categoryValues.nameValues,
    category_code: categoryValues.codeValues,
    subcategory: subCategoryValues.nameValues,
    subcategory_code: subCategoryValues.codeValues,
    procedure_name: procedureValues.nameValues,
    procedure_code: procedureValues.codeValues,
    institution: institutionValues || undefined,
  };
};

export const prepareFilterValuesForOptions = (filterValues = {}) => {
  const {
    group,
    category,
    subcategory,
    procedure,
    country,
    year,
  } = filterValues;
  const groupValues = formatNameAndCodeValue(group);
  const categoryValues = formatNameAndCodeValue(category);
  const subCategoryValues = formatNameAndCodeValue(subcategory);
  const procedureValues = formatNameAndCodeValue(procedure);
  return {
    groups: groupValues.nameValues,
    group_codes: groupValues.codeValues,
    categories: categoryValues.nameValues,
    category_codes: categoryValues.codeValues,
    subcategories: subCategoryValues.nameValues,
    subcategory_codes: subCategoryValues.codeValues,
    procedures: procedureValues.nameValues,
    procedure_codes: procedureValues.codeValues,
    country: formatValueToArray(country),
    year: formatValueToArray(year),
  };
};
