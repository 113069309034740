import React from 'react';

import { Header } from './Header';
import { FieldTable } from './FieldTable';
import { Logo } from '../../../../../../core/components/Logo/Logo';
import { EquipmentFieldTable } from './EquipmentFieldTable';

import { getModalTableFields } from '../../utils/laboratoryModal.utils';

import { FILTERS } from '../../../../constants/filters.constants';
import { LABORATORY_PDF_SELECTORS } from '../../constants/pdfSelectors.constants';

export const PdfContent = ({ selectedLaboratory }) => {
  const renderFooter = (page) => {
    return (
      <div className="pdf-footer">
        <Logo />
        <p className="page-number">{page}</p>
      </div>
    );
  };
  return (
    <div className="pdf-wrapper">
      <div className="pdf-content">
        <div className={`pdf-page ${LABORATORY_PDF_SELECTORS.FIRST_PAGE}`}>
          <Header selectedLaboratory={selectedLaboratory} />
          <div className="pdf-info-block">
            <FieldTable
              selectedLaboratory={selectedLaboratory}
              fields={getModalTableFields(FILTERS.GENERAL_INFORMATION)}
              title="General information"
              className="general-info"
              isPdfRow
            />
          </div>
          <div className="pdf-info-block">
            <FieldTable
              selectedLaboratory={selectedLaboratory}
              fields={getModalTableFields(FILTERS.ADMINISTRATIVE)}
              title="Administrative"
              isPdfRow
            />
          </div>
          {renderFooter('01')}
        </div>
        <div className={`pdf-page ${LABORATORY_PDF_SELECTORS.SECOND_PAGE}`}>
          <Header selectedLaboratory={selectedLaboratory} />
          <div className="pdf-info-block">
            <EquipmentFieldTable
              selectedLaboratory={selectedLaboratory}
              fields={getModalTableFields(FILTERS.EQUIPMENT)}
              title="Equipment"
              isPdfRow
            />
          </div>
          {renderFooter('02')}
        </div>
        <div className={`pdf-page ${LABORATORY_PDF_SELECTORS.THIRD_PAGE}`}>
          <Header selectedLaboratory={selectedLaboratory} />
          <div className="pdf-info-block">
            <FieldTable
              selectedLaboratory={selectedLaboratory}
              fields={getModalTableFields(FILTERS.PROCEDURES)}
              title="Procedures"
              isPdfRow
            />
          </div>
          {renderFooter('03')}
        </div>
      </div>
    </div>
  );
};
