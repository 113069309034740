import {
  GET_HOSPITALS_LIST_FAILURE,
  GET_HOSPITALS_LIST_REQUEST,
  GET_HOSPITALS_LIST_SUCCESS,
  GET_HOSPITALS_CALCULATED_DATA_FAILURE,
  GET_HOSPITALS_CALCULATED_DATA_REQUEST,
  GET_HOSPITALS_CALCULATED_DATA_SUCCESS,
} from './hospitalsData.actions';
import { CLEAR_STORE } from '../../../../core/user/user.actions';

import { HOSPITAL_NAME_FIELD, SORTING_DIRECTIONS } from '../../../../core/constants/core.constants';

export const initialState = {
  isLoading: false,
  hospitalsList: [],
  predictedHospitalsList: [],
  hospitalsCalculatedData: null,
  hospitalsPredictedCalculatedData: null,
  pagination: {
    currentPage: 1,
    count: 0,
  },
  sortedBy: HOSPITAL_NAME_FIELD,
  sortingDirection: SORTING_DIRECTIONS.ASC,
};

export function hospitalsDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HOSPITALS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_HOSPITALS_LIST_SUCCESS: {
      const {
        hospitalsList,
        predictedHospitalsList,
        count,
        currentPage,
        sortedBy,
        sortingDirection,
      } = action.payload;
      return {
        ...state,
        isLoading: false,
        hospitalsList: hospitalsList || [],
        predictedHospitalsList: predictedHospitalsList || [],
        pagination: {
          ...state.pagination,
          count,
          currentPage,
        },
        sortedBy,
        sortingDirection,
      };
    }
    case GET_HOSPITALS_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_HOSPITALS_CALCULATED_DATA_REQUEST:
      return {
        ...state,
      };
    case GET_HOSPITALS_CALCULATED_DATA_SUCCESS: {
      const {
        hospitalsCalculatedData,
        hospitalsPredictedCalculatedData,
      } = action.payload;
      return {
        ...state,
        hospitalsCalculatedData,
        hospitalsPredictedCalculatedData,
      };
    }
    case GET_HOSPITALS_CALCULATED_DATA_FAILURE: {
      return {
        ...state,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
