import {
  BOOLEAN_TABLE_CELL_CONTENT,
  OTHER_BOOLEAN_HOSPITAL_TABLE_KEYS,
  NO_DATA_MESSAGE,
  PHONE_KEY,
} from './hospitalsData.constants';
import { ROLE_DOWNLOAD_LIMIT } from '../../../../core/constants/core.constants';

export const handleBooleanCellContent = (value) => {
  if (!value) {
    return NO_DATA_MESSAGE;
  }
  return value === '1' ? BOOLEAN_TABLE_CELL_CONTENT : value;
};

export const handlePhoneCellContent = (value, delimiter = ',') => {
  if (!value) {
    return NO_DATA_MESSAGE;
  }
  return value?.telephones?.map((item) => item?.telephone).join(delimiter);
};

export const getTableCellContent = (value, key) => {
  if (key === PHONE_KEY) {
    return handlePhoneCellContent(value);
  }
  if (OTHER_BOOLEAN_HOSPITAL_TABLE_KEYS.indexOf(key) !== -1) {
    return handleBooleanCellContent(value);
  }
  if (!value) {
    return NO_DATA_MESSAGE;
  }
  if (typeof value === 'boolean') {
    return BOOLEAN_TABLE_CELL_CONTENT;
  }
  return value;
};

export const getMaxDownloadLimit = (role) => ROLE_DOWNLOAD_LIMIT[role];
