import { ProceduresServiceApiProvider } from '../../services/ProceduresServiceApiProvider';

import { getOffset } from '../../../../core/utils/core.utils';

import {
  DEFAULT_PAGE_LIMIT,
  INSTITUTION_NAME_FIELD,
  SORTING_DIRECTIONS,
} from '../../../../core/constants/core.constants';
import { filtersSelector } from '../filters/filters.selector';

export const GET_INSTITUTIONS_LIST_REQUEST = '[INSTITUTIONS] GET HOSPITALS LIST REQUEST';
export const GET_INSTITUTIONS_LIST_SUCCESS = '[INSTITUTIONS] GET HOSPITALS LIST SUCCESS';
export const GET_INSTITUTIONS_LIST_FAILURE = '[INSTITUTIONS] GET HOSPITALS LIST FAILURE';

export const TOGGLE_PROCEDURES_LIST_MODAL = '[INSTITUTIONS] TOGGLE PROCEDURES LIST MODAL';

export const GET_PROCEDURES_LIST_REQUEST = '[INSTITUTIONS] GET PROCEDURES LIST REQUEST';
export const GET_PROCEDURES_LIST_SUCCESS = '[INSTITUTIONS] GET PROCEDURES LIST SUCCESS';
export const GET_PROCEDURES_LIST_FAILURE = '[INSTITUTIONS] GET PROCEDURES LIST FAILURE';

export function getInstitutionsList({
  limit = DEFAULT_PAGE_LIMIT,
  currentPage,
  sortedBy = INSTITUTION_NAME_FIELD,
  sortingDirection = SORTING_DIRECTIONS.ASC,
}) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_INSTITUTIONS_LIST_REQUEST,
    });
    try {
      const filterValues = filtersSelector.filterValues(getState());
      const filterConfig = {
        limit,
        offset: getOffset({ currentPage }),
        filterValues,
        sortedBy,
        sortingDirection,
      };
      const { data } = await ProceduresServiceApiProvider.getInstitutionsList(filterConfig);
      return dispatch({
        type: GET_INSTITUTIONS_LIST_SUCCESS,
        payload: {
          institutionsList: data.hospitals,
          count: data.count,
          currentPage,
          sortedBy,
          sortingDirection,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_INSTITUTIONS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export function toggleProceduresListModal({
  shouldShowProceduresModal,
  selectedInstitutionId,
}) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_PROCEDURES_LIST_MODAL,
      payload: {
        shouldShowProceduresModal,
        selectedInstitutionId,
      },
    });
  };
}

export function getProceduresList({
  id,
  year,
  limit = DEFAULT_PAGE_LIMIT,
  offset = 0,
}) {
  return async (dispatch) => {
    dispatch({
      type: GET_PROCEDURES_LIST_REQUEST,
    });
    try {
      const { data } = await ProceduresServiceApiProvider.getProceduresListByHospitalId({
        id,
        year,
        limit,
        offset,
      });
      dispatch({
        type: GET_PROCEDURES_LIST_SUCCESS,
        payload: {
          proceduresList: data.procedures,
          proceduresCount: data.count,
        },
      });
      return dispatch(toggleProceduresListModal({
        shouldShowProceduresModal: true,
        selectedInstitutionId: id,
      }));
    } catch (error) {
      return dispatch({
        type: GET_PROCEDURES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}
