import { toast } from 'react-toastify';

import { LabscopeServiceApiProvider } from '../../services/LabscopeServiceApiProvider';
import { ProceduresServiceApiProvider } from '../../../surgiscope/services/ProceduresServiceApiProvider';

import { getLaboratoriesList } from '../laboratoriesData/laboratoriesData.actions';

import { filtersSelector } from './filters.selector';

import { FILTERS } from '../../constants/filters.constants';
import { MAIN_APP_CONTAINERS, TOAST_CONSTANTS } from '../../../../core/constants/core.constants';

import { areFieldsDependentOnCountrySelected } from '../../utils/laboratoriesServiceDataFormatter';

export const GET_AVAILABLE_COUNTRIES_REQUEST = '[LABSCOPE FILTERS] GET AVAILABLE COUNTRIES REQUEST';
export const GET_AVAILABLE_COUNTRIES_SUCCESS = '[LABSCOPE FILTERS] GET AVAILABLE COUNTRIES SUCCESS';
export const GET_AVAILABLE_COUNTRIES_FAILURE = '[LABSCOPE FILTERS] GET AVAILABLE COUNTRIES FAILURE';

export const GET_FILTERING_OPTIONS_REQUEST = '[LABSCOPE FILTERS] GET FILTERING OPTIONS REQUEST';
export const GET_FILTERING_OPTIONS_SUCCESS = '[LABSCOPE FILTERS] GET FILTERING OPTIONS SUCCESS';
export const GET_FILTERING_OPTIONS_FAILURE = '[LABSCOPE FILTERS] GET FILTERING OPTIONS FAILURE';

export const GET_AVAILABLE_CITIES_SUCCESS = '[LABSCOPE FILTERS] GET AVAILABLE CITIES SUCCESS';
export const GET_AVAILABLE_CITIES_REQUEST = '[LABSCOPE FILTERS] GET AVAILABLE CITIES REQUEST';

export const GET_AVAILABLE_STATES_SUCCESS = '[LABSCOPE FILTERS] GET AVAILABLE STATES SUCCESS';
export const GET_AVAILABLE_STATES_REQUEST = '[LABSCOPE FILTERS] GET AVAILABLE STATES REQUEST';

export const SET_FILTER_VALUE = '[LABSCOPE FILTERS] SET FILTER VALUE';
export const SET_ACTIVE_FILTER = '[LABSCOPE FILTERS] SET ACTIVE FILTER';

export const CLEAR_FILTERS_DEPENDENT_ON_COUNTRY = '[LABSCOPE FILTERS] CLEAR STATE AND CITY';
export const CLEAR_FILTERS = '[LABSCOPE FILTERS] CLEAR FILTERS';

const MAP_CONTAINER_NAME_TO_API_PROVIDER = {
  [MAIN_APP_CONTAINERS.HOSPISCOPE]: LabscopeServiceApiProvider,
  [MAIN_APP_CONTAINERS.SURGISCOPE]: ProceduresServiceApiProvider,
  [MAIN_APP_CONTAINERS.LABSCOPE]: ProceduresServiceApiProvider,
};

export const getFilteringOptions = (countries) => async (dispatch) => {
  dispatch({
    type: GET_FILTERING_OPTIONS_REQUEST,
  });
  try {
    const filteringOptions = await LabscopeServiceApiProvider.getFilteringOptions({
      countries,
    });
    return dispatch({
      type: GET_FILTERING_OPTIONS_SUCCESS,
      payload: {
        filteringOptions: filteringOptions.data,
      },
    });
  } catch (e) {
    return dispatch({
      type: GET_FILTERING_OPTIONS_FAILURE,
    });
  }
};

export const getAvailableCities = ({
  countries,
  state,
  city,
  currentPageContext = MAIN_APP_CONTAINERS.HOSPISCOPE,
}) => async (dispatch) => {
  dispatch({
    type: GET_AVAILABLE_CITIES_REQUEST,
  });
  const citiesRequest = MAP_CONTAINER_NAME_TO_API_PROVIDER[currentPageContext].getCitiesByCountriesAndCityName;
  const { data } = await citiesRequest({
    countries,
    state,
    city,
  });
  return dispatch({
    type: GET_AVAILABLE_CITIES_SUCCESS,
    payload: {
      availableCities: data,
    },
  });
};

export const setFilterValue = ({ filterName, filterValue }) => (dispatch) => {
  return dispatch({
    type: SET_FILTER_VALUE,
    payload: {
      filterName,
      filterValue,
    },
  });
};

export const setActiveFilter = (activeFilter) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_FILTER,
    payload: {
      activeFilter,
    },
  });
};

export const clearFiltersDependentOnCountry = () => (dispatch, getState) => {
  const generalInformation = filtersSelector.filterValues(getState())[FILTERS.GENERAL_INFORMATION];
  if (areFieldsDependentOnCountrySelected(generalInformation)) {
    toast.info(TOAST_CONSTANTS.FIELDS_REMOVED);
  }
  return dispatch({
    type: CLEAR_FILTERS_DEPENDENT_ON_COUNTRY,
  });
};

export const clearFilters = () => (dispatch) => {
  return dispatch({
    type: CLEAR_FILTERS,
  });
};

export const performSearch = ({ filterName, filterValue, shouldClearFilters }) => (dispatch, getState) => {
  if (shouldClearFilters) {
    clearFilters()(dispatch);
  }
  setFilterValue({ filterName, filterValue })(dispatch);
  setActiveFilter(null)(dispatch);
  return getLaboratoriesList({ currentPage: 1 })(dispatch, getState);
};
