import { toast } from 'react-toastify';

import { CoreServiceApiProvider } from '../services/CoreServiceApiProvider';

import { navigateToPage } from '../routing/history';

import {
  auth,
  firebaseApp,
  FIREBASE_TOKEN_NAME,
} from '../services/firebase.service';
import { LocalStorageService } from '../services/localStorage.service';

import { filtersSelector } from '../../containers/common/store/filters/filters.selector';
import {
  filtersSelector as surgiscopeFiltersSelector,
} from '../../containers/surgiscope/store/filters/filters.selector';
import {
  filtersSelector as pricescopeFiltersSelector,
} from '../../containers/pricescope/store/filters/filters.selector';
import {
  filtersSelector as labscopeFiltersSelector,
} from '../../containers/labscope/store/filters/filters.selector';

import { TOAST_CONSTANTS } from '../../containers/admin/constants/admin.constants';
import { MAIN_APP_CONTAINERS } from '../constants/core.constants';

export const LOGIN_REQUEST = '[USER] LOGIN REQUEST';
export const LOGIN_SUCCESS = '[USER] LOGIN SUCCESS';
export const LOGIN_FAILURE = '[USER] LOGIN FAILURE';

export const GET_SAVED_SEARCHES_REQUEST = '[USER] GET SAVED SEARCHES REQUEST';
export const GET_SAVED_SEARCHES_SUCCESS = '[USER] GET SAVED SEARCHES SUCCESS';
export const GET_SAVED_SEARCHES_FAILURE = '[USER] GET SAVED SEARCHES FAILURE';

export const SAVE_SEARCH_REQUEST = '[USER] SAVE SEARCH REQUEST';
export const SAVE_SEARCH_FAILURE = '[USER] SAVE SEARCH FAILURE';

export const DELETE_SEARCH_REQUEST = '[USER] DELETE SEARCH REQUEST';
export const DELETE_SEARCH_FAILURE = '[USER] DELETE SEARCH FAILURE';

export const VERIFY_AUTH_REQUEST = '[USER] VERIFY AUTH REQUEST';
export const VERIFY_AUTH_SUCCESS = '[USER] VERIFY AUTH SUCCESS';
export const VERIFY_AUTH_FAILURE = '[USER] VERIFY AUTH FAILURE';

export const CLEAR_STORE = '[USER] CLEAR STORE';

export const login = ({ email, password }) => async (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    await firebaseApp.auth().signInWithEmailAndPassword(email, password);
    const token = await firebaseApp.auth().currentUser.getIdToken();
    LocalStorageService.set(FIREBASE_TOKEN_NAME, token);
    const { data } = await CoreServiceApiProvider.getUserInfo();
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return navigateToPage('/');
  } catch (e) {
    toast.error(TOAST_CONSTANTS.FAILURE);
    return dispatch({
      type: LOGIN_FAILURE,
      payload: e,
    });
  }
};

export const logout = () => async (dispatch) => {
  await firebaseApp.auth().signOut();
  LocalStorageService.remove(FIREBASE_TOKEN_NAME);
  dispatch({
    type: CLEAR_STORE,
  });
  return navigateToPage('/login');
};

export const verifyAuth = () => (dispatch) => {
  dispatch({
    type: VERIFY_AUTH_REQUEST,
  });
  auth.onAuthStateChanged(async (user) => {
    if (user !== null) {
      const token = await user.getIdToken();
      LocalStorageService.set(FIREBASE_TOKEN_NAME, token);
      try {
        const { data } = await CoreServiceApiProvider.getUserInfo();
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            userData: data,
          },
        });
        dispatch({
          type: VERIFY_AUTH_SUCCESS,
        });
      } catch (e) {
        dispatch({
          type: VERIFY_AUTH_FAILURE,
        });
      }
    } else {
      dispatch({
        type: VERIFY_AUTH_FAILURE,
      });
    }
  });
};

const MAP_CONTAINER_NAME_TO_GET_REQUEST = {
  [MAIN_APP_CONTAINERS.HOSPISCOPE]: CoreServiceApiProvider.getSavedSearches,
  [MAIN_APP_CONTAINERS.SURGISCOPE]: CoreServiceApiProvider.getSurgiscopeSavedSearches,
  [MAIN_APP_CONTAINERS.PRICESCOPE]: CoreServiceApiProvider.getPricescopeSavedSearches,
  [MAIN_APP_CONTAINERS.LABSCOPE]: CoreServiceApiProvider.getLabscopeSavedSearches,
};

const MAP_CONTAINER_NAME_TO_SAVE_REQUEST = {
  [MAIN_APP_CONTAINERS.HOSPISCOPE]: CoreServiceApiProvider.saveSearch,
  [MAIN_APP_CONTAINERS.SURGISCOPE]: CoreServiceApiProvider.saveSurgiscopeSearch,
  [MAIN_APP_CONTAINERS.PRICESCOPE]: CoreServiceApiProvider.savePricescopeSearch,
  [MAIN_APP_CONTAINERS.LABSCOPE]: CoreServiceApiProvider.saveLabscopeSearch,
};

const MAP_CONTAINER_NAME_TO_DELETE_REQUEST = {
  [MAIN_APP_CONTAINERS.HOSPISCOPE]: CoreServiceApiProvider.deleteSearch,
  [MAIN_APP_CONTAINERS.SURGISCOPE]: CoreServiceApiProvider.deleteSurgiscopeSearch,
  [MAIN_APP_CONTAINERS.PRICESCOPE]: CoreServiceApiProvider.deletePricescopeSearch,
  [MAIN_APP_CONTAINERS.LABSCOPE]: CoreServiceApiProvider.deleteLabscopeSearch,
};

const getFiltersValueByAppContext = (context, state) => {
  const MAP_CONTAINER_NAME_TO_FILTERS_SELECTOR = {
    [MAIN_APP_CONTAINERS.HOSPISCOPE]: filtersSelector.filterValues,
    [MAIN_APP_CONTAINERS.SURGISCOPE]: surgiscopeFiltersSelector.filterValues,
    [MAIN_APP_CONTAINERS.PRICESCOPE]: pricescopeFiltersSelector.filterValues,
    [MAIN_APP_CONTAINERS.LABSCOPE]: labscopeFiltersSelector.filterValues,
  };
  return MAP_CONTAINER_NAME_TO_FILTERS_SELECTOR[context](state);
};

export const getSavedSearches = (currentPageContext = MAIN_APP_CONTAINERS.HOSPISCOPE) => async (dispatch) => {
  dispatch({
    type: GET_SAVED_SEARCHES_REQUEST,
  });
  try {
    const request = MAP_CONTAINER_NAME_TO_GET_REQUEST[currentPageContext];
    const { data } = await request();
    return dispatch({
      type: GET_SAVED_SEARCHES_SUCCESS,
      payload: {
        savedSearches: data.savedSearches,
      },
    });
  } catch (e) {
    return dispatch({
      type: GET_SAVED_SEARCHES_FAILURE,
    });
  }
};

export const saveSearch = ({
  name,
  resultsCount,
  currentPageContext = MAIN_APP_CONTAINERS.HOSPISCOPE,
}) => async (dispatch, getState) => {
  dispatch({
    type: SAVE_SEARCH_REQUEST,
  });
  try {
    const state = getState();
    const filterValues = getFiltersValueByAppContext(currentPageContext, state);
    const request = MAP_CONTAINER_NAME_TO_SAVE_REQUEST[currentPageContext];
    await request({ data: filterValues, name, resultsCount });
    toast.success(TOAST_CONSTANTS.SEARCH_SAVED);
    return getSavedSearches(currentPageContext)(dispatch);
  } catch (e) {
    return dispatch({
      type: SAVE_SEARCH_FAILURE,
    });
  }
};

export const deleteSearch = ({ id, currentPageContext }) => async (dispatch) => {
  dispatch({
    type: DELETE_SEARCH_REQUEST,
  });
  try {
    const request = MAP_CONTAINER_NAME_TO_DELETE_REQUEST[currentPageContext];
    await request({ id });
    toast.success(TOAST_CONSTANTS.SEARCH_REMOVED);
    return getSavedSearches(currentPageContext)(dispatch);
  } catch (e) {
    return dispatch({
      type: DELETE_SEARCH_FAILURE,
    });
  }
};
