import {
  GET_CLIENTS_LIST_REQUEST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAILURE, CREATE_CLIENT_REQUEST, CREATE_CLIENT_FAILURE,
} from './clientsDashboard.actions';
import { CLEAR_STORE } from '../../../../core/user/user.actions';

export const initialState = {
  isLoading: false,
  clientsList: [],
};

export function clientsDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENTS_LIST_SUCCESS: {
      const { clientsList } = action.payload;
      return {
        ...state,
        isLoading: false,
        clientsList: clientsList || [],
      };
    }
    case GET_CLIENTS_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CREATE_CLIENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CREATE_CLIENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_STORE: {
      return initialState;
    }
    default:
      return state;
  }
}
