import React from 'react';
import { Tab } from 'semantic-ui-react';

import { generateHospitalsTabsPanes } from './hospitalsTabs.ui.utils';

export const HospitalsTabs = ({
  activeTabIndex,
  hospitalsList,
  predictedHospitalsList,
  sortedBy,
  sortingDirection,
  setActiveTabIndex,
  toggleHospitalModal,
  getHospitalsList,
}) => (
  <Tab
    activeIndex={activeTabIndex}
    menu={{ secondary: true, pointing: true, className: 'hospitals-data-tabs-menu' }}
    panes={generateHospitalsTabsPanes({
      hospitalsList,
      predictedHospitalsList,
      sortedBy,
      sortingDirection,
      toggleHospitalModal,
      getHospitalsList,
    })}
    className="hospitals-data-tabs"
    onTabChange={(event, { activeIndex }) => setActiveTabIndex(activeIndex)}
  />
);
