export const INSTITUTION_MENU_ITEMS = {
  HOSPITAL_NAME: {
    label: 'Hospital Name',
    placeholder: 'Type here',
    name: 'hospital_name',
  },
  HOSPITAL_ID: {
    label: 'Hospital ID',
    placeholder: 'Type here',
    name: 'hospital_id',
  },
};
